import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/faq'
import * as faqAPI from './faqAPI'

const initialState = {
    faq: {},
    faqs: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    totalFaqs: 0
}

export const { createFaq, deleteFaq, getFaq, getFaqs, updateFaq } = faqAPI

const faqSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getFaq.pending, (state) =>
            Object.assign(state, {
                faq: {},
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getFaq.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                faq: toState(data.faq),
                isLoading: false
            })
        })
        builder.addCase(getFaqs.pending, (state) =>
            Object.assign(state, {
                faqs: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getFaqs.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                faqs: map(data.faqs, (item) => toState(item)),
                isLoading: false,
                totalFaqs: data.count
            })
        })
        builder.addMatcher(
            isAnyOf(createFaq.pending, deleteFaq.pending, updateFaq.pending),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createFaq.fulfilled,
                deleteFaq.fulfilled,
                updateFaq.fulfilled
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true
                })
        )
        builder.addMatcher(
            isAnyOf(createFaq.rejected, deleteFaq.rejected, updateFaq.rejected),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(getFaq.rejected, getFaqs.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'FAQ',
    reducers: {
        clearFaq: () => initialState
    }
})

export const { clearFaq } = faqSlice.actions

const { reducer } = faqSlice
export default reducer
