import { actionBlockOptions } from 'common/options'
import { statusOptions } from 'common/options/penerbit'

const filterConfig = [
    {
        name: 'email',
        placeholder: 'Cari Email',
        query: 'email',
        type: 'input'
    },
    {
        name: 'mobileNumber',
        placeholder: 'Cari Nomor Telepon Seluler',
        query: 'mobileNumber',
        type: 'input'
    },
    {
        name: 'entityName',
        placeholder: 'Cari Nama',
        query: 'entityName',
        type: 'input'
    },
    {
        name: 'isActive',
        options: actionBlockOptions,
        placeholder: 'Cari Pengguna Aktif/Tidak',
        query: 'isActive',
        type: 'select'
    },
    {
        name: 'status',
        options: statusOptions,
        placeholder: 'Cari Status KYC',
        query: 'status',
        type: 'select'
    }
]

export default filterConfig
