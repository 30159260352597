import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import * as inboxOptions from 'common/options/inbox'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import moment from 'moment'
import * as inboxDetailTransformer from './inboxDetail'
import * as pemodalTransformer from './pemodal'
import * as penerbitTransformer from './penerbit'
import * as productTransformer from './product'
import * as purchaseOrderTransformer from './purchaseOrder'

const setUser = (item, tableName) => {
    const checkUser = get(item, tableName, '')

    if (checkUser === 'pemodal')
        return Object.assign(pemodalTransformer.toState(item.pemodal), {
            fromTable: 'pemodal'
        })
    else if (checkUser === 'penerbit')
        return Object.assign(penerbitTransformer.toState(item.penerbit), {
            fromTable: 'penerbit'
        })
    else if (checkUser === 'admin_user') {
        const adminDetail = find(
            item.inboxDetails,
            (detail) => detail.createdByFromTable === 'admin_user'
        )

        const returnObj = {
            fromTable: 'admin_user',
            name: isEmpty(adminDetail) ? 'Admin' : adminDetail.createdBy
        }

        return isEmpty(adminDetail)
            ? Object.assign(returnObj, { username: 'admin' })
            : Object.assign(returnObj, { username: adminDetail.createdBy })
    }

    return item
}

export const toApi = (data) => {
    const inbox_detail = !isEmpty(data.inboxDetail)
        ? inboxDetailTransformer.toApi(data.inboxDetail)
        : undefined

    return {
        admin_user_id: get(data, 'adminUserId', undefined),
        created_at: get(data, 'createdAt', undefined),
        created_by: get(data, 'createdBy', undefined),
        entity_code: get(data, 'entityCode', undefined),
        from_table: get(data, 'fromTable', undefined),
        id: get(data, 'id', undefined),
        inbox_detail,
        status: get(data, 'status', undefined),
        to_table: get(data, 'toTable', undefined),
        to_user: get(data, 'toUser', undefined),
        updated_by: get(data, 'updatedBy', undefined)
    }
}

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const penerbit = !isEmpty(data.penerbit)
        ? penerbitTransformer.toState(data.penerbit)
        : {}
    const product = !isEmpty(data.product)
        ? productTransformer.toState(data.product)
        : {}
    const purchaseOrder = !isEmpty(data.purchaseOrder)
        ? purchaseOrderTransformer.toState(data.purchaseOrder)
        : {}

    let inboxDetails = !isEmpty(data.inboxDetails)
        ? map(data.inboxDetails, (item) => inboxDetailTransformer.toState(item))
        : []
    const lastMessage = get(last(inboxDetails), 'message', '')

    const sender = setUser(data, 'fromTable') || null
    const receiver = setUser(data, 'toTable') || null
    const statusOption = inboxOptions.inboxStatus(data, inboxDetails)
    const status = statusOption.value
    const senderName = get(sender, 'name', null)

    return {
        createdAt,
        fromTable: data.fromTable,
        id: data.id,
        lastMessage,
        penerbit,
        product,
        productId: data.productId,
        purchaseOrder,
        purchaseOrderId: data.purchaseOrderId,
        receiver,
        sender,
        senderName,
        status,
        statusOption,
        toTable: data.toTable,
        updatedAt
    }
}
