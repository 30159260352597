import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/inboxDetail'

import * as inboxDetailApi from './inboxDetailApi'

const initialState = {
    groupedInboxDetails: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false
}

export const {
    createInboxDetail,
    getInboxDetails,
    updateInboxDetail,
    updateInboxDetails
} = inboxDetailApi

const inboxDetailSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getInboxDetails.pending, (state) => {
            return Object.assign(state, {
                isLoading: true
            })
        })
        builder.addCase(getInboxDetails.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                groupedInboxDetails: map(
                    data.groupedInboxDetails || [],
                    (groupedInboxDetail) =>
                        map(groupedInboxDetail, (inboxDetail) =>
                            toState(inboxDetail)
                        )
                ),
                isLoading: false
            })
        })
        builder.addCase(getInboxDetails.rejected, (state) => {
            return Object.assign(state, {
                isLoading: false
            })
        })
        builder.addMatcher(
            isAnyOf(
                createInboxDetail.pending,
                updateInboxDetail.pending,
                updateInboxDetails.pending
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false,
                    isError: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createInboxDetail.fulfilled,
                updateInboxDetail.fulfilled,
                updateInboxDetails.fulfilled
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true,
                    isError: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createInboxDetail.rejected,
                updateInboxDetail.rejected,
                updateInboxDetails.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: false,
                    isError: true
                })
        )
    },
    initialState,
    name: 'INBOX_DETAIL',
    reducers: {
        clearInboxDetail: () => initialState
    }
})

export const { clearInboxDetail } = inboxDetailSlice.actions

const { reducer } = inboxDetailSlice
export default reducer
