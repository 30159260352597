import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Pagination } from 'antdcomponents'
import get from 'lodash/get'
import has from 'lodash/has'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { addQueryString, parseQueryString } from 'utility/url'
import usePrevious from 'utility/usePrevious'

const ConnectedPagination = (props) => {
    const {
        disableAddQueryToString,
        defaultCurrent,
        defaultPageSize,
        maxSupportedCount,
        onChange,
        pageSizeOptions,
        showSizeChanger,
        size,
        total
    } = props

    const initialCurrent =
        props.current && disableAddQueryToString
            ? props.current
            : defaultCurrent

    const location = useLocation()
    const navigate = useNavigate()

    const [current, setCurrent] = useState(initialCurrent)
    const [pageSize, setPageSize] = useState(defaultPageSize)
    const [isInit, setIsInit] = useState(false)

    const search = get(location, 'search', '')
    const prevSearch = usePrevious(search)
    useEffect(() => {
        if (prevSearch !== search || !isInit) {
            setIsInit(true)
            const queries = parseQueryString()
            if (has(queries, '_limit') || has(queries, '_page')) {
                setCurrent(Number(queries._page) || defaultCurrent)
                setPageSize(Number(queries._limit) || defaultPageSize)
                onChange(current, pageSize)
            }
        }
    }, [
        current,
        defaultCurrent,
        defaultPageSize,
        isInit,
        navigate,
        onChange,
        pageSize,
        prevSearch,
        search
    ])

    const handleChange = (current, pageSize) => {
        if (!disableAddQueryToString) {
            addQueryString(navigate, {
                _limit: pageSize,
                _page: current
            })
        }

        setCurrent(current)
        setPageSize(pageSize)
        onChange(current, pageSize)
    }

    return (
        <Pagination
            current={current}
            defaultCurrent={defaultCurrent}
            defaultPageSize={defaultPageSize}
            maxSupportedCount={maxSupportedCount}
            onChange={handleChange}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            showSizeChanger={showSizeChanger}
            size={size}
            total={total}
        />
    )
}

ConnectedPagination.propTypes = {
    // DONOT USE: this comp should be able to manage its own current page if
    // this is only used in conjuction with props.disableAddQueryToString
    current: PropTypes.number,
    // DONOT USE
    defaultCurrent: PropTypes.number,
    defaultPageSize: PropTypes.number,
    disableAddQueryToString: PropTypes.bool,
    history: PropTypes.object,
    maxSupportedCount: PropTypes.number,
    onChange: PropTypes.func,
    onRef: PropTypes.func,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
    showSizeChanger: PropTypes.bool,
    size: PropTypes.string,
    total: PropTypes.number
}

ConnectedPagination.defaultProps = {
    defaultCurrent: DEFAULT_PAGINATION_CONFIG.defaultCurrent,
    defaultPageSize: DEFAULT_PAGINATION_CONFIG.defaultPageSize,
    disableAddQueryToString: false,
    maxSupportedCount: 0,
    onChange: noop,
    onRef: noop,
    pageSizeOptions: DEFAULT_PAGINATION_CONFIG.pageSizeOptions,
    showSizeChanger: true,
    size: '',
    total: 0
}

export default ConnectedPagination
