import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import split from 'lodash/split'
import deleteAllCookies from 'utility/deleteAllCookies'
import * as notification from 'utility/notification'
import removePersistentData from 'utility/removePersistentData'

const throwError = (action, next, response) => {
    const error = response.body
    if (error.status < 200 || error.status >= 300) {
        notification.error(
            error.errorMessage || `${error.status || 500} Unknown Cause`
        )
    }
    if (error.status === 401) {
        removePersistentData('authToken')
        deleteAllCookies()
        return next({
            errorMessage: error.errorMessage,
            statusCode: 401,
            type: 'AUTHENTICATION/logout'
        })
    }

    return next({
        errorMessage: get(error, 'errorMessage', response.statusText),
        statusCode: error.status,
        type: action.type
    })
}

const throwSuccess = (response) => {
    const res = response.body
    if (res && res.status >= 200 && res.status < 300) {
        return Promise.resolve(res)
    }
    if (response.text && response.status >= 200 && response.status < 300) {
        return Promise.resolve({
            data: { text: response.text },
            status: response.status
        })
    }
    throw res
}

const asyncAction = () => (next) => (action) => {
    const type = last(split(action.type, '/'))
    const error = get(action, 'error', {})

    const { payload } = action
    const response = payload
    if (!isEmpty(error)) {
        return throwError(action, next, response)
    }
    if (includes(['fulfilled'], type)) {
        try {
            if (response) {
                return throwSuccess(response).then((res) =>
                    next({
                        data: res.data,
                        statusCode: res.status,
                        type: action.type
                    })
                )
            }
        } catch (e) {
            return throwError(action, next, e)
        }
    }

    return next(action)
}

export default asyncAction
