import formatPrice from '../utility/formatPrice'

export const toState = (data) => {
    const from = data.from || 0
    const to = data.to || 0
    const value = `${formatPrice(from)} - ${formatPrice(to)}`
    return {
        id: data.id || null,
        value
    }
}
