import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import * as bankListTransformer from './bankList'

export const toApi = (data) => ({
    account_holder_name: data.accountHolderName,
    account_number: data.accountNumber,
    bank_list_id: data.bankListId,
    created_by: data.createdBy,
    id: data.id,
    pemodal_id: data.pemodalId,
    updated_by: data.updatedBy
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const bankList = !isEmpty(data.bankList)
        ? bankListTransformer.toState(data.bankList)
        : {}

    return {
        accountHolderName: data.accountHolderName || '',
        accountNumber: data.accountNumber || '',
        bankList,
        bankListId: data.bankListId || null,
        createdAt,
        createdBy: data.createdBy || '',
        id: data.id || null,
        pemodalId: data.pemodalId || null,
        updatedAt,
        updatedBy: data.updatedBy || ''
    }
}
