import * as productCategorySlice from 'features/product_category/productCategorySlice'
import clone from 'lodash/clone'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import AddEditView from './AddEditView'

const AddEditViewContainer = (props) => {
    const params = useParams()
    return <AddEditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const productCategory = clone(state.productCategory.productCategory)
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]
    let initialValues = { status: 'draft' }
    if (windowType !== 'add' && !isEmpty(productCategory)) {
        initialValues = productCategory
    }

    return {
        initialValues,
        isActionLoading: state.productCategory.isActionLoading,
        isActionSuccess: state.productCategory.isActionSuccess,
        isLoading: state.productCategory.isLoading,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearProductCategory: productCategorySlice.clearProductCategory,
    createProductCategory: productCategorySlice.createProductCategory,
    getProductCategory: productCategorySlice.getProductCategory,
    updateProductCategory: productCategorySlice.updateProductCategory
}

const EditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'productCategory'
})(AddEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(EditViewForm)
