import { default as AntdRow } from 'antd/lib/row'
import colors from 'basic/colors'
import { StyledSpan } from 'components/StyledComponents'
import PropTypes from 'prop-types'

const IsVerifiedSpan = (props) => {
    const { hideUnverified = false, isVerified = false } = props

    if (hideUnverified && !isVerified) {
        return null
    }

    return (
        <AntdRow justify='end'>
            <StyledSpan color={isVerified ? colors.purpleHeart : colors.rose}>
                * {isVerified ? '' : 'Belum'} Terverifikasi
            </StyledSpan>
        </AntdRow>
    )
}

IsVerifiedSpan.propTypes = {
    hideUnverified: PropTypes.bool,
    isVerified: PropTypes.bool
}

export default IsVerifiedSpan
