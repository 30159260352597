import { default as AntdBreadcrumb } from 'antd/lib/breadcrumb'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const AntdBreadcrumbItem = AntdBreadcrumb.Item

const Breadcrumb = (props) => {
    const { items } = props

    const renderItem = (item, key) => {
        if (item.linkTo) {
            return (
                <AntdBreadcrumbItem key={key}>
                    <Link to={item.linkTo}>{item.label}</Link>
                </AntdBreadcrumbItem>
            )
        }

        return <AntdBreadcrumbItem key={key}>{item.label}</AntdBreadcrumbItem>
    }

    return (
        <AntdBreadcrumb separator='>'>
            {map(items, (item, key) => renderItem(item, key))}
        </AntdBreadcrumb>
    )
}

Breadcrumb.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            linkTo: PropTypes.string
        })
    ).isRequired
}

export default Breadcrumb
