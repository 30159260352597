import AntBadge from 'antd/lib/badge'
import { statusOptions } from 'common/options/contactUs'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'
import map from 'lodash/map'
import React, { Fragment } from 'react'

const rowActions = (id) => [
    {
        id,
        type: 'view'
    },
    {
        id,
        type: 'edit'
    }
]

const getColor = (status) =>
    find(statusOptions, (item) => item.value === status).color

const columns = (renderActions) => [
    {
        dataIndex: 'id',
        title: 'ID',
        width: 50
    },
    {
        dataIndex: 'status',
        render: (status) => (
            <Fragment>
                <AntBadge color={getColor(status)} />
                <span>{capitalize(status)}</span>
            </Fragment>
        ),
        sorter: true,
        title: 'Status',
        width: 100
    },
    {
        dataIndex: 'email',
        title: 'Email',
        width: 150
    },
    {
        dataIndex: 'subject',
        render: (subject) => capitalize(subject),
        title: 'Subyek',
        width: 150
    },
    {
        dataIndex: 'message',
        render: (message) => capitalize(message),
        title: 'Pertanyaan',
        width: 300
    },
    {
        dataIndex: 'createdAt',
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
