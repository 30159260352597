import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/district'
import * as districtAPI from './districtAPI'

const initialState = {
    districts: [],
    isError: false,
    isLoading: false,
    totalDistricts: 0
}

export const { getDistricts } = districtAPI

const districtSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getDistricts.pending, (state) =>
            Object.assign(state, {
                districts: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getDistricts.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                districts: map(data.districts, (item) => toState(item)),
                isLoading: false,
                totalDistricts: data.count
            })
        })
        builder.addCase(getDistricts.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false
            })
        )
    },
    initialState,
    name: 'DISTRICT',
    reducers: {
        clearDistrict: () => initialState
    }
})

export const { clearDistrict } = districtSlice.actions

const { reducer } = districtSlice
export default reducer
