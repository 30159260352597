import { Breadcrumb, Space } from 'antdcomponents'
import ProductCategoryListContainer from 'product_category/ListContainer'
import ProductRibbonListContainer from 'product_ribbon/ListContainer'
import PropTypes from 'prop-types'
import React from 'react'

const List = (props) => {
    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Kategori & Tag' }
    ]

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <ProductCategoryListContainer {...props} />
            <br />
            <ProductRibbonListContainer {...props} />
        </Space>
    )
}

List.propTypes = {
    dispatch: PropTypes.func,
    location: PropTypes.object,
    navigate: PropTypes.func
}

export default List
