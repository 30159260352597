import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import { default as AntdTypography } from 'antd/lib/typography'
import { BackButton, Breadcrumb, Button, Form, Space } from 'antdcomponents'
import { StyledAntdDivider } from 'components/StyledComponents'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { blockTextChanger } from 'utility/blockTextChanger'
import getBase64 from 'utility/getBase64'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'
import PenerbitData from './comps/PenerbitData'
import PenerbitDetail from './comps/PenerbitDetail'
import { submitErrorValidator } from './helper'

const AntdTypographyText = AntdTypography.Text

const AddEditView = (props) => {
    const {
        annualSalaries,
        bankLists,
        businessSectors,
        change,
        cities,
        districts,
        entityTypes,
        clearAnnualSalary,
        clearBankList,
        clearBusinessSector,
        clearCity,
        clearDistrict,
        clearEntityType,
        clearFundingAmount,
        clearIncomeSource,
        clearPenerbit,
        clearProductType,
        createPenerbit,
        dispatch,
        fundingAmounts,
        getAnnualSalaries,
        getBankLists,
        getBusinessSectors,
        getCities,
        getProvinces,
        getDistricts,
        getEntityTypes,
        getFundingAmounts,
        getIncomeSources,
        getPenerbit,
        getProductTypes,
        handleSubmit,
        initialValues,
        incomeSources,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isSendEmailLoading,
        isSendEmailSuccess,
        location,
        navigate,
        params,
        productTypes,
        provinces,
        sendWelcomeEmailPenerbit,
        updatePenerbit,
        username,
        windowType
    } = props

    const [error, setError] = useState('')
    const [penerbitKeyState, setPenerbitKeyState] = useState(0)

    const queries = get(location, 'search', '')
    const identifier = get(params, 'identifier', null)

    const cityId = get(initialValues, 'cityId', null)
    const email = get(initialValues, 'email', '')
    const isActive = get(initialValues, 'isActive', false)
    const isTermsAndConditionsApproved = get(
        initialValues,
        'isTermsAndConditionsApproved',
        false
    )
    const isRiskStatementApproved = get(
        initialValues,
        'isRiskStatementApproved',
        false
    )
    const termsAndConditionsApprovalDate = get(
        initialValues,
        'termsAndConditionsApprovalDate',
        null
    )
    const riskStatementApprovalDate = get(
        initialValues,
        'riskStatementApprovalDate',
        null
    )

    const formValues = useSelectorFormValues('penerbit')
    const penerbitDetails = get(formValues, 'penerbitDetails', [])
    const penerbitDocuments = get(formValues, 'penerbitDocuments', [])
    const penerbitDetailKey = get(formValues, 'penerbitDetailKey', [])

    const isWindowTypeAdd = windowType === 'add'
    const isWindowTypeEdit = windowType === 'edit'
    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Penerbit', linkTo: '/penerbit' },
        {
            label: isWindowTypeAdd
                ? getPageName(windowType)
                : `${getPageName(windowType)} (${email})`
        }
    ]
    const fetchPenerbit = () => getPenerbit(identifier)

    //componentDidMount
    useEffect(() => {
        clearPenerbit()
        getBankLists()
        getCities()
        getProvinces()
        getIncomeSources()
        getBusinessSectors()
        getEntityTypes()
        getProductTypes()
        getFundingAmounts()
        getAnnualSalaries()
        if (isWindowTypeAdd) {
            change('penerbitDetails', get(initialValues, 'penerbitDetails', []))
            change(
                'penerbitManagementFees',
                get(initialValues, 'penerbitManagementFees', [])
            )
        }
        if (identifier) {
            fetchPenerbit()
        }
        if (cityId) {
            getDistricts({ options: { cityId } })
        }

        return () => {
            dispatch(clearAnnualSalary())
            dispatch(clearBankList())
            dispatch(clearBusinessSector())
            dispatch(clearCity())
            dispatch(clearDistrict())
            dispatch(clearEntityType())
            dispatch(clearFundingAmount())
            dispatch(clearIncomeSource())
            dispatch(clearPenerbit())
            dispatch(clearProductType())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            if (isWindowTypeAdd) {
                navigate('/penerbit')
            } else {
                change('penerbitDetailKey', penerbitKeyState)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionLoading, isActionSuccess])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/penerbit')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const handleBlockUnblock = () => change('isActive', !isActive)

    const handleFinish = async () => {
        const currentError = submitErrorValidator(
            get(penerbitDetails, penerbitDetailKey, {}),
            penerbitDocuments
        )
        setError(currentError)
        if (!isEmpty(currentError)) {
            return
        }

        const allPenerbitDetailDocuments = flatten(
            map(penerbitDetails, (item, key) => {
                let newPenerbitDetailDocuments = get(
                    item,
                    'penerbitDetailDocuments',
                    []
                )
                newPenerbitDetailDocuments = map(
                    newPenerbitDetailDocuments,
                    (item) =>
                        Object.assign(item, { key: get(item, 'key', key) })
                )
                return newPenerbitDetailDocuments
            })
        )
        const allDocuments = concat(
            penerbitDocuments,
            allPenerbitDetailDocuments
        )
        const newDocuments = await Promise.all(
            Array.from(allDocuments).map(getBase64)
        )
        const fieldNamePenerbitDocument = ['npwp', 'documents']
        const newPenerbitDocuments = filter(newDocuments, (item) =>
            includes(fieldNamePenerbitDocument, item.fieldName)
        )
        const newPenerbitDetailDocuments = filter(
            newDocuments,
            (item) => !includes(fieldNamePenerbitDocument, item.fieldName)
        )
        const penerbitDetailUnverified = find(
            penerbitDetails,
            (item) => !item.isKycVerified
        )
        const isAllPenerbitDetailVerified = isEmpty(penerbitDetailUnverified)
        let status = get(formValues, 'status', '')
        if (isAllPenerbitDetailVerified) {
            status = 'verified'
        }
        let data = Object.assign(cloneDeep(formValues), {
            penerbitDetails: map(penerbitDetails, (penerbitDetail, key_1) =>
                Object.assign(penerbitDetail, {
                    penerbitDetailDocuments: filter(
                        newPenerbitDetailDocuments,
                        (item) => item.key === key_1
                    )
                })
            ),
            penerbitDocuments: newPenerbitDocuments,
            status
        })
        if (isWindowTypeAdd) {
            data = Object.assign(data, { createdBy: username })
            createPenerbit(data)
        }
        if (isWindowTypeEdit) {
            data = Object.assign(data, { updatedBy: username })
            updatePenerbit(data)
        }
    }

    const renderButton = () =>
        !isWindowTypeView && (
            <AntdSpace>
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    type='primary'
                    value='Simpan'
                />
                {isWindowTypeEdit && (
                    <Button
                        danger={isActive}
                        htmlType='submit'
                        loading={isActionLoading}
                        name='block-ublock-btn'
                        onClick={handleBlockUnblock}
                        type={isActive ? 'primary' : 'default'}
                        value={startCase(blockTextChanger(isActive))}
                    />
                )}
            </AntdSpace>
        )

    const renderNote = () => (
        <div>
            <strong>
                <u>Catatan:</u>
            </strong>
            <ul>
                <li>
                    email hanya bisa dimasukan pada awal tambah penerbit, harap
                    masukan email yang benar
                </li>
                <li>
                    minimal terdapat satu personel penerbit
                    (direksi/direktur/penanggung jawab)
                </li>
            </ul>
        </div>
    )

    const renderPenerbitData = () => (
        <PenerbitData
            bankLists={bankLists}
            businessSectors={businessSectors}
            change={change}
            cities={cities}
            clearDistrict={clearDistrict}
            districts={districts}
            entityTypes={entityTypes}
            fundingAmounts={fundingAmounts}
            getCities={getCities}
            getDistricts={getDistricts}
            identifier={identifier}
            initialValues={initialValues}
            isRiskStatementApproved={isRiskStatementApproved}
            isSendEmailLoading={isSendEmailLoading}
            isSendEmailSuccess={isSendEmailSuccess}
            isTermsAndConditionsApproved={isTermsAndConditionsApproved}
            isWindowTypeAdd={isWindowTypeAdd}
            isWindowTypeView={isWindowTypeView}
            penerbitDocuments={penerbitDocuments}
            productTypes={productTypes}
            provinces={provinces}
            riskStatementApprovalDate={riskStatementApprovalDate}
            sendWelcomeEmailPenerbit={sendWelcomeEmailPenerbit}
            termsAndConditionsApprovalDate={termsAndConditionsApprovalDate}
        />
    )

    const renderPenerbitDetail = () => (
        <PenerbitDetail
            annualSalaries={annualSalaries}
            change={change}
            cities={cities}
            clearDistrict={clearDistrict}
            districts={districts}
            getCities={getCities}
            getDistricts={getDistricts}
            hometowns={cities}
            incomeSources={incomeSources}
            initialValues={initialValues}
            isWindowTypeView={isWindowTypeView}
            penerbitDetails={penerbitDetails}
            provinces={provinces}
            setPenerbitKeyState={(value) => setPenerbitKeyState(value)}
        />
    )

    const renderError = () =>
        error && (
            <AntdTypographyText type='danger'>
                {error}
                <br />
                <br />
            </AntdTypographyText>
        )

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/penerbit${queries}`} />
            <Form
                colon={false}
                labelAlign='left'
                labelCol={!isWindowTypeView ? {} : { flex: '200px' }}
                labelWrap
                layout={!isWindowTypeView ? 'vertical' : 'horizontal'}
                onFinish={handleSubmit(handleFinish)}
                wrapperCol={{ flex: 1 }}
            >
                <Space direction='vertical'>
                    {!isWindowTypeView && renderNote()}
                    {renderPenerbitData()}
                    <StyledAntdDivider />
                    {renderPenerbitDetail()}
                    {renderError()}
                    {renderButton()}
                </Space>
            </Form>
        </Space>
    )
}

AddEditView.propTypes = {
    annualSalaries: PropTypes.array,
    bankLists: PropTypes.array,
    businessSectors: PropTypes.array,
    change: PropTypes.func,
    cities: PropTypes.array,
    clearAnnualSalary: PropTypes.func,
    clearBankList: PropTypes.func,
    clearBusinessSector: PropTypes.func,
    clearCity: PropTypes.func,
    clearDistrict: PropTypes.func,
    clearEntityType: PropTypes.func,
    clearFundingAmount: PropTypes.func,
    clearIncomeSource: PropTypes.func,
    clearPenerbit: PropTypes.func,
    clearProductType: PropTypes.func,
    createPenerbit: PropTypes.func,
    dispatch: PropTypes.func,
    districts: PropTypes.array,
    entityTypes: PropTypes.array,
    fundingAmounts: PropTypes.array,
    getAnnualSalaries: PropTypes.func,
    getBankLists: PropTypes.func,
    getBusinessSectors: PropTypes.func,
    getCities: PropTypes.func,
    getDistricts: PropTypes.func,
    getEntityTypes: PropTypes.func,
    getFundingAmounts: PropTypes.func,
    getIncomeSources: PropTypes.func,
    getPenerbit: PropTypes.func,
    getProductTypes: PropTypes.func,
    getProvinces: PropTypes.func,
    handleSubmit: PropTypes.func,
    incomeSources: PropTypes.array,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.any,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSendEmailLoading: PropTypes.bool,
    isSendEmailSuccess: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    productTypes: PropTypes.array,
    provinces: PropTypes.array,
    sendWelcomeEmailPenerbit: PropTypes.func,
    updatePenerbit: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default AddEditView
