import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import * as message from 'utility/message'
import * as config from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        clearPemodal,
        dispatch,
        getPemodals,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        pemodals,
        totalPemodals,
        updatePemodal
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Pemodal' }
    ]

    useEffect(() => {
        const fetchPemodals = () => {
            const offset = (page - 1) * limit
            getPemodals({ limit, offset, options: { ...options, orderBy } })
        }

        fetchPemodals()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }

        return () => {
            dispatch(clearPemodal())
        }
    }, [
        clearPemodal,
        dispatch,
        getPemodals,
        isActionLoading,
        isActionSuccess,
        limit,
        options,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id, isActive } = pemodals[key]
        if (includes(['block', 'unblock'], type)) {
            const data = { id, isActive: !isActive }
            return updatePemodal(data)
        }
        if (type === 'edit') {
            return navigate(`/pemodal/edit/${id}`)
        }
        if (type === 'view') {
            return navigate(`/pemodal/view/${id}`)
        }
    }

    const handleClear = () => {
        setOptions({})
        setOrderBy(defaultOrderBy)
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const handleSearch = (newOptions) => setOptions(newOptions)

    const renderActions = (text, record, index) => (
        <TableRowActions
            options={config.rowActions(index, handleActions, record)}
        />
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onSearch={handleSearch}
                totalItems={totalPemodals}
            />
            <Table
                columns={config.columns(renderActions)}
                dataSource={config.dataSource(pemodals)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    clearPemodal: PropTypes.func,
    dispatch: PropTypes.func,
    getPemodals: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    pemodals: PropTypes.array,
    totalPemodals: PropTypes.number,
    updatePemodal: PropTypes.func
}

export default List
