import { default as AntdBadge } from 'antd/lib/badge'
import { getUserAdminTypeOptions } from 'common/options'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import truncate from 'lodash/truncate'
import React from 'react'

const rowActions = (id) => [
    {
        id,
        type: 'view'
    },
    {
        id,
        type: 'edit'
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'id',
        title: 'ID',
        width: 50
    },
    {
        dataIndex: 'statusOption',
        render: (object) => (
            <AntdBadge color={object.color} text={capitalize(object.label)} />
        ),
        title: 'Status',
        width: 150
    },
    {
        children: [
            {
                dataIndex: 'sender',
                render: (item) => getUserAdminTypeOptions(item.fromTable).label,
                title: 'Tipe',
                width: 100
            },
            {
                dataIndex: 'sender',
                render: (item) => item.name,
                title: 'Nama',
                width: 200
            },
            {
                dataIndex: 'sender',
                render: (item) => item.username,
                title: 'Username',
                width: 200
            }
        ],
        title: 'Pengirim'
    },
    {
        children: [
            {
                dataIndex: 'receiver',
                render: (item) => getUserAdminTypeOptions(item.fromTable).label,
                title: 'Tipe',
                width: 100
            },
            {
                dataIndex: 'receiver',
                render: (item) => item.name,
                title: 'Nama',
                width: 200
            },
            {
                dataIndex: 'receiver',
                render: (item) => item.username,
                title: 'Username',
                width: 200
            }
        ],
        title: 'Penerima'
    },
    {
        dataIndex: 'lastMessage',
        render: (string) => truncate(string),
        title: 'Pesan Terbaru',
        width: 250
    },
    {
        dataIndex: 'product',
        title: 'Produk',
        width: 200
    },
    {
        dataIndex: 'order',
        title: 'Order',
        width: 200
    },
    {
        dataIndex: 'createdAt',
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
