import isLocalStorageAvailable from './isLocalStorageAvailable'

const removePersistentData = (key) => {
    if (isLocalStorageAvailable()) {
        window.localStorage.removeItem(key)
        return
    }
    const expiry = '=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
    document.cookie = `${key}${expiry}`
    return
}

export default removePersistentData
