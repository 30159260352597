import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/productType'
import * as productTypeAPI from './productTypeAPI'

const initialState = {
    isError: false,
    isLoading: false,
    productTypes: [],
    totalProductTypes: 0
}

export const { getProductTypes } = productTypeAPI

const productTypeSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getProductTypes.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productTypes: []
            })
        )
        builder.addCase(getProductTypes.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productTypes: map(data.productTypes, (item) => toState(item)),
                totalProductTypes: data.count
            })
        })
        builder.addCase(getProductTypes.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false
            })
        )
    },
    initialState,
    name: 'PRODUCT_TYPE',
    reducers: {
        clearProductType: () => initialState
    }
})

export const { clearProductType } = productTypeSlice.actions

const { reducer } = productTypeSlice
export default reducer
