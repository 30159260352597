import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import startCase from 'lodash/startCase'

const genders = ['laki-laki', 'perempuan']
export const genderOptions = map(genders, (item) => ({
    label: capitalize(item),
    value: item
}))

const types = ['pemodal', 'penerbit']
export const typeOptions = map(types, (item) => ({
    label: startCase(item),
    value: item
}))
