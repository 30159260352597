import LoginContainer from 'authentication/login/LoginContainer'

// core components
import Add from 'common/access/Add'
import Edit from 'common/access/Edit'
import List from 'common/access/List'
import View from 'common/access/View'

// main components
import NoMatch from 'no_match/NoMatch'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import HomeContainer from './home/HomeContainer'
import Layout from './Layout'
import 'whatwg-fetch'

const RouteHandler = () => {
    return (
        <Routes>
            {/* Routes nest inside one another. Nested route paths build upon
                parent route paths, and nested route elements render inside
                parent route elements. See the note about <Outlet> below. */}
            <Route element={<Layout />} path='/'>
                <Route element={<HomeContainer />} index />
                <Route element={<LoginContainer />} path='login' />
                <Route element={<NoMatch />} path='404' />

                <Route element={<List />} path='/:name' />
                <Route element={<Add />} path='/:name/add' />
                <Route element={<Edit />} path='/:name/edit/:identifier' />
                <Route element={<View />} path='/:name/view/:identifier' />

                {/* Using path="*"" means "match anything", so this route
                    acts like a catch-all for URLs that we don't have explicit
                    routes for. */}
                <Route
                    element={<Navigate replace to='/category-and-tag' />}
                    path='category'
                />
                <Route
                    element={<Navigate replace to='/category-and-tag' />}
                    path='tag'
                />
            </Route>
        </Routes>
    )
}

export default RouteHandler
