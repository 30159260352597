import { FormItem, Input } from 'antdcomponents'
import PropTypes from 'prop-types'

const ReduxFormInput = (props) => {
    const {
        dark,
        disabled,
        formItemProps,
        input,
        label,
        meta,
        placeholder,
        ...restProps
    } = props

    const { onChange, name, value } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <Input
                {...restProps}
                dark={dark}
                disabled={disabled}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormInput.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string
}

export default ReduxFormInput
