import * as contactUsSlice from 'features/contact_us/contactUsSlice'
import get from 'lodash/get'
import split from 'lodash/split'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import EditView from './EditView'

const ContactUsEditViewContainer = (props) => {
    const params = useParams()
    return <EditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]

    return {
        initialValues: state.contactUs.contactUs,
        isActionLoading: state.contactUs.isActionLoading,
        isActionSuccess: state.contactUs.isActionSuccess,
        isLoading: state.contactUs.isLoading,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearContactUs: contactUsSlice.clearContactUs,
    getContactUs: contactUsSlice.getContactUs,
    updateContactUs: contactUsSlice.updateContactUs
}

const EditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'contactUs'
})(ContactUsEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(EditViewForm)
