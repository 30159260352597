import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/adminUser'
import * as adminUserAPI from './adminUserAPI'

const initialState = {
    adminUser: {},
    adminUsers: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    totalAdminUsers: 0
}

export const {
    createAdminUser,
    deleteAdminUser,
    getAdminUser,
    getAdminUsers,
    updateAdminUser
} = adminUserAPI

const adminUserSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getAdminUser.pending, (state) =>
            Object.assign(state, {
                adminUser: {},
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getAdminUser.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                adminUser: toState(data.adminUser),
                isLoading: false
            })
        })
        builder.addCase(getAdminUsers.pending, (state) =>
            Object.assign(state, {
                adminUsers: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getAdminUsers.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                adminUsers: map(data.adminUsers, (item) => toState(item)),
                isLoading: false,
                totalAdminUsers: data.count
            })
        })
        builder.addMatcher(
            isAnyOf(
                createAdminUser.pending,
                deleteAdminUser.pending,
                updateAdminUser.pending
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createAdminUser.fulfilled,
                deleteAdminUser.fulfilled,
                updateAdminUser.fulfilled
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true
                })
        )
        builder.addMatcher(
            isAnyOf(
                createAdminUser.rejected,
                deleteAdminUser.rejected,
                updateAdminUser.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(getAdminUser.rejected, getAdminUsers.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'ADMIN_USER',
    reducers: {
        clearAdminUser: () => initialState
    }
})

export const { clearAdminUser } = adminUserSlice.actions

const { reducer } = adminUserSlice
export default reducer
