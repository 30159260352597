import map from 'lodash/map'
import { blockTextChanger, getTypeAction } from 'utility/blockTextChanger'

const rowActions = (index, handleActions, record, currAdminName) => [
    {
        id: record.id,
        type: 'view'
    },
    {
        id: record.id,
        type: 'edit'
    },
    {
        confirmContent: `Apakah anda yakin ingin hapus pengguna admin ini (${record.email} - ${record.username})?`,
        onClick: handleActions(index, 'delete'),
        type: 'delete'
    },
    {
        confirmContent: `Apakah anda yakin ingin ${blockTextChanger(
            record.isActive
        )} pengguna admin ini (${record.email} / ${record.username})?`,
        disabled: record.username === currAdminName,
        onClick: handleActions(index, getTypeAction(record.isActive)),
        type: getTypeAction(record.isActive)
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'isActive',
        render: (bool) => (bool ? 'Ya' : 'Tidak'),
        sorter: true,
        title: 'Aktif',
        width: 50
    },
    {
        dataIndex: 'email',
        title: 'Email',
        width: 200
    },
    {
        dataIndex: 'username',
        sorter: true,
        title: 'Username',
        width: 150
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        sorter: true,
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 200
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
