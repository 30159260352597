import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/inboxDetail'
import * as api from '../api'

export const createInboxDetail = createAsyncThunk(
    'CREATE_INBOX_DETAIL',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create('/inbox-detail/create', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getInboxDetails = createAsyncThunk(
    'GET_INBOX_DETAILS',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/inbox-details', data)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateInboxDetail = createAsyncThunk(
    'UPDATE_INBOX_DETAIL',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/inbox-detail/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateInboxDetails = createAsyncThunk(
    'UPDATE_INBOX_DETAILS',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/inbox-details/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
