import * as purchaseOrderSlice from 'features/purchase_order/purchaseOrderSlice'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import View from './View'

const ViewContainer = (props) => {
    const params = useParams()
    return <View {...props} params={params} />
}

const mapStateToProps = (state) => ({
    initialValues: state.purchaseOrder.purchaseOrder,
    isLoading: state.purchaseOrder.isLoading,
    roles: state.authentication.roles,
    username: state.authentication.username
})

const mapDispatchToProps = {
    clearPurchaseOrder: purchaseOrderSlice.clearPurchaseOrder,
    getPurchaseOrder: purchaseOrderSlice.getPurchaseOrder
}

const ViewForm = reduxForm({
    enableReinitialize: true,
    form: 'purchaseOrder'
})(ViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm)
