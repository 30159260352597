import isMobile from 'is-mobile'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdTable from './StyledAntdTable'

const keyMap = {
    ascend: 'asc',
    descend: 'desc'
}

const Table = (props) => {
    const {
        bordered,
        columns,
        components,
        dataSource,
        expandedRowKeys,
        expandedRowRender,
        loading,
        onChange,
        onExpand,
        onRow,
        pagination,
        rowExpandable,
        rowKey,
        rowSelection,
        scroll,
        showHeader,
        size,
        title
    } = props

    const handleChange = (pagination, filters, sorter) => {
        let newSorter = null
        if (!isUndefined(get(sorter, 'order'))) {
            newSorter = sorter
            newSorter.order = keyMap[newSorter.order]
        }
        onChange(pagination, filters, newSorter)
    }

    const newColumns = map(columns, (column) => {
        let newColumn = column
        if (newColumn.dataIndex === 'actions') {
            /**
             * kalo make isMobile(), bakalan bisa save space buat table nya, tapi g bisa responsive layout
             * karen isMobile() ini check user-agent string jadi kalau kita dari desktop terus
             * resize window nya, action (edit, view, etc) bakalan tetep fixed. Tapi kalau kita lgsg
             * akses website dari handphone, actionnya bakalan ga makan tempat table nya.
             */
            const fixed = isMobile() ? '' : 'right'
            newColumn = Object.assign(newColumn, { fixed })
        }
        return newColumn
    })

    return (
        <StyledAntdTable
            bordered={bordered}
            columns={newColumns}
            components={components}
            dataSource={dataSource}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={expandedRowRender}
            loading={loading}
            onChange={handleChange}
            onExpand={onExpand}
            onRow={onRow}
            pagination={pagination}
            rowExpandable={rowExpandable}
            rowKey={rowKey}
            rowSelection={rowSelection}
            scroll={Object.assign(scroll, { y: 480 })}
            showHeader={showHeader}
            size={size}
            title={title}
        />
    )
}

Table.propTypes = {
    bordered: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            colSpan: PropTypes.number,
            dataIndex: PropTypes.string,
            defaultSortOrder: PropTypes.oneOf(['ascend', 'descend']),
            key: PropTypes.string,
            onCell: PropTypes.func,
            onHeaderCell: PropTypes.func,
            sorter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
    ).isRequired,
    components: PropTypes.object,
    dataSource: PropTypes.array,
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string),
    expandedRowRender: PropTypes.func,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onExpand: PropTypes.func,
    onRow: PropTypes.func,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    rowExpandable: PropTypes.func,
    rowKey: PropTypes.string,
    rowSelection: PropTypes.any,
    scroll: PropTypes.object,
    showHeader: PropTypes.bool,
    size: PropTypes.oneOf(['default', 'middle', 'small']),
    title: PropTypes.func
}

Table.defaultProps = {
    bordered: false,
    columns: [],
    dataSource: [],
    loading: false,
    onChange: noop,
    onExpand: noop,
    onRow: noop,
    pagination: false,
    rowKey: 'key',
    scroll: { x: 'max-content' },
    showHeader: true,
    size: 'middle'
}

export default Table
