import { FormItem, Select } from 'antdcomponents'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import PropTypes from 'prop-types'

const ReduxFormSelect = (props) => {
    const {
        dark,
        defaultValue,
        formItemProps,
        input,
        keyValue,
        isLoading,
        label,
        meta,
        options,
        ...restProps
    } = props

    const { name } = input
    const currentValue = props.value || input.value

    const { touched, error } = meta
    const isError = touched && !!error

    let validateStatus = undefined
    if (isError) validateStatus = 'error'
    if (isLoading) validateStatus = 'validating'

    const handleChange = (value) => {
        if (!keyValue || !value) return input.onChange(value)
        if (!isArray(value))
            return input.onChange(
                find(options, (item) => item.value[keyValue] === value)['value']
            )
        return input.onChange(
            map(
                value,
                (item) =>
                    find(options, (option) => option.value[keyValue] === item)[
                        'value'
                    ]
            )
        )
    }

    const handleFilterBy = (input, option) =>
        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0

    let transformedValue = map(currentValue, (item) => item[keyValue])
    if (!isArray(currentValue)) transformedValue = currentValue[keyValue]
    if (!keyValue || !currentValue) transformedValue = currentValue
    if (currentValue === '') transformedValue = undefined

    let newOptions = options
    if (keyValue) {
        newOptions = map(options, (item) => ({
            label: item.label,
            value: item.value[keyValue]
        }))
    }

    return (
        <FormItem
            dark={dark}
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={validateStatus}
            {...formItemProps}
        >
            <Select
                {...restProps}
                dark={dark}
                defaultValue={defaultValue}
                filterBy={handleFilterBy}
                name={name}
                onChange={handleChange}
                options={newOptions}
                value={transformedValue}
            />
        </FormItem>
    )
}

ReduxFormSelect.propTypes = {
    dark: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    keyValue: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    options: PropTypes.array,
    value: PropTypes.string
}

export default ReduxFormSelect
