import { VALID_IMAGE_EXTENSIONS, VALID_PDF_EXTENSION } from 'constants/formats'
// eslint-disable-next-line
import ImgCrop from 'antd-img-crop'
import { default as AntdEmpty } from 'antd/lib/empty'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import { default as AntdTypography } from 'antd/lib/typography'
import {
    BackButton,
    Breadcrumb,
    Button,
    Form,
    ReduxFormInput,
    ReduxFormInputNumber,
    ReduxFormPicker,
    ReduxFormSelect,
    ReduxFormTextArea,
    Space,
    Upload
} from 'antdcomponents'
import {
    canceledStatus,
    dividendPeriodOptions,
    finishedStatus,
    onGoingStatus,
    statusOptions
} from 'common/options/product'
import StyledLabel from 'components/styled_label/StyledLabel'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import slugify from 'slug'
import defaultViewRow from 'utility/defaultViewRow'
import { required, todayOnwards } from 'utility/formValidation'
import getBase64 from 'utility/getBase64'
import getLabelOption from 'utility/getLabelOption'
import getPageName from 'utility/getPageName'
import getPenerbitName from 'utility/getPenerbitName'
import getSummaryProduct from 'utility/getSummaryProduct'
import mapSelectOptions from 'utility/mapSelectOptions'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'
import { disabledDate } from './helper'

const AntdTypographyText = AntdTypography.Text

const AddEditView = (props) => {
    const {
        change,
        createProduct,
        clearProduct,
        dispatch,
        error,
        getPenerbits,
        getProduct,
        getProductCategories,
        getProductRibbons,
        getProductTypes,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        params,
        penerbits,
        productCategories,
        productRibbons,
        productTypes,
        updateProduct,
        username,
        windowType
    } = props

    const formValues = useSelectorFormValues('product')
    const code = get(formValues, 'code', '')
    const numberShare = get(formValues, 'numberShare', 1)
    const pricePerShare = get(formValues, 'pricePerShare', 0)
    const productDocuments = get(formValues, 'productDocuments', [])
    const productTypeId = get(formValues, 'productTypeId', null)
    const status = get(formValues, 'status', '')

    const activeDate = get(formValues, 'activeDate', [])
    const endDate = activeDate[1]
    const startDate = activeDate[0]

    const productImagesValue = get(formValues, 'productImages', [])
    const prospectusValue = get(formValues, 'prospectus', [])
    const dividendReportsValue = filter(
        productDocuments,
        (item) => item.identifier === 'dividendReports'
    )
    const financialReportsValue = filter(
        productDocuments,
        (item) => item.identifier === 'financialReports'
    )

    const [productImages, setProductImages] = useState(productImagesValue)
    const [prospectus, setProspectus] = useState(prospectusValue)
    const [dividendReports, setDividendReports] = useState(dividendReportsValue)
    const [financialReports, setFinancialReports] = useState(
        financialReportsValue
    )

    const queries = get(location, 'search', '')
    const identifier = get(params, 'identifier', null)

    const name = get(initialValues, 'name', '')

    const isWindowTypeAdd = windowType === 'add'
    const isWindowTypeEdit = windowType === 'edit'
    const isWindowTypeView = windowType === 'view'

    const { isFinished, isOnGoing } = getSummaryProduct(
        startDate,
        endDate,
        status
    )

    const isEditable = !includes(onGoingStatus, status) || !isOnGoing
    const isOfferStatus = includes(
        [...canceledStatus, ...finishedStatus],
        initialValues.status
    )
    const isDisabled = isWindowTypeView || !isEditable || isOfferStatus
    const prevIsLoading = usePrevious(isLoading)

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Produk', linkTo: '/product' },
        {
            label: isWindowTypeAdd
                ? getPageName(windowType)
                : `${getPageName(windowType)} (${name})`
        }
    ]

    useEffect(() => {
        getPenerbits({ options: { isActive: true, status: 'verified' } })
        getProductCategories()
        getProductRibbons()
        getProductTypes()

        if (!isWindowTypeAdd && identifier) {
            const fetchProduct = async () => getProduct(identifier)
            fetchProduct()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //files inititalvalues if initialState empty
    useEffect(() => {
        setProductImages(productImagesValue)
        setProspectus(prospectusValue)
        setDividendReports(dividendReportsValue)
        setFinancialReports(financialReportsValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(initialValues)])

    useEffect(() => {
        if (!isActionLoading) {
            if (isActionSuccess && isWindowTypeAdd) {
                message.success('data sukses terkirim')
                navigate(`/product${queries}`)
            }
            if (isActionSuccess && isWindowTypeEdit) {
                message.success('perubahan tersimpan')
                const fetchProduct = async () => getProduct(identifier)
                fetchProduct()
            }
        }

        return () => {
            dispatch(clearProduct())
        }
    }, [
        clearProduct,
        dispatch,
        getProduct,
        identifier,
        isActionLoading,
        isActionSuccess,
        isWindowTypeAdd,
        isWindowTypeEdit,
        navigate,
        queries
    ])

    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/product')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const handleChangePenerbit = (value) => {
        const penerbit = find(penerbits, (item) => item.id === value)
        change('penerbitName', getPenerbitName(penerbit))
        change('penerbit.businessSector.value', penerbit.businessSector.value)
    }

    const handleChangeNumberShare = (value) =>
        change('target', value * pricePerShare)

    const handleChangePricePerShare = (value) =>
        change('target', value * numberShare)

    const handleChangeStatus = (value) => {
        if (!includes(finishedStatus, value)) {
            change('settlementDate', null)
        }
    }

    const handleChangeTitle = (value) => change('slug', slugify(value))

    const handleFinish = async (values) => {
        const fileList = compact(
            concat(
                values.productDocuments,
                values.productImages,
                values.prospectus
            )
        )
        const convertedFilelist = await Promise.all(map(fileList, getBase64))
        const newProductDocuments = filter(convertedFilelist, (item) =>
            includes(['dividendReports', 'financialReports'], item.fieldName)
        )
        let newProductImages = filter(
            convertedFilelist,
            (item) => item.fieldName === 'productImages'
        )
        newProductImages = map(newProductImages, (item, key) => {
            return Object.assign(item, { sequence: Number(key) })
        })
        const newProspectus = find(
            convertedFilelist,
            (item) => item.fieldName === 'prospectus'
        )

        let data = Object.assign(values, {
            endDate,
            productDocuments: newProductDocuments,
            productImages: newProductImages,
            prospectus: newProspectus,
            startDate,
            updatedBy: username
        })

        if (isWindowTypeAdd) {
            data = Object.assign(data, { createdBy: username })
            return createProduct(data)
        }
        if (isWindowTypeEdit) {
            return updateProduct(data)
        }
    }

    const handleOpenNewTab = (route) => () => window.open(route, '_blank')

    const handleRemove = (fieldName) => (options) => {
        const newFileList = get(options, 'fileList', [])

        if (fieldName === 'dividendReports') {
            const newProductDocuments = concat(newFileList, financialReports)
            change('productDocuments', newProductDocuments)
            setDividendReports(newFileList)
        }
        if (fieldName === 'financialReports') {
            const newProductDocuments = concat(newFileList, dividendReports)
            change('productDocuments', newProductDocuments)
            setFinancialReports(newFileList)
        } else {
            change(fieldName, newFileList)
            if (fieldName === 'productImages') {
                setProductImages(newFileList)
            }
            if (fieldName === 'prospectus') {
                setProspectus(newFileList)
            }
        }
    }

    const handleUpload = (fieldName) => (options) => {
        const { fileList = [], onProgress = noop, onSuccess = noop } = options
        const newFileList = map(cloneDeep(fileList), (item) => {
            return Object.assign(item, {
                fieldName,
                identifier: get(item, 'identifier', fieldName),
                onProgress,
                onSuccess
            })
        })

        if (fieldName === 'dividendReports') {
            const newProductDocuments = concat(newFileList, financialReports)
            change('productDocuments', newProductDocuments)
            setDividendReports(newFileList)
        }
        if (fieldName === 'financialReports') {
            const newProductDocuments = concat(newFileList, dividendReports)
            change('productDocuments', newProductDocuments)
            setFinancialReports(newFileList)
        } else {
            change(fieldName, newFileList)
            if (fieldName === 'productImages') {
                setProductImages(newFileList)
            }
            if (fieldName === 'prospectus') {
                setProspectus(newFileList)
            }
        }
    }

    const renderButton = () => (
        <AntdSpace>
            {!isWindowTypeView && (
                <Button
                    disabled={error}
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    type='primary'
                    value='Simpan'
                />
            )}
            {isWindowTypeView && (
                <Button
                    name='list-order-btn'
                    onClick={handleOpenNewTab(
                        `/purchase-order?productCode=${code}`
                    )}
                    value='Daftar Order'
                />
            )}
            {isWindowTypeView && (
                <Button
                    disabled={isOnGoing}
                    name='download-disbursement-btn'
                    value='Unduh Pembayaran'
                />
            )}
        </AntdSpace>
    )

    const renderDocument = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Dokumen</StyledLabel>
            <Upload
                accept={VALID_PDF_EXTENSION}
                disabled={isWindowTypeView}
                fileList={prospectus}
                label='Prospektus'
                name='prospectus'
                onRemove={handleRemove('prospectus')}
                onUpload={handleUpload('prospectus')}
            />
            {isWindowTypeView && isEmpty(prospectus) && <AntdEmpty />}
            <Upload
                accept={VALID_PDF_EXTENSION}
                disabled={isWindowTypeView}
                fileList={financialReports}
                label='Laporan Keuangan'
                limit={10}
                name='financialReports '
                onRemove={handleRemove('financialReports')}
                onUpload={handleUpload('financialReports')}
            />
            {isWindowTypeView && isEmpty(financialReports) && <AntdEmpty />}
            <Upload
                accept={VALID_PDF_EXTENSION}
                disabled={isWindowTypeView}
                fileList={dividendReports}
                label='Laporan Dividen'
                limit={10}
                name='dividendReports'
                onRemove={handleRemove('dividendReports')}
                onUpload={handleUpload('dividendReports')}
            />
            {isWindowTypeView && isEmpty(dividendReports) && <AntdEmpty />}
            <br />
        </Space>
    )

    const renderError = () =>
        error && (
            <AntdTypographyText type='danger'>
                {error}
                <br />
                <br />
            </AntdTypographyText>
        )

    const renderImages = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Gambar</StyledLabel>
            {isWindowTypeView && isEmpty(productImages) && <AntdEmpty />}
            <ImgCrop rotate>
                <Upload
                    accept={VALID_IMAGE_EXTENSIONS}
                    disabled={isWindowTypeView}
                    enableCropper
                    fileList={productImages}
                    label={
                        isWindowTypeView
                            ? ''
                            : '(ukuran yang direkomendasikan: 800px x 800px)'
                    }
                    limit={10}
                    listType='picture-card'
                    name='productImages'
                    onRemove={handleRemove('productImages')}
                    onUpload={handleUpload('productImages')}
                />
            </ImgCrop>
            <br />
        </Space>
    )

    const renderOther = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Lainnya</StyledLabel>
            <Field
                component={ReduxFormInput}
                disabled={isWindowTypeView || isOfferStatus}
                formItemProps={{ required: true }}
                label='Judul (maksimal 100 karakter)'
                name='title'
                onChange={handleChangeTitle}
                validate={[required]}
            />
            <Field
                component={ReduxFormInput}
                disabled={isWindowTypeView || isOfferStatus}
                formItemProps={{ required: true }}
                label='Slug'
                name='slug'
                validate={[required]}
            />
            <Field
                component={ReduxFormTextArea}
                disabled={isWindowTypeView || isOfferStatus}
                label='Deskripsi (maksimal 256 karakter)'
                name='description'
                rows={5}
            />
            <br />
        </Space>
    )

    const renderViewOther = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Lainnya</StyledLabel>
            {defaultViewRow(
                'Judul (maksimal 100 karakter)',
                initialValues.title
            )}
            {defaultViewRow('Slug', initialValues.slug)}
            {defaultViewRow('Deskripsi', initialValues.description)}
            <br />
        </Space>
    )

    const renderViewPenerbit = () => (
        <Space direction='vertical' size='middle'>
            <StyledLabel level={5}>Penerbit</StyledLabel>
            {defaultViewRow(
                'Nama',
                getLabelOption(
                    initialValues.penerbitId,
                    mapSelectOptions(penerbits, [
                        'entityIdentificationNo',
                        'name'
                    ])
                )
            )}
            {defaultViewRow(
                'Sektor',
                get(initialValues, 'penerbit.businessSector.value', '')
            )}
            <br />
        </Space>
    )

    const renderPenerbit = () => (
        <Space direction='vertical' size='middle'>
            <StyledLabel level={5}>Penerbit</StyledLabel>
            <Field
                component={ReduxFormSelect}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Nama'
                name='penerbitId'
                onChange={handleChangePenerbit}
                options={mapSelectOptions(penerbits, [
                    'entityIdentificationNo',
                    'name'
                ])}
                validate={[required]}
            />
            <Field
                component={ReduxFormInput}
                disabled
                formItemProps={{ required: true }}
                label='Sektor'
                name='penerbit.businessSector.value'
                validate={[required]}
            />
            <br />
        </Space>
    )

    const renderViewProduct = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Detail</StyledLabel>
            {defaultViewRow(
                'Tipe',
                getLabelOption(
                    initialValues.productTypeId,
                    mapSelectOptions(productTypes, 'title')
                )
            )}
            {defaultViewRow(
                'Kategori',
                getLabelOption(
                    initialValues.productCategoryId,
                    mapSelectOptions(productCategories, 'title')
                )
            )}
            {defaultViewRow(
                'Tag',
                getLabelOption(
                    initialValues.productRibbonId,
                    mapSelectOptions(productRibbons, 'title')
                )
            )}
            {defaultViewRow('Kode', initialValues.code)}
            {defaultViewRow('Tanggal', initialValues.activeDate, 'rangeDate')}
            {defaultViewRow('Harga Per Lembar', initialValues.pricePerShare)}
            {defaultViewRow('Jumlah Lembar', initialValues.numberShare)}
            {defaultViewRow('Jumlah Lembar', initialValues.numberShare)}
            {defaultViewRow('Total Pendanaan', initialValues.target)}
            {defaultViewRow(
                'Minimum Pembelian / Ukuran Lembar',
                initialValues.shareSize
            )}
            {defaultViewRow(
                'Periode Dividen',
                getLabelOption(
                    initialValues.dividendPeriod,
                    dividendPeriodOptions
                )
            )}
            {defaultViewRow('Omzet Tahun Lalu', initialValues.turnoverLastYear)}
            {productTypeId === 1 &&
                defaultViewRow('Suku Bunga', initialValues.interestRate)}
            {productTypeId === 1 &&
                defaultViewRow('Kupon Bunga', initialValues.couponRate)}
            {!isWindowTypeAdd &&
                defaultViewRow('Dana Terkumpul', initialValues.collected)}
            {defaultViewRow('Catatan', initialValues.remarks)}
            <br />
        </Space>
    )

    const disabledActiveDate =
        initialValues.status === 'active'
            ? [true, false]
            : isWindowTypeView || isOfferStatus

    const renderProduct = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Detail</StyledLabel>
            <Field
                component={ReduxFormSelect}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Tipe'
                name='productTypeId'
                options={mapSelectOptions(productTypes, 'title')}
                validate={[required]}
            />
            <Field
                component={ReduxFormSelect}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Kategori'
                name='productCategoryId'
                options={mapSelectOptions(productCategories, 'title')}
                validate={[required]}
            />
            <Field
                allowClear
                component={ReduxFormSelect}
                disabled={isWindowTypeView}
                label='Tag'
                name='productRibbonId'
                options={mapSelectOptions(productRibbons, 'title')}
            />
            <Field
                component={ReduxFormInput}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Kode'
                name='code'
                validate={[required]}
            />
            <Field
                component={ReduxFormPicker}
                disabled={disabledActiveDate}
                disabledDate={disabledDate(false)}
                formItemProps={{ required: true }}
                label='Tanggal'
                name='activeDate'
                type='range'
                validate={[required, todayOnwards]}
            />
            <Space size='large'>
                <Field
                    component={ReduxFormInputNumber}
                    disabled={isDisabled}
                    formItemProps={{ required: true }}
                    label='Harga Per Lembar'
                    max={5000000}
                    min={0}
                    name='pricePerShare'
                    onChange={handleChangePricePerShare}
                    validate={[required]}
                />
                <Field
                    component={ReduxFormInputNumber}
                    disabled={isDisabled}
                    formItemProps={{ required: true }}
                    label='Jumlah Lembar'
                    min={1}
                    name='numberShare'
                    onChange={handleChangeNumberShare}
                    validate={[required]}
                />
            </Space>
            <Field
                component={ReduxFormInputNumber}
                disabled
                label='Total Pendanaan'
                name='target'
            />
            <Field
                component={ReduxFormInputNumber}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Minimum Pembelian / Ukuran Lembar'
                max={numberShare}
                min={1}
                name='shareSize'
                validate={[required]}
            />
            <Space size='large'>
                <Field
                    component={ReduxFormSelect}
                    disabled={isDisabled}
                    formItemProps={{ required: true }}
                    label='Periode Dividen'
                    name='dividendPeriod'
                    options={dividendPeriodOptions}
                    validate={[required]}
                />
                <Field
                    component={ReduxFormInputNumber}
                    disabled={isDisabled}
                    formItemProps={{ required: true }}
                    label='Omzet Tahun Lalu'
                    name='turnoverLastYear'
                    validate={[required]}
                />
            </Space>
            {productTypeId === 1 && (
                <Space size='large'>
                    <Field
                        component={ReduxFormInputNumber}
                        disabled={isDisabled}
                        formItemProps={{ required: productTypeId === 1 }}
                        label='Suku Bunga'
                        name='interestRate'
                    />
                    <Field
                        component={ReduxFormInputNumber}
                        disabled={isDisabled}
                        formItemProps={{ required: productTypeId === 1 }}
                        label='Kupon Bunga'
                        name='couponRate'
                    />
                </Space>
            )}
            {!isWindowTypeAdd && (
                <Field
                    component={ReduxFormInputNumber}
                    disabled
                    label='Dana Terkumpul'
                    name='collected'
                />
            )}
            <Field
                component={ReduxFormTextArea}
                disabled={isWindowTypeView}
                label='Catatan'
                name='remarks'
                rows={5}
            />
            <br />
        </Space>
    )

    const renderViewSettlementDate = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Pasca Pendanaan</StyledLabel>
            {defaultViewRow('Tanggal Settelment', initialValues.settlementDate)}
            <br />
        </Space>
    )

    const renderSettlementDate = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Pasca Pendanaan</StyledLabel>
            <Field
                component={ReduxFormPicker}
                disabled={!isFinished || isOfferStatus}
                disabledDate={disabledDate(true)}
                formItemProps={!isFinished ? {} : { required: true }}
                label='Tanggal Settlement'
                name='settlementDate'
                validate={!isFinished ? [] : [required, todayOnwards]}
            />
            <br />
        </Space>
    )

    let newStatusOptions = statusOptions
    if (isWindowTypeAdd) {
        newStatusOptions = filter(statusOptions, (item) =>
            includes(onGoingStatus, item.value)
        )
    }

    const renderViewStatus = () => (
        <Space direction='vertical'>
            {defaultViewRow(
                'Status',
                getLabelOption(initialValues.status, newStatusOptions)
            )}
            <br />
        </Space>
    )

    const renderStatus = () => {
        return (
            <Space direction='vertical'>
                <Field
                    component={ReduxFormSelect}
                    disabled={isWindowTypeView || isOfferStatus}
                    formItemProps={{ required: true }}
                    label='Status'
                    name='status'
                    onChange={handleChangeStatus}
                    options={newStatusOptions}
                    validate={[required]}
                />
                <br />
            </Space>
        )
    }

    if (isLoading || isActionLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/product${queries}`} />
            <Form onFinish={handleSubmit(handleFinish)}>
                <Space direction='vertical'>
                    {!isWindowTypeView ? renderStatus() : renderViewStatus()}
                    {!isWindowTypeView
                        ? renderPenerbit()
                        : renderViewPenerbit()}
                    {!isWindowTypeView ? renderProduct() : renderViewProduct()}
                    {renderImages()}
                    {renderDocument()}
                    {!isWindowTypeView
                        ? renderSettlementDate()
                        : renderViewSettlementDate()}
                    {!isWindowTypeView ? renderOther() : renderViewOther()}
                    {renderError()}
                    {renderButton()}
                </Space>
            </Form>
        </Space>
    )
}

AddEditView.propTypes = {
    change: PropTypes.func,
    clearProduct: PropTypes.func,
    createProduct: PropTypes.func,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    getPenerbits: PropTypes.func,
    getProduct: PropTypes.func,
    getProductCategories: PropTypes.func,
    getProductRibbons: PropTypes.func,
    getProductTypes: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isUpdatingPassword: PropTypes.array,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    penerbits: PropTypes.array,
    productCategories: PropTypes.array,
    productRibbons: PropTypes.array,
    productTypes: PropTypes.array,
    updateProduct: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default AddEditView
