import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/contactUs'
import * as contactUsAPI from './contactUsAPI'

const initialState = {
    contactUs: {},
    contactUsList: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    totalContactUs: 0
}

export const { getContactUs, getContactUsList, updateContactUs } = contactUsAPI

const contactUsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getContactUs.pending, (state) =>
            Object.assign(state, {
                contactUs: {},
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getContactUs.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                contactUs: toState(data.contactUs),
                isLoading: false,
                totalContactUs: data.count
            })
        })
        builder.addCase(getContactUsList.pending, (state) =>
            Object.assign(state, {
                contactUsList: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getContactUsList.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                contactUsList: map(data.contactUsList, (contactUs) =>
                    toState(contactUs)
                ),
                isLoading: false,
                totalContactUs: data.count
            })
        })
        builder.addCase(updateContactUs.pending, (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false
            })
        )
        builder.addCase(updateContactUs.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true
            })
        )
        builder.addCase(updateContactUs.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true
            })
        )
        builder.addMatcher(
            isAnyOf(getContactUs.rejected, getContactUsList.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'CONTACT_US',
    reducers: {
        clearContactUs: () => initialState
    }
})

export const { clearContactUs } = contactUsSlice.actions

const { reducer } = contactUsSlice
export default reducer
