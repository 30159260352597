import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'
import moment from 'moment'

export const toApi = (data) => ({
    created_by: data.createdBy,
    email: data.email,
    id: data.id,
    is_active: isString(data.isActive)
        ? data.isActive === 'true'
        : data.isActive,
    password: data.password,
    roles: data.roles,
    updated_by: data.updatedBy,
    username: data.username
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const roles = !isEmpty(data.roles)
        ? map(data.roles, (role) => role.value)
        : []

    return {
        createdAt,
        createdBy: data.createdBy || '',
        email: data.email || '',
        id: data.id || null,
        isActive: data.isActive,
        roles,
        updatedAt,
        updatedBy: data.updatedBy || '',
        username: data.username || ''
    }
}
