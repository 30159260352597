import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/productRibbon'
import * as api from '../api'

export const createProductRibbon = createAsyncThunk(
    'CREATE_PRODUCT_RIBBON',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create('/product-ribbon/create', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const deleteProductRibbon = createAsyncThunk(
    'DELETE_PRODUCT_RIBBON',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.deleteById('/product-ribbon/delete', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProductRibbon = createAsyncThunk(
    'GET_PRODUCT_RIBBON',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/product-ribbon', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProductRibbons = createAsyncThunk(
    'GET_PRODUCT_RIBBONS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/product-ribbons', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateProductRibbon = createAsyncThunk(
    'UPDATE_PRODUCT_RIBBON',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/product-ribbon/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
