import { FormItem, InputNumber } from 'antdcomponents'
import PropTypes from 'prop-types'
import React from 'react'

const ReduxFormInputNumber = (props) => {
    const { formItemProps, input, label, meta, ...rest } = props
    const { name, onChange, value } = input
    const { touched, error } = meta

    const handleChange = (val) => {
        onChange(val || 0, name)
    }

    const isError = touched && !!error

    return (
        <FormItem
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
            {...formItemProps}
        >
            <InputNumber
                {...rest}
                name={name}
                onChange={handleChange}
                value={value || 0}
            />
        </FormItem>
    )
}

ReduxFormInputNumber.propTypes = {
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired
}

export default ReduxFormInputNumber
