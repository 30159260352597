import { default as AntdButton } from 'antd/lib/button'
import styled from 'styled-components'

const StyledAntdButton = styled(AntdButton)`
    &&& {
        color: grey;
        margin: 10px 0;
        padding: 0;
    }
`

export default StyledAntdButton
