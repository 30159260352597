import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/penerbit'
import * as api from '../api'

export const createPenerbit = createAsyncThunk(
    'CREATE_PENERBIT',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create('/penerbit/create', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const exportPenerbits = createAsyncThunk(
    'EXPORT_PENERBITS',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/penerbits/export', { options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPenerbit = createAsyncThunk(
    'GET_PENERBIT',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/penerbit', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPenerbits = createAsyncThunk(
    'GET_PENERBITS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/penerbits', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPenerbitStatuses = createAsyncThunk(
    'GET_PENERBITS_STATUSES',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/penerbits/statuses', {
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const sendWelcomeEmailPenerbit = createAsyncThunk(
    'SEND_WELCOME_EMAIL_PENERBIT',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.create('/penerbit/send-mail', { id })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updatePenerbit = createAsyncThunk(
    'UPDATE_PENERBIT',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/penerbit/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
