import { DATE_SLASH_FORMAT, HOUR_FORMAT } from 'constants/formats'
import * as inboxSlice from 'features/inbox/inboxSlice'
import * as inboxDetailSlice from 'features/inbox_detail/inboxDetailSlice'
import get from 'lodash/get'
import split from 'lodash/split'
import moment from 'moment/moment'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import EditView from './EditView'

const EditViewContainer = (props) => {
    const params = useParams()
    return <EditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]
    const username = state.authentication.username
    const momentTime = moment()
    const inbox = state.inbox.inbox

    const initialValues = {
        adminUserId: state.authentication.id,
        createdBy: username,
        createdByFromTable: 'admin_user',
        date: momentTime.format(DATE_SLASH_FORMAT),
        hour: momentTime.format(HOUR_FORMAT),
        isFromAdmin: true,
        isRead: false
    }

    return {
        groupedInboxDetails: state.inboxDetail.groupedInboxDetails,
        inbox,
        initialValues,
        isActionLoadingInbox: state.inbox.isActionLoading,
        isActionLoadingInboxDetail: state.inboxDetail.isActionLoading,
        isActionSuccessInbox: state.inbox.isActionSuccess,
        isLoadingInbox: state.inbox.isLoading,
        isLoadingInboxDetail: state.inboxDetail.isLoading,
        roles: state.authentication.roles,
        username,
        windowType
    }
}

const mapDispatchToProps = {
    clearInbox: inboxSlice.clearInbox,
    clearInboxDetail: inboxDetailSlice.clearInboxDetail,
    createInboxDetail: inboxDetailSlice.createInboxDetail,
    getInbox: inboxSlice.getInbox,
    getInboxDetails: inboxDetailSlice.getInboxDetails,
    updateInbox: inboxSlice.updateInbox,
    updateInboxDetail: inboxDetailSlice.updateInboxDetail,
    updateInboxDetails: inboxDetailSlice.updateInboxDetails
}

const EditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'inbox'
})(EditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(EditViewForm)
