import { default as AntdInput } from 'antd/lib/input'
import colors from 'basic/colors'
import styled from 'styled-components'

const AntdInputTextArea = AntdInput.TextArea
const StyledAntdInputTextArea = styled(AntdInputTextArea)`
    &&& {
        resize: none;

        &,
        &:-internal-autofill-selected {
            border-color: ${(props) =>
                props.$dark ? colors.robbinEggBlue : 'silver'};
            border-width: 2px;
            width: 100%;s
            ${(props) =>
                !props.disabled &&
                `
                background-color: transparent;
                color: ${props.$dark ? 'white' : 'black'};
            `}
        }
    }
`

StyledAntdInputTextArea.defaultProps = {
    placeholder: 'Masukkan data'
}

export default StyledAntdInputTextArea
