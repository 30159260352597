import { MenuOutlined } from '@ant-design/icons'
import colors from 'basic/colors'
import { programs } from 'common/options/adminUser'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import split from 'lodash/split'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { reveal as Menu } from 'react-burger-menu'
import { useNavigate } from 'react-router-dom'
import menuList from './menuList'
import {
    StyledAntdDivider,
    StyledAntdMenu,
    StyledLogo,
    StyledWelcomeUsername
} from './StyledComponents'

const BurgerMenu = (props) => {
    const { onLogout, pathname, roles, username } = props
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)

    const allReads = filter(roles, (item) => includes(item, '_READ'))
    const programList = filter(programs, (item) =>
        includes(allReads, item.abbreviation + '_READ')
    )
    const menus = menuList(programList)

    const handleClick = (e) => {
        handleChangeOpenState()
        const route = get(menus, `[${e.key}.route]`, '')

        if (route === '/logout') {
            return onLogout()
        }

        return navigate(route)
    }

    const renderMenus = () => {
        const items = map(menus, (item, key) => ({ key, label: item.name }))

        const route = '/' + split(pathname, '/')[1]
        const key = findIndex(menus, (item) => item.route === route)

        return (
            <StyledAntdMenu
                items={items}
                mode='inline'
                onClick={handleClick}
                selectedKeys={[String(key)]}
                theme='dark'
            />
        )
    }

    const handleChangeOpenState = (value) => setIsOpen(value || !isOpen)

    const styles = {
        bmBurgerBars: {
            background: colors.gray
        },
        bmBurgerButton: {
            margin: '15px',
            position: 'fixed'
        },
        bmCross: {
            display: 'none'
        },
        bmCrossButton: {
            display: 'none'
        },
        bmMenu: {
            background: colors.persianIndigo
        }
    }

    return (
        <Menu
            customBurgerIcon={<MenuOutlined />}
            disableCloseOnEsc
            isOpen={isOpen}
            onClose={handleChangeOpenState}
            onOpen={handleChangeOpenState}
            outerContainerId={'outer-container'}
            pageWrapId={'page-wrapper'}
            styles={styles}
        >
            <MenuOutlined
                onClick={handleChangeOpenState}
                style={{
                    color: 'white',
                    height: '20px',
                    margin: '18px 12px',
                    width: '20px'
                }}
            />
            <StyledLogo onClick={handleChangeOpenState} />
            <StyledAntdDivider />
            <StyledWelcomeUsername>Hi, {username} !</StyledWelcomeUsername>
            {renderMenus()}
        </Menu>
    )
}

BurgerMenu.propTypes = {
    onLogout: PropTypes.func,
    pathname: PropTypes.string,
    roles: PropTypes.array,
    username: PropTypes.string
}

export default BurgerMenu
