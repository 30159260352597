import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import * as message from 'utility/message'
import { columns, dataSource, rowActions } from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        faqs,
        clearFaq,
        deleteFaq,
        dispatch,
        getFaqs,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        totalFaqs
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOptions = {
        status: 'all'
    }
    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState({
        ...omit(queries, ['_limit', '_page']),
        ...defaultOptions
    })
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )
    const [orderBy, setOrderBy] = useState(defaultOrderBy)

    useEffect(() => {
        const fetchFaqs = async () => {
            const offset = (page - 1) * limit
            return await getFaqs({
                limit,
                offset,
                options: { ...options, orderBy }
            })
        }
        fetchFaqs()

        // componentDidUpdate
        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            fetchFaqs()
        }

        // componentWillUnmount
        return () => {
            dispatch(clearFaq())
        }
    }, [
        clearFaq,
        dispatch,
        getFaqs,
        isActionLoading,
        isActionSuccess,
        limit,
        options,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id } = faqs[key]
        if (type === 'delete') {
            return deleteFaq(id)
        }
        if (type === 'edit') {
            return navigate(`/frequently-asked-question/edit/${id}`)
        }
        if (type === 'view') {
            return navigate(`/frequently-asked-question/view/${id}`)
        }
    }

    const handleAdd = () => navigate('/frequently-asked-question/add')

    const handleSearch = (newOptions) => setOptions(newOptions)

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleClear = () => {
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
        setOptions(defaultOptions)
        setOrderBy(defaultOrderBy)
    }

    const renderActions = (text, record, index) => (
        <TableRowActions options={rowActions(index, handleActions, record)} />
    )

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Pertanyaan Yang Sering Diajukan' }
    ]

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig}
                onAdd={handleAdd}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onSearch={handleSearch}
                totalItems={totalFaqs}
            />
            <Table
                columns={columns(renderActions)}
                dataSource={dataSource(faqs)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    clearFaq: PropTypes.func,
    deleteFaq: PropTypes.func,
    dispatch: PropTypes.func,
    faqs: PropTypes.array,
    getFaqs: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    totalFaqs: PropTypes.number
}

export default List
