import * as adminUserSlice from 'features/admin_user/adminUserSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => <List {...props} />

const mapStateToProps = (state) => ({
    adminUsers: state.adminUser.adminUsers,
    isActionLoading: state.adminUser.isActionLoading,
    isActionSuccess: state.adminUser.isActionSuccess,
    isLoading: state.adminUser.isLoading,
    totalAdminUsers: state.adminUser.totalAdminUsers,
    username: state.authentication.username
})

const mapDispatchToProps = {
    clearAdminUser: adminUserSlice.clearAdminUser,
    deleteAdminUser: adminUserSlice.deleteAdminUser,
    getAdminUsers: adminUserSlice.getAdminUsers,
    updateAdminUser: adminUserSlice.updateAdminUser
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
