import { CloseCircleTwoTone } from '@ant-design/icons'
import colors from 'basic/colors'
import PropTypes from 'prop-types'
import React from 'react'
import StyledModal from './StyledComponents'

const Modal = (props) => {
    const {
        cancelText,
        children,
        closable,
        footer,
        maskClosable,
        okText,
        onCancel,
        onOk,
        title,
        visible
    } = props

    return (
        <StyledModal
            cancelText={cancelText}
            centered
            closable={closable}
            closeIcon={<CloseCircleTwoTone twoToneColor={colors.rose} />}
            destroyOnClose
            footer={footer}
            maskClosable={maskClosable}
            okText={okText}
            onCancel={onCancel}
            onOk={onOk}
            title={title}
            visible={visible}
        >
            {children}
        </StyledModal>
    )
}

Modal.propTypes = {
    cancelText: PropTypes.string,
    children: PropTypes.node,
    closable: PropTypes.bool,
    footer: PropTypes.node,
    maskClosable: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.node,
    visible: PropTypes.bool
}

Modal.defaultProps = {
    cancelText: 'Batal',
    closable: true,
    footer: null,
    maskClosable: false,
    okText: 'Oke'
}

export default Modal
