import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import last from 'lodash/last'
import split from 'lodash/split'
import moment from 'moment'

export const toApi = (data) => ({
    file: data.file,
    identifier: data.identifier,
    penerbit_id: data.penerbitId,
    type: data.type,
    url: data.url
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const identifier = data.identifier || ''
    const url = data.url || ''
    let name = ''
    if (url) {
        name = last(split(url, '/'))
    }

    return {
        createdAt,
        createdBy: data.createdBy || '',
        fieldName: identifier === 'npwp' ? 'npwp' : 'documents',
        id: data.id || null,
        identifier,
        name,
        penerbitId: data.penerbitId || null,
        updatedAt,
        updatedBy: data.updatedBy || '',
        url
    }
}
