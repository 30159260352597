import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import moment from 'moment'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { generateCsv } from 'utility/download'
import * as message from 'utility/message'
import { columns, dataSource, rowActions } from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        clearPenerbit,
        dispatch,
        error,
        exportData,
        exportPenerbits,
        getPenerbits,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isLoadingExport,
        location,
        navigate,
        penerbits,
        totalPenerbits,
        updatePenerbit
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    useEffect(() => {
        const fetchPenerbits = () => {
            const offset = (page - 1) * limit
            getPenerbits({ limit, offset, options: { ...options, orderBy } })
        }

        fetchPenerbits()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }
        if (!isLoadingExport && !error && exportData) {
            const fileName = `rekap_penerbit_${moment().format(
                DATE_HOUR_SLASH_FORMAT
            )}.csv`
            message.success('data berhasil diexport')
            generateCsv(exportData, fileName)
        }

        return () => {
            dispatch(clearPenerbit())
        }
    }, [
        clearPenerbit,
        dispatch,
        error,
        exportData,
        getPenerbits,
        isActionLoading,
        isActionSuccess,
        isLoadingExport,
        limit,
        options,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id, isActive } = penerbits[key]
        if (includes(['block', 'unblock'], type)) {
            const data = { id, isActive: !isActive }
            return updatePenerbit(data)
        }
        if (type === 'edit') {
            return navigate(`/penerbit/edit/${id}`)
        }
        if (type === 'view') {
            return navigate(`/penerbit/view/${id}`)
        }
    }

    const handleClear = () => {
        setOptions({})
        setOrderBy(defaultOrderBy)
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        let newOrderBy = defaultOrderBy

        if (!isEmpty(sorter)) {
            newOrderBy = { by: sorter.field, order: sorter.order }

            if (sorter.field === 'name') {
                newOrderBy = {
                    by: ['$entity_type.value$', 'entity_name'],
                    order: [sorter.order, sorter.order]
                }
            }
        }
        setOrderBy(newOrderBy)
    }

    const handleExport = () => {
        exportPenerbits({
            options: {
                ...options,
                orderBy
            }
        })
    }

    const handleSearch = (newOptions) => setOptions(newOptions)

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Penerbit' }
    ]

    const renderActions = (text, record, index) => (
        <TableRowActions options={rowActions(index, handleActions, record)} />
    )

    const handleAdd = () => navigate('/penerbit/add')

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig}
                onAdd={handleAdd}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onExport={handleExport}
                onSearch={handleSearch}
                totalItems={totalPenerbits}
            />
            <Table
                columns={columns(renderActions)}
                dataSource={dataSource(penerbits)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    clearPenerbit: PropTypes.func,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    exportData: PropTypes.string,
    exportPenerbits: PropTypes.func,
    getPenerbits: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingExport: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    penerbits: PropTypes.array,
    totalPenerbits: PropTypes.number,
    updatePenerbit: PropTypes.func
}

export default List
