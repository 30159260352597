import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import { initialPenerbitManagementFees } from 'common/helper/penerbit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import trimStart from 'lodash/trimStart'
import moment from 'moment'
import * as businessSectorTransformer from './businessSector'
import * as cityTransformer from './city'
import * as districtTransformer from './district'
import * as entityTypeTransformer from './entityType'
import * as fundingAmountTransformer from './fundingAmount'
import * as penerbitBankinfoTransformer from './penerbitBankinfo'
import * as penerbitDetailTransformer from './penerbitDetail'
import * as penerbitDocumentTransformer from './penerbitDocument'
import * as penerbitManagementFeeTransformer from './penerbitManagementFee'

export const toApi = (data) => {
    const is_active = isString(data.isActive)
        ? data.isActive === 'true'
        : data.isActive
    const mobile_number = data.mobileNumber
        ? '+62' + trimStart(data.mobileNumber, '0')
        : undefined

    const terms_and_conditions_approval_date = moment(
        data.termsAndConditionsApprovalDate,
        DATE_HOUR_SLASH_FORMAT
    ).isValid()
        ? moment
              .utc(data.termsAndConditionsApprovalDate, DATE_HOUR_SLASH_FORMAT)
              .toISOString()
        : undefined
    const risk_statement_approval_date = moment(
        data.riskStatementApprovalDate,
        DATE_HOUR_SLASH_FORMAT
    ).isValid()
        ? moment
              .utc(data.riskStatementApprovalDate, DATE_HOUR_SLASH_FORMAT)
              .toISOString()
        : undefined

    const penerbit_bankinfo = !isEmpty(data.penerbitBankinfo)
        ? penerbitBankinfoTransformer.toApi(
              Object.assign(data.penerbitBankinfo, {
                  penerbitId: get(data, 'penerbitBankinfo.penerbitId', data.id)
              })
          )
        : undefined
    const penerbit_details = !isEmpty(data.penerbitDetails)
        ? map(data.penerbitDetails, (item) =>
              penerbitDetailTransformer.toApi(
                  Object.assign(item, {
                      penerbitId: data.id || item.penerbitId
                  })
              )
          )
        : undefined
    const penerbit_documents = !isEmpty(data.penerbitDocuments)
        ? map(data.penerbitDocuments, (item) =>
              penerbitDocumentTransformer.toApi(
                  Object.assign(item, {
                      penerbitId: data.id || item.penerbitId
                  })
              )
          )
        : undefined
    const penerbit_management_fees = !isEmpty(data.penerbitManagementFees)
        ? map(data.penerbitManagementFees, (item) =>
              penerbitManagementFeeTransformer.toApi(
                  Object.assign(item, {
                      penerbitId: data.id || item.penerbitId
                  })
              )
          )
        : undefined

    return {
        address: data.address,
        business_sector_id: data.businessSectorId,
        city_id: data.cityId,
        created_by: data.createdBy,
        description: data.description,
        district_id: data.districtId,
        email: data.email,
        entity_code: data.entityCode ? toLower(data.entityCode) : undefined,
        entity_identification_no: data.entityIdentificationNo,
        entity_name: data.entityName,
        entity_type_id: data.entityTypeId,
        funding_amount_id: data.fundingAmountId,
        id: data.id,
        is_account_profile_viewable: data.isAccountProfileViewable,
        is_active,
        is_email_verified: data.isEmailVerified,
        is_risk_statement_approved: data.isRiskStatementApproved,
        is_terms_and_conditions_approved: data.isTermsAndConditionsApproved,
        is_uploading: data.isUploading,
        mobile_number,
        penerbit_bankinfo,
        penerbit_details,
        penerbit_documents,
        penerbit_management_fees,
        postal_code: data.postalCode,
        product_type_id: data.productTypeId,
        profile_picture_url: data.profilePictureUrl,
        remarks: data.remarks,
        risk_statement_approval_date,
        status: data.status,
        terms_and_conditions_approval_date,
        updated_by: data.updatedBy,
        username: data.username,
        website_url: data.websiteUrl
    }
}

export const toState = (data) => {
    const sortedDetails = sortBy(data.penerbitDetails, 'id')
    const mobileNumber = data.mobileNumber
        ? replace(data.mobileNumber, '+62', '')
        : ''
    const productType = get(data.productType, 'title', '')

    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const termsAndConditionsApprovalDate = moment(
        data.termsAndConditionsApprovalDate
    ).isValid()
        ? moment(data.termsAndConditionsApprovalDate).format(
              DATE_HOUR_SLASH_FORMAT
          )
        : null
    const riskStatementApprovalDate = moment(
        data.riskStatementApprovalDate
    ).isValid()
        ? moment(data.riskStatementApprovalDate).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const businessSector = !isEmpty(data.businessSector)
        ? businessSectorTransformer.toState(data.businessSector)
        : {}
    const city = !isEmpty(data.city) ? cityTransformer.toState(data.city) : {}
    const district = !isEmpty(data.district)
        ? districtTransformer.toState(data.district)
        : {}
    const entityType = !isEmpty(data.entityType)
        ? entityTypeTransformer.toState(data.entityType)
        : {}
    const penerbitBankinfo = !isEmpty(data.penerbitBankinfo)
        ? penerbitBankinfoTransformer.toState(data.penerbitBankinfo)
        : {}
    const penerbitDetails = !isEmpty(data.penerbitDetails)
        ? map(sortedDetails, (item, key) =>
              Object.assign(penerbitDetailTransformer.toState(item), { key })
          )
        : []
    const penerbitDocuments = !isEmpty(data.penerbitDocuments)
        ? map(data.penerbitDocuments, (item) =>
              penerbitDocumentTransformer.toState(item)
          )
        : []
    const penerbitManagementFees = !isEmpty(data.penerbitManagementFees)
        ? map(data.penerbitManagementFees, (item) =>
              penerbitManagementFeeTransformer.toState(item)
          )
        : initialPenerbitManagementFees

    const fundingAmount = !isEmpty(data.fundingAmount)
        ? fundingAmountTransformer.toState(data.fundingAmount)
        : {}

    const entityTypeValue = get(entityType, 'value', '')
    const entityName = data.entityName || ''
    const name = entityTypeValue
        ? `${entityTypeValue} ${entityName}`
        : entityName

    return {
        address: data.address || '',
        businessSector,
        businessSectorId: data.businessSectorId || null,
        city,
        cityId: data.cityId || null,
        createdAt,
        createdBy: data.createdBy || '',
        description: data.description || '',
        district,
        districtId: data.districtId || null,
        email: data.email || '',
        entityCode: data.entityCode ? toUpper(data.entityCode) : '',
        entityIdentificationNo: data.entityIdentificationNo || '',
        entityName,
        entityType,
        entityTypeId: data.entityTypeId || null,
        fundingAmount,
        fundingAmountId: data.fundingAmountId || null,
        id: data.id || null,
        isAccountProfileViewable: data.isAccountProfileViewable || false,
        isActive: data.isActive || false,
        isEmailVerified: data.isEmailVerified || false,
        isRiskStatementApproved: data.isRiskStatementApproved || false,
        isTermsAndConditionsApproved:
            data.isTermsAndConditionsApproved || false,
        isWelcomeEmailSent: data.isWelcomeEmailSent || false,
        mobileNumber,
        name,
        penerbitBankinfo,
        penerbitDetails,
        penerbitDocuments,
        penerbitManagementFees,
        postalCode: data.postalCode || '',
        productType,
        productTypeId: data.productTypeId || null,
        profilePictureUrl: data.profilePictureUrl || '',
        remarks: data.remarks || '',
        riskStatementApprovalDate,
        status: data.status || '',
        termsAndConditionsApprovalDate,
        updatedAt,
        updatedBy: data.updatedBy || '',
        username: data.username || '',
        websiteUrl: data.websiteUrl || ''
    }
}
