import { default as AntdCollapse } from 'antd/lib/collapse'
import { Tag } from 'antdcomponents'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import indexOf from 'lodash/indexOf'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import keyBy from 'lodash/keyBy'
import keys from 'lodash/keys'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addQueryString } from 'utility/url'
import FilterBlockForm from './FilterBlockForm'

const AntdCollapsePanel = AntdCollapse.Panel

const getNewFilters = (filters, formState) => {
    const newFilters = cloneDeep(filters)
    forEach(newFilters, (filter) => {
        if (isFunction(filter.options)) {
            filter._renderOptions = filter.options
        }
        if (filter._renderOptions) {
            filter.options = filter._renderOptions(filter.dataSource, formState)
        }
    })
    return newFilters
}

const FilterBlock = (props) => {
    const { formState, form, ...rest } = props
    const {
        excludePagination,
        isCollapsed,
        onCollapse,
        onClear,
        onSearch,
        queries,
        resetField,
        setField,
        title
    } = props
    const navigate = useNavigate()

    const filters = getNewFilters(props.filters, formState)
    // const [filters, setFilters] = useState(initialFilters)
    const [isBlocking, setIsBlocking] = useState(false)
    const [tags, setTags] = useState([])

    // componentDidMount
    useEffect(() => {
        // let isQueryExist = some(
        //     filters,
        //     (item) => item.defaultValue !== undefined || item.required
        // )
        const filterQueries = map(filters, 'query')
        forEach(queries, (value, key) => {
            if (indexOf(filterQueries, key) >= 0) {
                setField(
                    key,
                    Number(value) + '' === value ? Number(value) : value
                )
                // isQueryExist = true
            }
        })

        // if (isQueryExist) {
        //     setIsBlocking(true)
        //     triggerSubmit()
        // }
        // else {
        //     forEach(filterQueries, (value) => resetField(value))
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     if (filters, formState) {
    //         const newFilters = getNewFilters(filters, formState)
    //         setFilters(newFilters)
    //     }
    //
    //     need to confirm? onFieldChange?
    //     if (formState) {
    //         onFieldChange(
    //             prevProps.formState,
    //             props.formState,
    //             prevProps.setField
    //         )
    //     }
    //     eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [filters, formState, getNewFilters, setFilters])
    // TODO: need to cleanup code above

    const getValue = (values, key) => {
        const value = values[key]

        const filterItem = find(filters, (item) => item.query === key)
        if (filterItem) {
            if (filterItem.type === 'select') {
                const valueLabelMapping = mapValues(
                    keyBy(filterItem.options, 'value'),
                    'label'
                )
                return map(
                    isArray(value) ? value : [value],
                    (item) => valueLabelMapping[item]
                ).join(', ')
            }
        }
        return value
    }

    const handleClickTag = (e) => e.stopPropagation()

    const handleRemoveTag = (value, name, e) => {
        e.stopPropagation()
        resetField(name)
    }

    const handleSearch = (values) => {
        const labelMapping = {}
        forEach(filters, (value) => {
            labelMapping[value.query]
        })

        const newValues = omitBy(values, (value) => {
            const isInvalid =
                (isArray(value) && !value[0] && !value[1]) || !value
            return isInvalid
        })

        const newTags = map(keys(newValues), (key) => ({
            name: key,
            value: getValue(newValues, key)
        }))
        setTags(newTags)

        // https://v5.reactrouter.com/web/example/preventing-transitions => preventChangePage
        if (isBlocking || excludePagination) {
            setIsBlocking(false)
        } else {
            newValues._page = 1
        }

        addQueryString(navigate, newValues, map(filters, 'query'))
        onSearch(omit(newValues, ['_page']))
    }

    const renderHeader = isCollapsed ? (
        title
    ) : (
        <div>
            <span>{title}</span>
            {map(tags, (tag) => (
                <Tag
                    closable
                    key={tag.name}
                    name={tag.name}
                    onClick={handleClickTag}
                    onClose={handleRemoveTag}
                    value={`${tag.value}`}
                />
            ))}
        </div>
    )

    return (
        <AntdCollapse
            activeKey={isCollapsed ? ['1'] : []}
            bordered={false}
            ghost={isCollapsed ? false : true}
            onChange={onCollapse}
        >
            <AntdCollapsePanel header={renderHeader} key='1' showArrow={false}>
                <FilterBlockForm
                    {...rest}
                    filters={filters}
                    form={form}
                    initialValues={formState}
                    onClear={onClear}
                    onSearch={handleSearch}
                />
            </AntdCollapsePanel>
        </AntdCollapse>
    )
}

FilterBlock.propTypes = {
    excludePagination: PropTypes.bool,
    filters: PropTypes.array,
    form: PropTypes.string.isRequired,
    formState: PropTypes.object,
    isCollapsed: PropTypes.bool,
    onClear: PropTypes.func,
    onCollapse: PropTypes.func,
    onSearch: PropTypes.func,
    queries: PropTypes.object,
    resetField: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    title: PropTypes.string
}

FilterBlock.defaultProps = {
    filters: [],
    onClear: noop,
    onSearch: noop
}

export default FilterBlock
