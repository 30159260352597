import { default as AntdSpin } from 'antd/lib/spin'
import PropTypes from 'prop-types'
import React from 'react'

const Spin = (props) => {
    const { delay, indicator, size, spinning, children } = props

    return (
        <AntdSpin
            delay={delay}
            indicator={indicator}
            size={size}
            spinning={spinning}
        >
            {children}
        </AntdSpin>
    )
}

Spin.propTypes = {
    children: PropTypes.any,
    delay: PropTypes.number,
    indicator: PropTypes.node,
    size: PropTypes.oneOf(['default', 'large', 'small']),
    spinning: PropTypes.bool,
    tip: PropTypes.string
}

Spin.defaultProps = {
    size: 'default',
    tip: ''
}

export default Spin
