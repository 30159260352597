import { DownOutlined } from '@ant-design/icons'
import { default as AntdDropdown } from 'antd/lib/dropdown'
import { default as AntdMenu } from 'antd/lib/menu'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'

const Dropdown = (props) => {
    const { disabled, options, placement, title, trigger } = props

    const handleClick = (item) => {
        const handler = options[Number(item.key)].onClick
        return handler ? handler() : null
    }

    const mapped = map(options, (option, key) => ({
        disabled: option.isDisabled,
        key: key.toString(),
        label: option.content
    }))

    const menu = <AntdMenu items={mapped} onClick={handleClick} />

    return (
        <AntdDropdown
            disabled={disabled}
            overlay={menu}
            placement={placement}
            trigger={trigger}
        >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
                {title} <DownOutlined />
            </a>
        </AntdDropdown>
    )
}

Dropdown.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
                .isRequired,
            isDisabled: PropTypes.bool,
            onClick: PropTypes.func
        })
    ).isRequired,
    placement: PropTypes.oneOf([
        'bottomCenter',
        'bottomLeft',
        'bottomRight',
        'topCenter',
        'topLeft',
        'topRight'
    ]),
    title: PropTypes.string,
    trigger: PropTypes.array
}
Dropdown.defaultProps = {
    disabled: false,
    options: [],
    placement: 'bottomLeft',
    title: '',
    trigger: ['hover']
}
export default Dropdown
