import { DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE } from 'constants/constants'
import { VALID_IMAGE_EXTENSIONS } from 'constants/formats'
import { PlusOutlined } from '@ant-design/icons'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdTypography } from 'antd/lib/typography'
import {
    ReduxFormInput,
    ReduxFormPicker,
    ReduxFormSelect,
    ReduxFormTextArea,
    Space,
    Upload
} from 'antdcomponents'
import { getInitialPenerbitDetail } from 'common/helper/penerbit'
import { genderOptions, verifiedStatusOptions } from 'common/options'
import { getCityOptions, getProvinceOptions } from 'common/options/city'
import {
    getCityDistricts,
    getSubdistrictOptions
} from 'common/options/district'
import IsVerifiedSpan from 'components/is_verified_span/IsVerifiedSpan'
import StyledLabel from 'components/styled_label/StyledLabel'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import toUpper from 'lodash/toUpper'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import defaultViewRow from 'utility/defaultViewRow'
import getLabelOption from 'utility/getLabelOption'
import mapSelectOptions from 'utility/mapSelectOptions'
import * as modal from 'utility/modal'
import useSelectorFormValues from 'utility/useSelectorFormValues'

const AntdTypographyLink = AntdTypography.Link
const AntdTypographyText = AntdTypography.Text

const PenerbitDetail = (props) => {
    const {
        annualSalaries,
        change,
        cities,
        clearDistrict,
        districts,
        getCities,
        getDistricts,
        hometowns,
        incomeSources,
        initialValues,
        isWindowTypeView,
        penerbitDetails,
        provinces,
        setPenerbitKeyState
    } = props

    const [currCities, setCurrCities] = useState([])
    const [currDistricts, setCurrDistricts] = useState([])

    const formValues = useSelectorFormValues('penerbit')
    const penerbitDetailKey = get(formValues, 'penerbitDetailKey', '0')
    const penerbitDetailIdentifier = `penerbitDetails[${penerbitDetailKey}]`

    const penerbitDetail = get(formValues, penerbitDetailIdentifier, {})
    const isKycVerified = get(
        initialValues,
        `penerbitDetails.${penerbitDetailKey}.isKycVerified`,
        false
    )
    const isEmailVerified = get(penerbitDetail, 'isEmailVerified', false)
    const city = get(penerbitDetail, 'city', {})
    const cityId = get(penerbitDetail, 'cityId', null)
    const districtId = get(penerbitDetail, 'districtId', null)
    const penerbitDetailDocuments = get(
        penerbitDetail,
        'penerbitDetailDocuments',
        []
    )

    const disableAll =
        isWindowTypeView || isEmpty(penerbitDetails) || isKycVerified

    const ktp = filter(
        penerbitDetailDocuments,
        (item) => item.fieldName === 'ktp'
    )
    const selfie = filter(
        penerbitDetailDocuments,
        (item) => item.fieldName === 'selfie'
    )

    const { province = '' } = city

    const getAllPenerbitDetailOptions = () =>
        map(penerbitDetails, (item, key) => ({
            label: item.idCardName,
            value: key
        }))

    const getVerifiedStatusOptions = () =>
        map(verifiedStatusOptions, (item) => ({
            ...item,
            value: item.value === 'true'
        }))

    useEffect(() => {
        if (province) {
            const penerbitCity = find(
                cities,
                (cit) => cit.province === province
            )
            if (!isEmpty(penerbitCity)) {
                setCurrCities(cities)
            }
        }
    }, [province, cities])

    useEffect(() => {
        if (cityId) {
            const penerbitDistrict = find(
                districts,
                (cit) => cit.cityId === cityId
            )
            if (!isEmpty(penerbitDistrict)) {
                setCurrDistricts(districts)
            }
        }
    }, [cityId, districts])

    useEffect(() => {
        const fetchCities = () => getCities({ options: { province } })
        if (province) {
            fetchCities()
        }
    }, [province, getCities])

    useEffect(() => {
        const fetchDistricts = () =>
            getDistricts({ options: { cityId: cityId } })
        if (cityId) {
            fetchDistricts()
        }
    }, [cityId, getDistricts])

    const handleAdd = async () => {
        const newPenerbitDetailKey = size(penerbitDetails)
        await change(
            `penerbitDetails[${newPenerbitDetailKey}]`,
            getInitialPenerbitDetail('Personel Baru')
        )
        change('penerbitDetailKey', newPenerbitDetailKey)
    }

    const handleChangeCity = (value) => {
        clearDistrict()
        const currCity = find(currCities, (item) => item.id === value)
        change(`${penerbitDetailIdentifier}.city`, currCity || city)
        change(`${penerbitDetailIdentifier}.districtId`, undefined)
        change(`${penerbitDetailIdentifier}.postalCode`, '')
    }

    const handleChangeDistrict = (value) => {
        const currDistrict = find(currDistricts, (item) => item.id === value)
        change(
            `${penerbitDetailIdentifier}.postalCode`,
            currDistrict.postalCode
        )
    }

    const handleChangeProvince = (value) => {
        clearDistrict()
        change(`${penerbitDetailIdentifier}.city`, { province: value })
        change(`${penerbitDetailIdentifier}.cityId`, null)
        change(`${penerbitDetailIdentifier}.districtId`, null)
    }

    const handleRemove = (fieldName) => (options) => {
        const { fileList = [] } = options
        let newPenerbitDetailDocuments = []
        if (fieldName === 'ktp') {
            newPenerbitDetailDocuments = concat(fileList, selfie)
        }
        if (fieldName === 'selfie') {
            newPenerbitDetailDocuments = concat(ktp, fileList)
        }
        change(
            `${penerbitDetailIdentifier}.penerbitDetailDocuments`,
            newPenerbitDetailDocuments
        )
    }

    const handleUpload = (fieldName) => (options) => {
        const { fileList = [], onProgress = noop, onSuccess = noop } = options
        const newFileList = map(cloneDeep(fileList), (item) => {
            return Object.assign(item, {
                fieldName,
                identifier: get(item, 'identifier', fieldName),
                key: penerbitDetailKey,
                onProgress,
                onSuccess
            })
        })

        let newPenerbitDetailDocuments = []
        if (fieldName === 'ktp') {
            newPenerbitDetailDocuments = concat(newFileList, selfie)
        }
        if (fieldName === 'selfie') {
            newPenerbitDetailDocuments = concat(ktp, newFileList)
        }
        change(
            `${penerbitDetailIdentifier}.penerbitDetailDocuments`,
            newPenerbitDetailDocuments
        )
    }

    const renderDropdownBottomNode = !isWindowTypeView && (
        <AntdTypographyLink onClick={handleAdd}>
            <PlusOutlined /> Tambah Personel
        </AntdTypographyLink>
    )

    const renderKtpAndSelfie = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Berkas Identitas</StyledLabel>
            <AntdRow align='middle' justify='space-between' type='flex'>
                <AntdCol span={11}>{renderUpload('ktp')}</AntdCol>
                <AntdCol offset={2} span={11}>
                    {renderUpload('selfie')}
                </AntdCol>
            </AntdRow>
            <br />
        </Space>
    )

    const renderViewKycVerified = () => (
        <Space direction='vertical'>
            {defaultViewRow(
                'Status KYC',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.isKycVerified`,
                        null
                    ),
                    getVerifiedStatusOptions()
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Link Digisign',
                get(
                    initialValues,
                    `${penerbitDetailIdentifier}.digisignUrl`,
                    ''
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            <br />
        </Space>
    )

    const renderKycVerified = () => (
        <Space direction='vertical'>
            <Field
                component={ReduxFormSelect}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Status KYC'
                name={`${penerbitDetailIdentifier}.isKycVerified`}
                options={getVerifiedStatusOptions()}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={disableAll}
                label='Link Digisign'
                name={`${penerbitDetailIdentifier}.digisignUrl`}
            />
            <br />
        </Space>
    )

    const renderNote = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Catatan</StyledLabel>
            <Field
                component={ReduxFormTextArea}
                disabled={disableAll}
                name={`${penerbitDetailIdentifier}.remarks`}
                rows={5}
            />
            <br />
        </Space>
    )

    const renderViewPenerbitDetail = () => (
        <Space direction='vertical'>
            {defaultViewRow(
                'Email',
                get(initialValues, `${penerbitDetailIdentifier}.email`, ''),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nomor Telepon Seluler',
                get(
                    initialValues,
                    `${penerbitDetailIdentifier}.mobileNumber`,
                    ''
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Jabatan',
                get(initialValues, `${penerbitDetailIdentifier}.position`, ''),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nomor KTP',
                get(
                    initialValues,
                    `${penerbitDetailIdentifier}.idCardNumber`,
                    ''
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nama Lengkap Sesuai KTP',
                get(
                    initialValues,
                    `${penerbitDetailIdentifier}.idCardName`,
                    ''
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Jenis Kelamin',
                get(initialValues, `${penerbitDetailIdentifier}.gender`, ''),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Tempat Lahir',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.hometownId`,
                        null
                    ),
                    getCityOptions(hometowns)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Tanggal Lahir',
                get(initialValues, `${penerbitDetailIdentifier}.birthDate`, ''),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Provinsi',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.city.province`,
                        null
                    ),
                    getProvinceOptions(provinces)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kota',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.cityId`,
                        null
                    ),
                    getCityOptions(currCities, city)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kecamatan',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.districtId`,
                        null
                    ),
                    getCityDistricts(districtId, currDistricts)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kelurahan',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.districtId`,
                        null
                    ),
                    getSubdistrictOptions(currDistricts, districtId)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kode Pos',
                get(
                    initialValues,
                    `${penerbitDetailIdentifier}.postalCode`,
                    ''
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Alamat Lengkap Sesuai KTP',
                get(initialValues, `${penerbitDetailIdentifier}.address`, ''),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nama Ibu Kandung',
                get(
                    initialValues,
                    `${penerbitDetailIdentifier}.motherName`,
                    ''
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Penghasilan Tahunan',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.annualSalaryId`,
                        null
                    ),
                    mapSelectOptions(annualSalaries, 'value', false)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Sumber Penghasilan',
                getLabelOption(
                    get(
                        initialValues,
                        `${penerbitDetailIdentifier}.incomeSourceId`,
                        null
                    ),
                    mapSelectOptions(incomeSources, 'value')
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
        </Space>
    )

    const renderPenerbitDetail = () => (
        <Space direction='vertical'>
            <div>
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormInput}
                    disabled={disableAll}
                    formItemProps={{
                        noMargin: isEmailVerified,
                        required: true
                    }}
                    label='Email'
                    name={`${penerbitDetailIdentifier}.email`}
                />
                <IsVerifiedSpan hideUnverified isVerified={isEmailVerified} />
            </div>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Nomor Telepon Seluler'
                name={`${penerbitDetailIdentifier}.mobileNumber`}
                prefix='+62'
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Jabatan'
                name={`${penerbitDetailIdentifier}.position`}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Nomor KTP'
                name={`${penerbitDetailIdentifier}.idCardNumber`}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Nama Lengkap Sesuai KTP'
                name={`${penerbitDetailIdentifier}.idCardName`}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Jenis Kelamin'
                name={`${penerbitDetailIdentifier}.gender`}
                options={genderOptions}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Tempat Lahir'
                name={`${penerbitDetailIdentifier}.hometownId`}
                options={getCityOptions(hometowns)}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormPicker}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Tanggal Lahir'
                name={`${penerbitDetailIdentifier}.birthDate`}
            />
            <Space>
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={disableAll}
                    formItemProps={{ required: true }}
                    label='Provinsi'
                    name={`${penerbitDetailIdentifier}.city.province`}
                    onChange={handleChangeProvince}
                    options={getProvinceOptions(provinces)}
                />
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={disableAll || isEmpty(city)}
                    formItemProps={{ required: true }}
                    label='Kota'
                    name={`${penerbitDetailIdentifier}.cityId`}
                    onChange={handleChangeCity}
                    options={getCityOptions(currCities, city)}
                />
            </Space>
            <Space>
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={disableAll || !cityId || isEmpty(currDistricts)}
                    formItemProps={{ required: true }}
                    label='Kecamatan'
                    name={`${penerbitDetailIdentifier}.districtId`}
                    onChange={handleChangeDistrict}
                    options={getCityDistricts(districtId, currDistricts)}
                />
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={
                        disableAll ||
                        !cityId ||
                        isEmpty(currDistricts) ||
                        !districtId
                    }
                    formItemProps={{ required: true }}
                    label='Kelurahan'
                    name={`${penerbitDetailIdentifier}.districtId`}
                    onChange={handleChangeDistrict}
                    options={getSubdistrictOptions(currDistricts, districtId)}
                />
            </Space>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView || !districtId || isKycVerified}
                formItemProps={{ required: true }}
                label='Kode Pos'
                name={`${penerbitDetailIdentifier}.postalCode`}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormTextArea}
                disabled={disableAll}
                formItemProps={{ required: true }}
                label='Alamat Lengkap Sesuai KTP'
                name={`${penerbitDetailIdentifier}.address`}
                placeholder='Contoh : Jalan Ahmad Yani Gang 5 Nomor 80'
                rows={3}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={disableAll}
                label='Nama Ibu Kandung'
                name={`${penerbitDetailIdentifier}.motherName`}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={disableAll}
                label='Penghasilan Tahunan'
                name={`${penerbitDetailIdentifier}.annualSalaryId`}
                options={mapSelectOptions(annualSalaries, 'value', false)}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={disableAll}
                label='Sumber Penghasilan'
                name={`${penerbitDetailIdentifier}.incomeSourceId`}
                options={mapSelectOptions(incomeSources, 'value')}
            />
            <br />
        </Space>
    )

    const handleChangeDetailKey = (value) => {
        setPenerbitKeyState(value)
    }

    const handleRemoveOption = (value) => (e) => {
        e.stopPropagation()
        const deletedPenerbitDetail = find(
            penerbitDetails,
            (item) => item.key === value
        )
        const idCardName = get(deletedPenerbitDetail, 'idCardName', '')
        modal.confirm({
            content: `Hapus personel ${idCardName}`,
            onOk: () => {
                let newPenerbitDetails = filter(
                    penerbitDetails,
                    (item) => item.key !== value
                )
                newPenerbitDetails = map(newPenerbitDetails, (item, key) =>
                    Object.assign(item, { key })
                )
                change('penerbitDetails', newPenerbitDetails)
                if (penerbitDetailKey >= value) {
                    change('penerbitDetailKey', penerbitDetailKey - 1)
                }
            }
        })
    }

    const renderTitle = () => (
        <Space>
            <StyledLabel level={5}>Screening</StyledLabel>
            <Field
                component={ReduxFormSelect}
                dropdownBottomNode={renderDropdownBottomNode}
                name='penerbitDetailKey'
                onChange={handleChangeDetailKey}
                onRemoveOption={handleRemoveOption}
                options={getAllPenerbitDetailOptions()}
            />
        </Space>
    )

    const renderUpload = (fieldName) => {
        const isFieldNameKtp = fieldName === 'ktp'
        const label = isFieldNameKtp ? toUpper(fieldName) : startCase(fieldName)
        const fileList = isFieldNameKtp ? ktp : selfie
        const name = fieldName + penerbitDetailKey

        return (
            <Upload
                accept={VALID_IMAGE_EXTENSIONS}
                disabled={disableAll}
                fileList={fileList}
                formItemProps={{ required: true }}
                label={label}
                limit={1}
                listType='picture-card'
                name={name}
                onRemove={handleRemove(fieldName)}
                onUpload={handleUpload(fieldName)}
            />
        )
    }

    return (
        <Space direction='vertical'>
            {renderTitle()}
            {isEmpty(penerbitDetails) && (
                <AntdTypographyText type='danger'>
                    * Mohon tambahkan personel terlebih dahulu
                </AntdTypographyText>
            )}
            {!isWindowTypeView
                ? renderPenerbitDetail()
                : renderViewPenerbitDetail()}
            {renderKtpAndSelfie()}
            {renderNote()}
            {!isWindowTypeView ? renderKycVerified() : renderViewKycVerified()}
        </Space>
    )
}

PenerbitDetail.propTypes = {
    annualSalaries: PropTypes.array,
    change: PropTypes.func,
    cities: PropTypes.array,
    clearDistrict: PropTypes.func,
    districts: PropTypes.array,
    getCities: PropTypes.func,
    getDistricts: PropTypes.func,
    hometowns: PropTypes.array,
    incomeSources: PropTypes.array,
    initialValues: PropTypes.object,
    isWindowTypeView: PropTypes.bool,
    penerbitDetails: PropTypes.array,
    provinces: PropTypes.array,
    setPenerbitKeyState: PropTypes.func
}

export default PenerbitDetail
