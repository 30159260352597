import { default as AntdInput } from 'antd/lib/input'
import colors from 'basic/colors'
import styled from 'styled-components'

const StyledAntdInput = styled(AntdInput)`
    &&& {
        padding: 0;
        input {
            padding: 4px 0 4px 11px;
            ${(props) => (props.suffix ? '' : 'padding-left: 11px;')}
        }
        .ant-input-suffix {
            padding-right: 11px;
        }

        &,
        input,
        input:-internal-autofill-selected {
            border-color: ${(props) =>
                props.$dark ? colors.robbinEggBlue : 'silver'};
            border-width: 2px;
            width: 100%;
            ${(props) =>
                !props.disabled &&
                `
                background-color: transparent;
                color: ${props.$dark ? 'white' : 'black'};
            `}
        }
    }
`

export default StyledAntdInput
