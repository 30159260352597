import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import DatePicker from './DatePicker'
import DatetimePicker from './DatetimePicker'
import MonthPicker from './MonthPicker'
import RangePicker from './RangePicker'
import TimePicker from './TimePicker'

const Picker = (props) => {
    const {
        defaultValue,
        disabled,
        disabledDate,
        format,
        minuteStep,
        mode,
        name,
        onChange,
        onOpenChange,
        placeholder,
        size,
        timeProps,
        type,
        value
    } = props

    let PickerComponent = DatePicker
    if (type === 'datetime') {
        PickerComponent = DatetimePicker
    }
    if (type === 'month') {
        PickerComponent = MonthPicker
    }
    if (type === 'range') {
        PickerComponent = RangePicker
    }
    if (type === 'time') {
        PickerComponent = TimePicker
    }

    return (
        <PickerComponent
            defaultValue={defaultValue}
            disabled={disabled}
            disabledDate={disabledDate}
            format={format}
            minuteStep={minuteStep}
            mode={mode}
            name={name}
            onChange={onChange}
            onOpenChange={onOpenChange}
            placeholder={placeholder}
            size={size}
            timeProps={timeProps}
            value={value}
        />
    )
}

Picker.propTypes = {
    bordered: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDate: PropTypes.func,
    format: PropTypes.string,
    minuteStep: PropTypes.number,
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onOpenChange: PropTypes.func,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    timeProps: PropTypes.object,
    type: PropTypes.oneOf(['date', 'datetime', 'month', 'range', 'time']),
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

Picker.defaultProps = {
    bordered: true,
    disabledDate: noop,
    minuteStep: 5,
    placeholder: 'Pilih tanggal',
    size: 'default',
    type: 'date'
}

export default Picker
