import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { default as AntdTooltip } from 'antd/lib/tooltip'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledAntdPagination } from './StyledComponents'

const Pagination = (props) => {
    const {
        current,
        defaultCurrent,
        defaultPageSize,
        onChange,
        pageSize,
        pageSizeOptions,
        showSizeChanger,
        size
    } = props

    const getTotal = () => {
        const { total, maxSupportedCount } = props

        if (!maxSupportedCount) {
            return total
        }

        return total > maxSupportedCount ? maxSupportedCount : total
    }

    const renderTotal = () => {
        const { total, maxSupportedCount } = props
        if (!maxSupportedCount) {
            return <span>{`Total ${total} item`}</span>
        }

        if (total > maxSupportedCount) {
            return (
                <AntdTooltip content={`${total}`}>
                    <span>{`Total ${maxSupportedCount}+ item`}</span>
                </AntdTooltip>
            )
        } else {
            return <span>{`Total ${total} item`}</span>
        }
    }

    return (
        <StyledAntdPagination
            current={current}
            defaultCurrent={defaultCurrent}
            defaultPageSize={defaultPageSize}
            onChange={(current, pageSize) => onChange(current, pageSize)}
            onShowSizeChange={(current, pageSize) =>
                onChange(defaultCurrent, pageSize)
            }
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            showQuickJumper={false}
            showSizeChanger={showSizeChanger}
            showTotal={() => renderTotal()}
            size={size}
            total={getTotal()}
        />
    )
}

Pagination.propTypes = {
    current: PropTypes.number,
    defaultCurrent: PropTypes.number,
    defaultPageSize: PropTypes.number,
    maxSupportedCount: PropTypes.number,
    onChange: PropTypes.func,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
    showSizeChanger: PropTypes.bool,
    size: PropTypes.string,
    total: PropTypes.number
}

Pagination.defaultProps = {
    defaultCurrent: DEFAULT_PAGINATION_CONFIG.defaultCurrent,
    defaultPageSize: DEFAULT_PAGINATION_CONFIG.defaultPageSize,
    maxSupportedCount: 0,
    onChange: noop,
    pageSizeOptions: DEFAULT_PAGINATION_CONFIG.pageSizeOptions,
    showSizeChanger: true,
    size: ''
}

export default Pagination
