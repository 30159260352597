import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/pemodal'
import * as pemodalAPI from './pemodalAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    pemodal: {},
    pemodalStatuses: {},
    pemodals: [],
    totalPemodals: 0
}

export const { getPemodal, getPemodals, updatePemodal, getPemodalStatuses } =
    pemodalAPI

const pemodal = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPemodal.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                pemodal: {}
            })
        )
        builder.addCase(getPemodal.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                pemodal: toState(data.pemodal)
            })
        })
        builder.addCase(getPemodals.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                pemodals: []
            })
        )
        builder.addCase(getPemodals.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                pemodals: map(data.pemodals, (item) => toState(item)),
                totalPemodals: data.count
            })
        })
        builder.addCase(getPemodalStatuses.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getPemodalStatuses.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                pemodalStatuses: data
            })
        })
        builder.addCase(updatePemodal.pending, (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false
            })
        )
        builder.addCase(updatePemodal.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true
            })
        )
        builder.addCase(updatePemodal.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true
            })
        )
        builder.addMatcher(
            isAnyOf(
                getPemodal.rejected,
                getPemodals.rejected,
                getPemodalStatuses.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'PEMODAL',
    reducers: {
        clearPemodal: () => initialState
    }
})

export const { clearPemodal } = pemodal.actions

const { reducer } = pemodal
export default reducer
