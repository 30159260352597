import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/penerbit'
import * as penerbitAPI from './penerbitAPI'

const initialState = {
    error: '',
    exportData: '',
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    isLoadingExport: false,
    isSendEmailLoading: false,
    isSendEmailSuccess: false,
    penerbit: {},
    penerbitStatuses: {},
    penerbits: [],
    totalPenerbits: 0
}

export const {
    createPenerbit,
    exportPenerbits,
    getPenerbit,
    getPenerbits,
    getPenerbitStatuses,
    updatePenerbit,
    sendWelcomeEmailPenerbit
} = penerbitAPI

const penerbitSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(exportPenerbits.pending, (state) =>
            Object.assign(state, {
                error: '',
                exportData: '',
                isLoadingExport: true
            })
        )
        builder.addCase(exportPenerbits.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                exportData: data.text,
                isLoadingExport: false
            })
        })
        builder.addCase(exportPenerbits.rejected, (state, action) =>
            Object.assign(state, {
                error: action.errorMessage,
                isLoadingExport: false
            })
        )
        builder.addCase(getPenerbit.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbit: {}
            })
        )
        builder.addCase(getPenerbit.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbit: toState(data.penerbit)
            })
        })
        builder.addCase(getPenerbits.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbits: []
            })
        )
        builder.addCase(getPenerbits.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbits: map(data.penerbits, (item) => toState(item)),
                totalPenerbits: data.count
            })
        })
        builder.addCase(getPenerbitStatuses.pending, (state) =>
            Object.assign(state, {
                isLoading: true
            })
        )
        builder.addCase(getPenerbitStatuses.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbitStatuses: data
            })
        })
        builder.addCase(sendWelcomeEmailPenerbit.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isSendEmailLoading: true,
                isSendEmailSuccess: false
            })
        )
        builder.addCase(sendWelcomeEmailPenerbit.fulfilled, (state) => {
            return Object.assign(state, {
                isSendEmailLoading: false,
                isSendEmailSuccess: true
            })
        })
        builder.addCase(sendWelcomeEmailPenerbit.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isSendEmailLoading: false
            })
        )
        builder.addCase(createPenerbit.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true
            })
        )
        builder.addCase(updatePenerbit.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
                penerbit: toState(data.penerbit)
            })
        })
        builder.addMatcher(
            isAnyOf(createPenerbit.pending, updatePenerbit.pending),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false
                })
        )
        builder.addMatcher(
            isAnyOf(createPenerbit.rejected, updatePenerbit.rejected),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(
                getPenerbit.rejected,
                getPenerbits.rejected,
                getPenerbitStatuses.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'PENERBIT',
    reducers: {
        clearPenerbit: () => initialState
    }
})

export const { clearPenerbit } = penerbitSlice.actions

const { reducer } = penerbitSlice
export default reducer
