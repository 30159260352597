import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { reset } from 'redux-form'
import * as message from 'utility/message'
import * as config from './config'
import filterConfig from './filterConfig'
import CreateInbox from './modal/CreateInbox'

const List = (props) => {
    const {
        handleSubmit,
        inboxes,
        isLoading,
        isActionLoading,
        isActionSuccess,
        totalInboxes,
        clearInbox,
        clearPemodal,
        clearPenerbit,
        createInbox,
        getInboxes,
        getPemodals,
        getPenerbits,
        dispatch,
        pemodals,
        penerbits,
        userId,
        username
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))
    const stateParams = useLocation()
    const stateValue = stateParams.state

    const [choice, setChoice] = useState(0)
    const [isVisible, setIsVisible] = useState(false)

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Kotak Masuk' }
    ]

    useEffect(() => {
        const fetchInbox = () => {
            const offset = (page - 1) * limit
            getInboxes({
                limit,
                offset,
                options: { ...options, orderBy }
            })
        }

        fetchInbox()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }

        return () => {
            dispatch(clearInbox())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        clearInbox,
        dispatch,
        isActionLoading,
        isActionSuccess,
        limit,
        options,
        orderBy,
        page
    ])

    const handleCancel = () => setIsVisible(false)

    const handleClear = () => {
        setOptions({})
        setOrderBy(defaultOrderBy)
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const handleFinish = (values) => {
        const inboxObj = {
            adminUserId: userId,
            createdBy: username,
            fromTable: 'admin_user',
            inboxDetail: { message: values.message },
            toTable: lowerCase(values.toTable)
        }

        if (values.toTable === 'penerbit')
            Object.assign(inboxObj, { entityCode: choice })
        else Object.assign(inboxObj, { toUser: choice })

        createInbox(inboxObj)
        setIsVisible(false)
        dispatch(reset('inbox'))
    }

    useEffect(() => {
        setIsVisible(stateValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearch = (newOptions) => setOptions(newOptions)

    const renderActions = (text, record) => (
        <TableRowActions options={config.rowActions(record.id)} />
    )
    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <CreateInbox
                clearPemodal={clearPemodal}
                clearPenerbit={clearPenerbit}
                getPemodals={getPemodals}
                getPenerbits={getPenerbits}
                handleFinish={handleFinish}
                handleSubmit={handleSubmit}
                isVisible={isVisible}
                onCancel={handleCancel}
                pemodals={pemodals}
                penerbits={penerbits}
                setChoice={setChoice}
            />
            <TableHeader
                filterConfig={filterConfig}
                onAdd={() => setIsVisible(true)}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onSearch={handleSearch}
                totalItems={totalInboxes}
            />
            <Table
                bordered
                columns={config.columns(renderActions)}
                dataSource={config.dataSource(inboxes)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    clearInbox: PropTypes.func,
    clearPemodal: PropTypes.func,
    clearPenerbit: PropTypes.func,
    createInbox: PropTypes.func,
    dispatch: PropTypes.func,
    getInboxes: PropTypes.func,
    getPemodals: PropTypes.func,
    getPenerbits: PropTypes.func,
    handleSubmit: PropTypes.func,
    inboxes: PropTypes.array,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    pemodals: PropTypes.array,
    penerbits: PropTypes.array,
    totalInboxes: PropTypes.number,
    userId: PropTypes.number,
    username: PropTypes.string
}

export default List
