import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/incomeSource'
import * as incomeSourceAPI from './incomeSourceAPI'

const initialState = {
    incomeSources: [],
    isError: false,
    isLoading: false,
    totalIncomeSources: 0
}

export const { getIncomeSources } = incomeSourceAPI

const incomeSourceSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getIncomeSources.pending, (state) =>
            Object.assign(state, {
                incomeSources: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getIncomeSources.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                incomeSources: map(data.incomeSources, (item) => toState(item)),
                isLoading: false,
                totalIncomeSources: data.count
            })
        })
        builder.addCase(getIncomeSources.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false
            })
        )
    },
    initialState,
    name: 'INCOME_SOURCE',
    reducers: {
        clearIncomeSource: () => initialState
    }
})

export const { clearIncomeSource } = incomeSourceSlice.actions

const { reducer } = incomeSourceSlice
export default reducer
