import { default as AntdAutoComplete } from 'antd/lib/auto-complete'
import { FormItem } from 'antdcomponents'
import PropTypes from 'prop-types'
import React from 'react'

const ReduxFormAutoComplete = (props) => {
    const {
        allowClear,
        options,
        meta,
        label,
        formItemProps,
        input,
        keyValue,
        value,
        ...restProps
    } = props
    const { name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    const handleSearch = (value) => {
        input.onChange(value)
    }

    const handleChange = (value) => {
        if (!keyValue || !value) return input.onChange(value)
        return input.onChange(
            find(
                options,
                (option) => toString(option.value[keyValue]) === value
            )['value']
        )
    }

    return (
        <FormItem
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
            {...formItemProps}
        >
            <AntdAutoComplete
                {...restProps}
                allowClear={allowClear}
                name={name}
                onChange={handleChange}
                onSearch={handleSearch}
                options={options}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormAutoComplete.propTypes = {
    allowClear: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    keyValue: PropTypes.string,
    label: PropTypes.any,
    meta: PropTypes.object.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.string
}

ReduxFormAutoComplete.defaultProps = {
    allowClear: true,
    notFoundContent: 'Tidak ditemukan',
    placeholder: 'Masukkan data'
}

export default ReduxFormAutoComplete
