import { FormItem, InputPassword } from 'antdcomponents'
import PropTypes from 'prop-types'
import React from 'react'

const ReduxFormInputPassword = (props) => {
    const { dark, disabled, formItemProps, input, label, meta, ...rest } = props
    const { extra, required } = formItemProps || {}
    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            dark={dark}
            extra={extra}
            hasFeedback={isError}
            help={isError && error}
            label={label}
            name={name}
            required={required}
            validateStatus={isError ? 'error' : undefined}
            {...formItemProps}
        >
            <InputPassword
                {...rest}
                dark={dark}
                disabled={disabled}
                inputValue={value}
                name={name}
                onChange={onChange}
            />
        </FormItem>
    )
}

ReduxFormInputPassword.propTypes = {
    bordered: PropTypes.bool,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object.isRequired
}

export default ReduxFormInputPassword
