/* eslint-disable react-hooks/exhaustive-deps */
import Tooltip from 'antd/es/tooltip'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdForm } from 'antd/lib/form'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import {
    BackButton,
    Breadcrumb,
    Button,
    ReduxFormInput,
    Space
} from 'antdcomponents'
import colors from 'basic/colors'
import imagePath from 'basic/imagePath'
import * as inboxOptions from 'common/options/inbox'
import StyledLabel from 'components/styled_label/StyledLabel'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { Field } from 'redux-form'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import * as modal from 'utility/modal'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'
import ProductOrderCard from './comps/ProductOrderCard'
import {
    StyledAntdDivider,
    StyledCheckCircleTwoTone,
    StyledCloseCircleTwoTone,
    StyledMessageBox,
    StyledMessageRow,
    StyledSendButtonImage
} from './StyledComponents'

const EditView = (props) => {
    const {
        change,
        clearInbox,
        clearInboxDetail,
        createInboxDetail,
        dispatch,
        getInbox,
        getInboxDetails,
        groupedInboxDetails,
        inbox,
        initialValues,
        isActionLoadingInbox,
        isActionSuccessInbox,
        isActionLoadingInboxDetail,
        isLoadingInbox,
        isLoadingInboxDetail,
        location,
        navigate,
        params,
        updateInbox,
        updateInboxDetail,
        updateInboxDetails,
        username,
        windowType
    } = props

    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const isWindowTypeView = windowType === 'view'
    const isExpiredInbox = inbox.status === 'expired'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Kotak Masuk', linkTo: '/inbox' },
        { label: getPageName(windowType) }
    ]
    const formValues = useSelectorFormValues('inbox')

    useEffect(() => {
        if (!isActionLoadingInbox && isActionSuccessInbox) {
            message.success('data sukses terkirim')
        }
    }, [isActionLoadingInbox, isActionSuccessInbox])

    //componentDidMount
    useEffect(() => {
        if (identifier) {
            fetchInbox()
        }

        return () => {
            dispatch(clearInbox())
            dispatch(clearInboxDetail())
        }
    }, [])

    const prevIsLoadingInbox = usePrevious(isLoadingInbox)
    useEffect(() => {
        if (prevIsLoadingInbox && !isLoadingInbox && !inbox.id) {
            navigate('/inbox')
        }
        // eslint-disable-next-line
    }, [isLoadingInbox])
    const fetchInbox = async () => {
        await clearInboxDetail()
        await getInbox(identifier)
        await fetchInboxDetails()
    }

    const fetchInboxDetails = () =>
        getInboxDetails({ options: { inboxId: identifier } })

    const handleBulkUpdateDetails = (data) => async () => {
        await updateInboxDetails(data)
        fetchInboxDetails()
    }

    const handleSendMessage = async () => {
        const newMessage = get(formValues, 'message', '')

        if (!newMessage) {
            return null
        }

        const newInboxDetail = Object.assign(initialValues, {
            inboxId: identifier,
            isRead: true,
            isReviewed: true,
            message: newMessage
        })

        await createInboxDetail(newInboxDetail)
        await fetchInboxDetails()
        change('message', '')
    }

    const handleUpdateInbox = () => {
        modal.confirm({
            content:
                'Percakapan yang ditandai sebagai selesai tidak dapat mengirim maupun menerima pesan dari manapun.',
            onOk: () => {
                const data = {
                    id: identifier,
                    status: 'expired',
                    updatedBy: username
                }
                updateInbox(data)
            }
        })
    }

    const handleUpdateInboxDetail = async (values) => {
        const updatedData = {
            id: values.id,
            isDeleted: values.isDeleted || 0,
            isReviewed: values.isReviewed,
            updatedBy: username
        }

        await updateInboxDetail(updatedData)
        fetchInboxDetails()
    }

    const handleClickDetailCard = (total, id) => () => {
        if (total) {
            window.open('/product/view/' + id, '_blank')
        } else {
            window.open('/purchase-order/view/' + id, '_blank')
        }
    }

    const renderConversation = () => (
        <Space direction='vertical'>
            {map(groupedInboxDetails, (detail, key) => {
                const messageNode = (
                    <Fragment>
                        <AntdRow align='middle' justify='center'>
                            <span>{detail[0].date}</span>
                        </AntdRow>
                        <Space direction='vertical'>
                            {renderMessages(detail)}
                        </Space>
                    </Fragment>
                )

                if (key !== 0 || (!inbox.productId && !inbox.purchaseOrderId)) {
                    return messageNode
                }

                let categoryTitle = get(inbox, 'product.category', null)
                if (!categoryTitle) {
                    categoryTitle = get(
                        inbox,
                        'purchaseOrder.product.category',
                        null
                    )
                }
                let imageUrl = get(inbox, 'product.productImages.0.url', null)
                if (!imageUrl) {
                    imageUrl = get(
                        inbox,
                        'purchaseOrder.product.productImages.0.url',
                        null
                    )
                }
                let typeTitle = get(inbox, 'product.type', null)
                if (!typeTitle) {
                    typeTitle = get(inbox, 'purchaseOrder.product.type', null)
                }
                const entityName =
                    get(inbox, 'penerbit.entityType.value', null) +
                    ' ' +
                    get(inbox, 'penerbit.entityName', null)
                const noDoc = get(inbox, 'purchaseOrder.noDoc', '')
                const total = get(inbox, 'product.target', 0)

                return (
                    <Fragment>
                        <ProductOrderCard
                            category={categoryTitle}
                            entityName={entityName}
                            imageUrl={imageUrl}
                            noDoc={noDoc}
                            onClick={handleClickDetailCard(
                                total,
                                inbox.productId || inbox.purchaseOrderId
                            )}
                            total={total}
                            type={typeTitle}
                        />
                        {messageNode}
                    </Fragment>
                )
            })}
        </Space>
    )

    const renderMessages = (detail) =>
        map(detail, (item, key) => {
            const isApproved = item.isReviewed && !item.isDeleted
            const isRejected = item.isReviewed && item.isDeleted
            const disabledSign = isApproved || isRejected || isWindowTypeView

            return (
                <StyledMessageRow isFromAdmin={item.isFromAdmin} key={key}>
                    <StyledMessageBox>
                        <div className='header'>
                            {item.createdBy + ' ' + item.hour}
                        </div>
                        <div className='content'>{item.message}</div>
                    </StyledMessageBox>
                    {!item.isFromAdmin && (
                        <AntdSpace>
                            <StyledCheckCircleTwoTone
                                disabled={disabledSign}
                                onClick={
                                    disabledSign
                                        ? null
                                        : () =>
                                              handleUpdateInboxDetail({
                                                  ...item,
                                                  isReviewed: 1
                                              })
                                }
                                twoToneColor={
                                    isApproved
                                        ? colors.blueberry
                                        : colors.silver
                                }
                            />
                            <StyledCloseCircleTwoTone
                                disabled={disabledSign}
                                onClick={
                                    disabledSign
                                        ? null
                                        : () =>
                                              handleUpdateInboxDetail({
                                                  ...item,
                                                  isDeleted: 1,
                                                  isReviewed: 1
                                              })
                                }
                                twoToneColor={
                                    isRejected ? colors.rose : colors.silver
                                }
                            />
                        </AntdSpace>
                    )}
                </StyledMessageRow>
            )
        })

    const SendMessageButton = () => {
        if (isExpiredInbox || isWindowTypeView) {
            return null
        }

        return (
            <Tooltip title='Kirim Pesan'>
                <StyledSendButtonImage
                    onClick={handleSendMessage}
                    src={imagePath.sendButton}
                    width={20}
                />
            </Tooltip>
        )
    }

    const renderMessageField = () => {
        const disableMessageForm =
            isWindowTypeView || isActionLoadingInboxDetail || isExpiredInbox

        const disableBulkUpdate = isEmpty(
            filter(flatten(groupedInboxDetails), (conv) => !conv.isReviewed)
        )

        return (
            <Fragment>
                <Field
                    component={ReduxFormInput}
                    disabled={disableMessageForm}
                    formItemProps={{ required: true }}
                    name='message'
                    onPressEnter={handleSendMessage}
                    placeholder='Ketik Pesan'
                    suffix={SendMessageButton()}
                />
                <AntdRow align='center' gutter={[16, 16]} justify='center'>
                    <AntdCol>
                        <Button
                            disabled={disableMessageForm || disableBulkUpdate}
                            loading={isActionLoadingInboxDetail}
                            name='agree-all-btn'
                            onClick={handleBulkUpdateDetails({
                                inboxId: inbox.id,
                                isReviewed: 1
                            })}
                            type='primary'
                            value='Setujui Semua'
                        />
                    </AntdCol>
                    <AntdCol>
                        <Button
                            disabled={disableMessageForm || disableBulkUpdate}
                            loading={isActionLoadingInboxDetail}
                            name='reject-all-btn'
                            onClick={handleBulkUpdateDetails({
                                inboxId: inbox.id,
                                isDeleted: 1,
                                isReviewed: 1
                            })}
                            value='Tolak Semua'
                        />
                    </AntdCol>
                </AntdRow>
                <AntdRow align='center' gutter={[16, 16]} justify='center'>
                    <Button
                        disabled={disableMessageForm}
                        loading={isActionLoadingInboxDetail}
                        name='expire-inbox'
                        onClick={handleUpdateInbox}
                        type='primary'
                        value='Percakapan Ditandai Selesai'
                    />
                </AntdRow>
            </Fragment>
        )
    }

    const currStatus = inboxOptions.inboxStatus(
        inbox,
        flatten(groupedInboxDetails)
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/inbox${queries}`} />
            <AntdForm
                colon={false}
                disabled={isWindowTypeView}
                labelAlign='left'
                labelCol={isWindowTypeView ? { flex: '200px' } : {}}
                labelWrap
                layout={isWindowTypeView ? 'horizontal' : 'vertical'}
                loading={isLoadingInbox || isLoadingInboxDetail}
                wrapperCol={{ flex: 1 }}
            >
                <Space direction='vertical' size='middle'>
                    <Space direction='vertical' size={4}>
                        <StyledLabel level={5}>
                            Status: {currStatus.label}
                        </StyledLabel>
                        <StyledAntdDivider />
                    </Space>
                    <Space direction='vertical'>
                        <StyledLabel level={5} size={4}>
                            {`${get(inbox, 'sender.name')} - ${get(
                                inbox,
                                'receiver.name'
                            )}`}
                        </StyledLabel>
                        <StyledAntdDivider />
                    </Space>
                    {renderConversation()}
                    {renderMessageField()}
                </Space>
            </AntdForm>
        </Space>
    )
}

EditView.propTypes = {
    change: PropTypes.func,
    clearInbox: PropTypes.func,
    clearInboxDetail: PropTypes.func,
    createInboxDetail: PropTypes.func,
    dispatch: PropTypes.func,
    getInbox: PropTypes.func,
    getInboxDetails: PropTypes.func,
    groupedInboxDetails: PropTypes.array,
    id: PropTypes.number,
    inbox: PropTypes.object,
    initialValues: PropTypes.object,
    isActionLoadingInbox: PropTypes.bool,
    isActionLoadingInboxDetail: PropTypes.bool,
    isActionSuccessInbox: PropTypes.bool,
    isLoadingInbox: PropTypes.bool,
    isLoadingInboxDetail: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    roles: PropTypes.array,
    updateInbox: PropTypes.func,
    updateInboxDetail: PropTypes.func,
    updateInboxDetails: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default EditView
