import { default as AntdRadio } from 'antd/lib/radio'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledGroup } from './StyledComponents'

const RadioGroup = (props) => {
    const {
        defaultValue,
        disabled,
        name,
        onChange,
        options,
        size,
        type,
        value
    } = props

    const renderOptionLabel = (option) =>
        isFunction(option.renderLabel) ? option.renderLabel() : option.label

    const renderOptions = (options, type) => {
        let RadioComponent = AntdRadio
        if (type === 'button') {
            RadioComponent = AntdRadio.Button
        }

        return map(options, (o) => (
            <RadioComponent disabled={o.disabled} key={o.value} value={o.value}>
                {renderOptionLabel(o)}
            </RadioComponent>
        ))
    }

    const handleChange = (e) => onChange(e.target.value, name, e)

    return (
        <StyledGroup
            defaultValue={defaultValue}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            size={size}
            value={value}
        >
            {renderOptions(options, type)}
        </StyledGroup>
    )
}

RadioGroup.propTypes = {
    defaultValue: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.string,
            renderLabel: PropTypes.func,
            value: PropTypes.any.isRequired
        })
    ),
    size: PropTypes.oneOf(['small', 'default', 'large']),
    type: PropTypes.oneOf(['button', 'radio']),
    value: PropTypes.any
}

RadioGroup.defaultProps = {
    size: 'middle'
}

export default RadioGroup
