import { statusOptions } from 'common/options/penerbit'
import EyeIcon from 'components/eye_icon/EyeIcon'
import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import React from 'react'
import { blockTextChanger, getTypeAction } from 'utility/blockTextChanger'

const rowActions = (index, handleActions, record) => [
    {
        id: record.id,
        type: 'view'
    },
    {
        id: record.id,
        type: 'edit'
    },
    {
        confirmContent: `Apakah anda yakin ingin ${blockTextChanger(
            record.isActive
        )} penerbit ini (${record.email} / ${record.username})?`,
        onClick: handleActions(index, getTypeAction(record.isActive)),
        type: getTypeAction(record.isActive)
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'isActive',
        render: (bool) => (bool ? 'Ya' : 'Tidak'),
        sorter: true,
        title: 'Aktif',
        width: 100
    },
    {
        dataIndex: 'status',
        render: (text) => {
            const statusOption = find(
                statusOptions,
                (item) => item.value === text
            )
            return get(statusOption, 'label', '')
        },
        title: 'Status KYC',
        width: 150
    },
    {
        dataIndex: 'email',
        sorter: true,
        title: 'Email',
        width: 300
    },
    {
        dataIndex: 'mobileNumber',
        render: (text) => (text ? '(+62)' + text : ''),
        title: 'Nomor Telepon Seluler',
        width: 200
    },
    {
        dataIndex: 'entityIdentificationNo',
        title: 'Nomor NPWP',
        width: 150
    },
    {
        render: (object) => {
            const entityName = get(object, 'entityName', '')
            const name = get(object, 'name', '')
            return (
                <React.Fragment>
                    <EyeIcon route={`/product/?penerbitName=${entityName}`} />{' '}
                    {name}
                </React.Fragment>
            )
        },
        title: 'Nama',
        width: 250
    },
    {
        dataIndex: 'username',
        sorter: true,
        title: 'Username',
        width: 150
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'updatedBy',
        title: 'Diperbarui Oleh',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
