import { default as AntdForm } from 'antd/lib/form'
import colors from 'basic/colors'
import styled from 'styled-components'

const AntdFormItem = AntdForm.Item
const StyledAntdFormItem = styled(AntdFormItem)`
    &&& {
        .ant-form-item-label > label::after {
            margin: 0 2px !important;
        }
        .ant-form-item-label {
            padding: 0px;
            margin-right: 10px;
        }
        .ant-form-item-label
            > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none;
        }
        ${(props) =>
            props.$noMargin &&
            `
                margin: 0px;
        `}
        ${(props) =>
            props.required &&
            `
            .ant-form-item-label::after {
                color: ${colors.rose};
                content: '*';
            }
        `}
    }
`

export default StyledAntdFormItem
