import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/city'
import * as cityAPI from './cityAPI'

const initialState = {
    cities: [],
    isError: false,
    isLoading: false,
    provinces: [],
    totalCities: 0
}

export const { getCities, getProvinces } = cityAPI

const citySlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getCities.pending, (state) =>
            Object.assign(state, {
                cities: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getCities.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                cities: map(data.cities, (item) => toState(item)),
                isLoading: false,
                totalCities: data.count
            })
        })
        builder.addCase(getProvinces.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                provinces: []
            })
        )
        builder.addCase(getProvinces.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                provinces: data.provinces
            })
        })
        builder.addMatcher(
            isAnyOf(getCities.rejected, getProvinces.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'CITY',
    reducers: {
        clearCity: () => initialState
    }
})

export const { clearCity } = citySlice.actions

const { reducer } = citySlice
export default reducer
