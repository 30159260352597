import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/product'
import * as productAPI from './productAPI'

const initialState = {
    error: '',
    exportData: '',
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    isLoadingExport: false,
    product: {},
    productStatuses: {},
    products: [],
    totalProducts: 0
}

export const {
    createProduct,
    deleteProduct,
    exportProducts,
    getProduct,
    getProducts,
    getProductStatuses,
    updateProduct
} = productAPI

const productSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(exportProducts.pending, (state) =>
            Object.assign(state, {
                error: '',
                exportData: '',
                isLoadingExport: true
            })
        )
        builder.addCase(exportProducts.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                exportData: data.text,
                isLoadingExport: false
            })
        })
        builder.addCase(exportProducts.rejected, (state, action) =>
            Object.assign(state, {
                error: action.errorMessage,
                isLoadingExport: false
            })
        )
        builder.addCase(getProduct.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                product: {}
            })
        )
        builder.addCase(getProduct.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isError: false,
                isLoading: false,
                product: toState(data.product)
            })
        })
        builder.addCase(getProducts.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                products: []
            })
        )
        builder.addCase(getProducts.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isError: false,
                isLoading: false,
                products: map(data.products, (item) => toState(item)),
                totalProducts: data.count
            })
        })
        builder.addCase(getProductStatuses.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getProductStatuses.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productStatuses: data
            })
        })
        builder.addMatcher(
            isAnyOf(
                createProduct.pending,
                deleteProduct.pending,
                updateProduct.pending
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false,
                    isError: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createProduct.fulfilled,
                deleteProduct.fulfilled,
                updateProduct.fulfilled
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true,
                    isError: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createProduct.rejected,
                deleteProduct.rejected,
                updateProduct.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(
                getProduct.rejected,
                getProducts.rejected,
                getProductStatuses.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'PRODUCT',
    reducers: {
        clearProduct: () => initialState
    }
})

export const { clearProduct } = productSlice.actions

const { reducer } = productSlice
export default reducer
