import { default as AntdDescriptions } from 'antd/lib/descriptions'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'

const AntdDecsItem = AntdDescriptions.Item

const Descriptions = (props) => {
    const { bordered, items, layout, size, title } = props

    const renderItem = (item, key) => {
        return (
            <AntdDecsItem
                disabled={item.isDisabled}
                extra={item.extra}
                key={key.toString()}
                label={item.label}
                name={item.name}
            ></AntdDecsItem>
        )
    }

    return (
        <AntdDescriptions
            bordered={bordered}
            layout={layout}
            size={size}
            title={title}
        >
            {map(items, (item, key) => renderItem(item, key))}
        </AntdDescriptions>
    )
}

Descriptions.propTypes = {
    bordered: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            extra: PropTypes.node,
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    layout: PropTypes.oneOf(['vertical', 'horizontal']),
    size: PropTypes.oneOf(['default', 'middle', 'small']),
    title: PropTypes.string.isRequired
}

export default Descriptions
