import { statusOptions } from 'common/options/product'
import mapSelectOptions from 'utility/mapSelectOptions'

const filterConfig = (productTypes) => [
    {
        name: 'status',
        options: statusOptions,
        placeholder: 'Cari Status',
        query: 'status',
        type: 'select'
    },
    {
        name: 'productTypeId',
        options: mapSelectOptions(productTypes, 'title'),
        placeholder: 'Cari Tipe',
        query: 'productTypeId',
        type: 'select'
    },
    {
        name: 'code',
        placeholder: 'Cari Kode',
        query: 'code',
        type: 'input'
    },
    {
        name: 'penerbitName',
        placeholder: 'Cari Penerbit',
        query: 'penerbitName',
        type: 'input'
    }
]

export default filterConfig
