import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdMenu } from 'antd/lib/menu'
import imagePath from 'basic/imagePath'
import isMobile from 'is-mobile'
import styled from 'styled-components'

export const StyledLogo = styled.div`
    background-image: url(${imagePath.moneypool});
    background-repeat: no-repeat;
    background-size: contain;
    height: 50px;
    margin: ${() => (isMobile() ? '0px 50px 0px' : '0px 50px 0px')};
`

export const StyledAntdDivider = styled(AntdDivider)`
    &&& {
        margin: ${() => (isMobile() ? '10px' : '25px')};
        min-width: auto;
        width: auto;
    }
`

export const StyledAntdMenu = styled(AntdMenu)`
    &&& {
        .ant-menu-item {
            padding: 0px 50px !important;
        }
    }
`

export const StyledWelcomeUsername = styled.span`
    color: white;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    padding: 0px 50px !important;
`
