import * as faqSlice from 'features/faq/faqSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => <List {...props} />

const mapStateToProps = (state) => ({
    faqs: state.faq.faqs,
    isActionLoading: state.faq.isActionLoading,
    isActionSuccess: state.faq.isActionSuccess,
    isLoading: state.faq.isLoading,
    totalFaqs: state.faq.totalFaqs
})

const mapDispatchToProps = {
    clearFaq: faqSlice.clearFaq,
    deleteFaq: faqSlice.deleteFaq,
    getFaqs: faqSlice.getFaqs
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
