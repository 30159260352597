import programList from 'common/programList'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const Add = (props) => {
    const { roles } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const [addFunctionPath, setAddFunctionPath] = useState(undefined)
    const [isWriteAllowed, setIsWriteAllowed] = useState(false)

    useEffect(() => {
        // componentDidMount
        const program = find(
            programList,
            (item) => item.path === `/${params.name}`
        )
        const acl = get(program, 'acl', [])

        if (!isEmpty(acl)) {
            const readAcl = find(acl, (item) => includes(item, '_READ'))
            const readRole = find(roles, (item) => item === readAcl)
            if (isEmpty(readRole)) {
                return navigate('/')
            }

            const writeAcl = find(acl, (item) => includes(item, '_WRITE'))
            const writeRole = find(roles, (item) => item === writeAcl)
            const newIsWriteAllowed = writeAcl === writeRole
            if (!newIsWriteAllowed) {
                return navigate('/')
            }

            setAddFunctionPath(program.addFunctionPath)
            setIsWriteAllowed(newIsWriteAllowed)
        }
    }, [navigate, params, roles])

    if (!addFunctionPath) {
        return null
    }

    let Function = addFunctionPath
    return (
        <Function
            dispatch={dispatch}
            isWriteAllowed={isWriteAllowed}
            location={location}
            navigate={navigate}
            windowType='add'
        />
    )
}

Add.propTypes = {
    navigate: PropTypes.func,
    roles: PropTypes.array
}

const mapStateToProps = (state) => ({
    roles: state.authentication.roles
})

export default connect(mapStateToProps)(Add)
