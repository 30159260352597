import { notification } from 'antd'
import { Spin } from 'antdcomponents'
import React from 'react'

const MESSAGE_DURATION = 3

const triggerNotification = (
    type,
    description = '[ no description ]',
    message = 'Info',
    duration = MESSAGE_DURATION,
    icon = null
) => notification[type]({ description, duration, icon, message })

const error = (content) =>
    triggerNotification('error', content, 'Terjadi kesalahan!')
const info = (content) => triggerNotification('info', content, 'Info')
const success = (content) => triggerNotification('success', content, 'Sukses')
const warning = (content) =>
    triggerNotification('warning', content, 'Peringatan')
const loading = (content, message = 'Memuat', duration) =>
    triggerNotification('info', content, message, duration, <Spin />)
const destroy = (content) => triggerNotification('destroy', content)

export { error, info, success, warning, loading, destroy }
