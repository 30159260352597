import * as antdIcon from '@ant-design/icons'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdButton from './StyledAntdButton'

const Button = (props) => {
    const {
        danger,
        disabled,
        ghost,
        name,
        htmlType,
        icon,
        loading,
        onClick,
        onFocus,
        onMouseEnter,
        onMouseLeave,
        shape,
        size,
        type,
        value
    } = props

    const iconAlign = get(props, 'iconAlign', 'left')
    const AntdIcon = antdIcon[icon]

    let renderValue = null
    if (icon) {
        if (iconAlign === 'left') {
            renderValue = <AntdIcon />
        }
        if (value) {
            renderValue = (
                <span>
                    {renderValue} {value}
                </span>
            )
        }
        if (iconAlign === 'right') {
            renderValue = (
                <span>
                    {renderValue} <AntdIcon />
                </span>
            )
        }
    } else renderValue = value

    return (
        <StyledAntdButton
            danger={danger}
            disabled={disabled}
            ghost={ghost}
            htmlType={htmlType}
            loading={loading}
            name={name}
            onClick={onClick}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            shape={shape}
            size={size}
            type={type}
        >
            {renderValue}
        </StyledAntdButton>
    )
}

Button.propTypes = {
    danger: PropTypes.bool,
    disabled: PropTypes.bool,
    ghost: PropTypes.bool,
    htmlType: PropTypes.string,
    icon: PropTypes.node,
    loading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    shape: PropTypes.string,
    size: PropTypes.oneOf(['small', 'middle', 'large']),
    type: PropTypes.oneOf([
        'default',
        'dashed',
        'ghost',
        'primary',
        'link',
        'text'
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

Button.defaultProps = {
    shape: 'round'
}

export default Button
