import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { Button, ReduxFormInput, ReduxFormSelect, Space } from 'antdcomponents'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'
import { Field, Form, reduxForm } from 'redux-form'
import { required } from 'utility/formValidation'

const FilterBlockForm = (props) => {
    const {
        change,
        filters,
        handleSubmit,
        initialValues,
        onClear,
        onSearch,
        setField,
        submit
    } = props

    useEffect(() => {
        forEach(filters, (item) => {
            if (item.defaultValue !== undefined) {
                setField(item.query, item.defaultValue)
            }

            if (!isEmpty(item.children)) {
                const valueParent = get(initialValues, item.query, undefined)
                if (!valueParent) {
                    forEach(item.children, (child) => {
                        return setField(child.query, undefined)
                    })
                }
            }
        })
    }, [filters, initialValues, setField])

    const handleClear = () => {
        forEach(filters, (item) => {
            if (item.defaultValue === undefined) {
                setField(item.query, '')
            }
        })
        setTimeout(submit, 0)
        onClear()
    }

    const handleSearch = (values) => onSearch(values)

    const renderFilterInput = (item) => (
        <Field
            component={ReduxFormInput}
            disabled={item.disabled}
            formItemProps={{
                extra: item.hintText,
                required: item.required
            }}
            label={item.label}
            name={item.name}
            placeholder={item.placeholder}
            props={{
                onClear: item.onClear
            }}
            validate={item.required ? [required] : []}
        />
    )

    const renderFilterSelect = (item) => (
        <Field
            component={ReduxFormSelect}
            disabled={item.disabled}
            formItemProps={{ required: item.required }}
            label={item.label}
            name={item.name}
            props={{
                allowClear: !item.required,
                disabled: item.disabled,
                onClear: item.onClear,
                options: item.options,
                placeholder: item.placeholder,
                type: item.selectType
            }}
            validate={item.required ? [required] : []}
        />
    )

    const renderFilterField = () =>
        !isEmpty(filters) && (
            <Space direction='vertical'>
                {map(filters, (filter, key) => {
                    let parentFilter = filter
                    let children = null
                    if (!isEmpty(filter.children)) {
                        parentFilter = Object.assign(filter, {
                            onClear: () => {
                                map(filter.children, (child) =>
                                    change(child.name, '')
                                )
                            }
                        })

                        const valueParent = get(
                            initialValues,
                            filter.query,
                            undefined
                        )

                        children = map(filter.children, (item) => {
                            const newItem = Object.assign(item, {
                                disabled: !valueParent
                            })

                            return (
                                <AntdCol key={item.name} offset={1} span={23}>
                                    {newItem.type === 'select' &&
                                        renderFilterSelect(newItem)}
                                    {newItem.type === 'input' &&
                                        renderFilterInput(newItem)}
                                </AntdCol>
                            )
                        })
                    }

                    const parent = (
                        <AntdCol>
                            {filter.type === 'select' &&
                                renderFilterSelect(parentFilter)}
                            {filter.type === 'input' &&
                                renderFilterInput(parentFilter)}
                        </AntdCol>
                    )

                    return (
                        <Fragment key={key}>
                            {parent}
                            {children}
                        </Fragment>
                    )
                })}
            </Space>
        )

    const renderButton = () => (
        <AntdRow justify='end'>
            <AntdSpace>
                <Button
                    htmlType='submit'
                    icon='SearchOutlined'
                    name='filter-btn'
                    type='primary'
                    value='Filter'
                />
                <Button
                    name='reset-btn'
                    onClick={handleClear}
                    value='Atur Ulang'
                />
            </AntdSpace>
        </AntdRow>
    )

    return (
        <Form onSubmit={handleSubmit(handleSearch)}>
            <Space direction='vertical'>
                {renderFilterField()}
                {renderButton()}
            </Space>
        </Form>
    )
}

FilterBlockForm.propTypes = {
    change: PropTypes.func,
    filters: PropTypes.array,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    onClear: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    reset: PropTypes.func,
    setField: PropTypes.func,
    submit: PropTypes.func
}

export default reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    form: 'filter-block',
    initialValues: {}
})(FilterBlockForm)
