import { TimePicker as AntdTimePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

const TimePicker = (props) => {
    const {
        bordered,
        defaultValue,
        disabled,
        format,
        minuteStep,
        name,
        onChange,
        placeholder,
        size,
        value
    } = props

    const getAdjustedValueMoment = (timeString, format, minuteStep) => {
        let valMoment = timeString ? moment(timeString, format) : null

        if (valMoment) {
            let offset = valMoment.minutes() % minuteStep
            valMoment.subtract(offset, 'minutes')
        }
        return valMoment
    }

    const handleChange = (name, format) => (value) => {
        if (!value) {
            return onChange('', name)
        }

        let offset = value.minutes() % minuteStep
        value.subtract(offset, 'minutes')
        return onChange(value.format(format), name)
    }

    const newDefaultValue = getAdjustedValueMoment(
        defaultValue,
        format,
        minuteStep
    )

    const newValue = getAdjustedValueMoment(value, format, minuteStep)

    return (
        <AntdTimePicker
            bordered={bordered}
            defaultValue={newDefaultValue}
            disabled={disabled}
            format={format}
            minuteStep={minuteStep}
            onChange={handleChange(name, format)}
            placeholder={placeholder}
            showTime={true}
            size={size}
            value={newValue}
        />
    )
}

TimePicker.propTypes = {
    bordered: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    minuteStep: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.string
}

TimePicker.defaultProps = {
    bordered: true
}

export default TimePicker
