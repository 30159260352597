import * as faqOptions from 'common/options/faq'
import * as faqSlice from 'features/faq/faqSlice'
import clone from 'lodash/clone'
import first from 'lodash/first'
import get from 'lodash/get'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import AddEditView from './AddEditView'

const AddEditViewContainer = (props) => {
    const params = useParams()
    return <AddEditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const faq = clone(state.faq.faq)
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]

    return {
        initialValues:
            windowType === 'add'
                ? { category: first(faqOptions.categories) }
                : faq,
        isActionLoading: state.faq.isActionLoading,
        isActionSuccess: state.faq.isActionSuccess,
        isLoading: state.faq.isLoading,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearFaq: faqSlice.clearFaq,
    createFaq: faqSlice.createFaq,
    getFaq: faqSlice.getFaq,
    updateFaq: faqSlice.updateFaq
}

const AddEditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'faq'
})(AddEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(AddEditViewForm)
