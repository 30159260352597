import { default as AntdSelect } from 'antd/lib/select'
import colors from 'basic/colors'
import styled from 'styled-components'

export const StyledAntdSelect = styled(AntdSelect)`
    width: 100%;
    ${(props) =>
        !props.disabled &&
        `
            background-color: transparent;
            color: ${props.$dark ? 'white' : 'black'};
        `}

    .ant-select-selector {
        border-radius: 20px !important;
        ${(props) =>
            props.bordered &&
            `
                border-color: ${
                    props.$dark ? colors.robbinEggBlue : 'silver'
                } !important;
                border-width: 2px !important;
            `}

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        line-height: 32px !important;
    }

    .ant-select-arrow {
        ${(props) =>
            !props.bordered &&
            props.disabled &&
            `
            display: none;
        `}
    }

    span.ant-select-selection-item span.anticon.anticon-delete {
        display: none;
    }
`
