import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

// reference to programlist
const programs = sortBy(
    [
        { abbreviation: 'ADU', name: 'Admin User', route: '/admin-user' },
        {
            abbreviation: 'CAT',
            name: 'Kategori & Tag',
            route: '/category-and-tag'
        },
        { abbreviation: 'CON', name: 'Hubungi Kami', route: '/contact-us' },
        {
            abbreviation: 'FAQ',
            name: 'FAQ',
            route: '/frequently-asked-question'
        },
        { abbreviation: 'FIR', name: 'Pemodal', route: '/pemodal' },
        { abbreviation: 'INB', name: 'Kotak Masuk', route: '/inbox' },
        { abbreviation: 'PRO', name: 'Produk', route: '/product' },
        { abbreviation: 'PUB', name: 'Penerbit', route: '/penerbit' },
        { abbreviation: 'PUO', name: 'Order', route: '/purchase-order' }
    ],
    'name'
)

const programActions = ['READ', 'WRITE']
const programActionOptions = (program) =>
    map(programActions, (item) => ({
        label: item,
        value: `${program}_${item}`
    }))

export { programActionOptions, programs }
