import { statusOptions, subjectOptions } from 'common/options/contactUs'

const filterConfig = [
    {
        name: 'email',
        placeholder: 'Cari Email',
        query: 'email',
        type: 'input'
    },
    {
        name: 'subject',
        options: subjectOptions,
        placeholder: 'Subyek Pertanyaan',
        query: 'subject',
        type: 'select'
    },
    {
        name: 'status',
        options: statusOptions,
        placeholder: 'Cari Status',
        query: 'status',
        type: 'select'
    }
]

export default filterConfig
