import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import moment from 'moment'

export const toApi = (data) => ({
    amount: data.amount,
    id: data.id,
    penerbit_id: data.penerbitId,
    type: data.type,
    unit: data.unit
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        amount: data.amount || 0,
        createdAt,
        id: data.id || null,
        penerbitId: data.penerbitId || null,
        type: data.type || '',
        unit: data.unit || '',
        updatedAt
    }
}
