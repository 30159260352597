import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import moment from 'moment'

export const toApi = (data) => ({
    created_by: data.createdBy,
    id: data.id,
    identifier: data.identifier,
    pemodal_id: data.pemodalId,
    url: data.url
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        createdAt,
        createdBy: data.createdBy || '',
        id: data.id || null,
        identifier: data.identifier || '',
        pemodalId: data.pemodalId || null,
        url: data.url || ''
    }
}
