import { DeleteOutlined } from '@ant-design/icons'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import indexOf from 'lodash/indexOf'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import noop from 'lodash/noop'
import toLower from 'lodash/toLower'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { StyledAntdSelect } from './StyledComponents'

const Select = (props) => {
    const {
        allowClear,
        bordered,
        dark,
        defaultValue,
        disabled,
        dropdownBottomNode,
        dropdownStyle,
        filterBy,
        name,
        notFoundContent,
        onChange,
        onClear,
        onFocus,
        onRemoveOption,
        onSearch,
        optionFilterProp,
        optionLabelProp,
        options,
        placeholder,
        size,
        tokenSeparators,
        type
    } = props

    const [hoveredValue, setHoveredValue] = useState(null)
    const isOptionRemovable = isFunction(onRemoveOption) && options.length > 1

    const showSearch = isFunction(onSearch)
    const handleChange = (value) => {
        const newValue = value === undefined ? '' : value
        onChange(newValue, name)
    }
    const handleMouseOver = (value) => () => setHoveredValue(value)
    const handleSearch = (searchText) => onSearch(searchText, name)
    const handleSelect = (value) => onChange(value, name)

    const renderOption = (option, key) => {
        if (option.children) {
            return (
                <StyledAntdSelect.OptGroup key={key} label={option.label}>
                    {map(option.children, (o, k) => renderOption(o, k))}
                </StyledAntdSelect.OptGroup>
            )
        }

        return (
            <StyledAntdSelect.Option
                disabled={option.disabled}
                key={key}
                title={option.label}
                value={option.value}
            >
                <AntdRow
                    justify='space-between'
                    onMouseOver={
                        isOptionRemovable ? handleMouseOver(option.value) : noop
                    }
                >
                    <AntdCol>{option.label}</AntdCol>
                    {isOptionRemovable && hoveredValue === option.value && (
                        <AntdCol>
                            <DeleteOutlined
                                onClick={onRemoveOption(option.value)}
                            />
                        </AntdCol>
                    )}
                </AntdRow>
            </StyledAntdSelect.Option>
        )
    }

    const renderOptions = map(options, (o, key) => renderOption(o, key))

    let value = props.value
    if ((type === 'multiple' || type === 'tags') && !value) {
        value = []
    }

    const renderDropdown = (menu) => (
        <React.Fragment>
            {menu}
            {dropdownBottomNode}
        </React.Fragment>
    )

    return (
        <StyledAntdSelect
            $dark={dark}
            allowClear={allowClear}
            bordered={bordered}
            defaultActiveFirstOption={type === 'tags' ? true : false}
            defaultValue={defaultValue}
            disabled={disabled}
            dropdownMatchSelectWidth
            dropdownRender={renderDropdown}
            dropdownStyle={dropdownStyle}
            filterOption={filterBy}
            maxTagCount={30}
            maxTagPlaceholder='more...'
            mode={type}
            notFoundContent={notFoundContent}
            onChange={handleChange}
            onClear={onClear}
            onFocus={onFocus}
            onSearch={handleSearch}
            onSelect={handleSelect}
            optionFilterProp={optionFilterProp}
            optionLabelProp={optionLabelProp}
            placeholder={placeholder}
            showSearch={showSearch}
            size={size}
            tokenSeparators={tokenSeparators}
            value={value}
        >
            {renderOptions}
        </StyledAntdSelect>
    )
}
Select.propTypes = {
    allowClear: PropTypes.bool,
    bordered: PropTypes.bool,
    dark: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    disabled: PropTypes.bool,
    dropdownBottomNode: PropTypes.node,
    dropdownStyle: PropTypes.object,
    filterBy: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    name: PropTypes.string.isRequired,
    notFoundContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onFocus: PropTypes.func,
    onRemoveOption: PropTypes.func,
    onSearch: PropTypes.func,
    optionFilterProp: PropTypes.string,
    optionLabelProp: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.array,
            disabled: PropTypes.bool,
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.string,
                PropTypes.number
            ])
        })
    ).isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    tokenSeparators: PropTypes.array,
    type: PropTypes.oneOf(['simple', 'multiple', 'tags']),
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number)
    ])
}

Select.defaultProps = {
    bordered: true,
    filterBy: (input, option) =>
        indexOf(toLower(option.props.children), toLower(input)) >= 0,
    notFoundContent: 'Tidak Ditemukan',
    onClear: noop,
    onSearch: noop,
    options: [],
    placeholder: 'Pilih disini',
    type: 'simple'
}

export default Select
