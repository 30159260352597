import * as adminUserSlice from 'features/admin_user/adminUserSlice'
import * as programSlice from 'features/program/programSlice'
import camelCase from 'lodash/camelCase'
import clone from 'lodash/clone'
import find from 'lodash/find'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import AddEditView from './AddEditView'

const AddEditViewContainer = (props) => {
    const params = useParams()
    return <AddEditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const adminUser = clone(state.adminUser.adminUser)
    const isLoadingAdminUser = state.adminUser.isLoading
    const isLoadingProgram = state.program.isLoading
    const programs = state.program.programs
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]

    const isLoading = isLoadingAdminUser || isLoadingProgram
    let initialValues = { isActive: true, isUpdatingPassword: true }
    if (windowType !== 'add' && !isEmpty(adminUser)) {
        initialValues = Object.assign(adminUser, {
            isUpdatingPassword: false,
            password: 'stubpassword'
        })
        if (!isLoadingProgram) {
            let acls = {}
            const roles = get(adminUser, 'roles', [])
            const roleGroupByAbbreviation = groupBy(
                roles,
                (item) => split(item, '_')[0]
            )
            map(roleGroupByAbbreviation, (role, key) => {
                const program = find(
                    programs,
                    (item) => item.abbreviation === key
                )
                if (!isEmpty(program)) {
                    acls[`${camelCase(program.name)}`] = role
                }
            })
            initialValues = Object.assign(adminUser, acls)
        }
    }

    return {
        initialValues,
        isActionLoading: state.adminUser.isActionLoading,
        isActionSuccess: state.adminUser.isActionSuccess,
        isLoading,
        programs,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearAdminUser: adminUserSlice.clearAdminUser,
    clearProgram: programSlice.clearProgram,
    createAdminUser: adminUserSlice.createAdminUser,
    getAdminUser: adminUserSlice.getAdminUser,
    getPrograms: programSlice.getPrograms,
    updateAdminUser: adminUserSlice.updateAdminUser
}

const AddEditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'adminUser'
})(AddEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(AddEditViewForm)
