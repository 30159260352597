import { VALID_IMAGE_EXTENSIONS } from 'constants/formats'
import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdEmpty } from 'antd/lib/empty'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import { default as AntdTypography } from 'antd/lib/typography'
import {
    BackButton,
    Breadcrumb,
    Button,
    Form,
    ReduxFormInput,
    ReduxFormSelect,
    ReduxFormTextArea,
    Space,
    Upload
} from 'antdcomponents'
import { kycStatusOptions } from 'common/options/pemodal'
import { genderOptions as userGenderOptions } from 'common/options/user'
import StyledLabel from 'components/styled_label/StyledLabel'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import toUpper from 'lodash/toUpper'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'
import { Field } from 'redux-form'
import { blockTextChanger } from 'utility/blockTextChanger'
import defaultViewRow from 'utility/defaultViewRow'
import { notSuccess, required } from 'utility/formValidation'
import getLabelOption from 'utility/getLabelOption'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import * as modal from 'utility/modal'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'

const AntdTypographyText = AntdTypography.Text

const EditView = (props) => {
    const {
        change,
        clearPemodal,
        dispatch,
        getPemodal,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        params,
        updatePemodal,
        username,
        windowType
    } = props

    const formValues = useSelectorFormValues('pemodal')
    const kycStatus = get(formValues, 'kycStatus', '')
    const kycStatusFailed = kycStatus === 'failed'
    const kycStatusVerified = kycStatus === 'verified'

    const isActive = get(initialValues, 'isActive', false)
    const isEmailVerified = get(initialValues, 'isEmailVerified', false)
    const isMobileNumberVerified = get(
        initialValues,
        'isMobileNumberVerified',
        false
    )
    const isDigisignActivated = get(initialValues, 'isDigisignActivated', false)
    const isTermsAndConditionsApproved = get(
        initialValues,
        'isTermsAndConditionsApproved',
        false
    )
    const isRiskStatementApproved = get(
        initialValues,
        'isRiskStatementApproved',
        false
    )
    const isSuccess = get(initialValues, 'isSuccess', false)
    const isVerified =
        get(initialValues, 'kycStatus', 'preliminary') === 'verified'
    const ktp = get(initialValues, 'ktp', [])
    const kycVerifiedAt = get(initialValues, 'kycVerifiedAt', null)
    const termsAndConditionsApprovalDate = get(
        initialValues,
        'termsAndConditionsApprovalDate',
        null
    )
    const profilePictureUrl = get(initialValues, 'profilePictureUrl', '')
    const riskStatementApprovalDate = get(
        initialValues,
        'riskStatementApprovalDate',
        null
    )
    const selfie = get(initialValues, 'selfie', [])

    const queries = get(location, 'search', '')
    const identifier = get(params, 'identifier', null)

    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Pemodal', linkTo: '/pemodal' },
        { label: `${getPageName(windowType)} (${initialValues.email})` }
    ]

    useEffect(() => {
        if (identifier) {
            const fetchPemodal = async () => getPemodal(identifier)
            fetchPemodal()
        }

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }

        return () => {
            dispatch(clearPemodal())
        }
    }, [
        clearPemodal,
        dispatch,
        getPemodal,
        identifier,
        isActionLoading,
        isActionSuccess,
        queries
    ])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/pemodal')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const getKycObject = () => {
        let kycObject = {
            isDigisignActivated: false,
            kycStatus: 'preliminary',
            kycVerifiedAt: null
        }

        if (kycStatusVerified) {
            kycObject = {
                kycStatus: 'verified',
                kycVerifiedAt: moment()
            }
        } else if (kycStatusFailed) {
            kycObject = {
                ...kycObject,
                kycStatus: 'failed'
            }
        }
        return kycObject
    }

    const handleBlockUnblock = () => change('isActive', !isActive)

    const handleFinish = () => {
        if (initialValues.kycStatus !== kycStatus && kycStatusVerified) {
            modal.confirm({
                content:
                    'Dengan ini, admin moneypool.co.id telah melakukan KYC pada pemodal ini dan menyetujui bahwa data yang diinput adalah benar adanya.',
                onOk: handleSubmitKyc
            })
        } else {
            handleSubmitKyc()
        }
    }

    const handleSubmitKyc = () => {
        const { id, remarks, isActive } = formValues
        const kycObject = getKycObject()
        const data = Object.assign(kycObject, {
            id,
            isActive,
            remarks,
            updatedBy: username
        })
        updatePemodal(data)
    }

    const renderButton = () =>
        !isWindowTypeView && (
            <AntdSpace>
                <Button
                    disabled={isSuccess || isVerified}
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    type='primary'
                    value='Simpan'
                />
                <Button
                    danger={isActive}
                    htmlType='submit'
                    loading={isActionLoading}
                    name='block-ublock-btn'
                    onClick={handleBlockUnblock}
                    type={isActive ? 'primary' : 'default'}
                    value={startCase(blockTextChanger(isActive))}
                />
            </AntdSpace>
        )

    const renderKtpAndSelfie = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Berkas Identitas</StyledLabel>
            <AntdRow align='middle' justify='space-between' type='flex'>
                <AntdCol span={11}>{renderUpload('ktp')}</AntdCol>
                <AntdCol offset={2} span={11}>
                    {renderUpload('selfie')}
                </AntdCol>
            </AntdRow>
            <br />
        </Space>
    )

    const renderScreening = () => {
        const disabledKycStatus =
            isWindowTypeView ||
            isSuccess ||
            isVerified ||
            !isEmailVerified ||
            !isMobileNumberVerified

        const disabledOptions = ['success']
        if (!isEmailVerified || !isMobileNumberVerified) {
            disabledOptions.push('verified')
        }

        return (
            <Space direction='vertical'>
                <StyledLabel level={5}>Status Screening</StyledLabel>
                <Field
                    component={ReduxFormSelect}
                    disabled={disabledKycStatus}
                    formItemProps={{ required: true }}
                    label='Status KYC'
                    name='kycStatus'
                    options={kycStatusOptions(disabledOptions)}
                    validate={[notSuccess, required]}
                />
                <AntdTypographyText>Aktivasi Akun Digisign</AntdTypographyText>
                <AntdTypographyText
                    type={isDigisignActivated ? 'success' : 'danger'}
                >
                    {isDigisignActivated
                        ? `telah aktif pada ${kycVerifiedAt}`
                        : 'belum divalidasi Digisign'}
                </AntdTypographyText>
                <br />
            </Space>
        )
    }

    const renderNote = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Catatan</StyledLabel>
            <Field
                component={ReduxFormTextArea}
                disabled={isWindowTypeView || isSuccess || isVerified}
                name='remarks'
                rows={5}
            />
            <br />
        </Space>
    )

    const renderViewPemodalBankInfo = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Rekening Bank</StyledLabel>
            {defaultViewRow(
                'Nama Bank',
                get(initialValues, 'pemodalBankinfo.bankList.name', '')
            )}
            {defaultViewRow(
                'Nama Pemilik Bank',
                get(initialValues, 'pemodalBankinfo.accountHolderName', '')
            )}
            {defaultViewRow(
                'Nomor Rekening',
                get(initialValues, 'pemodalBankinfo.accountNumber', '')
            )}
        </Space>
    )

    const renderPemodalBankInfo = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Rekening Bank</StyledLabel>
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nama Bank'
                name='pemodalBankinfo.bankList.name'
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nama Pemilik Bank'
                name='pemodalBankinfo.accountHolderName'
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nomor Rekening'
                name='pemodalBankinfo.accountNumber'
            />
            <br />
        </Space>
    )

    const renderViewPemodalDetail = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Screening</StyledLabel>
            {defaultViewRow(
                'Nomor KTP',
                get(initialValues, 'pemodalDetail.idCardNumber', '')
            )}
            {defaultViewRow(
                'Nama Lengkap Sesuai KTP',
                get(initialValues, 'pemodalDetail.idCardName', '')
            )}
            {defaultViewRow(
                'Jenis Kelamin',
                getLabelOption(initialValues.gender, userGenderOptions)
            )}
            {defaultViewRow(
                'Tempat Lahir',
                get(initialValues, 'hometown.name', '')
            )}
            {defaultViewRow('Tanggal Lahir', initialValues.birthDate)}
            {defaultViewRow(
                'Provinsi',
                get(initialValues, 'city.province', '')
            )}
            {defaultViewRow('Kota', get(initialValues, 'city.name', ''))}
            {defaultViewRow(
                'Kecamatan',
                get(initialValues, 'district.name', '')
            )}
            {defaultViewRow(
                'Kelurahan',
                get(initialValues, 'district.subdistrict', '')
            )}
            {defaultViewRow(
                'Kode Pos',
                get(initialValues, 'district.postalCode', '')
            )}
            {defaultViewRow('Alamat Lengkap Sesuai KTP', initialValues.address)}
            {defaultViewRow(
                'Nama Ibu Kandung',
                get(initialValues, 'pemodalDetail.motherName', '')
            )}
            {defaultViewRow(
                'Penghasilan Tahunan',
                get(initialValues, 'pemodalDetail.annualSalary.value', 0)
            )}
            {defaultViewRow(
                'Sumber Penghasilan',
                get(initialValues, 'pemodalDetail.incomeSource.value', 0)
            )}
            {defaultViewRow(
                'Tujuan Beli',
                get(initialValues, 'pemodalDetail.investmentGoal.value', 0)
            )}
        </Space>
    )

    const renderPemodalDetail = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Screening</StyledLabel>
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nomor KTP'
                name='pemodalDetail.idCardNumber'
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nama Lengkap Sesuai KTP'
                name='pemodalDetail.idCardName'
            />
            <Field
                bordered={false}
                component={ReduxFormSelect}
                disabled
                label='Jenis Kelamin'
                name='gender'
                optionFilterProp='label'
                options={userGenderOptions}
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Tempat Lahir'
                name='hometown.name'
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Tanggal Lahir'
                name='birthDate'
            />
            <Space>
                <Field
                    bordered={false}
                    component={ReduxFormInput}
                    disabled
                    label='Provinsi'
                    name='city.province'
                />
                <Field
                    bordered={false}
                    component={ReduxFormInput}
                    disabled
                    label='Kota'
                    name='city.name'
                />
            </Space>
            <Space>
                <Field
                    bordered={false}
                    component={ReduxFormInput}
                    disabled
                    label='Kecamatan'
                    name='district.name'
                />
                <Field
                    bordered={false}
                    component={ReduxFormInput}
                    disabled
                    label='Kelurahan'
                    name='district.subdistrict'
                />
            </Space>
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Kode Pos'
                name='district.postalCode'
            />
            <Field
                bordered={false}
                component={ReduxFormTextArea}
                disabled
                label='Alamat Lengkap Sesuai KTP'
                name='address'
                rows={3}
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nama Ibu Kandung'
                name='pemodalDetail.motherName'
            />
            <Field
                bordered={false}
                component={ReduxFormSelect}
                disabled
                label='Penghasilan Tahunan'
                name='pemodalDetail.annualSalary.value'
            />
            <Field
                bordered={false}
                component={ReduxFormSelect}
                disabled
                label='Sumber Penghasilan'
                name='pemodalDetail.incomeSource.value'
            />
            <Field
                bordered={false}
                component={ReduxFormSelect}
                disabled
                label='Tujuan Beli'
                name='pemodalDetail.investmentGoal.value'
            />
            <br />
        </Space>
    )

    const renderViewProfile = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Profil</StyledLabel>
            <AntdAvatar shape='circle' size={65} src={profilePictureUrl} />
            {defaultViewRow('Email', initialValues.email)}
            {defaultViewRow('Username', initialValues.username)}
            {defaultViewRow(
                'Nomor Telepon Seluler',
                `+62 ${initialValues.mobileNumber}`
            )}
            <br />
        </Space>
    )

    const renderProfile = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Profil</StyledLabel>
            <AntdAvatar shape='circle' size={65} src={profilePictureUrl} />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Email'
                name='email'
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Username'
                name='username'
            />
            <Field
                bordered={false}
                component={ReduxFormInput}
                disabled
                label='Nomor Telepon Seluler'
                name='mobileNumber'
                prefix='+62'
            />
            <br />
        </Space>
    )

    const renderRiskStatement = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Pernyataan Risiko</StyledLabel>
            <AntdTypographyText
                type={isRiskStatementApproved ? 'success' : 'danger'}
            >
                {isRiskStatementApproved &&
                    `dipahami dan disetujui pengguna pada ${riskStatementApprovalDate}`}
                {!isRiskStatementApproved && 'belum disetujui pengguna'}
            </AntdTypographyText>
            <br />
        </Space>
    )

    const renderTermsAndConditions = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Syarat & Ketentuan</StyledLabel>
            <AntdTypographyText
                type={isTermsAndConditionsApproved ? 'success' : 'danger'}
            >
                {isTermsAndConditionsApproved &&
                    `dipahami dan disetujui pengguna pada ${termsAndConditionsApprovalDate}`}
                {!isTermsAndConditionsApproved && 'belum disetujui pengguna'}
            </AntdTypographyText>
            <br />
        </Space>
    )

    const renderUpload = (fieldName) => {
        const isFieldNameKtp = fieldName === 'ktp'
        const label = isFieldNameKtp ? toUpper(fieldName) : startCase(fieldName)
        const fileList = isFieldNameKtp ? ktp : selfie

        return (
            <Fragment>
                <Upload
                    accept={VALID_IMAGE_EXTENSIONS}
                    disabled
                    fileList={fileList}
                    formItemProps={{ required: true }}
                    label={label}
                    listType='picture-card'
                    name={fieldName}
                />
                {isEmpty(fileList) && <AntdEmpty />}
            </Fragment>
        )
    }

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/pemodal${queries}`} />
            <Form
                colon={false}
                labelAlign='left'
                labelCol={isWindowTypeView ? { flex: '200px' } : {}}
                labelWrap
                layout={isWindowTypeView ? 'horizontal' : 'vertical'}
                onFinish={handleSubmit(handleFinish)}
                wrapperCol={{ flex: 1 }}
            >
                <Space direction='vertical'>
                    {!isWindowTypeView ? renderProfile() : renderViewProfile()}
                    {renderTermsAndConditions()}
                    {renderRiskStatement()}
                    {!isWindowTypeView
                        ? renderPemodalDetail()
                        : renderViewPemodalDetail()}
                    {renderKtpAndSelfie()}
                    {renderScreening()}
                    {!isWindowTypeView
                        ? renderPemodalBankInfo()
                        : renderViewPemodalBankInfo()}
                    {renderNote()}
                    {renderButton()}
                </Space>
            </Form>
        </Space>
    )
}

EditView.propTypes = {
    change: PropTypes.func,
    clearPemodal: PropTypes.func,
    dispatch: PropTypes.func,
    getPemodal: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.any,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    updatePemodal: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default EditView
