import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdTypography } from 'antd/lib/typography'
import { Button, Dropdown } from 'antdcomponents'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import * as modal from 'utility/modal'

const AntdTypographyText = AntdTypography.Text

const typeToLabel = {
    block: 'Blokir',
    cancel: 'Batalkan',
    delete: 'Hapus',
    edit: 'Ubah',
    unblock: 'Buka Blokir',
    view: 'Lihat'
}

const readOnlyLabels = ['view']

const TableRowActions = (props) => {
    const { location } = useOutletContext()

    const handleClick = (option) => () =>
        modal.confirm({
            content: option.confirmContent,
            onOk: option.onClick
        })

    const renderDropdownContent = (option) => {
        let labelShow = option.label

        if (includes(['view', 'edit'], option.type) && option.id) {
            const currPath = option.customPath || location.pathname
            const link = `${currPath}/${option.type}/${option.id}`
            labelShow = <a href={link}>{option.label}</a>
        }

        if (!option.needConfirm) {
            return labelShow
        }

        return (
            <AntdTypographyText onClick={handleClick(option)}>
                {labelShow}
            </AntdTypographyText>
        )
    }

    const renderOption = (option) => {
        const onClick = option.needConfirm
            ? handleClick(option)
            : option.onClick

        const button = (
            <Button
                name='option-btn'
                onClick={onClick}
                type='link'
                value={option.label}
            />
        )

        if (includes(['view', 'edit'], option.type) && option.id) {
            const currPath = option.customPath || location.pathname
            const link = `${currPath}/${option.type}/${option.id}`
            return <a href={link}>{button}</a>
        }

        return button
    }

    let { options, isWriteAllowed } = props
    const filtered = filter(
        options,
        (option) =>
            isWriteAllowed || includes(readOnlyLabels, toLower(option.type))
    )
    options = map(filtered, (option) => {
        if (option.type) option.label = typeToLabel[option.type] || option.label
        if (includes(['block', 'cancel', 'delete', 'unblock'], option.type)) {
            option.needConfirm = true
        }
        return option
    })
    if (options.length === 0) {
        return <span />
    }
    if (options.length === 1) {
        return renderOption(options[0])
    }
    if (options.length === 2) {
        return (
            <AntdSpace size={2}>
                {renderOption(options[0])}
                {renderOption(options[1])}
            </AntdSpace>
        )
    }
    if (options.length === 3) {
        return (
            <AntdSpace size={2}>
                {renderOption(options[0])}
                {renderOption(options[1])}
                {renderOption(options[2])}
            </AntdSpace>
        )
    }

    const restOptions = slice(options, 1, options.length)
    const dropdownOptions = map(restOptions, (option) => ({
        content: renderDropdownContent(option),
        onClick: option.needConfirm ? null : option.onClick
    }))

    return (
        <AntdSpace size={2}>
            {renderOption(options[0])}
            <AntdDivider type='vertical' />
            <Dropdown name='more' options={dropdownOptions} title='Lainnya' />
        </AntdSpace>
    )
}

TableRowActions.propTypes = {
    isWriteAllowed: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            confirmContent: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.string
            ]),
            label: PropTypes.string,
            needConfirm: PropTypes.bool,
            onClick: PropTypes.func.isRequired,
            render: PropTypes.func,
            type: PropTypes.oneOf([
                'block',
                'cancel',
                'delete',
                'edit',
                'unblock',
                'view'
            ])
        })
    ).isRequired
}

const mapStateToProps = (state) => ({
    isWriteAllowed: state.authentication.isWriteAllowed
})

export default connect(mapStateToProps)(TableRowActions)
