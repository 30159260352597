import colors from 'basic/colors'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

const statusesInd = ['baru', 'diproses', 'selesai']
const statuses = ['new', 'in progress', 'expired']
const statusColors = [colors.salmon, colors.springGreen, colors.blueberry]

export const inboxStatus = (inbox, inboxDetails = []) => {
    if (size(inboxDetails) <= 1) {
        return getStatus('new')
    }
    if (inbox.status !== 'expired') {
        return getStatus('in progress')
    }

    return getStatus(inbox.status)
}

export const statusOptions = (disabled) =>
    sortBy(
        map(statuses, (item, key) => ({
            color: statusColors[key],
            disabled: includes(disabled, item),
            label: startCase(statusesInd[key]),
            value: item
        })),
        'label'
    )

export const getStatus = (status) => {
    const statusObj = find(statusOptions(), (item) => item.value === status)
    return isEmpty(statusObj) ? { color: '', label: '', value: '' } : statusObj
}
