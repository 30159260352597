import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import { defaultAmounts, typeOptions } from 'common/options/managementFee'
import map from 'lodash/map'
import moment from 'moment'

export const getInitialPenerbitDetail = (idCardName) => ({
    idCardName,
    isKycVerified: false,
    kycVerifiedAt: moment().format(DATE_HOUR_SLASH_FORMAT)
})

export const initialPenerbitManagementFees = map(typeOptions, (type, key) => ({
    amount: defaultAmounts[key],
    type: type.value,
    unit: '%'
}))
