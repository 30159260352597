import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getPrograms = createAsyncThunk(
    'GET_PROGRAMS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/programs', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
