import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import * as message from 'utility/message'
import { columns, dataSource, rowActions } from './config'

const List = (props) => {
    const {
        clearProductCategory,
        deleteProductCategory,
        dispatch,
        getProductCategories,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        productCategories,
        totalProductCategories
    } = props
    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    useEffect(() => {
        const fetchProductCategories = () => {
            const options = { orderBy, status: 'all' }
            const offset = (page - 1) * limit
            getProductCategories({ limit, offset, options })
        }
        fetchProductCategories()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }

        return () => {
            dispatch(clearProductCategory())
        }
    }, [
        clearProductCategory,
        dispatch,
        getProductCategories,
        isActionLoading,
        isActionSuccess,
        limit,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id } = productCategories[key]

        if (type === 'delete') {
            return deleteProductCategory(id)
        }
    }

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const handleAdd = () => navigate('/category/add')

    const renderActions = (text, record, index) => (
        <TableRowActions options={rowActions(index, handleActions, record)} />
    )

    return (
        <section>
            <Space direction='vertical'>
                <TableHeader
                    onAdd={handleAdd}
                    onChangePagination={handleChangePagination}
                    totalItems={totalProductCategories}
                />
                <Table
                    columns={columns(renderActions)}
                    dataSource={dataSource(productCategories)}
                    loading={isLoading}
                    onChange={handleChangeTable}
                />
            </Space>
        </section>
    )
}

List.propTypes = {
    clearProductCategory: PropTypes.func,
    deleteProductCategory: PropTypes.func,
    dispatch: PropTypes.func,
    getProductCategories: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    productCategories: PropTypes.array,
    totalProductCategories: PropTypes.number
}

export default List
