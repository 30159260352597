import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'

const getCityDistricts = (currDistrictId, districts) => {
    if (isEmpty(districts)) {
        return []
    }
    const groupedDistricts = groupBy(districts, 'name')

    return map(groupedDistricts, (dist, key) => {
        let currDistFind = { id: null }

        if (currDistrictId) {
            currDistFind = find(dist, (dis) => dis.id === currDistrictId) || {
                id: null
            }
        }

        return {
            label: startCase(key),
            value: currDistFind.id || dist[0].id
        }
    })
}

const getSubdistrictOptions = (districts, currDistrictId) => {
    if (!currDistrictId || isEmpty(districts)) {
        return []
    }

    const currDistrict = find(districts, (dist) => dist.id === currDistrictId)
    if (isEmpty(currDistrict)) {
        return []
    }

    const currSubs = !isEmpty(districts)
        ? filter(districts, (district) => district.name === currDistrict.name)
        : [currDistrict]

    return map(currSubs, (sub) => ({
        label: startCase(sub.subdistrict),
        value: sub.id
    }))
}

export { getCityDistricts, getSubdistrictOptions }
