// replace default theme in here
// see https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

const colors = require('./colors')

const fontStyle = 'normal !important'
const fontWeight = 'normal !important'
const arTechniFont = 'AR Techni'
const hammersmithOneFont = 'Hammersmith One'

const headingStyle = {
    fontFamily: [
        arTechniFont,
        arTechniFont,
        hammersmithOneFont,
        hammersmithOneFont,
        hammersmithOneFont
    ],
    fontSizeDesktop: ['48px', '36px', '24px', '20px', '16px'],
    fontSizeMobile: ['36px', '20px', '16px', '16px', '14px'],
    fontStyle,
    fontWeight,
    letterSpacing: '1px'
}

const paragraphStyle = {
    fontFamily: arTechniFont,
    fontSizeDesktop: '18px',
    fontSizeMobile: '12px',
    fontStyle,
    fontWeight,
    letterSpacing: '1px'
}

const spanStyle = {
    fontFamily: arTechniFont,
    fontSizeDesktop: '12px',
    fontSizeMobile: '9px',
    fontStyle,
    fontWeight,
    letterSpacing: '1px'
}

// Base Scaffolding Variables
// ---
const base = {
    '@border-radius-base': '20px',
    '@font-family': arTechniFont
}

// Divider
const divider = {
    '@divider-color': 'silver'
}

// Form
const form = {
    '@form-item-margin-bottom': '0'
}

// Layout
const layout = {
    '@layout-body-background': 'white',
    '@layout-sider-background': colors.persianIndigo
}

// Menu
const menu = {
    '@menu-dark-bg': 'transparent',
    '@menu-item-font-size': '20px'
}

// Table
const table = {
    '@table-expanded-row-bg': 'white'
}

const defaultModifyVars = {
    '@disabled-color': colors.gray,
    '@heading-color': colors.persianIndigo,
    '@primary-color': colors.blueberry,
    '@text-color': colors.persianIndigo,
    arTechniFont,
    fontStyle,
    fontWeight,
    hammersmithOneFont,
    headingStyle,
    paragraphStyle,
    spanStyle
}

const modifyVars = Object.assign(
    base,
    defaultModifyVars,
    divider,
    form,
    layout,
    menu,
    table
)

module.exports = modifyVars
