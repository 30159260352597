import { default as AntdBadge } from 'antd/lib/badge'
import {
    finishedStatus,
    statusColors,
    statuses,
    statusesInd
} from 'common/options/product'
import EyeIcon from 'components/eye_icon/EyeIcon'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import React from 'react'
import formatPrice from 'utility/formatPrice'
import getRenderStatusPurchaseOrder from 'utility/getRenderStatusPurchaseOrder'

const rowActions = (index, handleActions, record) => {
    let actions = [
        {
            id: record.id,
            type: 'view'
        },
        {
            id: record.id,
            type: 'edit'
        }
    ]

    if (!includes(finishedStatus, record.status)) {
        actions = [
            ...actions,
            {
                confirmContent: `Apakah anda yakin ingin membatalkan produk ini (${record.code} / ${record.penerbitName})?`,
                onClick: handleActions(index, 'cancel'),
                type: 'cancel'
            }
        ]
    }

    return actions
}

const columns = (renderActions) => [
    {
        dataIndex: 'status',
        render: (text) => {
            const index = findIndex(statuses, (item) => item === text)
            const statusInd = statusesInd[index]
            const statusColor = statusColors[index]
            return <AntdBadge color={statusColor} text={startCase(statusInd)} />
        },
        title: 'Status',
        width: 150
    },
    {
        dataIndex: 'type',
        title: 'Tipe',
        width: 100
    },
    {
        dataIndex: 'code',
        title: 'Kode',
        width: 50
    },
    {
        dataIndex: 'penerbitName',
        render: (value, record) => {
            return (
                <React.Fragment>
                    <EyeIcon route={`/penerbit/view/${record.penerbitId}`} />{' '}
                    {value}
                </React.Fragment>
            )
        },
        title: 'Penerbit',
        width: 200
    },
    {
        dataIndex: 'numberShare',
        title: 'Lembar Total',
        width: 100
    },
    {
        dataIndex: 'soldShare',
        title: 'Lembar Terjual',
        width: 150
    },
    {
        dataIndex: 'pricePerShare',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Harga Per Lembar',
        width: 150
    },
    {
        dataIndex: 'target',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Target Dana',
        width: 150
    },
    {
        dataIndex: 'collected',
        render: (number) => `Rp ${formatPrice(number)}`,
        sorter: true,
        title: 'Dana Terkumpul',
        width: 150
    },
    {
        dataIndex: 'startDate',
        sorter: true,
        title: 'Tanggal Mulai',
        width: 150
    },
    {
        dataIndex: 'endDate',
        sorter: true,
        title: 'Tanggal Berakhir',
        width: 150
    },
    {
        dataIndex: 'settlementDate',
        render: (date) => (isNull(date) ? '-' : date),
        sorter: true,
        title: 'Tanggal Settlement',
        width: 150
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

const expandedColumns = (purchaseOrders) => [
    {
        dataIndex: 'createdAt',
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'noDoc',
        render: (text) => {
            const purchaseOrder = find(
                purchaseOrders,
                (item) => item.noDoc === text
            )
            const purchaseOrderId = get(purchaseOrder, 'id', null)
            return (
                <React.Fragment>
                    {
                        <EyeIcon
                            route={`/purchase-order/view/${purchaseOrderId}`}
                        />
                    }{' '}
                    {text}
                </React.Fragment>
            )
        },
        title: 'Nomor Order',
        width: 150
    },
    {
        dataIndex: 'pemodal',
        render: (object) => get(object, 'username'),
        title: 'Username',
        width: 100
    },
    {
        dataIndex: 'quantity',
        title: 'Jumlah Lembar',
        width: 100
    },
    {
        dataIndex: 'subtotal',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Subtotal',
        width: 100
    },
    {
        dataIndex: 'adminFee',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Biaya Admin',
        width: 100
    },
    {
        dataIndex: 'transactionFee',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Biaya Transaksi',
        width: 150
    },
    {
        render: (object) => getRenderStatusPurchaseOrder(object),
        title: 'Status',
        width: 150
    }
]

const expandedDataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions, expandedColumns, expandedDataSource }
