import { default as AntdTable } from 'antd/lib/table'
import colors from 'basic/colors'
import styled from 'styled-components'

const StyledAntdTable = styled(AntdTable)`
    &&& {
        border-collapse: collapse;
        table-layout: auto;
        overflow-x: auto;
        width: 100%;

        tr > td {
            border: none;
            font-size: 13px;
        }

        tr > th:not(.ant-table-cell-fix-right) {
            background: transparent;
        }
        tr > th {
            font-size: 14px;
            color: ${colors.persianIndigo};
        }

        .ant-table-expanded-row-fixed {
            padding: 0;
        }
    }
`

export default StyledAntdTable
