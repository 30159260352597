import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import moment from 'moment'

export const toApi = (data) => ({
    color_code: toLower(data.colorCode),
    created_by: data.createdBy,
    id: data.id,
    status: data.status,
    title: data.title,
    updated_by: data.updatedBy
})

export const toState = (data) => {
    const colorCode = data.colorCode ? toUpper(data.colorCode) : ''

    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        colorCode,
        createdAt,
        createdBy: data.createdBy || '',
        id: data.id || null,
        status: data.status || '',
        title: data.title || '',
        totalProducts: data.totalProducts || 0,
        updatedAt,
        updatedBy: data.updatedBy || ''
    }
}
