import { DATE_SLASH_FORMAT } from 'constants/formats'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { createGlobalStyle } from 'styled-components'
import StyledAntdRangePicker from './StyledAntdRangePicker'

/**
 * dropdown nya rangePicker ini muncul sebagai <div/>
 * diluar `#root` div, jadi harus pake global styling
 * buat style dropdown nya.
 * Ini bisa aja salah, aku agak bingung mau implement styling
 * flex-wrap: wrap; nya dimana
 */
const GlobalStyle = createGlobalStyle`
    @media screen and (max-width: 768px) {
        .ant-picker-panels {
            flex-wrap: wrap !important;
            justify-content: center;
        }
    }
    `

const RangePicker = (props) => {
    const {
        bordered,
        defaultValue,
        disabled,
        disabledDate,
        format,
        name,
        onChange,
        picker,
        showTime,
        value
    } = props

    const newDefaultValue = !isEmpty(defaultValue)
        ? [moment(defaultValue[0], format), moment(defaultValue[1], format)]
        : []
    const newValue = !isEmpty(value)
        ? [moment(value[0], format), moment(value[1], format)]
        : []

    const handleChange = (name, format) => (dates) => {
        const newDates = !isEmpty(dates)
            ? [moment(dates[0]).format(format), moment(dates[1]).format(format)]
            : []
        onChange(newDates, name)
    }

    return (
        <React.Fragment>
            <GlobalStyle />
            <StyledAntdRangePicker
                bordered={bordered}
                defaultValue={newDefaultValue}
                disabled={disabled}
                disabledDate={disabledDate}
                format={format}
                onChange={handleChange(name, format)}
                picker={picker}
                showTime={showTime}
                style={{ width: '100%' }}
                value={newValue}
            />
        </React.Fragment>
    )
}

RangePicker.propTypes = {
    bordered: PropTypes.bool,
    defaultValue: PropTypes.array,
    disabled: PropTypes.bool,
    disabledDate: PropTypes.func,
    format: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    picker: PropTypes.string,
    showTime: PropTypes.bool,
    value: PropTypes.any
}

RangePicker.defaultProps = {
    bordered: true,
    format: DATE_SLASH_FORMAT,
    placeholder: 'Pilih rentang tanggal'
}

export default RangePicker
