import { FormItem, RadioGroup } from 'antdcomponents'
import PropTypes from 'prop-types'
import React from 'react'

const ReduxFormRadioGroup = (props) => {
    const { input, meta, label, formItemProps, ...rest } = props
    const { onChange, name, value } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
            {...formItemProps}
        >
            <RadioGroup
                {...rest}
                name={name}
                onChange={onChange}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormRadioGroup.propTypes = {
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired
}

export default ReduxFormRadioGroup
