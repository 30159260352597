import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/purchaseOrder'
import * as purchaseOrderAPI from './purchaseOrderAPI'

const initialState = {
    error: '',
    exportData: '',
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    isLoadingExport: false,
    purchaseOrder: {},
    purchaseOrders: [],
    totalPurchaseOrders: 0
}

export const {
    exportPurchaseOrders,
    getPurchaseOrder,
    getPurchaseOrders,
    updatePurchaseOrder
} = purchaseOrderAPI

const purchaseOrderSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(exportPurchaseOrders.pending, (state) =>
            Object.assign(state, {
                error: '',
                exportData: '',
                isLoadingExport: true
            })
        )
        builder.addCase(exportPurchaseOrders.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                exportData: data.text,
                isLoadingExport: false
            })
        })
        builder.addCase(exportPurchaseOrders.rejected, (state, action) =>
            Object.assign(state, {
                error: action.errorMessage,
                isLoadingExport: false
            })
        )
        builder.addCase(getPurchaseOrder.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                purchaseOrder: {}
            })
        )
        builder.addCase(getPurchaseOrder.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                purchaseOrder: toState(data.purchaseOrder)
            })
        })
        builder.addCase(getPurchaseOrders.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                purchaseOrders: []
            })
        )
        builder.addCase(getPurchaseOrders.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                purchaseOrders: map(data.purchaseOrders, (item) =>
                    toState(item)
                ),
                totalPurchaseOrders: data.count
            })
        })
        builder.addMatcher(
            isAnyOf(getPurchaseOrder.rejected, getPurchaseOrders.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'PURCHASE_ORDER',
    reducers: {
        clearPurchaseOrder: () => initialState
    }
})

export const { clearPurchaseOrder } = purchaseOrderSlice.actions

const { reducer } = purchaseOrderSlice
export default reducer
