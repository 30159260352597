import * as contactUsSlice from 'features/contact_us/contactUsSlice'
import { connect } from 'react-redux'
import List from './List'

const ContactUsListContainer = (props) => {
    return <List {...props} />
}

const mapStateToProps = (state) => ({
    contactUsList: state.contactUs.contactUsList,
    isActionLoading: state.contactUs.isActionLoading,
    isActionSuccess: state.contactUs.isActionSuccess,
    isLoading: state.contactUs.isLoading,
    totalContactUs: state.contactUs.totalContactUs
})

const mapDispatchToProps = {
    clearContactUs: contactUsSlice.clearContactUs,
    getContactUsList: contactUsSlice.getContactUsList
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUsListContainer)
