import * as penerbitSlice from 'features/penerbit/penerbitSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => {
    return <List {...props} />
}

const mapStateToProps = (state) => ({
    error: state.penerbit.error,
    exportData: state.penerbit.exportData,
    isActionLoading: state.penerbit.isActionLoading,
    isActionSuccess: state.penerbit.isActionSuccess,
    isLoading: state.penerbit.isLoading,
    isLoadingExport: state.purchaseOrder.isLoadingExport,
    penerbits: state.penerbit.penerbits,
    totalPenerbits: state.penerbit.totalPenerbits
})

const mapDispatchToProps = {
    clearPenerbit: penerbitSlice.clearPenerbit,
    exportPenerbits: penerbitSlice.exportPenerbits,
    getPenerbits: penerbitSlice.getPenerbits,
    updatePenerbit: penerbitSlice.updatePenerbit
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
