import map from 'lodash/map'
import startCase from 'lodash/startCase'

const categories = ['umum', 'pemodal', 'penerbit']
const categoryOptions = map(categories, (item) => ({
    label: startCase(item),
    value: item
}))

export { categories, categoryOptions }
