import { FormItem, TextArea } from 'antdcomponents'
import PropTypes from 'prop-types'
import React from 'react'

const ReduxFormTextArea = (props) => {
    const { input, meta, label, formItemProps, ...restProps } = props
    const { onChange, name, value } = input
    const { touched, error, warning } = meta
    const isError = touched && !!error
    const isWarning = !!warning

    let validateStatus = undefined
    if (isError) {
        validateStatus = 'error'
    } else if (isWarning) {
        validateStatus = 'warning'
    }

    return (
        <FormItem
            hasFeedback={isError || isWarning}
            help={isError || warning}
            label={label}
            validateStatus={validateStatus}
            {...formItemProps}
        >
            <TextArea
                {...restProps}
                name={name}
                onChange={onChange}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormTextArea.propTypes = {
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired
}

export default ReduxFormTextArea
