import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/inbox'
import * as inboxAPI from './inboxAPI'

const initialState = {
    inbox: {},
    inboxes: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    totalInboxes: 0
}

export const { createInbox, getInbox, getInboxes, updateInbox } = inboxAPI

const inboxSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getInbox.pending, (state) =>
            Object.assign(state, {
                inbox: {},
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getInbox.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                inbox: toState(data.inbox),
                isLoading: false
            })
        })
        builder.addCase(getInboxes.pending, (state) =>
            Object.assign(state, {
                inboxes: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getInboxes.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                inboxes: map(data.inboxes, (inbox) => toState(inbox)),
                isLoading: false,
                totalInboxes: data.count
            })
        })
        builder.addMatcher(
            isAnyOf(createInbox.pending, updateInbox.pending),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false
                })
        )
        builder.addMatcher(
            isAnyOf(createInbox.fulfilled, updateInbox.fulfilled),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true
                })
        )
        builder.addMatcher(
            isAnyOf(createInbox.rejected, updateInbox.rejected),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(getInbox.rejected, getInboxes.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'INBOX',
    reducers: {
        clearInbox: () => initialState
    }
})

export const { clearInbox } = inboxSlice.actions

const { reducer } = inboxSlice
export default reducer
