import { DATE_HOUR_SLASH_FORMAT, DATE_SLASH_FORMAT } from 'constants/formats'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import split from 'lodash/split'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import moment from 'moment'
import getPenerbitName from 'utility/getPenerbitName'
import getSummaryProduct from 'utility/getSummaryProduct'
import * as penerbitTransformer from './penerbit'
import * as productCategoryTransformer from './productCategory'
import * as productDocumentTransformer from './productDocument'
import * as productImageTransformer from './productImage'
import * as productRibbonTransformer from './productRibbon'
import * as purchaseOrderTransformer from './purchaseOrder'

export const toApi = (data) => {
    const end_date = moment(data.endDate, DATE_SLASH_FORMAT).isValid()
        ? moment.utc(data.endDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined
    const start_date = moment(data.startDate, DATE_SLASH_FORMAT).isValid()
        ? moment.utc(data.startDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined

    const settlement_date = moment(
        data.settlementDate,
        DATE_SLASH_FORMAT
    ).isValid()
        ? moment.utc(data.settlementDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : null

    const id = data.id
    let product_documents = !isEmpty(data.productDocuments)
        ? map(data.productDocuments, (item) =>
              productDocumentTransformer.toApi(item)
          )
        : undefined
    let product_images = !isEmpty(data.productImages)
        ? map(data.productImages, (item) => productImageTransformer.toApi(item))
        : undefined
    const prospectus = !isEmpty(data.prospectus)
        ? {
              file: get(data.prospectus, 'file', ''),
              type: get(data.prospectus, 'type', ''),
              url: get(data.prospectus, 'url', '')
          }
        : undefined

    if (id) {
        if (!isEmpty(product_documents)) {
            product_documents = map(product_documents, (item) =>
                Object.assign(item, { product_id: id })
            )
        }
        if (!isEmpty(product_images)) {
            product_images = map(product_images, (item) =>
                Object.assign(item, { product_id: id })
            )
        }
    }

    const number_share = data.numberShare
    const price_per_share = data.pricePerShare
    const target = number_share * price_per_share

    return {
        code: data.code ? toLower(data.code) : undefined,
        coupon_rate: data.couponRate,
        created_by: data.createdBy,
        description: data.description,
        dividend_period: data.dividendPeriod,
        done_management_fee: data.doneManagementFee,
        end_date,
        failed_management_fee: data.failedManagementFee,
        full_management_fee: data.fullManagementFee,
        id,
        interest_rate: data.interestRate,
        is_uploading: data.isUploading,
        number_share,
        outstanding_number_share: data.outstandingNumberShare,
        penerbit_id: data.penerbitId,
        price_per_share,
        product_category_id: data.productCategoryId,
        product_documents,
        product_images,
        product_ribbon_id: data.productRibbonId,
        product_type_id: data.productTypeId,
        prospectus,
        remarks: data.remarks,
        settlement_date,
        share_size: data.shareSize,
        slug: data.slug,
        start_date,
        status: data.status,
        target,
        title: data.title,
        turnover_last_year: data.turnoverLastYear,
        updated_by: data.updatedBy
    }
}

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const endDate = moment(data.endDate).isValid()
        ? moment(data.endDate).format(DATE_SLASH_FORMAT)
        : null
    const startDate = moment(data.startDate).isValid()
        ? moment(data.startDate).format(DATE_SLASH_FORMAT)
        : null
    const settlementDate = moment(data.settlementDate).isValid()
        ? moment(data.settlementDate).format(DATE_SLASH_FORMAT)
        : null

    const penerbit = !isEmpty(data.penerbit)
        ? penerbitTransformer.toState(data.penerbit)
        : {}
    const productCategory = !isEmpty(data.productCategory)
        ? productCategoryTransformer.toState(data.productCategory)
        : {}
    const productDocuments = !isEmpty(data.productDocuments)
        ? map(data.productDocuments, (item) =>
              productDocumentTransformer.toState(item)
          )
        : []
    const productImages = !isEmpty(data.productImages)
        ? map(data.productImages, (item) =>
              productImageTransformer.toState(item)
          )
        : []
    const productRibbon = !isEmpty(data.productRibbon)
        ? productRibbonTransformer.toState(data.productRibbon)
        : {}
    const purchaseOrders = !isEmpty(data.purchaseOrders)
        ? map(data.purchaseOrders, (item) =>
              purchaseOrderTransformer.toState(item)
          )
        : []

    const category = get(productCategory, 'title', '')
    const type = get(data.productType, 'title', '')

    const code = data.code ? toUpper(data.code) : ''
    const numberShare = get(data, 'numberShare', 0)
    const outstandingNumberShare = get(data, 'outstandingNumberShare', 0)
    const pricePerShare = get(data, 'pricePerShare', 0)
    const prospectusUrl = get(data, 'prospectusUrl', '')
    const status = get(data, 'status', '')

    const activeDate = [startDate, endDate]

    const penerbitName = getPenerbitName(penerbit)
    const name = `${code} - ${penerbitName}`

    const soldShare = numberShare - outstandingNumberShare
    const target = numberShare * pricePerShare
    const collected = soldShare * pricePerShare

    const { isExpiredDate, isFailed, isOnGoing, isOnGoingDate } =
        getSummaryProduct(startDate, endDate, status)

    const prospectus = prospectusUrl
        ? [
              {
                  fieldName: 'prospectus',
                  name: last(split(prospectusUrl, '/')),
                  url: prospectusUrl
              }
          ]
        : []

    return {
        activeDate,
        category,
        code,
        collected,
        couponRate: data.couponRate || 0,
        createdAt,
        createdBy: data.createdBy || '',
        description: data.description || '',
        dividendPeriod: data.dividendPeriod || '',
        doneManagementFee: data.doneManagementFee || 0,
        endDate,
        failedManagementFee: data.failedManagementFee || 0,
        fullManagementFee: data.fullManagementFee || 0,
        id: data.id || null,
        interestRate: data.interestRate || 0,
        isExpiredDate,
        isFailed,
        isOnGoing,
        isOnGoingDate,
        name,
        numberShare,
        outstandingNumberShare,
        penerbit,
        penerbitId: data.penerbitId || null,
        penerbitName,
        pricePerShare,
        productCategoryId: data.productCategoryId || null,
        productDocuments,
        productImages,
        productRibbon,
        productRibbonId: data.productRibbonId || null,
        productTypeId: data.productTypeId || null,
        prospectus,
        prospectusUrl,
        purchaseOrders,
        remarks: data.remarks || '',
        settlementDate,
        shareSize: data.shareSize || 0,
        slug: data.slug || '',
        soldShare,
        startDate,
        status,
        target,
        title: data.title || '',
        turnoverLastYear: data.turnoverLastYear || 0,
        type,
        updatedAt,
        updatedBy: data.updatedBy || ''
    }
}
