import PropTypes from 'prop-types'
import React from 'react'
import StyledInputNumber from './StyledComponents'

const InputNumber = (props) => {
    const {
        bordered,
        dark,
        defaultValue,
        disabled,
        formatter,
        max,
        min,
        name,
        onChange,
        onKeyDown,
        parser,
        size,
        step,
        value
    } = props

    const handleChange = (value) => onChange(value, name)

    return (
        <StyledInputNumber
            $dark={dark}
            bordered={bordered}
            defaultValue={defaultValue}
            disabled={disabled}
            formatter={formatter}
            max={max}
            min={min}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            parser={parser}
            size={size}
            step={step}
            value={value}
        />
    )
}

InputNumber.propTypes = {
    bordered: PropTypes.bool,
    dark: PropTypes.bool,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    formatter: PropTypes.func,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    parser: PropTypes.func,
    size: PropTypes.string,
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

InputNumber.defaultValue = {
    bordered: true
}

export default InputNumber
