import { DATE_SLASH_FORMAT } from 'constants/formats'
import { EyeOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { default as AntdBadge } from 'antd/lib/badge'
import { default as AntdCard } from 'antd/lib/card'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { Breadcrumb, Button, ReduxFormPicker, Space } from 'antdcomponents'
import colors from 'basic/colors'
import modifyVars from 'basic/modifyVars'
import * as productOptions from 'common/options/product'
import * as purchaseOrderOptions from 'common/options/purchaseOrder'
import isMobile from 'is-mobile'
import countBy from 'lodash/countBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import formatPrice from 'utility/formatPrice'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'
import InboxSection from './comps/InboxSection'
import { StyledText, StyledTitle } from './StyledComponents'

const formatDates = (dates, format) => [
    moment(dates[0], format).startOf('day').format(),
    moment(dates[1], format).endOf('day').format()
]

const layoutInitialState = {
    left: { span: 16 },
    product: { justify: 'space-around', span: 4.8 },
    right: { span: 8 },
    user: { span: 12 }
}

const getOptions = (dates) => {
    let dateFilter = {}
    if (!isEmpty(dates)) {
        dateFilter = {
            filteredDates: {
                after: dates[1],
                before: dates[0]
            }
        }
    }
    return {
        options: {
            ...dateFilter,
            orderBy: { by: 'createdAt', order: 'DESC' }
        }
    }
}

const Home = (props) => {
    const {
        authenticationCheck,
        clearInbox,
        clearPemodal,
        clearPenerbit,
        clearProduct,
        clearPurchaseOrder,
        dispatch,
        getInboxes,
        getPemodalStatuses,
        getPenerbitStatuses,
        getProductStatuses,
        getPurchaseOrders,
        inboxes,
        isLoadingAuth,
        isLoadingInbox,
        isLoadingPemodal,
        isLoadingPenerbit,
        isLoadingProduct,
        isLoadingPurchaseOrder,
        isTokenExpired,
        pemodalStatuses,
        penerbitStatuses,
        productStatuses,
        purchaseOrders,
        totalInboxes
    } = props

    const formValues = useSelectorFormValues('home')
    const filteredDates = get(formValues, 'filteredDates', [])
    const formattedDateValues = !isEmpty(filteredDates)
        ? formatDates(filteredDates, DATE_SLASH_FORMAT)
        : null

    const [dividend, setDividend] = useState(0)
    /**
     * useEffect diganti soalnya udah ada library isMobile(),
     * mestinya kalau website nya di resize dari desktop, ga responsive.
     * Tapi kalau website nya di akses dari mobile user agent, bakalan responsive
     */
    const layout = isMobile()
        ? {
              left: { ...layoutInitialState.left, span: 24 },
              product: { justify: 'center', span: 12 },
              right: { ...layoutInitialState.right, span: 24 },
              user: { span: 24 }
          }
        : layoutInitialState
    const [pemodalStatusOptions, setPemodalStatusOptions] = useState([])
    const [penerbitStatusOptions, setPenerbitStatusOptions] = useState([])
    const [purchaseOrderStatuses, setPurchaseOrderStatuses] = useState([])
    const [subtotal, setSubtotal] = useState(0)

    const breadcrumbItems = [{ label: 'Beranda', linkTo: '/' }]

    const prevIsTokenExpired = usePrevious(isTokenExpired)

    useEffect(() => {
        authenticationCheck()

        return () => {
            dispatch(clearProduct())
            dispatch(clearPurchaseOrder())
            dispatch(clearPemodal())
            dispatch(clearPenerbit())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            !isUndefined(prevIsTokenExpired) &&
            !isTokenExpired &&
            !isLoadingAuth
        ) {
            const initialOptions = getOptions(formattedDateValues)

            getProductStatuses(initialOptions)
            getPurchaseOrders(initialOptions)
            getPemodalStatuses(initialOptions)
            getPenerbitStatuses(initialOptions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        prevIsTokenExpired,
        isTokenExpired,
        isLoadingAuth,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(formattedDateValues)
    ])

    const getProductStatus = (key) => productStatuses[key]
    const checkValue = (obj) => (isUndefined(obj) ? 0 : obj)

    useEffect(() => {
        // flatten array in purchaseOrderTransactions
        const allPurchaseOrderTransactions = flatten(
            map(purchaseOrders, 'purchaseOrderTransactions')
        )

        // filter the arrays for dividen in purchaseOrderTransactions' description
        const filterPurchaseOrderTransactions = filter(
            allPurchaseOrderTransactions,
            (item) => includes(item.description, 'dividen')
        )

        const temp = countBy(purchaseOrders, 'status')
        // filter the arrays for status
        setPurchaseOrderStatuses(
            map(Object.keys(temp), (item) => {
                return { key: item, label: item, value: temp[item] }
            })
        )

        setSubtotal(sumBy(purchaseOrders, 'subtotal'))
        setDividend(sumBy(filterPurchaseOrderTransactions, 'amount'))
    }, [purchaseOrders])

    useEffect(() => {
        setPemodalStatusOptions([
            {
                label: 'Aktif - Prelim',
                value: checkValue(pemodalStatuses.prelim)
            },
            {
                label: 'Aktif - Sukses',
                value: checkValue(pemodalStatuses.success)
            },
            {
                label: 'Aktif - Gagal',
                value: checkValue(pemodalStatuses.failed)
            },
            {
                label: 'Aktif - Terverifikasi',
                value: checkValue(pemodalStatuses.verified)
            },
            {
                label: 'Non Aktif',
                value: checkValue(pemodalStatuses.inactive)
            }
        ])
    }, [pemodalStatuses])

    useEffect(() => {
        setPenerbitStatusOptions([
            {
                label: 'Aktif - Dalam Proses',
                value: checkValue(penerbitStatuses.in_process)
            },
            {
                label: 'Aktif - Terverifikasi',
                value: checkValue(penerbitStatuses.verified)
            },
            {
                label: 'Aktif - Ditolak',
                value: checkValue(penerbitStatuses.unverified)
            },
            {
                label: 'Non Aktif',
                value: checkValue(penerbitStatuses.inactive)
            }
        ])
    }, [penerbitStatuses])

    const handleRedirect = (path) => () => window.open(path, '_blank')

    const handleWIP = () => message.info('Fitur dalam progres!')

    const renderFilteredDates = () => (
        <Field
            component={ReduxFormPicker}
            formItemProps={{ required: true }}
            name='filteredDates'
            type='range'
        />
    )

    const renderProduct = () => (
        <AntdCard loading={isLoadingProduct}>
            <Space direction='vertical'>
                <AntdRow align='middle' gutter={[8, 8]} justify='space-between'>
                    <StyledTitle>Manajemen Produk</StyledTitle>
                    <Button
                        icon={'PlusOutlined'}
                        name='add-product-btn'
                        onClick={handleRedirect('/product/add')}
                        type='primary'
                        value={'Pendanaan Baru'}
                    />
                </AntdRow>
                <AntdRow align='middle' justify={layout.product.justify}>
                    {map(productOptions.statusOptions, (option) => {
                        const colorIndex = findIndex(
                            productOptions.statuses,
                            (item) => item === option.value
                        )
                        const statusColor =
                            productOptions.statusColors[colorIndex]
                        const value = isUndefined(
                            getProductStatus(option.value)
                        )
                            ? 0
                            : getProductStatus(option.value)
                        return (
                            <AntdCol
                                key={option.value}
                                span={layout.product.span}
                            >
                                <AntdRow align='center' justify='center'>
                                    <StyledTitle>{value}</StyledTitle>
                                </AntdRow>
                                <AntdRow
                                    align='middle'
                                    gutter={[16, 0]}
                                    justify='center'
                                >
                                    <AntdCol>
                                        <AntdBadge color={statusColor} />
                                    </AntdCol>
                                    <AntdCol>
                                        <span>{option.label}</span>
                                    </AntdCol>
                                    <AntdCol>
                                        <EyeOutlined
                                            color={colors.blueberry}
                                            onClick={handleRedirect(
                                                `/product?status=${option.value}`
                                            )}
                                        />
                                    </AntdCol>
                                </AntdRow>
                            </AntdCol>
                        )
                    })}
                </AntdRow>
            </Space>
        </AntdCard>
    )

    const renderPurchaseOrder = () => (
        <AntdCard loading={isLoadingPurchaseOrder}>
            <Space direction='vertical'>
                <StyledTitle>Pesanan</StyledTitle>
                <AntdRow
                    align='middle'
                    gutter={[8, 8]}
                    justify={layout.product.justify}
                >
                    {map(purchaseOrderOptions.statusOptions, (status) => {
                        const valueObject = find(
                            purchaseOrderStatuses,
                            (item) => item.key === status.value
                        )
                        const value = isUndefined(valueObject)
                            ? 0
                            : valueObject.value
                        return (
                            <AntdCol
                                key={status.value}
                                span={layout.product.span}
                            >
                                <AntdRow align='center' justify='center'>
                                    <StyledTitle>{value}</StyledTitle>
                                </AntdRow>
                                <AntdRow
                                    align='middle'
                                    gutter={[16, 0]}
                                    justify='center'
                                >
                                    <AntdCol>
                                        <AntdBadge color={status.color} />
                                    </AntdCol>
                                    <AntdCol>
                                        <span>{status.label}</span>
                                    </AntdCol>
                                    <AntdCol>
                                        <EyeOutlined
                                            color={colors.blueberry}
                                            onClick={handleRedirect(
                                                `/purchase-order?status=${status.value}`
                                            )}
                                        />
                                    </AntdCol>
                                </AntdRow>
                            </AntdCol>
                        )
                    })}
                </AntdRow>
            </Space>
        </AntdCard>
    )

    const renderSubtotal = () => (
        <AntdCard loading={isLoadingPurchaseOrder}>
            <AntdRow align='middle' justify='space-between'>
                <StyledTitle color={colors.graniteGray}>
                    Total Pendanaan
                </StyledTitle>
                {/* //TODO: add onClick function later! */}
                <InfoCircleOutlined onClick={handleWIP} />
            </AntdRow>
            <StyledTitle
                font={modifyVars.hammersmithOneFont}
            >{`IDR ${formatPrice(subtotal)}`}</StyledTitle>
            <StyledTitle color={colors.graniteGray}>
                Total Dividen Dibagikan
            </StyledTitle>
            <StyledTitle
                font={modifyVars.hammersmithOneFont}
            >{`IDR ${formatPrice(dividend)}`}</StyledTitle>
        </AntdCard>
    )

    const renderUser = (loading, title, renderStatus) => (
        <AntdCol span={layout.user.span}>
            <AntdCard loading={loading}>
                <Space direction='vertical'>
                    <StyledTitle>{title}</StyledTitle>
                    {map(renderStatus, (item) => (
                        <AntdRow justify='space-between' key={item.label}>
                            <span>{item.label}</span>
                            <StyledText>{item.value}</StyledText>
                        </AntdRow>
                    ))}
                </Space>
            </AntdCard>
        </AntdCol>
    )

    const renderUsers = () => (
        <AntdRow gutter={[16, 16]}>
            {renderUser(isLoadingPemodal, 'Pemodal', pemodalStatusOptions)}
            {renderUser(isLoadingPenerbit, 'Penerbit', penerbitStatusOptions)}
        </AntdRow>
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <AntdCol span={layout.right.span}>{renderFilteredDates()}</AntdCol>
            <AntdRow gutter={[16, 16]}>
                <AntdCol span={layout.left.span}>
                    <Space direction='vertical'>
                        {renderSubtotal()}
                        {renderPurchaseOrder()}
                        {renderProduct()}
                        {renderUsers()}
                    </Space>
                </AntdCol>
                <AntdCol span={layout.right.span}>
                    <Space direction='vertical'>
                        <InboxSection
                            clearInbox={clearInbox}
                            dispatch={dispatch}
                            getInboxes={getInboxes}
                            inboxes={inboxes}
                            isLoadingAuth={isLoadingAuth}
                            isLoadingInbox={isLoadingInbox}
                            isTokenExpired={isTokenExpired}
                            prevIsTokenExpired={prevIsTokenExpired}
                            totalInboxes={totalInboxes}
                        />
                    </Space>
                </AntdCol>
            </AntdRow>
        </Space>
    )
}

Home.propTypes = {
    authenticationCheck: PropTypes.func,
    clearInbox: PropTypes.func,
    clearPemodal: PropTypes.func,
    clearPenerbit: PropTypes.func,
    clearProduct: PropTypes.func,
    clearPurchaseOrder: PropTypes.func,
    dispatch: PropTypes.func,
    getInboxes: PropTypes.func,
    getPemodalStatuses: PropTypes.func,
    getPenerbitStatuses: PropTypes.func,
    getProductStatuses: PropTypes.func,
    getPurchaseOrders: PropTypes.func,
    inboxes: PropTypes.array,
    isLoadingAuth: PropTypes.bool,
    isLoadingInbox: PropTypes.bool,
    isLoadingPemodal: PropTypes.bool,
    isLoadingPenerbit: PropTypes.bool,
    isLoadingProduct: PropTypes.bool,
    isLoadingPurchaseOrder: PropTypes.bool,
    isTokenExpired: PropTypes.bool,
    pemodalStatuses: PropTypes.object,
    penerbitStatuses: PropTypes.object,
    productStatuses: PropTypes.object,
    purchaseOrders: PropTypes.array,
    totalInboxes: PropTypes.number
}

export default Home
