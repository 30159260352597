import colors from 'basic/colors'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

const subjects = [
    'akun bank',
    'dividen',
    'pemodal',
    'penerbit',
    'penyelenggara',
    'portfolio',
    'refund',
    'registrasi pemodal',
    'registrasi perusahaan',
    'screening',
    'secondary penerbit',
    'transaksi'
]

export const subjectOptions = sortBy(
    map(subjects, (item) => ({
        label: startCase(item),
        value: item
    }))
)

const statusesInd = ['baru', 'proses', 'selesai']
const statuses = ['new', 'process', 'done']

export const statusColors = [
    colors.salmon,
    colors.springGreen,
    colors.blueberry
]

export const statusOptions = sortBy(
    map(statuses, (item, key) => ({
        color: statusColors[key],
        label: startCase(statusesInd[key]),
        value: item
    })),
    ['label']
)
