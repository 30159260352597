import modifyVars from 'basic/modifyVars'
import ReactQuill from 'mym-react-quill'
import styled from 'styled-components'

const StyledReactQuill = styled(ReactQuill)`
    .ql-toolbar,
    .ql-container {
        border: 2px solid ${modifyVars.borderGray} !important;
    }
    .ql-toolbar {
        border-bottom: 0px !important;
        border-top-left-radius: ${modifyVars.defaultBorderRadius};
        border-top-right-radius: ${modifyVars.defaultBorderRadius};
    }
    .ql-container {
        border-top: 0px !important;
        border-bottom-left-radius: ${modifyVars.defaultBorderRadius};
        border-bottom-right-radius: ${modifyVars.defaultBorderRadius};
    }
    .ql-editor {
        min-height: 150px;
    }
`

export default StyledReactQuill
