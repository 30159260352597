import { actionBlockOptions } from 'common/options'
import { kycStatusOptions } from 'common/options/pemodal'

const filterConfig = [
    {
        name: 'email',
        placeholder: 'Cari Email',
        query: 'email',
        type: 'input'
    },
    {
        name: 'idCardName',
        placeholder: 'Cari Nama',
        query: 'idCardName',
        type: 'input'
    },
    {
        name: 'username',
        placeholder: 'Cari Username',
        query: 'username',
        type: 'input'
    },
    {
        name: 'isActive',
        options: actionBlockOptions,
        placeholder: 'Cari Pengguna Aktif/Tidak',
        query: 'isActive',
        type: 'select'
    },
    {
        name: 'kycStatus',
        options: kycStatusOptions,
        placeholder: 'Cari Status KYC Terverifikasi/Belum',
        query: 'kycStatus',
        type: 'select'
    }
]

export default filterConfig
