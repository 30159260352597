import { configureStore } from '@reduxjs/toolkit'
import { persistCombineReducers, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import asyncAction from './middleware/asyncAction'

import rootReducer from './reducers'

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

export default () => {
    let store = configureStore({
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: { ignoredPaths: ['form'] },
                serializableCheck: false
            }).concat(asyncAction),
        reducer: persistedReducer
    })
    // The store now has redux-thunk added and the Redux DevTools Extension is turned on

    let persistor = persistStore(store)
    return { persistor, store }
}
