import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/bankList'
import * as bankListAPI from './bankListAPI'

const initialState = {
    bankLists: [],
    isError: false,
    isLoading: false,
    totalBankLists: 0
}

export const { getBankLists } = bankListAPI

const bankListSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getBankLists.pending, (state) =>
            Object.assign(state, {
                bankLists: [],
                isError: false,
                isLoading: true
            })
        )
        builder.addCase(getBankLists.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                bankLists: map(data.bankLists, (item) => toState(item)),
                isLoading: false,
                totalBankLists: data.count
            })
        })
        builder.addCase(getBankLists.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false
            })
        )
    },
    initialState,
    name: 'BANK_LIST',
    reducers: {
        clearBankList: () => initialState
    }
})

export const { clearBankList } = bankListSlice.actions

const { reducer } = bankListSlice
export default reducer
