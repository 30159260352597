import * as authenticationSlice from 'features/authentication/authenticationSlice'
import * as inboxSlice from 'features/inbox/inboxSlice'
import * as pemodalSlice from 'features/pemodal/pemodalSlice'
import * as penerbitSlice from 'features/penerbit/penerbitSlice'
import * as productSlice from 'features/product/productSlice'
import * as purchaseOrderSlice from 'features/purchase_order/purchaseOrderSlice'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { reduxForm } from 'redux-form'
import Home from './Home'

const HomeContainer = (props) => {
    const dispatch = useDispatch()
    return <Home {...props} dispatch={dispatch} />
}

const mapStateToProps = (state) => ({
    inboxes: state.inbox.inboxes,
    isLoadingAuth: state.authentication.isLoading,
    isLoadingInbox: state.inbox.isLoading,
    isLoadingPemodal: state.pemodal.isLoading,
    isLoadingPenerbit: state.penerbit.isLoading,
    isLoadingProduct: state.product.isLoading,
    isLoadingPurchaseOrder: state.purchaseOrder.isLoading,
    isTokenExpired: state.authentication.isTokenExpired,
    pemodalStatuses: state.pemodal.pemodalStatuses,
    penerbitStatuses: state.penerbit.penerbitStatuses,
    productStatuses: state.product.productStatuses,
    purchaseOrders: state.purchaseOrder.purchaseOrders,
    totalInboxes: state.inbox.totalInboxes
})

const mapDispatchToProps = {
    authenticationCheck: authenticationSlice.authenticationCheck,
    clearInbox: inboxSlice.clearInbox,
    clearPemodal: pemodalSlice.clearPemodal,
    clearPenerbit: penerbitSlice.clearPenerbit,
    clearProduct: productSlice.clearProduct,
    clearPurchaseOrder: purchaseOrderSlice.clearPurchaseOrder,
    getInboxes: inboxSlice.getInboxes,
    getPemodalStatuses: pemodalSlice.getPemodalStatuses,
    getPenerbitStatuses: penerbitSlice.getPenerbitStatuses,
    getProductStatuses: productSlice.getProductStatuses,
    getPurchaseOrders: purchaseOrderSlice.getPurchaseOrders
}

const HomeForm = reduxForm({
    enableReinitialize: true,
    form: 'home'
})(HomeContainer)

export default connect(mapStateToProps, mapDispatchToProps)(HomeForm)
