import * as annualSalarySlice from 'features/annual_salary/annualSalarySlice'
import * as bankListSlice from 'features/bank_list/bankListSlice'
import * as businessSectorSlice from 'features/business_sector/businessSectorSlice'
import * as citySlice from 'features/city/citySlice'
import * as districtSlice from 'features/district/districtSlice'
import * as entityTypeSlice from 'features/entity_type/entityTypeSlice'
import * as fundingAmountSlice from 'features/funding_amount/fundingAmountSlice'
import * as incomeSourceSlice from 'features/income_source/incomeSourceSlice'
import * as penerbitSlice from 'features/penerbit/penerbitSlice'
import * as productTypeSlice from 'features/product_type/productTypeSlice'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import AddEditView from './AddEditView'
import { initialPenerbit } from './helper'

const AddEditViewContainer = (props) => {
    const params = useParams()
    return <AddEditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]

    const penerbit = cloneDeep(state.penerbit.penerbit)

    let initialValues = initialPenerbit
    if (!isEmpty(penerbit)) {
        initialValues = Object.assign(penerbit, {
            penerbitDetailKey: 0
        })
    }
    initialValues = Object.assign(initialValues, { isUploading: true })

    return {
        annualSalaries: state.annualSalary.annualSalaries,
        bankLists: state.bankList.bankLists,
        businessSectors: state.businessSector.businessSectors,
        cities: state.city.cities,
        districts: state.district.districts,
        entityTypes: state.entityType.entityTypes,
        fundingAmounts: state.fundingAmount.fundingAmounts,
        incomeSources: state.incomeSource.incomeSources,
        initialValues,
        isActionLoading: state.penerbit.isActionLoading,
        isActionSuccess: state.penerbit.isActionSuccess,
        isLoading: state.penerbit.isLoading,
        isSendEmailLoading: state.penerbit.isSendEmailLoading,
        isSendEmailSuccess: state.penerbit.isSendEmailSuccess,
        productTypes: state.productType.productTypes,
        provinces: state.city.provinces,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearAnnualSalary: annualSalarySlice.clearAnnualSalary,
    clearBankList: bankListSlice.clearBankList,
    clearBusinessSector: businessSectorSlice.clearBusinessSector,
    clearCity: citySlice.clearCity,
    clearDistrict: districtSlice.clearDistrict,
    clearEntityType: entityTypeSlice.clearEntityType,
    clearFundingAmount: fundingAmountSlice.clearFundingAmount,
    clearIncomeSource: incomeSourceSlice.clearIncomeSource,
    clearPenerbit: penerbitSlice.clearPenerbit,
    clearProductType: productTypeSlice.clearProductType,
    createPenerbit: penerbitSlice.createPenerbit,
    getAnnualSalaries: annualSalarySlice.getAnnualSalaries,
    getBankLists: bankListSlice.getBankLists,
    getBusinessSectors: businessSectorSlice.getBusinessSectors,
    getCities: citySlice.getCities,
    getDistricts: districtSlice.getDistricts,
    getEntityTypes: entityTypeSlice.getEntityTypes,
    getFundingAmounts: fundingAmountSlice.getFundingAmounts,
    getIncomeSources: incomeSourceSlice.getIncomeSources,
    getPenerbit: penerbitSlice.getPenerbit,
    getProductTypes: productTypeSlice.getProductTypes,
    getProvinces: citySlice.getProvinces,
    sendWelcomeEmailPenerbit: penerbitSlice.sendWelcomeEmailPenerbit,
    updatePenerbit: penerbitSlice.updatePenerbit
}

const AddEditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'penerbit'
})(AddEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(AddEditViewForm)
