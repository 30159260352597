import { default as AntdTypography } from 'antd/lib/typography'
import colors from 'basic/colors'
import modifyVars from 'basic/modifyVars'
import styled from 'styled-components'

const getColor = (color, dark) => {
    if (color) {
        return color
    } else if (dark) {
        return 'white'
    } else {
        return colors.persianIndigo
    }
}

const AntdTitle = AntdTypography.Title
const StyledLabel = styled(AntdTitle)`
    &&& {
        &,
        a {
            font-style: ${modifyVars.headingStyle.fontStyle};
            font-weight: ${modifyVars.headingStyle.fontWeight};
            font-size: ${(props) =>
                modifyVars.headingStyle.fontSizeDesktop[props.level - 1]};
            font-family: ${(props) =>
                modifyVars.headingStyle.fontFamily[props.level - 1]};
            letter-spacing: ${modifyVars.headingStyle.letterSpacing};
            margin: 0px;
            margin-bottom: 3px;
            color: ${(props) => getColor(props.color, props.$dark)};

            @media screen and (max-width: 768px) {
                font-size: ${(props) =>
                    modifyVars.headingStyle.fontSizeMobile[props.level - 1]};
            }
        }
    }
`

export default StyledLabel
