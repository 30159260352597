import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { StyledTableExpand, TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import moment from 'moment'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { generateCsv } from 'utility/download'
import * as message from 'utility/message'
import * as config from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        clearProductType,
        clearPurchaseOrder,
        dispatch,
        error,
        exportData,
        exportPurchaseOrders,
        getProductTypes,
        getPurchaseOrders,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isLoadingExport,
        location,
        productTypes,
        purchaseOrders,
        totalPurchaseOrders
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    useEffect(() => {
        getProductTypes()

        const fetchPurchaseOrders = () => {
            const offset = (page - 1) * limit
            getPurchaseOrders({
                limit,
                offset,
                options: { ...options, orderBy }
            })
        }

        fetchPurchaseOrders()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }
        if (!isLoadingExport && !error && exportData) {
            const fileName = `rekap_order_${moment().format(
                DATE_HOUR_SLASH_FORMAT
            )}.csv`
            message.success('data berhasil diexport')
            generateCsv(exportData, fileName)
        }

        return () => {
            dispatch(clearPurchaseOrder())
        }
    }, [
        clearProductType,
        clearPurchaseOrder,
        dispatch,
        error,
        exportData,
        getProductTypes,
        getPurchaseOrders,
        isActionLoading,
        isActionSuccess,
        isLoadingExport,
        limit,
        options,
        orderBy,
        page
    ])

    const handleClear = () => {
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setOptions({})
        setOrderBy(defaultOrderBy)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        let newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }

        if (!isEmpty(sorter)) {
            if (sorter.field === 'productCode') {
                newOrderBy = {
                    alias: '`product.code`',
                    order: sorter.order
                }
            }
            if (sorter.field === 'pemodalName') {
                newOrderBy = {
                    alias: '`pemodal.name`',
                    order: sorter.order
                }
            }
            if (sorter.field === 'pemodalEmail') {
                newOrderBy = {
                    alias: '`pemodal.email`',
                    order: sorter.order
                }
            }
        }
        setOrderBy(newOrderBy)
    }

    const handleExport = () => {
        exportPurchaseOrders({
            options: {
                ...options,
                orderBy
            }
        })
    }

    const handleSearch = (newOptions) => {
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setOptions(newOptions)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Order' }
    ]

    const renderActions = (text, record) => (
        <TableRowActions options={config.rowActions(record.id)} />
    )

    const renderExpandSection = (record) => {
        const purchaseOrderTransactions = get(
            record,
            'purchaseOrderTransactions',
            []
        )

        return (
            <StyledTableExpand
                columns={config.expandedColumns}
                dataSource={config.expandedDataSource(
                    purchaseOrderTransactions
                )}
            />
        )
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig(productTypes)}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onExport={handleExport}
                onSearch={handleSearch}
                totalItems={totalPurchaseOrders}
            />
            <Table
                columns={config.columns(renderActions)}
                dataSource={config.dataSource(purchaseOrders)}
                expandedRowRender={renderExpandSection}
                loading={isLoading}
                onChange={handleChangeTable}
                scroll={
                    purchaseOrders && purchaseOrders.length > 0
                        ? undefined
                        : { x: 10 }
                }
            />
        </Space>
    )
}

List.propTypes = {
    clearProductType: PropTypes.func,
    clearPurchaseOrder: PropTypes.func,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    exportData: PropTypes.string,
    exportPurchaseOrders: PropTypes.func,
    getProductTypes: PropTypes.func,
    getPurchaseOrders: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingExport: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    productTypes: PropTypes.array,
    purchaseOrders: PropTypes.array,
    totalPurchaseOrders: PropTypes.number
}

export default List
