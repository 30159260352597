import * as antdIcon from '@ant-design/icons'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { parseQueryString } from 'utility/url'
import StyledButton from './StyledComponents'

const BackButton = (props) => {
    const { func, lastLocation, route, value } = props
    const navigate = useNavigate()
    const queries = parseQueryString()

    const handleClick = () => {
        //run any function that pass from client
        if (isFunction(func)) {
            func()
        }

        // first: check if there is params in url
        if (queries.backLink) {
            navigate(queries.backLink)
            return
        }

        // second: check if we can go back
        if (lastLocation) {
            navigate(-1)
        } else {
            navigate(route)
        }
    }

    const icon = 'LeftOutlined'
    const AntdIcon = antdIcon[icon]

    const newValue = value || queries.backText || ''
    const renderValue = (
        <span>
            <AntdIcon /> {newValue}
        </span>
    )

    return (
        <StyledButton name='back-button-btn' onClick={handleClick} type='link'>
            {renderValue}
        </StyledButton>
    )
}

BackButton.propTypes = {
    func: PropTypes.func,
    lastLocation: PropTypes.object,
    location: PropTypes.object,
    route: PropTypes.string.isRequired,
    value: PropTypes.string
}

BackButton.defaultProps = {
    value: 'Kembali'
}

export default BackButton
