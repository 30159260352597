import AntdIcon from '@ant-design/icons'
import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSelect } from 'antd/lib/select'
import { Table } from 'antdcomponents'
import colors from 'basic/colors'
import styled from 'styled-components'
import TempTableHeader from './table_header/TableHeader'

export const StyledAntdIcon = styled(AntdIcon)`
    color: ${colors.blueberry};
`

export const StyledAntdDivider = styled(AntdDivider)`
    &&& {
        margin: 20px 0;
        min-width: auto;
        width: auto;
    }
`

export const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`

export const StyledTableExpand = styled(Table)`
    &&& {
        overflow-x: auto;
        width: 100%;

        tr > td {
            border: none;
            font-size: 13px;

            &:first-child {
                padding-left: 40px;
            }
        }

        tr > th {
            background: transparent;
            font-size: 14px;
            border: none !important;
            color: ${colors.persianIndigo};

            &::before {
                display: none !important;
            }

            &:first-child {
                padding-left: 40px;
            }
        }
        .ant-spin-container > * {
            margin: 0 !important;
        }
    }
`

export const StyledAntdRowButtonFilter = styled(AntdRow)`
    &&& {
        gap: 10px;
        display: flex;
        flex-direction: row-reverse;
    }
`

export const StyledSelect = styled(AntdSelect)`
    &&& {
        .ant-select-selector {
            border-radius: 20px;
            font-size: 15px;
            letter-spacing: 1px;
            width: 100%;
        }
    }
`

export const TableHeader = TempTableHeader

export const StyledSpan = styled.span`
    &&& {
        color: ${(props) => props.color};
        font-weight: 500;
        font-size: 12px;
    }
`
