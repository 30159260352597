import colors from 'basic/colors'
import concat from 'lodash/concat'
import map from 'lodash/map'
import startCase from 'lodash/startCase'

const dividendPeriods = ['3 bulan', '6 bulan', '12 bulan']
export const dividendPeriodOptions = map(dividendPeriods, (item) => ({
    label: item,
    value: `tiap ${item}`
}))

const ongoingStatusInd = ['tidak aktif', 'aktif']
const canceledStatusInd = ['failed offer']
const finishedStatusInd = ['full offer', 'done offer']
export const statusesInd = concat(
    ongoingStatusInd,
    canceledStatusInd,
    finishedStatusInd
)

export const onGoingStatus = ['inactive', 'active']
export const canceledStatus = ['failed']
export const finishedStatus = ['full', 'done']
export const statuses = concat(onGoingStatus, canceledStatus, finishedStatus)

const onGoingStatusColors = [colors.laserLemon, colors.springGreen]
const canceledStatusColors = [colors.salmon]
const finishedStatusColors = [colors.purpleHeart, colors.blueberry]
export const statusColors = concat(
    onGoingStatusColors,
    canceledStatusColors,
    finishedStatusColors
)

export const statusOptions = map(statuses, (item, key) => ({
    label: startCase(statusesInd[key]),
    value: item
}))
