import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import mapSelectOptions from 'utility/mapSelectOptions'

const getProvinceOptions = (provinces) =>
    map(provinces, (province) => ({
        label: province,
        value: province
    }))

const getCityOptions = (cities, city) => {
    if (isEmpty(cities)) {
        return []
    }
    let selectedCities = cities

    if (!isEmpty(city)) {
        selectedCities = !isEmpty(cities)
            ? filter(cities, (item) => item.province === city.province)
            : [city]
    }

    return mapSelectOptions(selectedCities, ['type', 'name'])
}

export { getProvinceOptions, getCityOptions }
