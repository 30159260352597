const getPageName = (windowType) => {
    let pageName = 'Tambah'
    if (windowType === 'edit') {
        pageName = 'Ubah'
    }
    if (windowType === 'view') {
        pageName = 'Lihat'
    }
    return pageName
}

export default getPageName
