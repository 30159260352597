import map from 'lodash/map'
import startCase from 'lodash/startCase'

const units = ['%', 'Rp']
const types = ['failed', 'full', 'done']
const defaultAmounts = [3, 4, 5]

const unitOptions = map(units, (item) => ({
    label: item,
    value: item
}))
const typeOptions = map(types, (item) => ({
    label: startCase(item),
    value: item
}))

export { unitOptions, typeOptions, defaultAmounts }
