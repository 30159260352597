import * as productRibbonSlice from 'features/product_ribbon/productRibbonSlice'
import clone from 'lodash/clone'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import AddEditView from './AddEditView'

const AddEditViewContainer = (props) => {
    const params = useParams()
    return <AddEditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const productRibbon = clone(state.productRibbon.productRibbon)
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]
    let initialValues = { status: 'Draft' }
    if (windowType !== 'add' && !isEmpty(productRibbon)) {
        initialValues = productRibbon
    }

    return {
        initialValues,
        isActionLoading: state.productRibbon.isActionLoading,
        isActionSuccess: state.productRibbon.isActionSuccess,
        isLoading: state.productRibbon.isLoading,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearProductRibbon: productRibbonSlice.clearProductRibbon,
    createProductRibbon: productRibbonSlice.createProductRibbon,
    getProductRibbon: productRibbonSlice.getProductRibbon,
    updateProductRibbon: productRibbonSlice.updateProductRibbon
}

const EditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'productRibbon'
})(AddEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(EditViewForm)
