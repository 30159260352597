import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/productCategory'
import * as api from '../api'

export const createProductCategory = createAsyncThunk(
    'CREATE_PRODUCT_CATEGORY',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create(
                '/product-category/create',
                toApi(data)
            )
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const deleteProductCategory = createAsyncThunk(
    'DELETE_PRODUCT_CATEGORY',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.deleteById('/product-category/delete', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProductCategory = createAsyncThunk(
    'GET_PRODUCT_CATEGORY',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/product-category', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProductCategories = createAsyncThunk(
    'GET_PRODUCT_CATEGORIES',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/product-categories', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateProductCategory = createAsyncThunk(
    'UPDATE_PRODUCT_CATEGORY',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update(
                '/product-category/update',
                toApi(data)
            )
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
