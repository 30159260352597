import { Row } from 'antd'
import { default as AntdForm } from 'antd/lib/form'
import {
    Button,
    Modal,
    ReduxFormAutoComplete,
    ReduxFormSelect,
    ReduxFormTextArea,
    Space
} from 'antdcomponents'
import { userTypeOptions } from 'common/options'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, change } from 'redux-form'
import { required } from 'utility/formValidation'
import useSelectorFormValues from 'utility/useSelectorFormValues'

const CreateInbox = (props) => {
    const {
        clearPemodal,
        clearPenerbit,
        getPemodals,
        getPenerbits,
        handleFinish,
        handleSubmit,
        isVisible,
        onCancel,
        pemodals,
        penerbits,
        setChoice
    } = props

    const formValues = useSelectorFormValues('inbox')

    const toUser = get(formValues, 'toUser', '')
    const toTable = get(formValues, 'toTable', '')
    const [toUserOptions, setToUserOptions] = useState([])
    const [userArray, setUserArray] = useState([])

    const dispatch = useDispatch()

    const onSelect = (value, choice) => setChoice(choice.key)

    if (isEmpty(toTable)) {
        dispatch(change('inbox', 'toTable', 'pemodal'))
    }

    useEffect(() => {
        const fetchPemodals = () => {
            getPemodals({
                options: {
                    idCardName: toUser,
                    orderBy: {
                        by: 'idCardName',
                        nestedModel: 'pemodalDetail',
                        order: 'ASC'
                    }
                }
            })
        }
        const fetchPenerbits = () => {
            getPenerbits({
                options: {
                    entityName: toUser,
                    orderBy: {
                        by: 'entityName',
                        order: 'ASC'
                    }
                }
            })
        }
        if (isEmpty(toUser)) {
            if (toTable === 'pemodal') {
                fetchPemodals()
            } else if (toTable === 'penerbit') {
                fetchPenerbits()
            }
        }
        return () => {
            dispatch(clearPenerbit())
            dispatch(clearPemodal())
        }
    }, [
        clearPemodal,
        clearPenerbit,
        dispatch,
        getPemodals,
        getPenerbits,
        toUser,
        toTable
    ])

    useEffect(() => {
        setToUserOptions([])
    }, [dispatch, toTable])

    useEffect(() => {
        if (toTable === 'pemodal' && !isEmpty(pemodals)) {
            setUserArray(pemodals)
        } else if (toTable === 'penerbit' && !isEmpty(penerbits)) {
            setUserArray(penerbits)
        }
        if (isEmpty(toUser)) {
            setUserArray([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pemodals, penerbits, toUser])

    useEffect(() => {
        if (toTable === 'pemodal') {
            setToUserOptions(
                map(pemodals, (item) => ({
                    key: item.username,
                    value: `${item.id} - ${get(
                        item,
                        'pemodalDetail.idCardName',
                        item.username
                    )}`
                }))
            )
        } else if (toTable === 'penerbit')
            setToUserOptions(
                map(penerbits, (item) => ({
                    key: item.entityCode,
                    value: `${item.entityCode} - ${item.entityName}`
                }))
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userArray])

    return (
        <Modal onCancel={onCancel} visible={isVisible}>
            <AntdForm
                colon={false}
                labelAlign='left'
                labelCol={{ span: 6 }}
                labelWrap
                onFinish={handleSubmit(handleFinish)}
                style={{ width: '100%' }}
                wrapperCol={{ span: 18 }}
            >
                <Space direction='vertical'>
                    <Field
                        component={ReduxFormSelect}
                        formItemProps={{ required: true }}
                        label='Tipe Pengguna'
                        name='toTable'
                        options={userTypeOptions}
                        validate={[required]}
                    />
                    <Field
                        component={ReduxFormAutoComplete}
                        disabled={isEmpty(toTable)}
                        formItemProps={{ required: true }}
                        label='Nama penerima'
                        name='toUser'
                        onSelect={onSelect}
                        options={toUserOptions}
                        validate={[required]}
                    />
                    <Field
                        component={ReduxFormTextArea}
                        formItemProps={{ required: true }}
                        label='Pesan'
                        name='message'
                        validate={[required]}
                    />
                    <Row justify='end'>
                        <Button
                            htmlType='submit'
                            name='save-btn'
                            value='Kirim'
                        />
                    </Row>
                </Space>
            </AntdForm>
        </Modal>
    )
}

CreateInbox.propTypes = {
    clearPemodal: PropTypes.func,
    clearPenerbit: PropTypes.func,
    getPemodals: PropTypes.func,
    getPenerbits: PropTypes.func,
    handleFinish: PropTypes.func,
    handleSubmit: PropTypes.func,
    isVisible: PropTypes.bool,
    onCancel: PropTypes.func,
    pemodals: PropTypes.array,
    penerbits: PropTypes.array,
    setChoice: PropTypes.func
}

export default CreateInbox
