import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/contactUs'
import * as api from '../api'

export const getContactUs = createAsyncThunk(
    'GET_CONTACT_US',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/contact-us', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getContactUsList = createAsyncThunk(
    'GET_CONTACT_US_LIST',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/contact-us-list', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateContactUs = createAsyncThunk(
    'UPDATE_CONTACT_US',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/contact-us/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
