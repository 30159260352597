import { DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE } from 'constants/constants'
import { VALID_IMAGE_EXTENSIONS, VALID_PDF_EXTENSION } from 'constants/formats'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdTypography } from 'antd/lib/typography'
import {
    Button,
    FormItem,
    ReduxFormInput,
    ReduxFormInputNumber,
    ReduxFormSelect,
    ReduxFormTextArea,
    Space,
    Upload
} from 'antdcomponents'
import { getCityOptions, getProvinceOptions } from 'common/options/city'
import {
    getCityDistricts,
    getSubdistrictOptions
} from 'common/options/district'
import { typeOptions, unitOptions } from 'common/options/managementFee'
import StyledLabel from 'components/styled_label/StyledLabel'
import { StyledAntdDivider } from 'components/StyledComponents'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import range from 'lodash/range'
import size from 'lodash/size'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import defaultViewRow from 'utility/defaultViewRow'
import { required, username, validEmail } from 'utility/formValidation'
import getLabelOption from 'utility/getLabelOption'
import mapSelectOptions from 'utility/mapSelectOptions'
import * as message from 'utility/message'
import useSelectorFormValues from 'utility/useSelectorFormValues'

const AntdTypographyText = AntdTypography.Text

const PenerbitData = (props) => {
    const {
        bankLists,
        businessSectors,
        change,
        cities,
        clearDistrict,
        districts,
        entityTypes,
        fundingAmounts,
        getCities,
        getDistricts,
        identifier,
        initialValues,
        isRiskStatementApproved,
        isSendEmailLoading,
        isSendEmailSuccess,
        isTermsAndConditionsApproved,
        isWindowTypeAdd,
        isWindowTypeView,
        penerbitDocuments,
        productTypes,
        provinces,
        riskStatementApprovalDate,
        sendWelcomeEmailPenerbit,
        termsAndConditionsApprovalDate
    } = props
    const [currCities, setCurrCities] = useState([])
    const [currDistricts, setCurrDistricts] = useState([])

    const documents = filter(
        penerbitDocuments,
        (item) => item.fieldName !== 'npwp'
    )
    const npwp = filter(penerbitDocuments, (item) => item.fieldName === 'npwp')

    const formValues = useSelectorFormValues('penerbit')
    const city = get(formValues, 'city', {})
    const cityId = get(formValues, 'cityId', null)
    const districtId = get(formValues, 'districtId', null)
    const isWelcomeEmailSent = get(formValues, 'isWelcomeEmailSent', false)
    const penerbitManagementFees = get(formValues, 'penerbitManagementFees', [])

    const { province = '' } = city

    useEffect(() => {
        if (isSendEmailSuccess && !isSendEmailLoading) {
            message.success('Email berhasil terkirim')
        }
    }, [isSendEmailLoading, isSendEmailSuccess])

    useEffect(() => {
        if (province) {
            const penerbitCity = find(
                cities,
                (item) => item.province === province
            )
            if (!isEmpty(penerbitCity)) {
                setCurrCities(cities)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cities])

    useEffect(() => {
        if (cityId) {
            const penerbitDistrict = find(
                districts,
                (cit) => cit.cityId === cityId
            )
            if (!isEmpty(penerbitDistrict)) {
                setCurrDistricts(districts)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districts])

    useEffect(() => {
        const fetchCities = () => getCities({ options: { province } })
        if (province) {
            fetchCities()
        }
    }, [province, getCities])

    useEffect(() => {
        const fetchDistricts = () =>
            getDistricts({ options: { cityId: cityId } })
        if (cityId) {
            fetchDistricts()
        }
    }, [cityId, getDistricts])

    useEffect(() => {
        const fetchDistricts = () =>
            getDistricts({ options: { cityId: cityId } })
        if (cityId) {
            fetchDistricts()
        }
    }, [cityId, getDistricts])

    const handleChangeCity = (value) => {
        clearDistrict()
        const currCity = find(currCities, (item) => item.id === value)
        change('districtId', null)
        change('postalCode', '')
        change('city', currCity)
    }

    const handleChangeDistrict = (value) => {
        const currDistrict = find(currDistricts, (dist) => dist.id === value)
        change('postalCode', currDistrict.postalCode)
    }

    const handleChangeProvince = (value) => {
        clearDistrict()
        change('cityId', null)
        change('districtId', null)
        change('postalCode', '')
        change('city', { province: value })
    }

    const handleRemove = (fieldName) => (options) => {
        const newFileList = get(options, 'fileList', [])
        let newPenerbitDocuments = []
        if (fieldName === 'npwp') {
            newPenerbitDocuments = concat(newFileList, documents)
        }
        if (fieldName === 'documents') {
            newPenerbitDocuments = concat(npwp, newFileList)
        }
        change('penerbitDocuments', newPenerbitDocuments)
    }

    const handleSendWelcomeEmail = () => sendWelcomeEmailPenerbit(identifier)

    const handleUpload = (fieldName) => (options) => {
        const { fileList = [], onProgress = noop, onSuccess = noop } = options
        const newFileList = map(cloneDeep(fileList), (item) => {
            return Object.assign(item, {
                fieldName,
                identifier: get(item, 'identifier', fieldName),
                onProgress,
                onSuccess
            })
        })

        let newPenerbitDocuments = []
        if (fieldName === 'npwp') {
            newPenerbitDocuments = concat(newFileList, documents)
        }
        if (fieldName === 'documents') {
            newPenerbitDocuments = concat(npwp, newFileList)
        }
        change('penerbitDocuments', newPenerbitDocuments)
    }

    const renderButton = () => (
        <Button
            disabled={isWelcomeEmailSent}
            loading={isSendEmailLoading}
            name='send-email'
            onClick={handleSendWelcomeEmail}
            value='Kirim Email Sambutan'
        />
    )

    const renderNote = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Catatan</StyledLabel>
            <Field
                component={ReduxFormTextArea}
                disabled={isWindowTypeView}
                name='remarks'
                rows={5}
            />
            <br />
        </Space>
    )

    const renderViewProfile = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Profil</StyledLabel>
            {defaultViewRow(
                'Email',
                initialValues.email,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Username',
                initialValues.username,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nomor Telepon Seluler',
                `+62 ${initialValues.mobileNumber}`,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kode',
                initialValues.entityCode,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nama',
                initialValues.name,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Sektor Usaha',
                getLabelOption(
                    initialValues.businessSectorId,
                    mapSelectOptions(businessSectors, 'value')
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Provinsi',
                getLabelOption(
                    get(initialValues, 'city.province', ''),
                    getProvinceOptions(provinces)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kota',
                getLabelOption(
                    initialValues.cityId,
                    getCityOptions(currCities, city)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kecamatan',
                getLabelOption(
                    initialValues.districtId,
                    getCityDistricts(districtId, currDistricts)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kelurahan',
                getLabelOption(
                    initialValues.districtId,
                    getSubdistrictOptions(currDistricts, districtId)
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Kode Pos',
                initialValues.postalCode,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Alamat Lengkap Usaha',
                initialValues.address,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nomor NPWP',
                initialValues.entityIdentificationNo,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Situs Resmi',
                initialValues.websiteUrl,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Deskripsi',
                initialValues.description,
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Tipe Pendanaan',
                getLabelOption(
                    initialValues.productTypeId,
                    mapSelectOptions(productTypes, 'title')
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nominal Pendanaan',
                getLabelOption(
                    initialValues.fundingAmountId,
                    mapSelectOptions(fundingAmounts, 'value')
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            <br />
        </Space>
    )

    const renderProfile = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Profil</StyledLabel>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={!isWindowTypeAdd}
                formItemProps={{ required: true }}
                label='Email'
                name='email'
                validate={[required, validEmail]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Username'
                name='username'
                validate={[required, username]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nomor Telepon Seluler'
                name='mobileNumber'
                prefix='+62'
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Kode'
                name='entityCode'
                validate={[required]}
            />
            <FormItem label='Nama' required>
                {!isWindowTypeView && (
                    <Space>
                        <Field
                            component={ReduxFormSelect}
                            name='entityTypeId'
                            options={mapSelectOptions(entityTypes, 'value')}
                            validate={[required]}
                        />
                        <Field
                            component={ReduxFormInput}
                            name='entityName'
                            validate={[required]}
                        />
                    </Space>
                )}
                {isWindowTypeView && (
                    <Field
                        bordered={!isWindowTypeView}
                        component={ReduxFormInput}
                        disabled
                        name='name'
                    />
                )}
            </FormItem>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Sektor Usaha'
                name='businessSectorId'
                options={mapSelectOptions(businessSectors, 'value')}
                validate={[required]}
            />
            <Space>
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={isWindowTypeView}
                    formItemProps={{ required: true }}
                    label='Provinsi'
                    name='city.province'
                    onChange={handleChangeProvince}
                    options={getProvinceOptions(provinces)}
                    validate={[required]}
                />
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={isWindowTypeView || isEmpty(city)}
                    formItemProps={{ required: true }}
                    label='Kota'
                    name='cityId'
                    onChange={handleChangeCity}
                    options={getCityOptions(currCities, city)}
                    validate={[required]}
                />
            </Space>
            <Space>
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={
                        isWindowTypeView || !cityId || isEmpty(currDistricts)
                    }
                    formItemProps={{ required: true }}
                    label='Kecamatan'
                    name='districtId'
                    onChange={handleChangeDistrict}
                    options={getCityDistricts(districtId, currDistricts)}
                    validate={[required]}
                />
                <Field
                    bordered={!isWindowTypeView}
                    component={ReduxFormSelect}
                    disabled={
                        isWindowTypeView ||
                        !cityId ||
                        isEmpty(currDistricts) ||
                        !districtId
                    }
                    formItemProps={{ required: true }}
                    label='Kelurahan'
                    name='districtId'
                    onChange={handleChangeDistrict}
                    options={getSubdistrictOptions(currDistricts, districtId)}
                    validate={[required]}
                />
            </Space>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView || !districtId}
                formItemProps={{ required: true }}
                label='Kode Pos'
                name='postalCode'
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Alamat Lengkap Usaha'
                name='address'
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nomor NPWP'
                name='entityIdentificationNo'
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                label='Situs Resmi'
                name='websiteUrl'
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormTextArea}
                disabled={isWindowTypeView}
                label='Deskripsi'
                name='description'
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Tipe Pendanaan'
                name='productTypeId'
                options={mapSelectOptions(productTypes, 'title')}
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nominal Pendanaan'
                name='fundingAmountId'
                options={mapSelectOptions(fundingAmounts, 'value')}
                validate={[required]}
            />
            <br />
        </Space>
    )

    const renderViewPenerbitBankInfo = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Rekening Bank</StyledLabel>
            {defaultViewRow(
                'Nama Bank',
                getLabelOption(
                    get(initialValues, 'penerbitBankinfo.bankListId', null),
                    mapSelectOptions(bankLists, 'name')
                ),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nama Pemilik Bank',
                get(initialValues, 'penerbitBankinfo.accountHolderName', null),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
            {defaultViewRow(
                'Nomor Rekening',
                get(initialValues, 'penerbitBankinfo.accountNumber', null),
                ...DEFAULT_VIEW_ROW_FULL_WIDTH_VALUE
            )}
        </Space>
    )

    const renderPenerbitBankInfo = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Rekening Bank</StyledLabel>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormSelect}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nama Bank'
                name='penerbitBankinfo.bankListId'
                options={mapSelectOptions(bankLists, 'name')}
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nama Pemilik Bank'
                name='penerbitBankinfo.accountHolderName'
                validate={[required]}
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nomor Rekening'
                name='penerbitBankinfo.accountNumber'
                validate={[required]}
            />
            <br />
        </Space>
    )

    const renderPenerbitDocument = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Dokumen</StyledLabel>
            <Upload
                accept={VALID_IMAGE_EXTENSIONS}
                disabled={isWindowTypeView}
                fileList={npwp}
                formItemProps={{ required: true }}
                label='NPWP'
                listType='picture'
                name='npwp'
                onRemove={handleRemove('npwp')}
                onUpload={handleUpload('npwp')}
            />
            <AntdRow>
                <AntdCol span={11}>
                    <Upload
                        accept={[VALID_IMAGE_EXTENSIONS, VALID_PDF_EXTENSION]}
                        disabled={isWindowTypeView}
                        fileList={documents}
                        label='Pendukung Lainnya'
                        limit={10}
                        name='documents'
                        onRemove={handleRemove('documents')}
                        onUpload={handleUpload('documents')}
                    />
                </AntdCol>
                <AntdCol offset={2} span={11}>
                    <Space direction='vertical' size={0}>
                        {map(documents, (item, key) => {
                            const index = findIndex(penerbitDocuments, item)
                            return (
                                <Field
                                    bordered={!isWindowTypeView}
                                    component={ReduxFormInput}
                                    disabled={isWindowTypeView}
                                    key={key}
                                    label={`Nama Berkas ${key + 1}`}
                                    name={`penerbitDocuments[${index}].identifier`}
                                />
                            )
                        })}
                    </Space>
                </AntdCol>
            </AntdRow>
            <br />
        </Space>
    )

    const renderPenerbitManagementFee = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Fee</StyledLabel>
            {map(range(0, size(penerbitManagementFees)), (item, key) => (
                <Space key={key}>
                    <Field
                        bordered
                        component={ReduxFormInputNumber}
                        disabled={isWindowTypeView}
                        formItemProps={{ required: true }}
                        name={`penerbitManagementFees.${item}.amount`}
                        validate={[required]}
                    />
                    <Field
                        component={ReduxFormSelect}
                        disabled={isWindowTypeView}
                        formItemProps={{ required: true }}
                        name={`penerbitManagementFees.${item}.unit`}
                        options={unitOptions}
                        validate={[required]}
                    />
                    <Field
                        component={ReduxFormSelect}
                        disabled
                        formItemProps={{ required: true }}
                        name={`penerbitManagementFees.${item}.type`}
                        options={typeOptions}
                        validate={[required]}
                    />
                </Space>
            ))}
            <br />
        </Space>
    )

    const renderRiskStatement = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Pernyataan Risiko</StyledLabel>
            <AntdTypographyText
                type={isRiskStatementApproved ? 'success' : 'danger'}
            >
                {isRiskStatementApproved &&
                    `dipahami dan disetujui pengguna pada ${riskStatementApprovalDate}`}
                {!isRiskStatementApproved && 'belum disetujui pengguna'}
            </AntdTypographyText>
            <br />
        </Space>
    )

    const renderTermsAndConditions = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Syarat & Ketentuan</StyledLabel>
            <AntdTypographyText
                type={isTermsAndConditionsApproved ? 'success' : 'danger'}
            >
                {isTermsAndConditionsApproved &&
                    `dipahami dan disetujui pengguna pada ${termsAndConditionsApprovalDate}`}
                {!isTermsAndConditionsApproved && 'belum disetujui pengguna'}
            </AntdTypographyText>
            <br />
        </Space>
    )

    return (
        <Space direction='vertical'>
            {!isWindowTypeView ? renderProfile() : renderViewProfile()}
            {renderTermsAndConditions()}
            {renderRiskStatement()}
            <StyledAntdDivider />
            {!isWindowTypeView
                ? renderPenerbitBankInfo()
                : renderViewPenerbitBankInfo()}
            <StyledAntdDivider />
            {renderPenerbitDocument()}
            {renderNote()}
            <StyledAntdDivider />
            {renderPenerbitManagementFee()}
            {isWindowTypeView && renderButton()}
        </Space>
    )
}

PenerbitData.propTypes = {
    bankLists: PropTypes.array,
    businessSectors: PropTypes.array,
    change: PropTypes.func,
    cities: PropTypes.array,
    clearDistrict: PropTypes.func,
    districts: PropTypes.array,
    entityTypes: PropTypes.array,
    fundingAmounts: PropTypes.array,
    getCities: PropTypes.func,
    getDistricts: PropTypes.func,
    identifier: PropTypes.string,
    initialValues: PropTypes.object,
    isRiskStatementApproved: PropTypes.bool,
    isSendEmailLoading: PropTypes.bool,
    isSendEmailSuccess: PropTypes.bool,
    isTermsAndConditionsApproved: PropTypes.bool,
    isWindowTypeAdd: PropTypes.bool,
    isWindowTypeView: PropTypes.bool,
    penerbitDocuments: PropTypes.array,
    productTypes: PropTypes.array,
    provinces: PropTypes.array,
    riskStatementApprovalDate: PropTypes.string,
    sendWelcomeEmailPenerbit: PropTypes.func,
    termsAndConditionsApprovalDate: PropTypes.string
}

export default PenerbitData
