import { default as AntdDatePicker } from 'antd/lib/date-picker'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

const DatetimePicker = (props) => {
    const {
        bordered,
        defaultValue,
        disabled,
        disabledDate,
        format,
        name,
        onChange,
        placeholder,
        timeProps,
        value
    } = props

    const handleChange = (name, format) => (value) => {
        const dateObj = value ? moment(value).format(format) : ''
        onChange(dateObj, name)
    }
    const newDefaultValue = defaultValue ? moment(defaultValue, format) : null

    const newValue = value ? moment(value, format) : null

    return (
        <AntdDatePicker
            bordered={bordered}
            defaultValue={newDefaultValue}
            disabled={disabled}
            disabledDate={disabledDate}
            format={format}
            onChange={handleChange(name, format)}
            placeholder={placeholder}
            showTime={!isEmpty(timeProps) ? timeProps : true}
            value={newValue}
        />
    )
}

DatetimePicker.propTypes = {
    bordered: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDate: PropTypes.func,
    format: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    timeProps: PropTypes.object,
    value: PropTypes.string
}

DatetimePicker.defaultProps = {
    bordered: true
}

export default DatetimePicker
