import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { StyledTableExpand, TableHeader } from 'components/StyledComponents'
import clone from 'lodash/clone'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import moment from 'moment'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { generateCsv } from 'utility/download'
import * as message from 'utility/message'
import * as config from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        clearProduct,
        clearProductType,
        dispatch,
        error,
        exportData,
        exportProducts,
        getProducts,
        getProductTypes,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isLoadingExport,
        location,
        navigate,
        products,
        productTypes,
        totalProducts,
        updateProduct,
        username
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Produk' }
    ]

    useEffect(() => {
        const fetchProducts = () => {
            const offset = (page - 1) * limit
            getProducts({
                limit,
                offset,
                options: { ...options, orderBy }
            })
        }

        fetchProducts()
        getProductTypes()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }
        if (!isLoadingExport && !error && exportData) {
            const fileName = `rekap_produk_${moment().format(
                DATE_HOUR_SLASH_FORMAT
            )}.csv`
            message.success('data berhasil diexport')
            generateCsv(exportData, fileName)
        }

        return () => {
            dispatch(clearProduct())
            dispatch(clearProductType())
        }
    }, [
        clearProduct,
        clearProductType,
        dispatch,
        error,
        exportData,
        getProducts,
        getProductTypes,
        isActionLoading,
        isActionSuccess,
        isLoadingExport,
        limit,
        options,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id } = products[key]
        if (type === 'cancel') {
            const data = {
                id,
                remarks: `dibatalkan oleh ${username}`,
                status: 'failed'
            }
            return updateProduct(data)
        }
        if (type === 'edit') {
            return navigate(`/product/edit/${id}`)
        }
        if (type === 'view') {
            return navigate(`/product/view/${id}`)
        }
    }

    const handleAdd = () => navigate('/product/add')

    const handleChangePagination = (page, limit) => {
        setExpandedRowKeys([])
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const handleClear = () => {
        setExpandedRowKeys([])
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setOptions({})
        setOrderBy(defaultOrderBy)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const handleExpand = (isExpanded, record) => {
        const rowKey = String(record.key)
        const newExpandedRowKeys = isExpanded
            ? concat(expandedRowKeys, rowKey)
            : filter(expandedRowKeys, (item) => item !== rowKey)
        setExpandedRowKeys(newExpandedRowKeys)
    }

    const handleExpandedRowRender = (record) => {
        const purchaseOrders = get(record, 'purchaseOrders', [])

        return (
            <StyledTableExpand
                columns={config.expandedColumns(purchaseOrders)}
                dataSource={config.expandedDataSource(purchaseOrders)}
            />
        )
    }

    const handleExport = () => {
        exportProducts({
            options: {
                ...options,
                orderBy
            }
        })
    }

    const handleRowExpandable = (record) => {
        const purchaseOrders = get(record, 'purchaseOrders', [])
        return !isEmpty(purchaseOrders)
    }

    const handleSearch = (newOptions) => {
        setExpandedRowKeys([])
        setOptions(newOptions)
    }

    const renderActions = (text, record, index) => (
        <TableRowActions
            options={config.rowActions(index, handleActions, record)}
        />
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig(productTypes)}
                onAdd={handleAdd}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onExport={handleExport}
                onSearch={handleSearch}
                totalItems={totalProducts}
            />
            <Table
                columns={config.columns(renderActions)}
                dataSource={config.dataSource(clone(products))}
                expandedRowKeys={expandedRowKeys}
                expandedRowRender={handleExpandedRowRender}
                loading={isLoading}
                onChange={handleChangeTable}
                onExpand={handleExpand}
                rowExpandable={handleRowExpandable}
                scroll={products && products.length > 0 ? undefined : { x: 10 }}
            />
        </Space>
    )
}

List.propTypes = {
    clearProduct: PropTypes.func,
    clearProductType: PropTypes.func,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    exportData: PropTypes.string,
    exportProducts: PropTypes.func,
    getProductTypes: PropTypes.func,
    getProducts: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingExport: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    productTypes: PropTypes.array,
    products: PropTypes.array,
    totalProducts: PropTypes.number,
    updateProduct: PropTypes.func,
    username: PropTypes.string
}

export default List
