import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import {
    BackButton,
    Breadcrumb,
    Button,
    Form,
    ReduxFormInput,
    Space
} from 'antdcomponents'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import defaultViewRow from 'utility/defaultViewRow'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'

const AddEditView = (props) => {
    const {
        change,
        clearProductRibbon,
        createProductRibbon,
        dispatch,
        getProductRibbon,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        params,
        updateProductRibbon,
        windowType
    } = props

    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const isWindowTypeAdd = windowType === 'add'
    const isWindowTypeEdit = windowType === 'edit'
    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Tag', linkTo: '/category-and-tag' },
        {
            label: isWindowTypeAdd
                ? getPageName(windowType)
                : `${getPageName(windowType)} (${initialValues.title})`
        }
    ]

    useEffect(() => {
        if (!isWindowTypeAdd && identifier) {
            const fetchProductRibbon = async () => getProductRibbon(identifier)
            fetchProductRibbon()
        }

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            navigate(`/category-and-tag${queries}`)
        }

        return () => {
            dispatch(clearProductRibbon())
        }
    }, [
        clearProductRibbon,
        dispatch,
        getProductRibbon,
        identifier,
        isActionLoading,
        isActionSuccess,
        isWindowTypeAdd,
        navigate,
        queries
    ])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/category-and-tag')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const handleDraft = () => change('status', 'draft')
    const handlePublish = () => change('status', 'published')

    const handleFinish = (values) => {
        const { colorCode, id, status, title } = values
        let data = { colorCode, status, title, updatedBy: props.username }

        if (isWindowTypeAdd) {
            data = Object.assign(data, { createdBy: props.username })
            createProductRibbon(data)
        }
        if (isWindowTypeEdit) {
            data = Object.assign(data, { id })
            updateProductRibbon(data)
        }
    }

    const renderButton = () =>
        !isWindowTypeView && (
            <AntdSpace>
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    onClick={handlePublish}
                    type='primary'
                    value='Publish'
                />
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='draft-btn'
                    onClick={handleDraft}
                    value='Simpan Sebagai Draft'
                />
            </AntdSpace>
        )

    const renderViewRibbon = () => (
        <Space direction='vertical'>
            {defaultViewRow('Nama', initialValues.title)}
            {defaultViewRow('Kode Warna', `#${initialValues.colorCode}`)}
        </Space>
    )

    const renderRibbon = () => (
        <Space direction='vertical'>
            <Field
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nama'
                name='title'
            />
            <Field
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Kode Warna'
                name='colorCode'
                prefix='#'
            />
        </Space>
    )

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/category-and-tag${queries}`} />
            <Form onFinish={handleSubmit(handleFinish)}>
                <Space direction='vertical'>
                    {!isWindowTypeView ? renderRibbon() : renderViewRibbon()}
                    {renderButton()}
                </Space>
            </Form>
        </Space>
    )
}

AddEditView.propTypes = {
    change: PropTypes.func,
    clearProductRibbon: PropTypes.func,
    createProductRibbon: PropTypes.func,
    dispatch: PropTypes.func,
    getProductRibbon: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.any,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    updateProductRibbon: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default AddEditView
