import { default as AntdSpin } from 'antd/lib/spin'
import {
    BackButton,
    Breadcrumb,
    Button,
    Form,
    ReduxFormInput,
    ReduxFormSelect,
    Space
} from 'antdcomponents'
import { statusOptions } from 'common/options/contactUs'
import StyledLabel from 'components/styled_label/StyledLabel'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import defaultViewRow from 'utility/defaultViewRow'
import { required } from 'utility/formValidation'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'

const EditView = (props) => {
    const {
        clearContactUs,
        dispatch,
        getContactUs,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        params,
        navigate,
        updateContactUs,
        username,
        windowType
    } = props

    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Hubungi Kami', linkTo: '/contact-us' },
        { label: `${getPageName(windowType)} (${initialValues.email})` }
    ]

    useEffect(() => {
        if (identifier) {
            const fetchContactUs = async () => getContactUs(identifier)
            fetchContactUs()
        }

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            navigate(`/contact-us${queries}`)
        }

        return () => {
            dispatch(clearContactUs())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        clearContactUs,
        dispatch,
        getContactUs,
        identifier,
        isActionSuccess,
        navigate,
        queries
    ])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/contact-us')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const renderStatus = () => (
        <Space direction='vertical'>
            <Field
                component={ReduxFormSelect}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Status'
                name='status'
                options={statusOptions}
                validate={[required]}
            />
            <br />
        </Space>
    )

    const handleFinish = (values) => {
        const { status, id } = values

        const data = {
            id,
            status,
            updatedBy: username
        }
        updateContactUs(data)
    }

    const renderButton = () =>
        !isWindowTypeView && (
            <Button
                htmlType='submit'
                loading={isActionLoading}
                name='save-btn'
                type='primary'
                value='Simpan'
            />
        )

    if (isLoading) {
        return <AntdSpin />
    }

    const renderViewForm = () => (
        <Space direction='vertical'>
            {renderStatus()}
            <StyledLabel level={5}>Hubungi Kami</StyledLabel>
            {defaultViewRow('Email', initialValues.email)}
            {defaultViewRow('Subyek', initialValues.subject)}
            {defaultViewRow('Pesan', initialValues.message)}
        </Space>
    )

    const renderDefaultForm = () => (
        <Space direction='vertical'>
            {renderStatus()}
            <StyledLabel level={5}>Hubungi Kami</StyledLabel>
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled
                label='Email'
                name='email'
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled
                label='Subyek'
                name='subject'
            />
            <Field
                bordered={!isWindowTypeView}
                component={ReduxFormInput}
                disabled
                label='Pesan'
                name='message'
            />
            {renderButton()}
        </Space>
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/contact-us${queries}`} />
            <Form
                colon={false}
                labelAlign='left'
                labelCol={isWindowTypeView ? { flex: '200px' } : {}}
                labelWrap
                layout={isWindowTypeView ? 'horizontal' : 'vertical'}
                onFinish={handleSubmit(handleFinish)}
                wrapperCol={{ flex: 1 }}
            >
                {isWindowTypeView ? renderViewForm() : renderDefaultForm()}
            </Form>
        </Space>
    )
}

EditView.propTypes = {
    change: PropTypes.func,
    clearContactUs: PropTypes.func,
    dispatch: PropTypes.func,
    getContactUs: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.any,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    updateContactUs: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default EditView
