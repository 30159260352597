import isLocalStorageAvailable from './isLocalStorageAvailable'

const setPersistentData = (key, value) => {
    if (isLocalStorageAvailable()) {
        window.localStorage.setItem(key, value)
        return
    }
    document.cookie = `${key}=${value}`
    return
}

export default setPersistentData
