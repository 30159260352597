import { actionBlockOptions } from 'common/options'

const filterConfig = [
    {
        name: 'email',
        placeholder: 'Cari Email',
        query: 'email',
        type: 'input'
    },
    {
        name: 'isActive',
        options: actionBlockOptions,
        placeholder: 'Cari Pengguna Aktif/Tidak',
        query: 'isActive',
        type: 'select'
    },
    {
        name: 'username',
        placeholder: 'Cari Username',
        query: 'username',
        type: 'input'
    }
]

export default filterConfig
