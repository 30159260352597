import last from 'lodash/last'
import split from 'lodash/split'

export const toApi = (data) => ({
    created_by: data.createdBy,
    file: data.file,
    product_id: data.productId,
    sequence: data.sequence,
    type: data.type,
    updated_by: data.updatedBy,
    url: data.url
})

export const toState = (data) => {
    const url = data.url || ''
    let name = ''
    if (url) {
        name = last(split(url, '/'))
    }

    return {
        fieldName: 'productImages',
        name,
        sequence: data.sequence || 0,
        url
    }
}
