import { statusDraftOrPublishedOptions } from 'common/options'
import { categoryOptions } from 'common/options/faq'
import concat from 'lodash/concat'

const filterConfig = [
    {
        name: 'status',
        options: concat(statusDraftOrPublishedOptions, [
            { label: 'Semua', value: 'all' }
        ]),
        placeholder: 'Cari Status',
        query: 'status',
        type: 'select'
    },
    {
        name: 'category',
        options: categoryOptions,
        placeholder: 'Cari Kategori',
        query: 'category',
        type: 'select'
    },
    {
        name: 'question',
        placeholder: 'Cari Pertanyaan',
        query: 'question',
        type: 'input'
    },
    {
        name: 'answer',
        placeholder: 'Cari Jawaban',
        query: 'answer',
        type: 'input'
    }
]

export default filterConfig
