import PropTypes from 'prop-types'
import React from 'react'
import StyledInputPassword from './StyledComponents'

const InputPassword = (props) => {
    const {
        dark,
        disabled,
        inputValue,
        maxLength,
        name,
        onChange,
        placeholder,
        prefix,
        size,
        type
    } = props

    const handleChange = (e) => onChange(e.target.value, name, e)

    return (
        <StyledInputPassword
            $dark={dark}
            bordered
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            prefix={prefix}
            size={size}
            type={type}
            value={inputValue}
        />
    )
}

InputPassword.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.string,
    type: PropTypes.string
}

InputPassword.defaultProps = {
    placeholder: 'Masukkan kata sandi'
}

export default InputPassword
