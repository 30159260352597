import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/productRibbon'
import * as productRibbonAPI from './productRibbonAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    productRibbon: {},
    productRibbons: [],
    totalProductRibbons: 0
}

export const {
    createProductRibbon,
    deleteProductRibbon,
    getProductRibbon,
    getProductRibbons,
    updateProductRibbon
} = productRibbonAPI

const productRibbon = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getProductRibbon.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productRibbon: {}
            })
        )
        builder.addCase(getProductRibbon.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productRibbon: toState(data.productRibbon)
            })
        })
        builder.addCase(getProductRibbons.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productRibbons: []
            })
        )
        builder.addCase(getProductRibbons.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productRibbons: map(data.productRibbons, (item) =>
                    toState(item)
                ),
                totalProductRibbons: data.count
            })
        })
        builder.addMatcher(
            isAnyOf(
                createProductRibbon.pending,
                deleteProductRibbon.pending,
                updateProductRibbon.pending
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createProductRibbon.fulfilled,
                deleteProductRibbon.fulfilled,
                updateProductRibbon.fulfilled
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true
                })
        )
        builder.addMatcher(
            isAnyOf(
                createProductRibbon.rejected,
                deleteProductRibbon.rejected,
                updateProductRibbon.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(getProductRibbon.rejected, getProductRibbons.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'PRODUCT_RIBBON',
    reducers: {
        clearProductRibbon: () => initialState
    }
})

export const { clearProductRibbon } = productRibbon.actions

const { reducer } = productRibbon
export default reducer
