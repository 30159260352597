import map from 'lodash/map'
import startCase from 'lodash/startCase'
import cleanHtmlTag from 'utility/cleanHtmlTag'

const rowActions = (index, handleActions, record) => [
    {
        onClick: handleActions(index, 'edit'),
        type: 'edit'
    },
    {
        confirmContent: `Apakah anda yakin ingin hapus faq (${
            record.category
        } - ${cleanHtmlTag(record.question)} - ${cleanHtmlTag(
            record.answer
        )})?`,
        onClick: handleActions(index, 'delete'),
        type: 'delete'
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'status',
        render: (text) => startCase(text),
        sorter: true,
        title: 'Status',
        width: 100
    },
    {
        dataIndex: 'category',
        render: (text) => startCase(text),
        sorter: true,
        title: 'Kategori',
        width: 100
    },
    {
        dataIndex: 'question',
        render: (text) => startCase(cleanHtmlTag(text)),
        title: 'Pertanyaan',
        width: 200
    },
    {
        dataIndex: 'answer',
        render: (text) => startCase(cleanHtmlTag(text)),
        title: 'Jawaban',
        width: 200
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        sorter: true,
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'updatedBy',
        sorter: true,
        title: 'Diperbarui Oleh',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 100
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
