import { DATE_HOUR_SLASH_FORMAT, DATE_SLASH_FORMAT } from 'constants/formats'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import last from 'lodash/last'
import map from 'lodash/map'
import replace from 'lodash/replace'
import split from 'lodash/split'
import trimStart from 'lodash/trimStart'
import moment from 'moment'
import * as cityTransformer from './city'
import * as districtTransformer from './district'
import * as pemodalBankinfoTransformer from './pemodalBankinfo'
import * as pemodalDetailTransformer from './pemodalDetail'
import * as pemodalDocumentTransformer from './pemodalDocument'

export const toApi = (data) => {
    const mobile_number = data.mobileNumber
        ? '+62' + trimStart(data.mobileNumber, '0')
        : undefined

    const birth_date = moment(data.birthDate, DATE_SLASH_FORMAT).isValid()
        ? moment.utc(data.birthDate, DATE_SLASH_FORMAT).toISOString()
        : undefined

    const kyc_verified_at = moment(
        data.kycVerifiedAt,
        DATE_HOUR_SLASH_FORMAT
    ).isValid()
        ? moment.utc(data.kycVerifiedAt, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined

    const pemodal_documents = !isEmpty(data.pemodalDocuments)
        ? map(data.pemodalDocuments, (item) =>
              pemodalDocumentTransformer.toApi(item)
          )
        : undefined

    const is_active = isString(data.isActive)
        ? data.isActive === 'true'
        : data.isActive
    const is_digisign_activated = isString(data.isDigisignActivated)
        ? data.isDigisignActivated === 'true'
        : data.isDigisignActivated

    return {
        address: data.address,
        birth_date,
        city_id: data.cityId,
        created_by: data.createdBy,
        district_id: data.districtId,
        email: data.email,
        gender: data.gender,
        hometown_id: data.hometownId,
        id: data.id,
        is_active,
        is_digisign_activated,
        is_email_verified: data.isEmailVerified,
        is_risk_statement_approved: data.isRiskStatementApproved,
        is_terms_and_conditions_approved: data.isTermsAndConditionsApproved,
        kyc_status: data.kycStatus,
        kyc_verified_at,
        mobile_number,
        pemodal_bankinfo_id: data.pemodalBankinfoId,
        pemodal_detail_id: data.pemodalDetailId,
        pemodal_document_id: data.pemodalDocumentId,
        pemodal_documents,
        postal_code: data.postalCode,
        profile_picture_url: data.profilePictureUrl,
        remarks: data.remarks,
        risk_statement_approval_date: data.riskStatementApprovalDate,
        terms_and_conditions_approval_date: data.termsAndConditionsApprovalDate,
        updated_by: data.updatedBy,
        username: data.username
    }
}

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const profilePictureUrl = get(data, 'profilePictureUrl', '')
    const mobileNumber = data.mobileNumber
        ? replace(data.mobileNumber, '+62', '')
        : ''

    const birthDate = moment(data.birthDate).isValid()
        ? moment(data.birthDate).format(DATE_SLASH_FORMAT)
        : null
    const kycVerifiedAt = moment(data.kycVerifiedAt).isValid()
        ? moment(data.kycVerifiedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const termsAndConditionsApprovalDate = moment(
        data.termsAndConditionsApprovalDate
    ).isValid()
        ? moment(data.termsAndConditionsApprovalDate).format(
              DATE_HOUR_SLASH_FORMAT
          )
        : null
    const riskStatementApprovalDate = moment(
        data.riskStatementApprovalDate
    ).isValid()
        ? moment(data.riskStatementApprovalDate).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const city = !isEmpty(data.city) ? cityTransformer.toState(data.city) : {}
    const district = !isEmpty(data.district)
        ? districtTransformer.toState(data.district)
        : {}
    const hometown = !isEmpty(data.hometown)
        ? cityTransformer.toState(data.hometown)
        : {}
    const pemodalBankinfo = !isEmpty(data.pemodalBankinfo)
        ? pemodalBankinfoTransformer.toState(data.pemodalBankinfo)
        : undefined
    const pemodalDetail = !isEmpty(data.pemodalDetail)
        ? pemodalDetailTransformer.toState(data.pemodalDetail)
        : {}

    const pemodalDocuments = !isEmpty(data.pemodalDocuments)
        ? map(data.pemodalDocuments, (item) =>
              pemodalDocumentTransformer.toState(item)
          )
        : undefined
    const ktp = filter(pemodalDocuments, (item) => item.identifier === 'ktp')
    const selfie = filter(
        pemodalDocuments,
        (item) => item.identifier === 'selfie'
    )

    const profilePicture = profilePictureUrl
        ? [
              {
                  fieldName: 'profilePicture',
                  name: last(split(profilePictureUrl, '/')),
                  url: profilePictureUrl
              }
          ]
        : []

    const kycStatus = data.kycStatus || ''
    const username = data.username || ''

    const name = `${get(pemodalDetail, 'idCardName', '')}`
    const isSuccess = kycStatus === 'success'

    return {
        address: data.address || '',
        birthDate,
        city,
        cityId: data.cityId || null,
        createdAt,
        createdBy: data.createdBy || '',
        district,
        districtId: data.districtId || null,
        email: data.email || '',
        gender: data.gender || '',
        hometown,
        hometownId: data.hometownId || null,
        id: data.id || null,
        isActive: data.isActive || false,
        isDigisignActivated: data.isDigisignActivated || false,
        isEmailVerified: data.isEmailVerified || false,
        isMobileNumberVerified: data.isMobileNumberVerified || false,
        isRiskStatementApproved: data.isRiskStatementApproved || false,
        isSuccess,
        isTermsAndConditionsApproved:
            data.isTermsAndConditionsApproved || false,
        ktp,
        kycStatus,
        kycVerifiedAt,
        mobileNumber,
        name,
        pemodalBankinfo,
        pemodalDetail,
        postalCode: data.postalCode || '',
        profilePicture,
        profilePictureUrl,
        remarks: data.remarks || '',
        riskStatementApprovalDate,
        selfie,
        termsAndConditionsApprovalDate,
        updatedAt,
        updatedBy: data.updatedBy || '',
        username
    }
}
