import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import {
    BackButton,
    Breadcrumb,
    Button,
    Form,
    ReduxFormInput,
    Space
} from 'antdcomponents'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import defaultViewRow from 'utility/defaultViewRow'
import { required } from 'utility/formValidation'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'

const AddEditView = (props) => {
    const {
        change,
        clearProductCategory,
        createProductCategory,
        dispatch,
        getProductCategory,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        params,
        updateProductCategory,
        windowType
    } = props
    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const isWindowTypeAdd = windowType === 'add'
    const isWindowTypeEdit = windowType === 'edit'
    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Kategori', linkTo: '/category-and-tag' },
        {
            label: isWindowTypeAdd
                ? getPageName(windowType)
                : `${getPageName(windowType)} (${initialValues.title})`
        }
    ]

    useEffect(() => {
        if (!isWindowTypeAdd && identifier) {
            const fetchProductCategory = async () =>
                getProductCategory(identifier)
            fetchProductCategory()
        }

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            navigate(`/category-and-tag${queries}`)
        }

        return () => {
            dispatch(clearProductCategory())
        }
    }, [
        clearProductCategory,
        dispatch,
        getProductCategory,
        identifier,
        isActionLoading,
        isActionSuccess,
        isWindowTypeAdd,
        navigate,
        queries
    ])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/category-and-tag')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const handleDraft = () => change('status', 'draft')
    const handlePublish = () => change('status', 'published')

    const handleFinish = (values) => {
        const { id, slug, status, title } = values
        let data = {
            slug,
            status,
            title,
            updatedBy: props.username
        }

        if (isWindowTypeAdd) {
            data = Object.assign(data, { createdBy: props.username })
            createProductCategory(data)
        }
        if (isWindowTypeEdit) {
            data = Object.assign(data, { id })
            updateProductCategory(data)
        }
    }

    const renderButton = () =>
        !isWindowTypeView && (
            <AntdSpace>
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    onClick={handlePublish}
                    type='primary'
                    value='Publish'
                />
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='draft-btn'
                    onClick={handleDraft}
                    value='Simpan Sebagai Draft'
                />
            </AntdSpace>
        )

    const renderViewCategory = () => (
        <Space direction='vertical'>
            {defaultViewRow('Nama', initialValues.title)}
            {defaultViewRow('Slug', initialValues.slug)}
        </Space>
    )

    const renderCategory = () => (
        <Space direction='vertical'>
            <div>
                <strong>
                    <u>Catatan:</u>
                </strong>
                <br />
                Maksimal karakter untuk nama kategori adalah 20 karakter
            </div>
            <Field
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Nama'
                maxLength={20}
                name='title'
                validate={[required]}
            />
            <Field
                component={ReduxFormInput}
                disabled={isWindowTypeView}
                formItemProps={{ required: true }}
                label='Slug'
                name='slug'
                validate={[required]}
            />
        </Space>
    )

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/category-and-tag${queries}`} />
            <Form onFinish={handleSubmit(handleFinish)}>
                <Space direction='vertical'>
                    {!isWindowTypeView
                        ? renderCategory()
                        : renderViewCategory()}
                    {renderButton()}
                </Space>
            </Form>
        </Space>
    )
}

AddEditView.propTypes = {
    change: PropTypes.func,
    clearProductCategory: PropTypes.func,
    createProductCategory: PropTypes.func,
    dispatch: PropTypes.func,
    getProductCategory: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.any,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    updateProductCategory: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default AddEditView
