import adminUser from 'features/admin_user/adminUserSlice'
import annualSalary from 'features/annual_salary/annualSalarySlice'
import authentication from 'features/authentication/authenticationSlice'
import bankList from 'features/bank_list/bankListSlice'
import businessSector from 'features/business_sector/businessSectorSlice'
import city from 'features/city/citySlice'
import contactUs from 'features/contact_us/contactUsSlice'
import district from 'features/district/districtSlice'
import entityType from 'features/entity_type/entityTypeSlice'
import faq from 'features/faq/faqSlice'
import fundingAmount from 'features/funding_amount/fundingAmountSlice'
import inbox from 'features/inbox/inboxSlice'
import inboxDetail from 'features/inbox_detail/inboxDetailSlice'
import incomeSource from 'features/income_source/incomeSourceSlice'
import investmentGoal from 'features/investment_goal/investmentGoalSlice'
import pemodal from 'features/pemodal/pemodalSlice'
import penerbit from 'features/penerbit/penerbitSlice'
import product from 'features/product/productSlice'
import productCategory from 'features/product_category/productCategorySlice'
import productRibbon from 'features/product_ribbon/productRibbonSlice'
import productType from 'features/product_type/productTypeSlice'
import program from 'features/program/programSlice'
import purchaseOrder from 'features/purchase_order/purchaseOrderSlice'
import { reducer as formReducer } from 'redux-form'

const reducers = {
    adminUser,
    annualSalary,
    authentication,
    bankList,
    businessSector,
    city,
    contactUs,
    district,
    entityType,
    faq,
    form: formReducer, // redux form
    fundingAmount,
    inbox,
    inboxDetail,
    incomeSource,
    investmentGoal,
    pemodal,
    penerbit,
    product,
    productCategory,
    productRibbon,
    productType,
    program,
    purchaseOrder
}

export default reducers
