import { default as AntdResult } from 'antd/lib/result'
import { Button } from 'antdcomponents'
import React from 'react'
import { useOutletContext } from 'react-router-dom'

const NoMatch = () => {
    const { navigate } = useOutletContext()
    const handleNavigate = (route) => () => navigate(route)

    const extra = (
        <Button
            name='go-back-btn'
            onClick={handleNavigate('/')}
            type='primary'
            value='Pergi Ke Beranda'
        />
    )

    return (
        <AntdResult
            extra={extra}
            status={404}
            subTitle='Ups! Sepertinya Anda Kehilangan Arah.'
            title='Maaf'
        />
    )
}

export default NoMatch
