import { Pagination } from 'antd'
import { default as AntdCard } from 'antd/lib/card'
import { default as AntdRow } from 'antd/lib/row'
import { Button, Space, Table } from 'antdcomponents'
import isUndefined from 'lodash/isUndefined'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import * as inboxConfig from '../config/inboxConfig'
import { StyledTitle } from '../StyledComponents'

const InboxSection = (props) => {
    const {
        clearInbox,
        dispatch,
        getInboxes,
        inboxes,
        isLoadingAuth,
        isLoadingInbox,
        isTokenExpired,
        prevIsTokenExpired,
        totalInboxes
    } = props

    const pageSize = 8
    const [page, setPage] = useState(1)
    const [totalState, setTotalState] = useState(totalInboxes)
    const navigate = useNavigate()
    useEffect(() => {
        if (totalInboxes !== 0) {
            setTotalState(totalInboxes)
        }
    }, [totalInboxes])

    useEffect(() => {
        const offset = (page - 1) * pageSize
        if (
            !isUndefined(prevIsTokenExpired) &&
            !isTokenExpired &&
            !isLoadingAuth
        ) {
            getInboxes({ limit: pageSize, offset })
        }

        return () => {
            dispatch(clearInbox())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevIsTokenExpired, isLoadingAuth, isTokenExpired, page])

    const handleChangePagination = (page) => {
        setPage(page)
    }

    const handleRedirectInboxPage = () => {
        const isVisibleState = true
        navigate('/inbox', { state: isVisibleState })
    }

    return (
        <AntdCard>
            <Space direction='vertical'>
                <AntdRow align='middle' justify='space-between'>
                    <Link to={'/inbox'}>
                        <StyledTitle>Kotak Masuk</StyledTitle>
                    </Link>
                    <Button
                        icon='PlusOutlined'
                        name='add-chat-btn'
                        onClick={handleRedirectInboxPage}
                        type='primary'
                        value='Percakapan Baru'
                    />
                </AntdRow>
                <Table
                    columns={inboxConfig.columns}
                    dataSource={inboxes}
                    loading={isLoadingInbox}
                />
                <AntdRow justify='end' type='flex'>
                    <Pagination
                        current={page}
                        onChange={handleChangePagination}
                        pageSize={pageSize}
                        showSizeChanger={false}
                        size='small'
                        total={totalState}
                    />
                </AntdRow>
            </Space>
        </AntdCard>
    )
}

InboxSection.propTypes = {
    clearInbox: PropTypes.func,
    dispatch: PropTypes.func,
    getInboxes: PropTypes.func,
    inboxes: PropTypes.array,
    isLoadingAuth: PropTypes.bool,
    isLoadingInbox: PropTypes.bool,
    isTokenExpired: PropTypes.bool,
    prevIsTokenExpired: PropTypes.bool,
    totalInboxes: PropTypes.number
}

export default InboxSection
