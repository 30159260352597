import { userAdminTypeOptions, yesNoOption } from 'common/options'
import { statusOptions } from 'common/options/inbox'

const filterConfig = [
    {
        name: 'status',
        options: statusOptions(),
        placeholder: 'Cari Status',
        query: 'status',
        type: 'select'
    },
    {
        children: [
            {
                name: 'receiver.username',
                placeholder: 'Cari Username Penerima',
                query: 'receiver.username',
                type: 'input'
            },
            {
                name: 'receiver.name',
                placeholder: 'Cari Nama Penerima',
                query: 'receiver.name',
                type: 'input'
            }
        ],
        name: 'receiver.type',
        options: userAdminTypeOptions,
        placeholder: 'Tipe Penerima',
        query: 'receiver.type',
        type: 'select'
    },
    {
        children: [
            {
                name: 'sender.username',
                placeholder: 'Cari Username Pengirim',
                query: 'sender.username',
                type: 'input'
            },
            {
                name: 'sender.name',
                placeholder: 'Cari Nama Pengirim',
                query: 'sender.name',
                type: 'input'
            }
        ],
        name: 'sender.type',
        options: userAdminTypeOptions,
        placeholder: 'Tipe Pengirim',
        query: 'sender.type',
        type: 'select'
    },
    {
        name: 'message',
        placeholder: 'Cari Pesan',
        query: 'message',
        type: 'input'
    },
    {
        name: 'isNeedReview',
        options: yesNoOption,
        placeholder: 'Perlu Ditinjau?',
        query: 'isNeedReview',
        type: 'select'
    }
]

export default filterConfig
