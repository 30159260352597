import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdSpace from './StyledAntdSpace'

const Space = (props) => {
    const { align, direction, children, size } = props

    return (
        <StyledAntdSpace align={align} direction={direction} size={size}>
            {children}
        </StyledAntdSpace>
    )
}

Space.propTypes = {
    align: PropTypes.oneOf(['center', 'end', 'start', 'baseline']),
    children: PropTypes.node,
    direction: PropTypes.oneOf(['vertical', 'horizontal']),
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['small', 'middle', 'large'])
    ])
}

Space.defaultProps = {
    align: 'center',
    size: 'small'
}

export default Space
