import FilterBlock from 'antdcomponents/filter_block/FilterBlock'
import random from 'lodash/random'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { change, getFormValues, submit } from 'redux-form'

const formName = `filter-block-${random(1, 100)}`

const ConnectedFilterBlock = (props) => {
    const { onMount } = props

    useEffect(() => {
        if (onMount) {
            onMount(formName)
        }
    }, [onMount])

    return <FilterBlock {...props} />
}

ConnectedFilterBlock.propTypes = {
    onMount: PropTypes.func
}

const mapStateToProps = (state) => ({
    formState: (formName) => getFormValues(formName)(state) || {}
})

const mapDispatchToProps = (dispatch) => ({
    resetField: (formName) => (fieldName) => {
        dispatch(change(formName, fieldName, ''))

        // @see: https://github.com/erikras/redux-form/issues/1366
        setTimeout(() => {
            dispatch(submit(formName))
        }, 0)
    },
    setField: (formName) => (name, value) => {
        dispatch(change(formName, name, value))
    },
    triggerSubmit: (formName) => () => {
        setTimeout(() => {
            dispatch(submit(formName))
        }, 0)
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,

    // dynamic formName for reduxForm
    form: formName,

    // new stateProps
    formState: stateProps.formState(formName),

    // new dispatchProps
    resetField: dispatchProps.resetField(formName),
    setField: dispatchProps.setField(formName),
    triggerSubmit: dispatchProps.triggerSubmit(formName)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ConnectedFilterBlock)
