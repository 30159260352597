import * as productSlice from 'features/product/productSlice'
import * as productTypeSlice from 'features/product_type/productTypeSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => <List {...props} />

const mapStateToProps = (state) => ({
    error: state.product.error,
    exportData: state.product.exportData,
    isActionLoading: state.product.isActionLoading,
    isActionSuccess: state.product.isActionSuccess,
    isLoading: state.product.isLoading,
    isLoadingExport: state.product.isLoadingExport,
    productTypes: state.productType.productTypes,
    products: state.product.products,
    totalProducts: state.product.totalProducts,
    username: state.authentication.username
})

const mapDispatchToProps = {
    clearProduct: productSlice.clearProduct,
    clearProductType: productTypeSlice.clearProductType,
    exportProducts: productSlice.exportProducts,
    getProductTypes: productTypeSlice.getProductTypes,
    getProducts: productSlice.getProducts,
    updateProduct: productSlice.updateProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
