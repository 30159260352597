import StyledLabel from 'components/styled_label/StyledLabel'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import StyledAntdFormItem from './StyledAntdFormItem'

const FormItem = (props) => {
    const {
        children,
        dark,
        extra,
        hasFeedback,
        help,
        isStyledLabel,
        label,
        labelCol,
        labelColor,
        labelLevel,
        loading,
        name,
        noLabel,
        noMargin,
        noStyle,
        required,
        rules,
        wrapperCol
    } = props
    let validateStatus = get(props, 'validateStatus')
    const validateStatusVal = loading ? 'validating' : validateStatus

    const labelTitle =
        !noLabel || !isEmpty(label) ? (
            <StyledLabel
                $dark={dark}
                color={labelColor}
                level={labelLevel || 5}
            >
                {label}
            </StyledLabel>
        ) : (
            ''
        )

    return (
        <StyledAntdFormItem
            $noMargin={noMargin}
            $required={required}
            extra={extra}
            hasFeedback={hasFeedback}
            help={help}
            label={isStyledLabel ? labelTitle : label}
            labelCol={labelCol}
            name={name}
            noStyle={noStyle}
            required={required}
            rules={rules}
            validateStatus={validateStatusVal}
            wrapperCol={wrapperCol}
        >
            {children}
        </StyledAntdFormItem>
    )
}

FormItem.propTypes = {
    children: PropTypes.node,
    dark: PropTypes.bool,
    extra: PropTypes.string,
    hasFeedback: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isStyledLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelCol: PropTypes.object,
    labelColor: PropTypes.any,
    labelLevel: PropTypes.number,
    loading: PropTypes.bool,
    name: PropTypes.string,
    noLabel: PropTypes.bool,
    noMargin: PropTypes.bool,
    noStyle: PropTypes.bool,
    required: PropTypes.bool,
    rules: PropTypes.any,
    validateStatus: PropTypes.string,
    wrapperCol: PropTypes.object
}

FormItem.defaultProps = {
    noLabel: true
}

export default FormItem
