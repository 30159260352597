import { default as AntdModal } from 'antd/lib/modal'

const triggerModal = (type, options) =>
    AntdModal[type](
        Object.assign(
            {
                cancelText: 'Batal',
                centered: true,
                destroyOnClose: true,
                maskClosable: false,
                okText: 'Oke'
            },
            options
        )
    )

const confirm = (options) =>
    triggerModal('confirm', Object.assign(options, { title: 'Konfirmasi' }))
const error = (options) => triggerModal('error', options)
const info = (options) => triggerModal('info', options)
const success = (options) => triggerModal('success', options)
const warning = (options) => triggerModal('warning', options)

const { destroyAll } = AntdModal

export { confirm, destroyAll, error, info, success, warning }
