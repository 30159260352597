import { default as AntdImage } from 'antd/lib/image'
import PropTypes from 'prop-types'
import {
    StyledAntdCard,
    StyledCompanySection,
    StyledDetailSection,
    StyledImageSection,
    StyledNumberSection,
    StyledProductOrderCard
} from './StyledComponents'
import formatPrice from '../../utility/formatPrice'

const ProductOrderCard = (props) => {
    const { category, entityName, imageUrl, noDoc, total, type, onClick } =
        props

    return (
        <StyledProductOrderCard onClick={onClick}>
            <StyledAntdCard>
                <StyledImageSection>
                    <AntdImage src={imageUrl} />
                </StyledImageSection>
                <StyledDetailSection>
                    <StyledCompanySection>
                        <div>
                            {category} | {type}
                        </div>
                        <div>{entityName}</div>
                    </StyledCompanySection>
                    <StyledNumberSection>
                        <div>{total ? 'Total Pendanaan' : 'Order ID'}</div>
                        <div>{total ? `Rp ${formatPrice(total)}` : noDoc}</div>
                    </StyledNumberSection>
                </StyledDetailSection>
            </StyledAntdCard>
        </StyledProductOrderCard>
    )
}

ProductOrderCard.propTypes = {
    category: PropTypes.string,
    entityName: PropTypes.string,
    imageUrl: PropTypes.string,
    noDoc: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    total: PropTypes.number,
    type: PropTypes.string
}

export default ProductOrderCard
