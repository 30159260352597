import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/productCategory'
import * as productCategoryAPI from './productCategoryAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    productCategories: [],
    productCategory: {},
    totalProductCategories: 0
}

export const {
    createProductCategory,
    deleteProductCategory,
    getProductCategory,
    getProductCategories,
    updateProductCategory
} = productCategoryAPI

const productCategory = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getProductCategory.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productCategory: {}
            })
        )
        builder.addCase(getProductCategory.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productCategory: toState(data.productCategory)
            })
        })
        builder.addCase(getProductCategories.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productCategories: []
            })
        )
        builder.addCase(getProductCategories.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productCategories: map(data.productCategories, (item) =>
                    toState(item)
                ),
                totalProductCategories: data.count
            })
        })
        builder.addMatcher(
            isAnyOf(
                createProductCategory.pending,
                deleteProductCategory.pending,
                updateProductCategory.pending
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false
                })
        )
        builder.addMatcher(
            isAnyOf(
                createProductCategory.fulfilled,
                deleteProductCategory.fulfilled,
                updateProductCategory.fulfilled
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isActionSuccess: true
                })
        )
        builder.addMatcher(
            isAnyOf(
                createProductCategory.rejected,
                deleteProductCategory.rejected,
                updateProductCategory.rejected
            ),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true
                })
        )
        builder.addMatcher(
            isAnyOf(getProductCategory.rejected, getProductCategories.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false
                })
        )
    },
    initialState,
    name: 'PRODUCT_CATEGORY',
    reducers: {
        clearProductCategory: () => initialState
    }
})

export const { clearProductCategory } = productCategory.actions

const { reducer } = productCategory
export default reducer
