import * as pemodalSlice from 'features/pemodal/pemodalSlice'
import get from 'lodash/get'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import EditView from './EditView'

const PemodalEditViewContainer = (props) => {
    const params = useParams()
    return <EditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]

    return {
        initialValues: state.pemodal.pemodal,
        isActionLoading: state.pemodal.isActionLoading,
        isActionSuccess: state.pemodal.isActionSuccess,
        isLoading: state.pemodal.isLoading,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearPemodal: pemodalSlice.clearPemodal,
    getPemodal: pemodalSlice.getPemodal,
    updatePemodal: pemodalSlice.updatePemodal
}

const EditViewForm = reduxForm({
    enableReinitialize: true,
    form: 'pemodal'
})(PemodalEditViewContainer)

export default connect(mapStateToProps, mapDispatchToProps)(EditViewForm)
