import capitalize from 'lodash/capitalize'
import concat from 'lodash/concat'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'

export const actionBlockOptions = [
    { label: 'Terblokir', value: 'false' },
    { label: 'Aktif', value: 'true' }
]

export const statusDraftOrPublishedOptions = [
    { label: 'Draf', value: 'draft' },
    { label: 'Published', value: 'published' }
]

export const verifiedStatusOptions = [
    { label: 'Belum Terverifikasi', value: 'false' },
    { label: 'Terverifikasi', value: 'true' }
]

const genders = ['laki-laki', 'perempuan']
export const genderOptions = map(genders, (item) => ({
    label: capitalize(item),
    value: item
}))

const userTypes = ['pemodal', 'penerbit']

export const userTypeOptions = map(userTypes, (item) => ({
    label: startCase(item),
    value: item
}))

export const userAdminTypeOptions = concat(
    [
        {
            label: startCase('admin'),
            value: 'admin_user'
        }
    ],
    userTypeOptions
)

export const getUserAdminTypeOptions = (value) => {
    const statusObj = find(userAdminTypeOptions, (item) => item.value === value)
    return isEmpty(statusObj) ? { label: '', value: '' } : statusObj
}

const yesNoInd = ['ya', 'tidak']
const yesNo = ['true', 'false']

export const yesNoOption = map(yesNo, (item, key) => ({
    label: startCase(yesNoInd[key]),
    value: item
}))
