import * as pemodalSlice from 'features/pemodal/pemodalSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const PemodalListContainer = (props) => {
    return <List {...props} />
}

const mapStateToProps = (state) => ({
    isActionLoading: state.pemodal.isActionLoading,
    isActionSuccess: state.pemodal.isActionSuccess,
    isLoading: state.pemodal.isLoading,
    pemodals: state.pemodal.pemodals,
    totalPemodals: state.pemodal.totalPemodals
})

const mapDispatchToProps = {
    clearPemodal: pemodalSlice.clearPemodal,
    getPemodals: pemodalSlice.getPemodals,
    updatePemodal: pemodalSlice.updatePemodal
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PemodalListContainer)
