import * as request from 'client/request'

export const create = (uri, data) => request.post(uri, data)

export const deleteById = (uri, id) => request.del(uri, { id })

export const fetchAll = (uri, { limit, offset, options }) =>
    request.get(uri, { limit, offset, options })

export const fetchById = (uri, id) => request.get(uri, { id })

export const update = (uri, data) => request.put(uri, data)
