import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from 'transformers/program'
import * as programAPI from './programAPI'

const initialState = {
    isError: false,
    isLoading: false,
    programs: [],
    totalPrograms: 0
}

export const { getPrograms } = programAPI

const programSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPrograms.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                programs: []
            })
        )
        builder.addCase(getPrograms.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                programs: map(data.programs, (item) => toState(item)),
                totalPrograms: data.count
            })
        })
        builder.addCase(getPrograms.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false
            })
        )
    },
    initialState,
    name: 'PROGRAM',
    reducers: {
        clearProgram: () => initialState
    }
})

export const { clearProgram } = programSlice.actions

const { reducer } = programSlice
export default reducer
