import { default as AntdLayout } from 'antd/lib/layout'
import BurgerMenu from 'components/burger_menu/BurgerMenu'
import * as authenticationSlice from 'features/authentication/authenticationSlice'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import deleteAllCookies from 'utility/deleteAllCookies'
import removePersistentData from 'utility/removePersistentData'
import { GlobalStyle, StyledAntdLayoutContent } from './StyledComponents'

const Layout = (props) => {
    const { authToken, logout, roles, username } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const pathname = location.pathname
    const isLoginPage = pathname === '/login'

    const context = {
        dispatch,
        location,
        navigate
    }

    useEffect(() => {
        if (!isLoginPage && !authToken) {
            navigate('/login')
        }
    }, [authToken, isLoginPage, navigate])

    const handleLogout = () => {
        removePersistentData('authToken')
        deleteAllCookies()
        dispatch(logout())
        return navigate('/')
    }

    const renderBurgerMenu = () => (
        <BurgerMenu
            onLogout={handleLogout}
            pathname={pathname}
            roles={roles}
            username={username}
        />
    )

    const renderSiteLayout = () => (
        <AntdLayout id='page-wrapper'>
            <GlobalStyle />
            <StyledAntdLayoutContent $isLoginPage={isLoginPage}>
                <Outlet context={context} />
            </StyledAntdLayoutContent>
        </AntdLayout>
    )

    if (isLoginPage) {
        return renderSiteLayout()
    }

    return (
        <AntdLayout id='outer-container'>
            {renderBurgerMenu()}
            {renderSiteLayout()}
        </AntdLayout>
    )
}

Layout.propTypes = {
    authToken: PropTypes.string,
    logout: PropTypes.func,
    roles: PropTypes.array,
    username: PropTypes.string
}

const mapStateToProps = (state) => ({
    authToken: state.authentication.authToken,
    roles: state.authentication.roles,
    username: state.authentication.username
})

const mapDispatchToProps = {
    logout: authenticationSlice.logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
