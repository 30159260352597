import * as penerbitSlice from 'features/penerbit/penerbitSlice'
import * as productSlice from 'features/product/productSlice'
import * as productCategorySlice from 'features/product_category/productCategorySlice'
import * as productRibbonSlice from 'features/product_ribbon/productRibbonSlice'
import * as productTypeSlice from 'features/product_type/productTypeSlice'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import AddEditView from './AddEditView'

const validate = (values) => {
    const { numberShare, shareSize } = values

    let errors = {}
    if (numberShare && shareSize && numberShare % shareSize !== 0) {
        errors._error =
            '*jumlah lembar dibagi dengan minimum pembelian tidak sesuai'
    }

    return errors
}

let AddEditViewContainer = (props) => {
    const params = useParams()
    return <AddEditView {...props} params={params} />
}

const mapStateToProps = (state, props) => {
    const pathname = get(props, 'location.pathname', '')
    const windowType = split(pathname, '/')[2]

    const isWindowTypeAdd = windowType === 'add'

    const isLoading = cloneDeep(state.product.isLoading)
    const product = cloneDeep(state.product.product)
    const isUploading = true
    let initialValues = {
        isUploading,
        numberShare: 1,
        pricePerShare: 0,
        shareSize: 1,
        status: 'inactive',
        target: 0
    }
    if (!isWindowTypeAdd && !isLoading && !isEmpty(product)) {
        const productImages = get(product, 'productImages', [])
        initialValues = Object.assign(product, { isUploading, productImages })
    }

    return {
        initialValues,
        isActionLoading: state.product.isActionLoading,
        isActionSuccess: state.product.isActionSuccess,
        isLoading,
        penerbits: state.penerbit.penerbits,
        productCategories: state.productCategory.productCategories,
        productRibbons: state.productRibbon.productRibbons,
        productTypes: state.productType.productTypes,
        username: state.authentication.username,
        windowType
    }
}

const mapDispatchToProps = {
    clearProduct: productSlice.clearProduct,
    createProduct: productSlice.createProduct,
    getPenerbits: penerbitSlice.getPenerbits,
    getProduct: productSlice.getProduct,
    getProductCategories: productCategorySlice.getProductCategories,
    getProductRibbons: productRibbonSlice.getProductRibbons,
    getProductTypes: productTypeSlice.getProductTypes,
    updateProduct: productSlice.updateProduct
}

AddEditViewContainer = reduxForm({
    enableReinitialize: true,
    form: 'product',
    validate
})(AddEditViewContainer)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditViewContainer)
