import { DATE_SLASH_FORMAT } from 'constants/formats'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdDatePicker from './StyledAntdDatePicker'

const DatePicker = (props) => {
    const {
        bordered,
        defaultValue,
        disabled,
        disabledDate,
        format,
        mode,
        name,
        onChange,
        placeholder,
        size,
        value
    } = props

    const newDefaultValue = defaultValue ? moment(defaultValue, format) : null
    const newValue = value ? moment(value, format) : null

    const handleChange = (name, format) => (value) => {
        const dateObj = value ? moment(value).format(format) : ''
        onChange(dateObj, name)
    }

    return (
        <StyledAntdDatePicker
            bordered={bordered}
            defaultValue={newDefaultValue}
            disabled={disabled}
            disabledDate={disabledDate}
            format={format}
            mode={mode}
            onChange={handleChange(name, format)}
            placeholder={placeholder}
            size={size}
            value={newValue}
        />
    )
}

DatePicker.propTypes = {
    bordered: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDate: PropTypes.func,
    format: PropTypes.string,
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.string
}

DatePicker.defaultProps = {
    bordered: true,
    format: DATE_SLASH_FORMAT,
    placeholder: 'Pilih tanggal'
}

export default DatePicker
