import concat from 'lodash/concat'

const pageSizeOptions = ['20', '50', '75', '100', '500']

export const DEFAULT_PAGINATION_CONFIG = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    pageSizeOptions: pageSizeOptions
}

export const pageSizeOptionsWithMaxAmount = (max = 5000) =>
    concat(pageSizeOptions, max.toString())
