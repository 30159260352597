import { DATE_HOUR_SLASH_FORMAT, DATE_SLASH_FORMAT } from 'constants/formats'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'
import replace from 'lodash/replace'
import trimStart from 'lodash/trimStart'
import moment from 'moment'
import * as annualSalaryTransformer from './annualSalary'
import * as cityTransformer from './city'
import * as districtTransformer from './district'
import * as incomeSourceTransformer from './incomeSource'
import * as penerbitDetailDocumentTransformer from './penerbitDetailDocument'

export const toApi = (data) => {
    const birth_date = moment(data.birthDate, DATE_SLASH_FORMAT).isValid()
        ? moment.utc(data.birthDate, DATE_SLASH_FORMAT).toISOString()
        : undefined
    const kyc_verified_at = moment(
        data.kycVerifiedAt,
        DATE_HOUR_SLASH_FORMAT
    ).isValid()
        ? moment.utc(data.kycVerifiedAt, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined

    const penerbit_detail_documents = !isEmpty(data.penerbitDetailDocuments)
        ? map(data.penerbitDetailDocuments, (item) =>
              penerbitDetailDocumentTransformer.toApi(
                  Object.assign(item, {
                      penerbitDetailId: data.id || item.penerbitDetailId
                  })
              )
          )
        : undefined

    const is_kyc_verified = isString(data.isKycVerified)
        ? data.isKycVerified === 'true'
        : data.isKycVerified
    const mobile_number = data.mobileNumber
        ? '+62' + trimStart(data.mobileNumber, '0')
        : undefined

    return {
        address: data.address,
        annual_salary_id: data.annualSalaryId,
        birth_date,
        city_id: data.cityId,
        created_by: data.createdBy,
        digisign_url: data.digisignUrl,
        district_id: data.districtId,
        email: data.email,
        gender: data.gender,
        hometown_id: data.hometownId,
        id: data.id,
        id_card_name: data.idCardName,
        id_card_number: data.idCardNumber,
        income_source_id: data.incomeSourceId,
        is_email_verified: data.isEmailVerified,
        is_kyc_verified,
        is_same_as_penerbit_email: data.isSameAsPenerbitEmail,
        kyc_verified_at,
        mobile_number,
        mother_name: data.motherName,
        penerbit_detail_documents,
        penerbit_id: data.penerbitId,
        position: data.position,
        postal_code: data.postalCode,
        remarks: data.remarks,
        updated_by: data.updatedBy
    }
}

export const toState = (data) => {
    const birthDate = moment(data.birthDate).isValid()
        ? moment(data.birthDate).format(DATE_SLASH_FORMAT)
        : null
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const kycVerifiedAt = moment(data.kycVerifiedAt).isValid()
        ? moment(data.kycVerifiedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const annualSalary = !isEmpty(data.annualSalary)
        ? annualSalaryTransformer.toState(data.annualSalary)
        : {}
    const city = !isEmpty(data.city) ? cityTransformer.toState(data.city) : {}
    const district = !isEmpty(data.district)
        ? districtTransformer.toState(data.district)
        : {}
    const hometown = !isEmpty(data.hometown)
        ? cityTransformer.toState(data.hometown)
        : {}
    const incomeSource = !isEmpty(data.incomeSource)
        ? incomeSourceTransformer.toState(data.incomeSource)
        : {}
    const penerbitDetailDocuments = !isEmpty(data.penerbitDetailDocuments)
        ? map(data.penerbitDetailDocuments, (item) =>
              penerbitDetailDocumentTransformer.toState(item)
          )
        : []

    const mobileNumber = data.mobileNumber
        ? replace(data.mobileNumber, '+62', '')
        : ''

    return {
        address: data.address || '',
        annualSalary,
        annualSalaryId: data.annualSalaryId || null,
        birthDate,
        city,
        cityId: data.cityId || null,
        createdAt,
        createdBy: data.createdBy || '',
        digisignUrl: data.digisignUrl || '',
        district,
        districtId: data.districtId || null,
        email: data.email || '',
        gender: data.gender || '',
        hometown,
        hometownId: data.hometownId || null,
        id: data.id || null,
        idCardName: data.idCardName || '',
        idCardNumber: data.idCardNumber || '',
        incomeSource,
        incomeSourceId: data.incomeSourceId || null,
        isEmailVerified: data.isEmailVerified || false,
        isKycVerified: data.isKycVerified || false,
        isSameAsPenerbitEmail: data.isSameAsPenerbitEmail || false,
        kycVerifiedAt,
        mobileNumber,
        motherName: data.motherName || '',
        penerbitDetailDocuments,
        penerbitId: data.penerbitId || null,
        position: data.position || '',
        postalCode: data.postalCode || '',
        remarks: data.remarks || '',
        updatedAt,
        updatedBy: data.updatedBy || ''
    }
}
