import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdSpace } from 'antd/lib/space'
import colors from 'basic/colors'
import styled from 'styled-components'

export const StyledAntdDivider = styled(AntdDivider)`
    &&& {
        margin: 0 0 10px;
        min-width: auto;
        width: auto;
    }
`

export const StyledCheckCircleTwoTone = styled(CheckCircleTwoTone)`
    &&& {
        ${(props) => (props.disabled ? '' : 'cursor: pointer;')}
    }
`
export const StyledCloseCircleTwoTone = styled(CloseCircleTwoTone)`
    &&& {
        ${(props) => (props.disabled ? '' : 'cursor: pointer;')}
    }
`

export const StyledMessageRow = styled(AntdSpace)`
    &&& {
        min-width: 30%;
        max-width: 70%;
        ${(props) => (props.isFromAdmin ? 'float: right;' : '')}

        > .ant-space-item:first-child {
            width: 100%;
        }
    }
`
export const StyledMessageBox = styled.div`
    &&& {
        border-radius: 20px;
        width: 100%;
        background: ${colors.black}0a;

        .header {
            color: ${colors.blueberry};
            text-align: right;
            padding: 10px 20px 0 20px;
        }
        .content {
            padding: 0 20px 10px 20px;
        }
    }
`

export const StyledSendButtonImage = styled.img`
    &&& {
        background-color: white;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }
`
