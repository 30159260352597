// NOTE:
// 1. Add new programs here (alphabetically)
// 2. Insert corresponding ACLs _READ and/or _WRITE for each program
// 3. Undefined / empty acl means program can be accessed by anyone
// 4. rootFunctionPath, editFunctionPath, viewFunctionPath keys are required,
//    and are functions to import the corresponding Function
// 5. group & level (up to 3) are required
//
// root = listing of all data (usually)
// view = read only of a single entry
// edit = edit a single entry

// reference to options/adminUser => programs
const programList = [
    {
        acl: ['ADU_READ', 'ADU_WRITE'],
        addFunctionPath: () =>
            require('admin_user/AddEditViewContainer').default,
        editFunctionPath: () =>
            require('admin_user/AddEditViewContainer').default,
        name: 'Admin User',
        path: '/admin-user',
        rootFunctionPath: () => require('admin_user/ListContainer').default,
        viewFunctionPath: () =>
            require('admin_user/AddEditViewContainer').default
    },
    {
        acl: ['FIR_READ', 'FIR_WRITE'],
        editFunctionPath: () => require('pemodal/EditViewContainer').default,
        name: 'Pemodal',
        path: '/pemodal',
        rootFunctionPath: () => require('pemodal/ListContainer').default,
        viewFunctionPath: () => require('pemodal/EditViewContainer').default
    },
    {
        acl: ['PRO_READ', 'PRO_WRITE'],
        addFunctionPath: () => require('product/AddEditViewContainer').default,
        editFunctionPath: () => require('product/AddEditViewContainer').default,
        name: 'Produk',
        path: '/product',
        rootFunctionPath: () => require('product/ListContainer').default,
        viewFunctionPath: () => require('product/AddEditViewContainer').default
    },
    {
        acl: ['CAT_READ', 'CAT_WRITE'],
        name: 'Kategori & Tag',
        path: '/category-and-tag',
        rootFunctionPath: () => require('category_and_tag/List').default
    },
    {
        acl: ['CAT_READ', 'CAT_WRITE'],
        addFunctionPath: () =>
            require('product_category/AddEditViewContainer').default,
        editFunctionPath: () =>
            require('product_category/AddEditViewContainer').default,
        name: 'Kategori',
        path: '/category',
        viewFunctionPath: () =>
            require('product_category/AddEditViewContainer').default
    },
    {
        acl: ['CAT_READ', 'CAT_WRITE'],
        addFunctionPath: () =>
            require('product_ribbon/AddEditViewContainer').default,
        editFunctionPath: () =>
            require('product_ribbon/AddEditViewContainer').default,
        name: 'Tag',
        path: '/tag',
        viewFunctionPath: () =>
            require('product_ribbon/AddEditViewContainer').default
    },
    {
        acl: ['CON_READ', 'CON_WRITE'],
        editFunctionPath: () => require('contact_us/EditViewContainer').default,
        name: 'Hubungi Kami',
        path: '/contact-us',
        rootFunctionPath: () => require('contact_us/ListContainer').default,
        viewFunctionPath: () => require('contact_us/EditViewContainer').default
    },
    {
        acl: ['FAQ_READ', 'FAQ_WRITE'],
        addFunctionPath: () => require('faq/AddEditViewContainer').default,
        editFunctionPath: () => require('faq/AddEditViewContainer').default,
        name: 'FAQ',
        path: '/frequently-asked-question',
        rootFunctionPath: () => require('faq/ListContainer').default,
        viewFunctionPath: () => require('faq/AddEditViewContainer').default
    },
    {
        acl: ['INB_READ', 'INB_WRITE'],
        editFunctionPath: () => require('inbox/EditViewContainer').default,
        name: 'Kotak Masuk',
        path: '/inbox',
        rootFunctionPath: () => require('inbox/ListContainer').default,
        viewFunctionPath: () => require('inbox/EditViewContainer').default
    },
    {
        acl: ['PUB_READ', 'PUB_WRITE'],
        addFunctionPath: () => require('penerbit/AddEditViewContainer').default,
        editFunctionPath: () =>
            require('penerbit/AddEditViewContainer').default,
        name: 'Penerbit',
        path: '/penerbit',
        rootFunctionPath: () => require('penerbit/ListContainer').default,
        viewFunctionPath: () => require('penerbit/AddEditViewContainer').default
    },
    {
        acl: ['PUO_READ', 'PUO_WRITE'],
        name: 'Order',
        path: '/purchase-order',
        rootFunctionPath: () => require('purchase_order/ListContainer').default,
        viewFunctionPath: () => require('purchase_order/ViewContainer').default
    }
]

export default programList
