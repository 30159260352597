import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import * as message from 'utility/message'
import * as config from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        adminUsers,
        clearAdminUser,
        deleteAdminUser,
        dispatch,
        getAdminUsers,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        totalAdminUsers,
        updateAdminUser,
        username
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Admin User' }
    ]

    useEffect(() => {
        const fetchAdminUsers = async () => {
            const offset = (page - 1) * limit
            return await getAdminUsers({
                limit,
                offset,
                options: { ...options, orderBy }
            })
        }
        fetchAdminUsers()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            fetchAdminUsers()
        }

        return () => {
            dispatch(clearAdminUser())
        }
    }, [
        clearAdminUser,
        dispatch,
        getAdminUsers,
        isActionLoading,
        isActionSuccess,
        limit,
        options,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id, isActive, roles } = adminUsers[key]
        if (includes(['block', 'unblock'], type)) {
            const data = { id, isActive: !isActive, roles }
            return updateAdminUser(data)
        }
        if (type === 'delete') {
            return deleteAdminUser(id)
        }
        if (type === 'edit') {
            return navigate(`/admin-user/edit/${id}`)
        }
        if (type === 'view') {
            return navigate(`/admin-user/view/${id}`)
        }
    }

    const handleAdd = () => navigate('/admin-user/add')

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const handleClear = () => {
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
        setOptions({})
        setOrderBy(defaultOrderBy)
    }

    const handleSearch = (newOptions) => setOptions(newOptions)

    const renderActions = (text, record, index) => (
        <TableRowActions
            options={config.rowActions(index, handleActions, record, username)}
        />
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig}
                onAdd={handleAdd}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onSearch={handleSearch}
                totalItems={totalAdminUsers}
            />
            <Table
                columns={config.columns(renderActions)}
                dataSource={config.dataSource(adminUsers)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    adminUsers: PropTypes.array,
    clearAdminUser: PropTypes.func,
    deleteAdminUser: PropTypes.func,
    dispatch: PropTypes.func,
    getAdminUsers: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    totalAdminUsers: PropTypes.number,
    updateAdminUser: PropTypes.func,
    username: PropTypes.string
}

export default List
