import colors from 'basic/colors'

const statusOptions = [
    {
        color: colors.springGreen,
        label: 'Menunggu',
        value: 'pending'
    },
    {
        color: colors.laserLemon,
        label: 'Kedaluwarsa',
        value: 'expired'
    },
    {
        color: colors.blueberry,
        label: 'Berhasil',
        value: 'paid'
    },
    {
        color: colors.salmon,
        label: 'Dibatalkan',
        value: 'canceled'
    }
]

export { statusOptions }
