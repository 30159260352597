import { Col, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import styled from 'styled-components'

const StyledValueCol = styled(Col)`
    color: #8f8f8f;
`

const defaultViewRow = (
    title,
    value,
    type,
    sizeValueMd = 4,
    sizeValueXs = 14
) => {
    let currVal = value

    if (type === 'rangeDate' && !isEmpty(value)) {
        currVal = `${value[0]} - ${value[1]}`
    }

    return (
        <Row>
            <Col md={{ span: 8 }} xs={{ span: 10 }}>
                {title}
            </Col>
            <StyledValueCol
                md={{ span: sizeValueMd }}
                xs={{ span: sizeValueXs }}
            >
                : {currVal}
            </StyledValueCol>
        </Row>
    )
}

export default defaultViewRow
