import * as authenticationSlice from 'features/authentication/authenticationSlice'
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Login from './Login'

const LoginContainer = (props) => <Login {...props} />

const mapStateToProps = (state) => ({
    authToken: state.authentication.authToken,
    isLoading: state.authentication.isLoading
})

const mapDispatchToProps = {
    authenticate: authenticationSlice.authenticate
}

const LoginForm = reduxForm({
    enableReinitialize: true,
    form: 'login'
})(LoginContainer)

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
