import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import {
    BackButton,
    Breadcrumb,
    Button,
    Form,
    FormItem,
    ReduxFormCheckboxGroup,
    ReduxFormInput,
    Space
} from 'antdcomponents'
import { programActionOptions } from 'common/options/adminUser'
import StyledLabel from 'components/styled_label/StyledLabel'
import camelCase from 'lodash/camelCase'
import concat from 'lodash/concat'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import { blockTextChanger } from 'utility/blockTextChanger'
import {
    password,
    required,
    username,
    validEmail
} from 'utility/formValidation'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'

const AddEditView = (props) => {
    const {
        change,
        clearAdminUser,
        clearProgram,
        createAdminUser,
        dispatch,
        getAdminUser,
        getPrograms,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        params,
        programs,
        updateAdminUser,
        windowType
    } = props
    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const { isActive, isUpdatingPassword } = useSelectorFormValues('adminUser')

    const isWindowTypeAdd = windowType === 'add'
    const isWindowTypeEdit = windowType === 'edit'
    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Admin User', linkTo: '/admin-user' },
        {
            label: isWindowTypeAdd
                ? getPageName(windowType)
                : `${getPageName(windowType)} (${initialValues.email})`
        }
    ]

    const typePassword = isUpdatingPassword ? undefined : 'password'

    const validatePassword = isUpdatingPassword
        ? [required, password]
        : undefined

    useEffect(() => {
        getPrograms()
        if (!isWindowTypeAdd && identifier) {
            const fetchAdminUser = async () => getAdminUser(identifier)
            fetchAdminUser()
        }

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            navigate(`/admin-user${queries}`)
        }

        return () => {
            dispatch(clearAdminUser())
            dispatch(clearProgram())
        }
    }, [
        clearAdminUser,
        clearProgram,
        dispatch,
        getAdminUser,
        getPrograms,
        identifier,
        isActionLoading,
        isActionSuccess,
        isWindowTypeAdd,
        navigate,
        queries
    ])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/admin-user')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const handleBlockUnblock = () => change('isActive', !isActive)

    const handleEditPassword = () => {
        const newIsUpdatingPassword = !isUpdatingPassword
        const newPassword = newIsUpdatingPassword ? '' : 'stubpassword'
        change('isUpdatingPassword', newIsUpdatingPassword)
        change('password', newPassword)
    }

    const handleFinish = (values) => {
        const { email, id, isActive, username } = values
        let password = undefined
        if (isUpdatingPassword) {
            password = get(values, 'password', undefined)
        }

        let roles = []
        map(programs, (item) => {
            const acl = get(values, camelCase(item.name), [])
            if (!isEmpty(acl)) {
                roles = concat(roles, acl)
            }
        })

        let data = {
            email,
            isActive,
            password,
            roles,
            updatedBy: props.username,
            username
        }

        if (windowType === 'add') {
            data = Object.assign(data, { createdBy: props.username })
            createAdminUser(data)
        }
        if (windowType === 'edit') {
            data = Object.assign(data, { id })
            updateAdminUser(data)
        }
    }

    const renderProgram = () => (
        <FormItem>
            <StyledLabel level={5}>Akses Kontrol</StyledLabel>
            {map(programs, (item, key) => (
                <AntdRow key={key} type='flex'>
                    <AntdCol span={12}>
                        <FormItem>{item.name}</FormItem>
                    </AntdCol>
                    <AntdCol span={12}>
                        <Field
                            component={ReduxFormCheckboxGroup}
                            disabled={windowType === 'view'}
                            formItemProps={{ noStyle: true }}
                            name={camelCase(item.name)}
                            options={programActionOptions(item.abbreviation)}
                        />
                    </AntdCol>
                </AntdRow>
            ))}
        </FormItem>
    )

    const renderAdminUser = () => (
        <Space direction='vertical'>
            <Field
                component={ReduxFormInput}
                disabled={windowType === 'view'}
                formItemProps={{ required: true }}
                label='Email'
                name='email'
                validate={[required, validEmail]}
            />
            <Field
                component={ReduxFormInput}
                disabled={windowType === 'view'}
                formItemProps={{ required: true }}
                label='Username'
                name='username'
                validate={[required, username]}
            />
            <AntdRow align='middle' type='flex'>
                <AntdCol span={isWindowTypeEdit ? 20 : 24}>
                    <Field
                        component={ReduxFormInput}
                        disabled={!isUpdatingPassword}
                        formItemProps={{ required: true }}
                        label='Kata Sandi'
                        name='password'
                        type={typePassword}
                        validate={validatePassword}
                    />
                </AntdCol>
                {isWindowTypeEdit && (
                    <AntdCol>
                        <Button
                            name='edit-password-btn'
                            onClick={handleEditPassword}
                            type='link'
                            value='Ubah'
                        />
                    </AntdCol>
                )}
            </AntdRow>
        </Space>
    )

    const renderButton = () =>
        !isWindowTypeView && (
            <AntdSpace>
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    type='primary'
                    value='Simpan'
                />
                {isWindowTypeEdit && (
                    <Button
                        danger={isActive}
                        htmlType='submit'
                        loading={isActionLoading}
                        name='block-unblock-btn'
                        onClick={handleBlockUnblock}
                        type={isActive ? 'primary' : 'default'}
                        value={startCase(blockTextChanger(isActive))}
                    />
                )}
            </AntdSpace>
        )

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/admin-user${queries}`} />
            <Form onFinish={handleSubmit(handleFinish)}>
                <Space direction='vertical'>
                    {renderAdminUser()}
                    {renderProgram()}
                    {renderButton()}
                </Space>
            </Form>
        </Space>
    )
}

AddEditView.propTypes = {
    change: PropTypes.func,
    clearAdminUser: PropTypes.func,
    clearProgram: PropTypes.func,
    createAdminUser: PropTypes.func,
    dispatch: PropTypes.func,
    getAdminUser: PropTypes.func,
    getPrograms: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isUpdatingPassword: PropTypes.array,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    programs: PropTypes.array,
    updateAdminUser: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default AddEditView
