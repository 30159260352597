import { StyledInboxBadge } from 'home/StyledComponents'
import capitalize from 'lodash/capitalize'
import truncate from 'lodash/truncate'
import React from 'react'

const columns = [
    {
        dataIndex: 'statusOption',
        render: (object) => (
            <StyledInboxBadge
                color={object.color}
                dot
                text={capitalize(object.label)}
            />
        ),
        title: 'Status'
    },
    {
        dataIndex: 'senderName',
        title: 'Pengirim'
    },
    {
        dataIndex: 'lastMessage',
        key: 'lastMessage',
        render: (string) => truncate(string),
        title: 'Pesan Terbaru'
    }
]

export { columns }
