import { kycStatusOptions } from 'common/options/pemodal'
import get from 'lodash/get'
import map from 'lodash/map'
import { blockTextChanger, getTypeAction } from 'utility/blockTextChanger'
import getLabelOption from 'utility/getLabelOption'

const rowActions = (index, handleActions, record) => [
    {
        id: record.id,
        type: 'view'
    },
    {
        id: record.id,
        type: 'edit'
    },
    {
        confirmContent: `Apakah anda yakin ingin ${blockTextChanger(
            record.isActive
        )} pemodal ini (${record.email} / ${record.username})?`,
        onClick: handleActions(index, getTypeAction(record.isActive)),
        type: getTypeAction(record.isActive)
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'isActive',
        render: (bool) => (bool ? 'Ya' : 'Tidak'),
        sorter: true,
        title: 'Aktif',
        width: 100
    },
    {
        dataIndex: 'kycStatus',
        render: (string) => getLabelOption(string, kycStatusOptions()),
        sorter: true,
        title: 'Status KYC',
        width: 150
    },
    {
        dataIndex: 'isDigisignActivated',
        render: (bool) => (bool ? 'Sudah' : 'Belum'),
        sorter: true,
        title: 'Akun Digisign Aktif',
        width: 150
    },
    {
        dataIndex: 'email',
        sorter: true,
        title: 'Email',
        width: 300
    },
    {
        dataIndex: 'mobileNumber',
        title: 'Nomor Telepon Seluler',
        width: 200
    },
    {
        dataIndex: 'pemodalDetail',
        render: (object) => get(object, 'idCardNumber'),
        title: 'Nomor KTP',
        width: 150
    },
    {
        dataIndex: 'pemodalDetail',
        render: (object) => get(object, 'idCardName'),
        title: 'Nama',
        width: 150
    },
    {
        dataIndex: 'username',
        sorter: true,
        title: 'Username',
        width: 150
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'updatedBy',
        title: 'Diperbarui Oleh',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
