import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import * as annualSalaryTransformer from './annualSalary'
import * as incomeSourceTransformer from './incomeSource'
import * as investmentGoalTransformer from './investmentGoal'

export const toApi = (data) => ({
    annual_salary_id: data.annualSalaryId,
    created_by: data.createdBy,
    id: data.id,
    id_card_name: data.idCardName,
    id_card_number: data.idCardNumber,
    income_source_id: data.incomeSourceId,
    investment_goal_id: data.investmentGoalId,
    mother_name: data.motherName,
    pemodal_id: data.pemodalId,
    updated_by: data.updatedBy
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const annualSalary = !isEmpty(data.annualSalary)
        ? annualSalaryTransformer.toState(data.annualSalary)
        : {}
    const incomeSource = !isEmpty(data.incomeSource)
        ? incomeSourceTransformer.toState(data.incomeSource)
        : {}
    const investmentGoal = !isEmpty(data.investmentGoal)
        ? investmentGoalTransformer.toState(data.investmentGoal)
        : {}

    return {
        annualSalary,
        annualSalaryId: data.annualSalaryId || null,
        createdAt,
        createdBy: data.createdBy || '',
        id: data.id || null,
        idCardName: data.idCardName || '',
        idCardNumber: data.idCardNumber || '',
        incomeSource,
        incomeSourceId: data.incomeSourceId || null,
        investmentGoal,
        investmentGoalId: data.investmentGoalId || null,
        motherName: data.motherName || '',
        pemodalId: data.pemodalId || null,
        updatedAt,
        updatedBy: data.updatedBy || ''
    }
}
