import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from 'client/request'

export const authenticate = createAsyncThunk(
    'AUTHENTICATE',
    async ({ identifier, password, user }, { rejectWithValue }) => {
        try {
            const data = { identifier, password, user }
            const res = await request.post('/authenticate', data)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const authenticationCheck = createAsyncThunk(
    'AUTHENTICATION_CHECK',
    async (data, { rejectWithValue }) => {
        try {
            const res = await request.get('/auth-check')
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
