import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const fieldName = get(file, 'fieldName', '')
        const identifier = get(file, 'identifier', '')
        const url = get(file, 'url', '')

        // penerbit detail document key
        const key = get(file, 'key', undefined)
        const baseData = { fieldName, identifier, key }

        const type = get(file, 'type', '')
        const originFileObj = get(file, 'originFileObj', {})

        // prioritize file to upload first
        if (!isEmpty(originFileObj)) {
            const reader = new FileReader()
            reader.readAsDataURL(originFileObj)
            reader.onload = () => {
                const base64Data = reader.result
                const base64Text = base64Data.substr(
                    indexOf(base64Data, ',') + 1
                )

                return resolve(
                    Object.assign(baseData, {
                        file: base64Text,
                        type
                    })
                )
            }
            reader.onerror = (error) => reject(error)
        }

        // if url exist
        if (url) {
            return resolve(Object.assign(baseData, { url }))
        }
    })

export default getBase64
