import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/product'
import * as api from '../api'

export const createProduct = createAsyncThunk(
    'CREATE_PRODUCT',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create('/product/create', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const deleteProduct = createAsyncThunk(
    'DELETE_PRODUCT',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.deleteById('/product/delete', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const exportProducts = createAsyncThunk(
    'EXPORT_PRODUCTS',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/products/export', { options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProduct = createAsyncThunk(
    'GET_PRODUCT',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/product', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProducts = createAsyncThunk(
    'GET_PRODUCTS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/products', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProductStatuses = createAsyncThunk(
    'GET_PRODUCT_STATUSES',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/products/statuses', {
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateProduct = createAsyncThunk(
    'UPDATE_PRODUCT',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/product/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
