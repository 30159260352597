import map from 'lodash/map'
import formatPrice from 'utility/formatPrice'

const columns = [
    {
        dataIndex: 'createdAt',
        title: 'Dibuat'
    },
    {
        dataIndex: 'description',
        title: 'Deskripsi'
    },
    {
        dataIndex: 'remarks',
        title: 'Dibuat oleh'
    },
    {
        dataIndex: 'amount',
        render: (data) => formatPrice(data),
        title: 'Total'
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource }
