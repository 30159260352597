import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/faq'
import * as api from '../api'

export const createFaq = createAsyncThunk(
    'CREATE_FAQ',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create('/faq/create', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const deleteFaq = createAsyncThunk(
    'DELETE_FAQ',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.deleteById('/faq/delete', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getFaq = createAsyncThunk(
    'GET_FAQ',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/faq', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getFaqs = createAsyncThunk(
    'GET_FAQS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/faqs', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateFaq = createAsyncThunk(
    'UPDATE_FAQ',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/faq/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
