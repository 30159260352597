import { EyeOutlined } from '@ant-design/icons'
import { StyledAntdIcon } from 'components/StyledComponents'
import PropTypes from 'prop-types'
import React from 'react'

const EyeIcon = (props) => {
    const { route } = props

    const handleOpenNewTab = () => window.open(route, '_blank')

    return <StyledAntdIcon component={EyeOutlined} onClick={handleOpenNewTab} />
}

EyeIcon.propTypes = {
    route: PropTypes.string
}

export default EyeIcon
