import { default as AntdSpace } from 'antd/lib/space'
import styled from 'styled-components'

const StyledAntdSpace = styled(AntdSpace)`
    &&& {
        width: 100%;

        > * {
            width: 100%;
        }
    }
`

export default StyledAntdSpace
