import includes from 'lodash/includes'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

const kycStatuses = ['preliminary', 'failed', 'success', 'verified']
const kycStatusesInd = ['prelim', 'gagal', 'sukses', 'terverifikasi']

const kycStatusOptions = (disabledValues = []) =>
    sortBy(
        map(kycStatuses, (item, key) => ({
            disabled: includes(disabledValues, item),
            label: startCase(kycStatusesInd[key]),
            value: item
        })),
        ['label']
    )

export { kycStatusOptions }
