import { default as AntdCol } from 'antd/lib/col'
import { default as AntdForm } from 'antd/lib/form'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import {
    BackButton,
    Breadcrumb,
    Button,
    FormItem,
    ReduxFormRadioGroup,
    ReduxFormRichInput,
    Space
} from 'antdcomponents'
import * as faqOptions from 'common/options/faq'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import getPageName from 'utility/getPageName'
import * as message from 'utility/message'
import usePrevious from 'utility/usePrevious'

const labelCol = {
    md: { span: 4 },
    sm: { span: 6 },
    xs: { span: 24 }
}
const formCol = {
    md: { span: 20 },
    sm: { span: 18 },
    xs: { span: 24 }
}

const AddEditView = (props) => {
    const {
        change,
        clearFaq,
        createFaq,
        dispatch,
        getFaq,
        handleSubmit,
        initialValues,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        params,
        updateFaq,
        username,
        windowType
    } = props

    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const isWindowTypeAdd = windowType === 'add'
    const isWindowTypeView = windowType === 'view'

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        {
            label: 'Pertanyaan Yang Sering Diajukan',
            linkTo: '/frequently-asked-question'
        },
        { label: getPageName(windowType) }
    ]

    useEffect(() => {
        if (!isWindowTypeAdd && identifier) {
            const fetchFaq = async () => getFaq(identifier)
            fetchFaq()
        }

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
            navigate(`/frequently-asked-question${queries}`)
        }

        // WillUnmount
        return () => {
            dispatch(clearFaq())
        }
    }, [
        clearFaq,
        dispatch,
        getFaq,
        identifier,
        isActionLoading,
        isActionSuccess,
        isWindowTypeAdd,
        navigate,
        queries
    ])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/frequently-asked-question')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const handleDraft = () => change('status', 'draft')
    const handlePublish = () => change('status', 'published')

    const handleFinish = (values) => {
        if (windowType === 'add') {
            const data = Object.assign(values, { createdBy: username })
            createFaq(data)
        }
        if (windowType === 'edit') {
            const data = Object.assign(values, { updatedBy: username })
            updateFaq(data)
        }
    }

    const renderFaq = () => (
        <Space direction='vertical'>
            <AntdRow>
                <AntdCol {...labelCol}>
                    <FormItem label='Pertanyaan' />
                </AntdCol>
                <AntdCol {...formCol}>
                    <Field
                        component={ReduxFormRichInput}
                        disabled={isWindowTypeView}
                        name='question'
                    />
                </AntdCol>
            </AntdRow>
            <br />
            <AntdRow>
                <AntdCol {...labelCol}>
                    <FormItem label='Jawaban' />
                </AntdCol>
                <AntdCol {...formCol}>
                    <Field
                        component={ReduxFormRichInput}
                        disabled={isWindowTypeView}
                        name='answer'
                    />
                </AntdCol>
            </AntdRow>
        </Space>
    )

    const renderButton = () =>
        !isWindowTypeView && (
            <AntdSpace>
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='save-btn'
                    onClick={handlePublish}
                    type='primary'
                    value='Publish'
                />
                <Button
                    htmlType='submit'
                    loading={isActionLoading}
                    name='draft-btn'
                    onClick={handleDraft}
                    value='Simpan Sebagai Draft'
                />
            </AntdSpace>
        )

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/frequently-asked-question${queries}`} />
            <AntdRow>
                <AntdCol {...labelCol}>
                    <FormItem label='Kategori' required={true} />
                </AntdCol>
                <AntdCol {...formCol}>
                    <Field
                        component={ReduxFormRadioGroup}
                        disabled={windowType === 'view'}
                        name='category'
                        options={faqOptions.categoryOptions}
                    />
                </AntdCol>
            </AntdRow>
            <AntdForm onFinish={handleSubmit(handleFinish)}>
                <Space direction='vertical'>
                    {renderFaq()}
                    {renderButton()}
                </Space>
            </AntdForm>
        </Space>
    )
}

AddEditView.propTypes = {
    change: PropTypes.func,
    clearFaq: PropTypes.func,
    createFaq: PropTypes.func,
    dispatch: PropTypes.func,
    getFaq: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isUpdatingPassword: PropTypes.array,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    updateFaq: PropTypes.func,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default AddEditView
