import { default as AntdSpace } from 'antd/lib/space'
import { Button } from 'antdcomponents'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const TableActions = (props) => {
    const {
        disabled,
        isWriteAllowed,
        loading,
        onAddClick,
        onClearAllClick,
        onExportClick,
        onSaveClick,
        onUpdateClick
    } = props

    return (
        <AntdSpace>
            {onClearAllClick && isWriteAllowed && onClearAllClick !== noop && (
                <Button
                    disabled={disabled['clearAll']}
                    loading={loading['clearAll']}
                    name='clear-btn'
                    onClick={onClearAllClick}
                    value='Hapus Semua yang Dipilih'
                />
            )}
            {onSaveClick && isWriteAllowed && (
                <Button
                    disabled={disabled['save']}
                    icon='SaveOutlined'
                    loading={loading['save']}
                    name='save-btn'
                    onClick={onSaveClick}
                    type='primary'
                    value='Simpan'
                />
            )}
            {onAddClick && isWriteAllowed && (
                <Button
                    disabled={disabled['add']}
                    icon='FileAddOutlined'
                    loading={loading['add']}
                    name='add-btn'
                    onClick={onAddClick}
                    type='primary'
                    value='Tambahkan'
                />
            )}
            {onUpdateClick && isWriteAllowed && onUpdateClick !== noop && (
                <Button
                    disabled={disabled['update']}
                    icon='EditOutlined'
                    loading={loading['update']}
                    name='update-btn'
                    onClick={onUpdateClick}
                    type='primary'
                    value='Perbarui'
                />
            )}
            {onExportClick && (
                <Button
                    disabled={disabled['export']}
                    icon='ExportOutlined'
                    loading={loading['export']}
                    name='export-btn'
                    onClick={onExportClick}
                    value='Ekspor'
                />
            )}
        </AntdSpace>
    )
}

TableActions.propTypes = {
    disabled: PropTypes.object,
    isWriteAllowed: PropTypes.bool,
    loading: PropTypes.object,
    onAddClick: PropTypes.func,
    onClearAllClick: PropTypes.func,
    onExportClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onUpdateClick: PropTypes.func
}
TableActions.defaultProps = {
    disabled: {},
    loading: {}
}

const mapStateToProps = (state) => ({
    isWriteAllowed: state.authentication.isWriteAllowed
})

export default connect(mapStateToProps)(TableActions)
