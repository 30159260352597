import { DATETIME_NOSEPARATOR_FORMAT } from 'constants/formats'
import moment from 'moment'

const generateFile = (data, fileName) => {
    let link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', fileName)
    link.click()
}

const generateCsv = (data, fileName) => {
    const defaultFileName = `csv_generated_${moment().format(
        DATETIME_NOSEPARATOR_FORMAT
    )}.csv`
    let csvData = 'data:text/csv;charset=utf-8,' + data
    csvData = encodeURI(csvData)
    generateFile(csvData, fileName || defaultFileName)
}

export { generateCsv, generateFile }
