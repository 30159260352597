import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import { default as AntdTypography } from 'antd/lib/typography'
import { BackButton, Breadcrumb, Form, Space, Table } from 'antdcomponents'
import { statusOptions } from 'common/options/purchaseOrder'
import EyeIcon from 'components/eye_icon/EyeIcon'
import StyledLabel from 'components/styled_label/StyledLabel'
import { StyledAntdDivider } from 'components/StyledComponents'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import defaultViewRow from 'utility/defaultViewRow'
import formatPrice from 'utility/formatPrice'
import getLabelOption from 'utility/getLabelOption'
import getPageName from 'utility/getPageName'
import usePrevious from 'utility/usePrevious'
import useSelectorFormValues from 'utility/useSelectorFormValues'
import * as transactionHistoryConfig from './transactionHistoryConfig'

const AntdTypographyText = AntdTypography.Text

const View = (props) => {
    const {
        clearPurchaseOrder,
        dispatch,
        getPurchaseOrder,
        isLoading,
        location,
        navigate,
        params,
        roles,
        windowType
    } = props
    const [isInit, setIsInit] = useState(true)

    const identifier = get(params, 'identifier', null)
    const queries = get(location, 'search', '')

    const rolePemodal = find(roles, (item) => includes(item, 'FIR'))
    const isHasAccessPemodal = !isEmpty(rolePemodal)

    const {
        adminFee,
        approvedAt,
        canceledDate,
        createdAt,
        initialValues,
        isCanceled,
        noDoc,
        pemodal = {},
        platformFee,
        product,
        purchaseOrderTransactions,
        status,
        subtotal,
        totalPayment,
        transactionFee
    } = useSelectorFormValues('purchaseOrder')
    const totalRefund = subtotal - platformFee

    useEffect(() => {
        if (isInit) {
            if (identifier) {
                const fetchProduct = async () => getPurchaseOrder(identifier)
                fetchProduct()
            }
            setIsInit(false)
        }

        // WillUnmount
        return () => {
            dispatch(clearPurchaseOrder())
        }
    }, [clearPurchaseOrder, dispatch, getPurchaseOrder, identifier, isInit])

    const prevIsLoading = usePrevious(isLoading)
    useEffect(() => {
        if (prevIsLoading && !isLoading && !initialValues.id) {
            navigate('/purchase-order')
        }
        // eslint-disable-next-line
    }, [isLoading])

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Order', linkTo: '/purchase-order' },
        { label: `${getPageName(windowType)} (${noDoc})` }
    ]

    const renderPemodal = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Pemodal</StyledLabel>
            {defaultViewRow(
                'Email',
                <AntdSpace>
                    {isHasAccessPemodal && (
                        <EyeIcon route={`/pemodal/view/${pemodal.id || ''}`} />
                    )}
                    {get(pemodal, 'email', '')}
                </AntdSpace>
            )}
            <br />
        </Space>
    )

    const renderPurchaseOrder = () => (
        <Space direction='vertical'>
            <StyledLabel level={5}>Detail</StyledLabel>
            {defaultViewRow('Tanggal Dibuat', createdAt)}
            {defaultViewRow('Status', getLabelOption(status, statusOptions))}
            {defaultViewRow('Nomor', noDoc)}
            {defaultViewRow('Penerbit', get(product, 'penerbit.name', ''))}
            {defaultViewRow('Kode', get(product, 'code', ''))}
            {defaultViewRow('Kategori', get(product, 'category', ''))}
            {defaultViewRow('Tipe', get(product, 'type', ''))}
            {defaultViewRow(
                'Harga Per Lembar',
                formatPrice(get(product, 'pricePerShare', ''))
            )}
            {defaultViewRow(
                'Jumlah Lembar',
                formatPrice(get(product, 'numberShare', ''))
            )}
            {defaultViewRow('Biaya Beli', formatPrice(subtotal))}
            {defaultViewRow('Biaya Admin', formatPrice(adminFee))}
            {defaultViewRow('Biaya Transaksi', formatPrice(transactionFee))}
            {defaultViewRow('Biaya Pembayaran', formatPrice(totalPayment))}
            {defaultViewRow(
                'Dengan ini saya menyetujui pembelian saham bisnis termasuk' +
                    'prospektus penerbit bersangkutan di dalamnya dan memahami segala' +
                    'risiko atas keputusan beli yang saya buat',
                <AntdTypographyText type='success'>
                    {`Dipahami dan disetujui pengguna pada ${approvedAt}`}
                </AntdTypographyText>
            )}
            {isCanceled &&
                defaultViewRow('Tanggal Pembatalan', formatPrice(canceledDate))}
            {isCanceled &&
                defaultViewRow('Biaya Platform', formatPrice(platformFee))}
            {isCanceled &&
                defaultViewRow(
                    'Total Pengembalian Dana',
                    formatPrice(totalRefund)
                )}
            <br />
        </Space>
    )

    const renderPurchaseOrderTransaction = () =>
        !isEmpty(purchaseOrderTransactions) && (
            <Space direction='vertical'>
                <StyledAntdDivider />
                <StyledLabel level={5}>Riwayat Transaksi</StyledLabel>
                <Table
                    columns={transactionHistoryConfig.columns}
                    dataSource={transactionHistoryConfig.dataSource(
                        purchaseOrderTransactions
                    )}
                    scroll={{ x: 800 }}
                />
            </Space>
        )

    if (isLoading) {
        return <AntdSpin />
    }

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <BackButton route={`/purchase-order${queries}`} />
            <Form
                colon={false}
                labelAlign='left'
                labelCol={{ flex: '220px' }}
                labelWrap
                layout={'horizontal'}
                wrapperCol={{ flex: 1 }}
            >
                <Space direction='vertical'>
                    {renderPemodal()}
                    {renderPurchaseOrder()}
                    {renderPurchaseOrderTransaction()}
                </Space>
            </Form>
        </Space>
    )
}

View.propTypes = {
    clearPurchaseOrder: PropTypes.func,
    dispatch: PropTypes.func,
    getPurchaseOrder: PropTypes.func,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    params: PropTypes.object,
    roles: PropTypes.array,
    username: PropTypes.string,
    windowType: PropTypes.string
}

export default View
