import * as productRibbonSlice from 'features/product_ribbon/productRibbonSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => {
    return <List {...props} />
}

const mapStateToProps = (state) => ({
    isActionLoading: state.productRibbon.isActionLoading,
    isActionSuccess: state.productRibbon.isActionSuccess,
    isLoading: state.productRibbon.isLoading,
    productRibbons: state.productRibbon.productRibbons,
    totalProductRibbons: state.productRibbon.totalProductRibbons
})

const mapDispatchToProps = {
    clearProductRibbon: productRibbonSlice.clearProductRibbon,
    deleteProductRibbon: productRibbonSlice.deleteProductRibbon,
    getProductRibbons: productRibbonSlice.getProductRibbons
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
