import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import StyledReactQuill from './StyledComponents'

const RichInput = (props) => {
    const {
        defaultValue,
        onBlur,
        onChange,
        onChangeSelection,
        onFocus,
        onKeyDown,
        onKeyPress,
        onKeyUp,
        placeholder,
        preserveWhitespace,
        readOnly,
        tabIndex,
        value
    } = props

    useEffect(() => {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = '//cdn.quilljs.com/1.2.6/quill.snow.css'
        document.body.appendChild(link)
    })

    const getModules = () => ({
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ size: ['small', false, 'large', 'huge'] }],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' }
                ],
                [{ script: 'sub' }, { script: 'super' }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['link'],
                ['clean']
            ]
        }
    })

    return (
        <StyledReactQuill
            defaultValue={defaultValue}
            modules={getModules()}
            onBlur={onBlur}
            onChange={onChange}
            onChangeSelection={onChangeSelection}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            preserveWhitespace={preserveWhitespace}
            readOnly={readOnly}
            tabIndex={tabIndex}
            value={value}
        />
    )
}

RichInput.propTypes = {
    defaultValue: PropTypes.any.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onChangeSelection: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    preserveWhitespace: PropTypes.bool,
    readOnly: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.string
}

RichInput.defaultProps = {
    defaultValue: ''
}

export default RichInput
