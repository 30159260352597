import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import {
    getInitialPenerbitDetail,
    initialPenerbitManagementFees
} from 'common/helper/penerbit'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import moment from 'moment'
import {
    lengthIdCardNumber,
    numberRequired,
    validEmail
} from 'utility/formValidation'

const initialPenerbitDetail = getInitialPenerbitDetail('Personel 1')
const initialPenerbit = {
    isAccountProfileViewable: true,
    isActive: true,
    isEmailVerified: true,
    isRiskStatementApproved: true,
    isTermsAndConditionsApproved: true,
    penerbitDetailKey: 0,
    penerbitDetails: [initialPenerbitDetail],
    penerbitManagementFees: initialPenerbitManagementFees,
    riskStatementApprovalDate: moment().format(DATE_HOUR_SLASH_FORMAT),
    status: 'unverified',
    termsAndConditionsApprovalDate: moment().format(DATE_HOUR_SLASH_FORMAT)
}

const submitErrorValidator = (penerbitDetail = {}, penerbitDocuments = {}) => {
    const {
        address,
        birthDate,
        city = {},
        cityId,
        districtId,
        email,
        gender,
        hometownId,
        mobileNumber,
        penerbitDetailDocuments,
        position,
        postalCode,
        idCardNumber,
        idCardName
    } = penerbitDetail

    const { province = '' } = city

    if (isEmpty(email)) {
        return '* email personel wajib diisi'
    } else {
        const validEmailError = validEmail(email)
        if (!isEmpty(validEmailError)) {
            return '* email personel ' + validEmailError
        }
    }
    if (isEmpty(mobileNumber)) {
        return '* nomor telepon seluler Personel wajib diisi'
    }
    if (isEmpty(position)) {
        return '* jabatan Personel wajib diisi'
    }
    if (isEmpty(idCardNumber)) {
        return '* nomor KTP personel wajib diisi'
    } else {
        const numberKtpError = numberRequired(idCardNumber)
        const lengthStringError = lengthIdCardNumber(idCardNumber)
        if (!isEmpty(numberKtpError)) {
            return '* nomor KTP personel ' + numberKtpError
        }
        if (!isEmpty(lengthStringError)) {
            return '* nomor KTP personel ' + lengthStringError
        }
    }
    if (isEmpty(idCardName)) {
        return '* nama lengkap sesuai KTP personel wajib diisi'
    }
    if (isEmpty(gender)) {
        return '* jenis kelamin personel wajib diisi'
    }
    if (!hometownId) {
        return '* tempat lahir personel wajib diisi'
    }
    if (isEmpty(birthDate)) {
        return '* tanggal lahir personel wajib diisi'
    }
    if (isEmpty(province)) {
        return '* provinsi personel wajib diisi'
    }
    if (!cityId) {
        return '* kota personel wajib diisi'
    }
    if (!districtId) {
        return '* kecamatan dan kelurahan personel wajib diisi'
    }
    if (isEmpty(postalCode)) {
        return '* kode pos personel wajib diisi'
    }
    if (isEmpty(address)) {
        return '* alamat lengkap sesuai KTP personel wajib diisi'
    }
    if (size(penerbitDetailDocuments) < 2) {
        return '* KTP / selfie wajib diisi'
    }
    if (size(penerbitDocuments) < 1) {
        return '* Foto npwp wajib diisi'
    }

    return ''
}

export { initialPenerbit, submitErrorValidator }
