import { default as AntdBadge } from 'antd/lib/badge'
import { default as AntdTypography } from 'antd/lib/typography'
import colors from 'basic/colors'
import modifyVars from 'basic/modifyVars'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const { Text, Title } = AntdTypography

const StyledInboxBadge = styled(AntdBadge)`
    &&& {
        .ant-badge-status-dot {
            width: 12px;
            height: 12px;
        }
    }
`

const StyledText = ({ children }) => {
    return (
        <Text
            style={{
                color: colors.graniteGray
            }}
        >
            {children}
        </Text>
    )
}

const StyledTitle = ({ children, color, font }) => {
    return (
        <Title
            level={4}
            style={{
                color: color,
                fontFamily: font,
                fontWeight: 'initial !important',
                margin: 0
            }}
        >
            {children}
        </Title>
    )
}

StyledText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

StyledText.defaultProps = {
    children: ''
}

StyledTitle.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    font: PropTypes.string
}

StyledTitle.defaultProps = {
    children: '',
    color: colors.persianIndigo,
    font: modifyVars.arTechniFont
}

export { StyledText, StyledTitle, StyledInboxBadge }
