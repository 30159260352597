import { default as AntdRow } from 'antd/lib/row'
import colors from 'basic/colors'
import imagePath from 'basic/imagePath'
import styled from 'styled-components'

export const StyledAntdRow = styled(AntdRow)`
    &&& {
        background-color: ${colors.persianIndigo};
        background-image: url(${imagePath.layoutBackground});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: ${(props) => props.height}px;
        padding: 16px;
    }
`
