import * as inboxSlice from 'features/inbox/inboxSlice'
import * as pemodalSlice from 'features/pemodal/pemodalSlice'
import * as penerbitSlice from 'features/penerbit/penerbitSlice'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import List from './List'

const ListContainer = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return <List {...props} dispatch={dispatch} navigate={navigate} />
}

const mapStateToProps = (state) => ({
    inboxes: state.inbox.inboxes,
    isActionLoading: state.inbox.isActionLoading,
    isActionSuccess: state.inbox.isActionSuccess,
    isLoading: state.inbox.isLoading,
    pemodals: state.pemodal.pemodals,
    penerbits: state.penerbit.penerbits,
    totalInboxes: state.inbox.totalInboxes,
    userId: state.authentication.id,
    username: state.authentication.username
})

const mapDispatchToProps = {
    clearInbox: inboxSlice.clearInbox,
    clearPemodal: pemodalSlice.clearPemodal,
    clearPenerbit: penerbitSlice.clearPenerbit,
    createInbox: inboxSlice.createInbox,
    getInboxes: inboxSlice.getInboxes,
    getPemodals: pemodalSlice.getPemodals,
    getPenerbits: penerbitSlice.getPenerbits
}

const ListForm = reduxForm({
    enableReinitialize: true,
    form: 'inbox'
})(ListContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ListForm)
