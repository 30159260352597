import {
    Button,
    Form,
    ReduxFormInput,
    ReduxFormInputPassword,
    Space
} from 'antdcomponents'
import { StyledAntdRow } from 'authentication/login/StyledComponents'
import imagePath from 'basic/imagePath'
import { StyledImage } from 'components/StyledComponents'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Field } from 'redux-form'
import { required } from 'utility/formValidation'
import setPersistentData from 'utility/setPersistentData'
import useWindowDimensions from 'utility/useWindowDimensions'

const Login = (props) => {
    const { authenticate, authToken, handleSubmit, isLoading } = props
    const navigate = useNavigate()
    const { height } = useWindowDimensions()

    useEffect(() => {
        if (authToken) {
            setPersistentData('authToken', authToken)
            navigate('/')
        }
    }, [authToken, navigate])

    const handleLogin = (values) => {
        const { identifier, password } = values
        const data = { identifier, password, user: 'admin' }
        authenticate(data)
    }

    return (
        <StyledAntdRow align='middle' height={height} justify='space-around'>
            <Form onFinish={handleSubmit(handleLogin)}>
                <center>
                    <Space direction='vertical'>
                        <StyledImage src={imagePath.moneypool} />
                        <Field
                            component={ReduxFormInput}
                            dark
                            formItemProps={{ isStyledLabel: true }}
                            label='Email/Username'
                            name='identifier'
                            placeholder='Contoh: example@moneypool.com'
                            validate={[required]}
                        />
                        <Field
                            component={ReduxFormInputPassword}
                            dark
                            formItemProps={{ isStyledLabel: true }}
                            label='Kata Sandi'
                            name='password'
                            placeholder='Masukkan kata sandi anda'
                            validate={[required]}
                        />
                        <Button
                            htmlType='submit'
                            loading={isLoading}
                            name='login-btn'
                            type='primary'
                            value='Masuk'
                        />
                    </Space>
                </center>
            </Form>
        </StyledAntdRow>
    )
}

Login.propTypes = {
    authToken: PropTypes.string,
    authenticate: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    navigate: PropTypes.func
}

export default Login
