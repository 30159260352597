import Icon from '@ant-design/icons'
import { default as AntdRow } from 'antd/lib/row'
import colors from 'basic/colors'
import styled from 'styled-components'

export const StyledIcon = styled(Icon)`
    color: ${colors.blueberry};
    font-size: 30px;
`

export const StyledAntdRow = styled(AntdRow)`
    margin-top: 15px;
    margin-bottom: 5px;
`
