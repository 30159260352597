import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getInvestmentGoals = createAsyncThunk(
    'GET_INVESTMENT_GOALS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/investment-goals', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
