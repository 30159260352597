import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import toLower from 'lodash/toLower'
import moment from 'moment'
import * as pemodalTransformer from './pemodal'
import * as productTransformer from './product'
import * as purchaseOrderTransactionTransformer from './purchaseOrderTransaction'

export const toApi = (data) => ({
    admin_fee: data.adminFee,
    id: data.id,
    invoice_url: data.invoiceUrl,
    no_doc: data.noDoc,
    payment_method: data.paymentMethod,
    pemodal_id: data.pemodalId,
    platform_fee: data.platformFee,
    product_id: data.productId,
    quantity: data.quantity,
    remarks: data.remarks,
    status: data.status,
    subtotal: data.subtotal,
    total_payment: data.totalPayment,
    transaction_fee: data.transactionFee,
    unit_price: data.unitPrice
})

export const toState = (data) => {
    const approvedAt = moment(data.approvedAt).isValid()
        ? moment(data.approvedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    const pemodal = !isEmpty(data.pemodal)
        ? pemodalTransformer.toState(data.pemodal)
        : {}
    const product = !isEmpty(data.product)
        ? productTransformer.toState(data.product)
        : {}
    const purchaseOrderTransactions = !isEmpty(data.purchaseOrderTransactions)
        ? map(data.purchaseOrderTransactions, (item) =>
              purchaseOrderTransactionTransformer.toState(item)
          )
        : []

    const lastDescription = get(
        last(purchaseOrderTransactions),
        'description',
        ''
    )
    const pemodalName = get(pemodal, 'name', '')

    const status = get(data, 'status', '')
    const isCanceled = status === 'canceled'
    const canceledPurchaseOrderTransaction = find(
        purchaseOrderTransactions,
        (item) => toLower(item.description) === 'pembelian dibatalkan'
    )
    const canceledDate = get(
        canceledPurchaseOrderTransaction,
        'createdAt',
        null
    )

    return {
        adminFee: data.adminFee || 0,
        approvedAt,
        canceledDate,
        createdAt,
        id: data.id || null,
        invoiceUrl: data.invoiceUrl || '',
        isCanceled,
        lastDescription,
        noDoc: data.noDoc || '',
        paymentMethod: data.paymentMethod || '',
        pemodal,
        pemodalId: data.pemodalId || null,
        pemodalName,
        platformFee: data.platformFee || 0,
        product,
        productId: data.productId || null,
        purchaseOrderTransactions,
        quantity: data.quantity || 0,
        remarks: data.remarks || '',
        status,
        subtotal: data.subtotal || 0,
        totalPayment: data.totalPayment || 0,
        transactionFee: data.transactionFee || 0,
        unitPrice: data.unitPrice || 0,
        updatedAt
    }
}
