import AntdInputNumber from 'antd/lib/input-number'
import colors from 'basic/colors'
import styled from 'styled-components'

const StyledInputNumber = styled(AntdInputNumber)`
    border-color: ${(props) => (props.$dark ? colors.robbinEggBlue : 'silver')};
    border-width: 2px;
    width: 100%;
    ${(props) =>
        !props.disabled &&
        `
        background-color: transparent;
        color: ${props.$dark ? 'white' : 'black'};
    `}

    .ant-input-number-handler-wrap {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
`

export default StyledInputNumber
