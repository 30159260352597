import { DATE_SLASH_FORMAT } from 'constants/formats'
import {
    canceledStatus,
    finishedStatus,
    onGoingStatus
} from 'common/options/product'
import concat from 'lodash/concat'
import includes from 'lodash/includes'
import moment from 'moment'

const getSummaryProduct = (startDate, endDate, status) => {
    const currentDate = moment()
    const isExpiredDate = moment(endDate, DATE_SLASH_FORMAT).isBefore(
        currentDate
    )
    const isOnGoingDate =
        moment(startDate, DATE_SLASH_FORMAT).isBefore(currentDate) &&
        moment(endDate, DATE_SLASH_FORMAT).isAfter(currentDate)

    const isFailed = isExpiredDate && includes(canceledStatus, status)
    const isFinished = includes(concat(canceledStatus, finishedStatus), status)
    const isOnGoing = isOnGoingDate && includes(onGoingStatus, status)

    return {
        isExpiredDate,
        isFailed,
        isFinished,
        isOnGoing,
        isOnGoingDate
    }
}

export default getSummaryProduct
