import { default as AntdDatePicker } from 'antd/lib/date-picker'
import colors from 'basic/colors'
import styled from 'styled-components'

const StyledAntdDatePicker = styled(AntdDatePicker)`
    &&& {
        &,
        &:-internal-autofill-selected {
            border-color: ${(props) =>
                props.$dark ? colors.robbinEggBlue : 'silver'};
            border-width: 2px;
            width: 100%;
            ${(props) =>
                !props.disabled &&
                `
                background-color: transparent;
                color: ${props.$dark ? 'white' : 'black'};
            `}
        }
    }
`

export default StyledAntdDatePicker
