import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/pemodal'
import * as api from '../api'

export const getPemodal = createAsyncThunk(
    'GET_PEMODAL',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/pemodal', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPemodals = createAsyncThunk(
    'GET_PEMODALS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/pemodals', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPemodalStatuses = createAsyncThunk(
    'GET_PEMODALS_STATUSES',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/pemodals/statuses', {
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updatePemodal = createAsyncThunk(
    'UPDATE_PEMODAL',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/pemodal/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
