import programList from 'common/programList'
import * as authenticationSlice from 'features/authentication/authenticationSlice'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const List = (props) => {
    const { roles, updateWriteAccess } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const [isWriteAllowed, setIsWriteAllowed] = useState(false)
    const [rootFunctionPath, setRootFunctionPath] = useState(undefined)

    useEffect(() => {
        // componentDidMount
        const program = find(
            programList,
            (item) => item.path === `/${params.name}`
        )

        if (isEmpty(program)) {
            return navigate('/404')
        }

        const acl = get(program, 'acl', [])
        if (!isEmpty(acl)) {
            const readAcl = find(acl, (item) => includes(item, '_READ'))
            const readRole = find(roles, (item) => item === readAcl)
            if (isEmpty(readRole)) {
                return navigate('/')
            }

            const writeAcl = find(acl, (item) => includes(item, '_WRITE'))
            const writeRole = find(roles, (item) => item === writeAcl)
            const newIsWriteAllowed = writeAcl === writeRole

            setIsWriteAllowed(newIsWriteAllowed)
            setRootFunctionPath(program.rootFunctionPath)
            updateWriteAccess(newIsWriteAllowed)
        }
    }, [navigate, params, roles, updateWriteAccess])

    if (!rootFunctionPath) {
        return null
    }

    let Function = rootFunctionPath
    return (
        <Function
            dispatch={dispatch}
            isWriteAllowed={isWriteAllowed}
            location={location}
            navigate={navigate}
            windowType='list'
        />
    )
}

List.propTypes = {
    roles: PropTypes.array,
    updateWriteAccess: PropTypes.func
}

const mapStateToProps = (state) => ({
    roles: state.authentication.roles
})

const mapDispatchToProps = {
    updateWriteAccess: authenticationSlice.updateWriteAccess
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
