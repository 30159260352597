import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

import StyledAntdInput from './StyledAntdInput'

const Input = (props) => {
    const {
        allowClear,
        bordered,
        dark,
        disabled,
        maxLength,
        name,
        onClear,
        onChange,
        onPressEnter,
        placeholder,
        prefix,
        size,
        suffix,
        type,
        value
    } = props

    const handleChange = (e) => {
        const val = e.target.value
        if (isEmpty(val)) {
            onClear()
        }
        onChange(val, name, e)
    }
    const handlePressEnter = (e) => onPressEnter(e.target.value, name, e)

    return (
        <StyledAntdInput
            $dark={dark}
            allowClear={allowClear}
            bordered={bordered}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            onChange={handleChange}
            onPressEnter={handlePressEnter}
            placeholder={placeholder}
            prefix={prefix}
            size={size}
            suffix={suffix}
            type={type}
            value={value}
        />
    )
}

Input.propTypes = {
    allowClear: PropTypes.bool,
    bordered: PropTypes.bool,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onPressEnter: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.string,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Input.defaultProps = {
    allowClear: true,
    onClear: noop,
    placeholder: 'Masukkan data'
}

export default Input
