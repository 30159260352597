import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import * as message from 'utility/message'
import { columns, dataSource, rowActions } from './config'

const List = (props) => {
    const {
        clearProductRibbon,
        deleteProductRibbon,
        dispatch,
        getProductRibbons,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        navigate,
        productRibbons,
        totalProductRibbons
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'updatedAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    useEffect(() => {
        const fetchProductRibbons = () => {
            const options = { orderBy, status: 'all' }
            const offset = (page - 1) * limit
            getProductRibbons({ limit, offset, options })
        }
        fetchProductRibbons()
        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }
        return () => {
            dispatch(clearProductRibbon())
        }
    }, [
        clearProductRibbon,
        dispatch,
        getProductRibbons,
        isActionLoading,
        isActionSuccess,
        limit,
        orderBy,
        page
    ])

    const handleActions = (key, type) => () => {
        const { id } = productRibbons[key]
        if (type === 'delete') {
            return deleteProductRibbon(id)
        }
        if (type === 'edit') {
            return navigate(`/tag/edit/${id}`)
        }
        if (type === 'view') {
            return navigate(`/tag/view/${id}`)
        }
    }

    const handleAdd = () => navigate('/tag/add')

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const renderActions = (text, record, index) => (
        <TableRowActions options={rowActions(index, handleActions, record)} />
    )

    return (
        <Space direction='vertical'>
            <TableHeader
                onAdd={handleAdd}
                onChangePagination={handleChangePagination}
                totalItems={totalProductRibbons}
            />
            <Table
                columns={columns(renderActions)}
                dataSource={dataSource(productRibbons)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    clearProductRibbon: PropTypes.func,
    deleteProductRibbon: PropTypes.func,
    dispatch: PropTypes.func,
    getProductRibbons: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    productRibbons: PropTypes.array,
    totalProductRibbons: PropTypes.number
}

export default List
