import { default as AntdTag } from 'antd/lib/tag'
import PropTypes from 'prop-types'
import React from 'react'

const Tag = (props) => {
    const { closable, color, name, onClose, value } = props
    const handleClose = (e) => onClose(value, name, e)

    return (
        <AntdTag closable={closable} color={color} onClose={handleClose}>
            {value}
        </AntdTag>
    )
}

Tag.propTypes = {
    closable: PropTypes.bool,
    color: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    value: PropTypes.string
}

export default Tag
