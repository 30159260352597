import { default as AntdCheckbox } from 'antd/lib/checkbox'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'

const AntdCheckboxGroup = AntdCheckbox.Group

const CheckboxGroup = (props) => {
    const { defaultValue, disabled, name, onChange, options, value } = props

    const renderLabel = (option) =>
        isFunction(option.label) ? option.label() : option.label

    const renderOptions = (options) =>
        map(options, (o) => (
            <AntdCheckbox disabled={o.disabled} key={o.value} value={o.value}>
                {renderLabel(o)}
            </AntdCheckbox>
        ))

    return (
        <AntdCheckboxGroup
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={(checkedList) => onChange(checkedList, name)}
            value={value}
        >
            {renderOptions(options)}
        </AntdCheckboxGroup>
    )
}

CheckboxGroup.propTypes = {
    defaultValue: PropTypes.arrayOf(PropTypes.any).isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.node,
                PropTypes.string
            ]).isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired
        })
    ),
    value: PropTypes.arrayOf(PropTypes.any)
}

CheckboxGroup.defaultProps = {
    defaultValue: [],
    disabled: false,
    onChange: noop,
    options: [],
    value: []
}

export default CheckboxGroup
