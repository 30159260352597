import { FilterFilled, FilterOutlined } from '@ant-design/icons'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { TableActions } from 'antdcomponents'
import { ConnectedFilterBlock, ConnectedPagination } from 'connected_components'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { parseQueryString } from 'utility/url'
import { StyledAntdRow, StyledIcon } from './StyledComponents'

const TableHeader = (props) => {
    const {
        filterConfig,
        onChangePagination,
        onAdd,
        onClear,
        onExport,
        onSearch,
        totalItems
    } = props
    const [isCollapsed, setIsCollapsed] = useState(false)
    const filters = filterConfig
    const showFilter = !isEmpty(filters)

    // componentDidMount
    const queries = parseQueryString()
    useEffect(() => {
        if (!isEmpty(queries)) {
            setIsCollapsed(true)
        }
        if (isEmpty(queries)) {
            setIsCollapsed(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCollapse = () => setIsCollapsed(!isCollapsed)

    return (
        <StyledAntdRow type='flex'>
            <AntdCol md={12} xs={24}>
                <AntdSpace align='center' size='middle'>
                    {showFilter && (
                        <StyledIcon
                            component={
                                isCollapsed ? FilterFilled : FilterOutlined
                            }
                            onClick={handleCollapse}
                        />
                    )}
                    <TableActions onAddClick={onAdd} onExportClick={onExport} />
                </AntdSpace>
            </AntdCol>
            <AntdCol md={12} xs={24}>
                <AntdRow justify='end' type='flex'>
                    <ConnectedPagination
                        onChange={onChangePagination}
                        size='small'
                        total={totalItems}
                    />
                </AntdRow>
            </AntdCol>
            {isCollapsed && showFilter && (
                <AntdCol lg={16} xs={24}>
                    <ConnectedFilterBlock
                        excludePagination
                        filters={filters}
                        isCollapsed={isCollapsed}
                        onClear={onClear}
                        onCollapse={handleCollapse}
                        onSearch={onSearch}
                        queries={queries}
                    />
                </AntdCol>
            )}
        </StyledAntdRow>
    )
}

TableHeader.propTypes = {
    filterConfig: PropTypes.array,
    onAdd: PropTypes.func,
    onChangePagination: PropTypes.func,
    onClear: PropTypes.func,
    onExport: PropTypes.func,
    onSearch: PropTypes.func,
    totalItems: PropTypes.number
}

TableHeader.defaultProps = {
    filterConfig: [],
    onChangePagination: noop,
    onClear: noop,
    onSearch: noop,
    totalItems: 0
}

export default TableHeader
