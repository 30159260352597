import * as productCategorySlice from 'features/product_category/productCategorySlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => {
    return <List {...props} />
}

const mapStateToProps = (state) => ({
    isActionLoading: state.productCategory.isActionLoading,
    isActionSuccess: state.productCategory.isActionSuccess,
    isLoading: state.productCategory.isLoading,
    productCategories: state.productCategory.productCategories,
    totalProductCategories: state.productCategory.totalProductCategories
})

const mapDispatchToProps = {
    clearProductCategory: productCategorySlice.clearProductCategory,
    deleteProductCategory: productCategorySlice.deleteProductCategory,
    getProductCategories: productCategorySlice.getProductCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
