import { VALID_IMAGE_EXTENSIONS } from 'constants/formats'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
// eslint-disable-next-line
import ImgCrop from 'antd-img-crop'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdUpload } from 'antd/lib/upload'
import { Button, FormItem, Modal } from 'antdcomponents'
import { StyledImage } from 'components/StyledComponents'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import last from 'lodash/last'
import map from 'lodash/map'
import merge from 'lodash/merge'
import noop from 'lodash/noop'
import size from 'lodash/size'
import split from 'lodash/split'
import toString from 'lodash/toString'
import trim from 'lodash/trim'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import * as message from 'utility/message'
import * as modal from 'utility/modal'

const Upload = (props) => {
    const {
        accept,
        csvHeaders,
        customValidators,
        disabled,
        enableCropper,
        fileList,
        formItemProps,
        label,
        limit,
        listType,
        maxSizeMb,
        onRemove,
        onUpload
    } = props

    const [filelistState, setFilelistState] = useState([])
    const [isPreview, setIsPreview] = useState(false)
    const [isTypePdf, setIsTypePdf] = useState(false)
    const [previewUrl, setPreviewUrl] = useState('')

    const newAccept = isArray(accept) ? toString(accept) : accept
    const showRemoveIcon = disabled !== true

    useEffect(() => {
        setFilelistState(isArray(fileList) ? fileList : [fileList])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(fileList)])

    const isValidFileType = (file) => {
        const { type } = file
        const acceptList = split(accept, ',')
        return indexOf(acceptList, type) > -1
    }

    const isValidFileSize = (file) => {
        const { size } = file
        const fileSizeMb = size / 1024 / 1024
        return maxSizeMb ? fileSizeMb < maxSizeMb : true
    }

    const handleUpload = ({ file, onError, onProgress, onSuccess }) => {
        let newFileList = filelistState
        if (enableCropper) {
            newFileList = filter(newFileList, (item) => item.uid !== file.uid)
            let newFile = find(filelistState, (item) => item.uid === file.uid)
            newFile = merge(newFile, file)
            newFile.originFileObj = file
            newFileList.push(newFile)
        }
        onUpload({ fileList: newFileList, onError, onProgress, onSuccess })
    }

    const handleValidationError = (onError, errorMessage) => {
        if (errorMessage) {
            message.error(errorMessage)
        }
        onError()
        setFilelistState(filelistState)
    }

    const validateCsv = (file, onProgress, onSuccess, onError) => {
        const csvValidator = customValidators.csv || {}
        const { validate } = csvValidator

        let reader = new FileReader()
        reader.onload = (event) => {
            let fileContents = event.target.result

            if (!fileContents) {
                return handleValidationError(onError, 'empty file')
            }

            if (
                !isEmpty(csvHeaders) &&
                trim(split(fileContents, '\n')[0]) !== join(csvHeaders, ',')
            ) {
                return handleValidationError(onError, 'invalid csv content')
            }

            if (validate && !validate(fileContents)) {
                return handleValidationError(onError, csvValidator.errorMessage)
            }

            handleUpload({ file, onError, onProgress, onSuccess })
        }
        reader.readAsText(file)
    }

    const validateImage = (file, onProgress, onSuccess, onError) => {
        const imageValidator = get(customValidators, 'image', {})
        const { validate } = imageValidator

        let reader = new FileReader()
        reader.onload = (event) => {
            const src = event.target.result

            let image = new Image()
            image.src = src
            image.onload = () => {
                if (validate && !validate(image)) {
                    return handleValidationError(
                        onError,
                        imageValidator.errorMessage
                    )
                }

                handleUpload({ file, onError, onProgress, onSuccess })
            }
        }
        reader.readAsDataURL(file)
    }

    const handleCustomRequest = (info) => {
        const { file, onError, onProgress, onSuccess } = info
        if (!isValidFileType(file)) {
            message.error('jenis file tidak valid')
            return onError()
        }
        if (!isValidFileSize(file)) {
            message.error(`melebihi file size limit ${maxSizeMb} MB`)
            return onError()
        }
        if (indexOf(split(VALID_IMAGE_EXTENSIONS, ','), file.type) > -1) {
            return validateImage(file, onProgress, onSuccess, onError)
        }
        if (indexOf(['text/csv', 'application/vnd.ms-excel'], file.type) > -1) {
            return validateCsv(file, onProgress, onSuccess, onError)
        }

        handleUpload({ file, onError, onProgress, onSuccess })
    }

    const handleChange = (info) => {
        const { file } = info
        const name = get(file, 'name', '')
        const status = get(file, 'status', '')

        if (!isValidFileType(file) || !isValidFileSize(file)) {
            message.error(`${name} unggahan file tidak valid.`)
            return
        }

        const newFileList = map(info.fileList, (item) => {
            const isNewFile = item.name === name
            if (isNewFile && item.percent === 0) {
                message.info(`${name} berkas berhasil terpilih`)
            }
            if (isNewFile && status !== 'done') {
                return Object.assign(item, { status: 'done', url: '' })
            }

            return item
        })

        setFilelistState(newFileList)
    }

    const handleCancel = () => {
        setIsPreview(!isPreview)
        setPreviewUrl('')
    }

    const handlePreview = async (file) => {
        let type = get(file, 'type', '')
        if (!type) {
            const name = get(file, 'name', '')
            type = last(split(name, '.'))
        } else {
            type = last(split(type, '/'))
        }
        const newIsTypePdf = type === 'pdf'

        let src = get(file, 'url', '')
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)

                reader.onload = () => resolve(reader.result)
            })
        }

        setIsPreview(!isPreview)
        setIsTypePdf(newIsTypePdf)
        setPreviewUrl(src)

        if (newIsTypePdf) {
            const pdfWindow = window.open('', '_blank')
            pdfWindow.document.write(
                "<body style='margin:0px;padding:0px;overflow:hidden'><iframe src='" +
                    encodeURI(src) +
                    "' frameborder='0' style='overflow:hidden;height:100%;width:100%'' height='100%' width='100%'></iframe></body>"
            )
        }

        return null
    }

    const handleRemove = (file) => {
        if (!disabled) {
            const name = get(file, 'name', '')
            modal.confirm({
                content: `Hapus ${name}?`,
                onOk: () => {
                    const newFileList = filter(
                        filelistState,
                        (item) => item.name !== file.name
                    )
                    message.warning(`${name} berhasil di hapus.`)

                    onRemove({ file, fileList: newFileList })
                    setFilelistState(newFileList)
                }
            })
        }

        return false
    }

    const renderButton = () => {
        if (size(filelistState) >= limit || disabled) {
            return null
        }

        if (listType === 'picture-card') {
            return (
                <span>
                    <PlusOutlined /> Unggah
                </span>
            )
        }

        const btnContent = (
            <span>
                <UploadOutlined /> Pilih File
            </span>
        )
        return <Button name='upload-btn' type='default' value={btnContent} />
    }

    const renderModal = () =>
        !isTypePdf && (
            <Modal onCancel={handleCancel} visible={isPreview}>
                <AntdRow justify='center' type='flex'>
                    <StyledImage src={previewUrl} />
                </AntdRow>
            </Modal>
        )

    const memoFilelist = useMemo(
        () =>
            map(filelistState, (file) => ({
                fieldName: file.fieldName,
                id: file.id,
                identifier: file.identifier,
                name: file.name,
                originFileObj: file.originFileObj,
                sequence: file.sequence,
                status: file.status,
                thumbUrl: file.thumbUrl,
                type: file.type,
                uid: file.uid,
                url: file.url
            })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(filelistState)]
    )

    const renderUpload = () => {
        return (
            <AntdUpload
                accept={newAccept}
                customRequest={handleCustomRequest}
                disabled={disabled}
                fileList={memoFilelist}
                listType={listType}
                onChange={handleChange}
                onPreview={handlePreview}
                onRemove={handleRemove}
                showUploadList={{ showRemoveIcon }}
            >
                {renderButton()}
            </AntdUpload>
        )
    }

    return (
        <FormItem label={label} {...formItemProps}>
            {!enableCropper && renderUpload()}
            {enableCropper && <ImgCrop>{renderUpload()}</ImgCrop>}
            {renderModal()}
        </FormItem>
    )
}

Upload.propTypes = {
    accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    csvHeaders: PropTypes.array,
    customValidators: PropTypes.shape({
        csv: PropTypes.shape({
            errorMessage: PropTypes.string,
            validate: PropTypes.func.isRequired
        }),
        image: PropTypes.shape({
            errorMessage: PropTypes.string,
            validate: PropTypes.func.isRequired
        })
    }),
    disabled: PropTypes.bool,
    enableCropper: PropTypes.bool,
    fileList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    formItemProps: PropTypes.object,
    label: PropTypes.string,
    limit: PropTypes.number,
    listType: PropTypes.oneOf(['picture', 'picture-card', 'text']),
    maxSizeMb: PropTypes.number,
    name: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    onUpload: PropTypes.func.isRequired
}

Upload.defaultProps = {
    csvHeaders: [],
    customValidators: {},
    disabled: false,
    limit: 1,
    maxSizeMb: 4,
    onRemove: noop
}

export default Upload
