import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import get from 'lodash/get'
import moment from 'moment'

export const toApi = (data) => ({
    admin_user_id: get(data, 'adminUserId', undefined),
    created_by: get(data, 'createdBy', undefined),
    created_by_from_table: get(data, 'createdByFromTable', undefined),
    id: data.id || null,
    inbox_id: get(data, 'inboxId', undefined),
    is_deleted: get(data, 'isDeleted', undefined),
    is_read: get(data, 'isRead', undefined),
    is_reviewed: get(data, 'isReviewed', undefined),
    message: get(data, 'message', undefined),
    updated_by: get(data, 'updatedBy', undefined)
})

export const toState = (data) => {
    const momentTime = moment(data.createdAt).isValid()
        ? moment(data.createdAt)
        : null

    const createdAt = momentTime.format(DATE_HOUR_SLASH_FORMAT)
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        ...data,
        createdAt,
        isDeleted: data.isDeleted || false,
        isFromAdmin: data.createdByFromTable === 'admin_user',
        isReviewed: data.isReviewed || false
    }
}
