import { DATE_HOUR_SLASH_FORMAT } from 'constants/formats'
import moment from 'moment'

export const toApi = (data) => {
    const updated_at = moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)

    return {
        id: data.id,
        status: data.status,
        updated_at,
        updated_by: data.updatedBy
    }
}

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const updatedAt = moment(data.updatedAt).isValid()
        ? moment(data.updatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        createdAt,
        email: data.email,
        id: data.id,
        message: data.message,
        status: data.status,
        subject: data.subject,
        updatedAt
    }
}
