import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from 'transformers/purchaseOrder'
import * as api from '../api'

export const exportPurchaseOrders = createAsyncThunk(
    'EXPORT_PURCHASE_ORDERS',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/purchase-orders/export', {
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPurchaseOrder = createAsyncThunk(
    'GET_PURCHASE_ORDER',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.fetchById('/purchase-order', id)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPurchaseOrders = createAsyncThunk(
    'GET_PURCHASE_ORDERS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/purchase-orders', {
                limit,
                offset,
                options
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updatePurchaseOrder = createAsyncThunk(
    'UPDATE_PURCHASE_ORDER',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.update('/purchase-order/update', toApi(data))
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
