import programList from 'common/programList'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const View = (props) => {
    const { roles } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const [viewFunctionPath, setViewFunctionPath] = useState(undefined)
    const isWriteAllowed = false

    useEffect(() => {
        // componentDidMount
        const program = find(
            programList,
            (item) => item.path === `/${params.name}`
        )

        if (isEmpty(program)) {
            return navigate('/')
        }

        const acl = get(program, 'acl', [])
        if (!isEmpty(acl)) {
            const readAcl = find(acl, (item) => includes(item, '_READ'))
            const readRole = find(roles, (item) => item === readAcl)
            if (isEmpty(readRole)) {
                return navigate('/')
            }

            setViewFunctionPath(program.viewFunctionPath)
        }
    }, [navigate, params, roles])

    if (!viewFunctionPath) {
        return null
    }

    let Function = viewFunctionPath
    return (
        <Function
            dispatch={dispatch}
            isWriteAllowed={isWriteAllowed}
            location={location}
            navigate={navigate}
            windowType='view'
        />
    )
}

const mapStateToProps = (state) => ({
    roles: state.authentication.roles
})

View.propTypes = {
    roles: PropTypes.array
}

export default connect(mapStateToProps)(View)
