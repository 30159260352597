import map from 'lodash/map'
import startCase from 'lodash/startCase'

const rowActions = (index, handleActions, record) => [
    {
        customPath: '/category',
        id: record.id,
        type: 'view'
    },
    {
        customPath: '/category',
        id: record.id,
        type: 'edit'
    },
    {
        confirmContent: `Apakah anda yakin ingin hapus kategori ${record.title}?`,
        onClick: handleActions(index, 'delete'),
        type: 'delete'
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'status',
        render: (text) => startCase(text),
        title: 'Status',
        width: 100
    },
    {
        dataIndex: 'title',
        sorter: true,
        title: 'Judul',
        width: 150
    },
    {
        dataIndex: 'slug',
        title: 'Slug',
        width: 150
    },
    {
        dataIndex: 'totalProducts',
        title: 'Jumlah',
        width: 100
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        sorter: true,
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'updatedBy',
        sorter: true,
        title: 'Diperbarui Oleh',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
