import isLocalStorageAvailable from './isLocalStorageAvailable'

const getPersistentData = (key) => {
    if (isLocalStorageAvailable() && window.localStorage.getItem(key)) {
        return window.localStorage.getItem(key)
    }

    const regex = new RegExp(
        '(?:(?:^|.*;\\s*)' + key + '\\s*\\=\\s*([^;]*).*$)|^.*$'
    )
    const cookie = document.cookie.replace(regex, '$1')
    if (cookie) {
        return cookie
    }
    return null
}

export default getPersistentData
