import { Tag } from 'antdcomponents'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import React from 'react'

const rowActions = (index, handleActions, record) => [
    {
        customPath: '/tag',
        id: record.id,
        type: 'view'
    },
    {
        customPath: '/tag',
        id: record.id,
        type: 'edit'
    },
    {
        confirmContent: (
            <span>
                Apakah anda yakin ingin hapus tag {record.title} &nbsp;{' '}
                <Tag
                    color={`#${record.colorCode}`}
                    value={`#${record.colorCode}`}
                />
                ?
            </span>
        ),
        onClick: handleActions(index, 'delete'),
        type: 'delete'
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'status',
        render: (text) => startCase(text),
        title: 'Status',
        width: 100
    },
    {
        dataIndex: 'title',
        sorter: true,
        title: 'Judul',
        width: 150
    },
    {
        dataIndex: 'colorCode',
        render: (text) => <Tag color={`#${text}`} value={`#${text}`} />,
        title: 'Kode Warna',
        width: 150
    },
    {
        dataIndex: 'totalProducts',
        title: 'Jumlah',
        width: 100
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        sorter: true,
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        dataIndex: 'updatedBy',
        sorter: true,
        title: 'Diperbarui Oleh',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 150
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions }
