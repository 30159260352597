import { DEFAULT_PAGINATION_CONFIG } from 'constants/pagination'
import { Breadcrumb, Space, Table, TableRowActions } from 'antdcomponents'
import { TableHeader } from 'components/StyledComponents'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import * as message from 'utility/message'
import * as config from './config'
import filterConfig from './filterConfig'

const List = (props) => {
    const {
        clearContactUs,
        contactUsList,
        dispatch,
        getContactUsList,
        isActionLoading,
        isActionSuccess,
        isLoading,
        location,
        totalContactUs
    } = props

    const search = get(location, 'search', '')
    const queries = qs.parse(search.substr(1))

    const defaultOrderBy = {
        by: 'createdAt',
        order: 'DESC'
    }

    const [limit, setLimit] = useState(
        Number(queries.limit) || DEFAULT_PAGINATION_CONFIG.defaultPageSize
    )
    const [options, setOptions] = useState(omit(queries, ['_limit', '_page']))
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [page, setPage] = useState(
        Number(queries.page) || DEFAULT_PAGINATION_CONFIG.defaultCurrent
    )

    const breadcrumbItems = [
        { label: 'Beranda', linkTo: '/' },
        { label: 'Hubungi Kami' }
    ]

    useEffect(() => {
        const fetchContactUs = () => {
            const offset = (page - 1) * limit
            getContactUsList({
                limit,
                offset,
                options: { ...options, orderBy }
            })
        }

        fetchContactUs()

        if (!isActionLoading && isActionSuccess) {
            message.success('data sukses terkirim')
        }

        return () => {
            dispatch(clearContactUs())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearContactUs, dispatch, limit, options, orderBy, page])

    const handleClear = () => {
        setOptions({})
        setOrderBy(defaultOrderBy)
        setLimit(DEFAULT_PAGINATION_CONFIG.defaultPageSize)
        setPage(DEFAULT_PAGINATION_CONFIG.defaultCurrent)
    }

    const handleChangePagination = (page, limit) => {
        setLimit(limit)
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        const newOrderBy = isEmpty(sorter)
            ? defaultOrderBy
            : { by: sorter.field, order: sorter.order }
        setOrderBy(newOrderBy)
    }

    const handleSearch = (newOptions) => setOptions(newOptions)

    const renderActions = (text, record) => (
        <TableRowActions options={config.rowActions(record.id)} />
    )

    return (
        <Space direction='vertical'>
            <Breadcrumb items={breadcrumbItems} />
            <TableHeader
                filterConfig={filterConfig}
                onChangePagination={handleChangePagination}
                onClear={handleClear}
                onSearch={handleSearch}
                totalItems={totalContactUs}
            />
            <Table
                columns={config.columns(renderActions)}
                dataSource={config.dataSource(contactUsList)}
                loading={isLoading}
                onChange={handleChangeTable}
            />
        </Space>
    )
}

List.propTypes = {
    clearContactUs: PropTypes.func,
    contactUsList: PropTypes.array,
    dispatch: PropTypes.func,
    getContactUsList: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    navigate: PropTypes.func,
    totalContactUs: PropTypes.number
}

export default List
