import { default as AntdModal } from 'antd/lib/modal'
import styled from 'styled-components'

const StyledModal = styled(AntdModal)`
    &&& {
        .ant-modal-body {
            padding: 40px 30px 10px 30px;
        }
    }
`

export default StyledModal
