import indexOf from 'lodash/indexOf'
import omit from 'lodash/omit'
import split from 'lodash/split'
import trimStart from 'lodash/trimStart'
import superagent from 'superagent'
import getPersistentData from 'utility/getPersistentData'
import * as notification from 'utility/notification'

const defaultTime = 30 * 1000
const defaultTimeout = (time) => ({
    deadline: time, // but allow 30 seconds for the file to finish loading.
    response: time // Wait 5 seconds for the server to start sending,
})

const handleError = (err) => {
    if (err.timeout) {
        /* timed out! */
        notification.error('Oh tidak, batas waktu habis')
    }
}

const getAgent = (url, type = 'get', time = defaultTime) => {
    // api
    const URL_API =
        process.env.NODE_ENV === 'production'
            ? 'https://api.moneypool.co.id/'
            : 'http://localhost:9000/'
    const newUrl = URL_API + trimStart(url, '/')

    return superagent[type](newUrl)
        .withCredentials()
        .accept('application/json')
        .type('application/json')
        .set({
            Authorization: `Bearer ${getPersistentData('authToken')}`
        })
        .timeout(defaultTimeout(time))
        .on('error', handleError)
}

export const post = (uri, data = {}) => getAgent(uri, 'post').send(data)

export const get = (uri, qs = {}) => {
    if (!qs.headless) {
        return getAgent(uri, 'get').query(qs)
    }
    const forceTakeFromCookie = true
    return getAgent(uri, 'get')
        .set({
            Authorization: `Bearer ${getPersistentData(
                'authToken',
                forceTakeFromCookie
            )}`
        })
        .query(omit(qs, 'headless'))
}

export const put = (uri, data = {}) => getAgent(uri, 'put').send(data)

export const patch = (uri, data = {}) => getAgent(uri, 'patch').send(data)

export const del = (uri, data = {}) => getAgent(uri, 'del').send(data)

export const upload = (uri, onProgress, onSuccess, data = {}) =>
    getAgent(uri, 'post')
        .on('progress', defaultProgressFunc(onProgress))
        .on('success', onSuccess())
        .send(data)

export const defaultProgressFunc = (onProgress) => (progressEvent) => {
    const { percent } = progressEvent
    onProgress({ percent })
}

// @see: https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata/5100158
export const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString
    if (indexOf(split(dataURI, ',')[0], 'base64') >= 0) {
        byteString = atob(split(dataURI, ',')[1])
    } else {
        byteString = unescape(split(dataURI, ',')[1])
    }

    // separate out the mime component
    let mimeString = split(split(split(dataURI, ',')[0], ':')[1], ';')[0]

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
}
