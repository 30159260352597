// https://abouts.co/ - color name
module.exports = {
    black: '#000000',
    blueberry: '#726EFF', // selesai - done
    graniteGray: '#656565', // warna text untuk dashboard page
    gray: '#8F8F8F',
    laserLemon: '#FBFF65', // tidak aktif - inactive
    persianIndigo: '#2A1467',
    purpleHeart: '#4C19D9', // full
    robbinEggBlue: '#29E8E5',
    rose: '#FF4D4F',
    salmon: '#FF7E64', // gagal - failed
    silver: '#C4C4C4',
    springGreen: '#09E67E' // aktif - active
}
