import { message as AntdMessage } from 'antd'
import noop from 'lodash/noop'

const MESSAGE_DURATION = 3

const isShowingMessage = {}

const triggerMessage = (
    type,
    content = '[ no content ]',
    duration = MESSAGE_DURATION,
    onClose = noop
) => AntdMessage[type](content, duration, onClose)

const error = (content) => {
    if (isShowingMessage[content]) return
    isShowingMessage[content] = true
    triggerMessage('error', content, MESSAGE_DURATION, () => {
        isShowingMessage[content] = false
    })
}
const info = (content) => triggerMessage('info', content)
const loading = (content) => triggerMessage('loading', content)
const success = (content) => triggerMessage('success', content)
const warn = (content) => triggerMessage('warn', content)
const warning = (content) => triggerMessage('warning', content)

export { error, info, loading, success, warn, warning }
