import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

const statuses = ['unverified', 'in process', 'verified']
const statusesInd = ['belum terverifikasi', 'dalam proses', 'terverifikasi']
const statusOptions = sortBy(
    map(statuses, (item, key) => ({
        label: startCase(statusesInd[key]),
        value: item
    })),
    ['label']
)

export { statusOptions }
