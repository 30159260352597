import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import * as authenticationAPI from './authenticationAPI'

const initialState = {
    authToken: '',
    email: '',
    id: null,
    isLoading: false,
    isTokenExpired: false,
    isWriteAllowed: false,
    roles: [],
    username: ''
}

export const { authenticate, authenticationCheck } = authenticationAPI

const authenticationSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(authenticationCheck.pending, (state) =>
            Object.assign(state, {
                isLoading: true,
                isTokenExpired: false
            })
        )
        builder.addCase(authenticationCheck.fulfilled, (state) =>
            Object.assign(state, {
                isLoading: false,
                isTokenExpired: false
            })
        )
        builder.addCase(authenticationCheck.rejected, (state) =>
            Object.assign(state, {
                isLoading: false,
                isTokenExpired: true
            })
        )
        builder.addCase(authenticate.pending, (state) =>
            Object.assign(state, {
                isLoading: true,
                isTokenExpired: false
            })
        )
        builder.addCase(authenticate.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            const { token, userDetail } = data
            return Object.assign(state, {
                authToken: token,
                email: userDetail.email,
                id: userDetail.id,
                isLoading: false,
                roles: map(userDetail.roles, (item) => item.value),
                username: userDetail.username
            })
        })
        builder.addCase(authenticate.rejected, (state) =>
            Object.assign(state, {
                isLoading: false
            })
        )
    },
    initialState,
    name: 'AUTHENTICATION',
    reducers: {
        logout: () => initialState,
        tokenExpired: (state) => Object.assign(state, { isTokenExpired: true }),
        updateWriteAccess: (state, action) =>
            Object.assign(state, { isWriteAllowed: action.payload })
    }
})

export const { finishforgotPassword, logout, tokenExpired, updateWriteAccess } =
    authenticationSlice.actions

const { reducer } = authenticationSlice
export default reducer
