import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import omit from 'lodash/omit'
import qs from 'qs'

/**
 * Replace the url with given querys
 * @param {object} location - An object from react-router-dom, use withRouter to get the object.
 * @param {object} navigate - An object from react-router-dom, use withRouter to get the object.
 * @param {object} querys - The querys you want to append to url.
 * @param {array} omitKeys - These keys will be removed before adding the querys.
 *
 * @example
 * // replace the url with /pathname?id=1&isActive=false
 * addQueryString(location, navigate, {id: 1, q: 'hello', isActive: false}, ['q'])
 */
export function addQueryString(navigate, querys, omitKeys) {
    const location = window.location

    if (!location || !location.pathname) return

    let url = location.pathname
    const search = location.search.slice(1)
    const originalQuerys = qs.parse(search)
    const newQuerys = omit(originalQuerys, omitKeys)
    const queryString = qs.stringify(
        {
            ...newQuerys,
            ...querys
        },
        { arrayFormat: 'repeat' }
    )
    if (queryString) {
        url += '?' + queryString
        navigate(url, { replace: true })
    } else {
        navigate(url, { replace: true })
    }
}

export function parseQueryString() {
    const location = window.location

    if (!location) return {}
    let search = get(location, 'search', '')
    if (indexOf(search, '?') !== 0) return {}
    return qs.parse(search.substr(1))
}

export function getBackUrl(baseUrl, backLink, backText) {
    return `${baseUrl}?backLink=${encodeURIComponent(
        backLink
    )}&backText=${encodeURIComponent(backText)}`
}
