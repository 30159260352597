import { DATETIME_SLASH_FULL_FORMAT } from 'constants/formats'
import startCase from 'lodash/startCase'
import moment from 'moment'

export const toApi = (data) => ({
    amount: data.amount,
    description: data.description,
    id: data.id,
    purchase_order_id: data.purchaseOrderId,
    remarks: data.remarks
})

export const toState = (data) => {
    let createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATETIME_SLASH_FULL_FORMAT)
        : null

    if (data.createdBy === 'xendit') {
        createdAt = moment(data.createdAt).isValid()
            ? moment(data.createdAt)
                  .add(24, 'h')
                  .format(DATETIME_SLASH_FULL_FORMAT)
            : null
    }

    return {
        amount: data.amount || 0,
        createdAt,
        createdBy: data.createdBy || '',
        description: data.description ? startCase(data.description) : '-',
        id: data.id || null,
        purchaseOrderId: data.purchaseOrderId || null,
        remarks: data.remarks ? data.remarks : '-'
    }
}
