import * as productTypeSlice from 'features/product_type/productTypeSlice'
import * as purchaseOrderSlice from 'features/purchase_order/purchaseOrderSlice'
import React from 'react'
import { connect } from 'react-redux'
import List from './List'

const ListContainer = (props) => {
    return <List {...props} />
}

const mapStateToProps = (state) => ({
    error: state.purchaseOrder.error,
    exportData: state.purchaseOrder.exportData,
    isActionLoading: state.purchaseOrder.isActionLoading,
    isActionSuccess: state.purchaseOrder.isActionSuccess,
    isLoading: state.purchaseOrder.isLoading,
    isLoadingExport: state.purchaseOrder.isLoadingExport,
    productTypes: state.productType.productTypes,
    purchaseOrders: state.purchaseOrder.purchaseOrders,
    totalPurchaseOrders: state.purchaseOrder.totalPurchaseOrders
})

const mapDispatchToProps = {
    clearProductType: productTypeSlice.clearProductType,
    clearPurchaseOrder: purchaseOrderSlice.clearPurchaseOrder,
    exportPurchaseOrders: purchaseOrderSlice.exportPurchaseOrders,
    getProductTypes: productTypeSlice.getProductTypes,
    getPurchaseOrders: purchaseOrderSlice.getPurchaseOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
