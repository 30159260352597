import EyeIcon from 'components/eye_icon/EyeIcon'
import get from 'lodash/get'
import map from 'lodash/map'
import React from 'react'
import formatPrice from 'utility/formatPrice'
import getRenderStatusPurchaseOrder from 'utility/getRenderStatusPurchaseOrder'

const rowActions = (id) => [
    {
        id,
        type: 'view'
    }
]

const columns = (renderActions) => [
    {
        dataIndex: 'noDoc',
        sorter: true,
        title: 'Nomor',
        width: 100
    },
    {
        dataIndex: 'pemodalName',
        title: 'Pemodal',
        width: 250
    },
    {
        dataIndex: 'product',
        render: (object) => get(object, 'type', ''),
        title: 'Tipe',
        width: 50
    },
    {
        dataIndex: 'product',
        render: (object) => {
            const productId = get(object, 'id', null)
            const productName = get(object, 'name', '')
            return (
                <React.Fragment>
                    <EyeIcon route={`/product/view/${productId}`} />{' '}
                    {productName}
                </React.Fragment>
            )
        },
        title: 'Produk',
        width: 300
    },
    {
        dataIndex: 'quantity',
        title: 'Jumlah Lembar',
        width: 150
    },
    {
        dataIndex: 'adminFee',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Biaya Admin',
        width: 100
    },
    {
        dataIndex: 'transactionFee',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Biaya Transaksi',
        width: 150
    },
    {
        dataIndex: 'platformFee',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Biaya Platform',
        width: 150
    },
    {
        dataIndex: 'totalPayment',
        render: (number) => `Rp ${formatPrice(number)}`,
        sorter: true,
        title: 'Total Pembayaran',
        width: 150
    },
    {
        dataIndex: 'createdAt',
        sorter: true,
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'updatedAt',
        sorter: true,
        title: 'Diperbarui Pada',
        width: 150
    },
    {
        render: (object) => getRenderStatusPurchaseOrder(object),
        title: 'Status',
        width: 150
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        width: 100
    }
]

const dataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

const expandedColumns = [
    {
        dataIndex: 'createdAt',
        title: 'Dibuat Pada',
        width: 150
    },
    {
        dataIndex: 'createdBy',
        title: 'Dibuat Oleh',
        width: 150
    },
    {
        dataIndex: 'description',
        title: 'Deskripsi',
        width: 150
    },
    {
        dataIndex: 'remarks',
        title: 'Catatan',
        width: 150
    },
    {
        dataIndex: 'amount',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Nominal',
        width: 150
    }
]

const expandedDataSource = (data) =>
    map(data, (item, key) => ({
        ...item,
        key: String(key)
    }))

export { columns, dataSource, rowActions, expandedColumns, expandedDataSource }
