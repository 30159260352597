import { statusOptions } from 'common/options/purchaseOrder'
import mapSelectOptions from 'utility/mapSelectOptions'

const filterConfig = (productTypes) => [
    {
        name: 'noDoc',
        placeholder: 'Cari Nomor',
        query: 'noDoc',
        type: 'input'
    },
    {
        name: 'pemodalName',
        placeholder: 'Cari Nama Pemodal',
        query: 'pemodalName',
        type: 'input'
    },
    {
        name: 'productCode',
        placeholder: 'Cari Kode Produk',
        query: 'productCode',
        type: 'input'
    },
    {
        name: 'status',
        options: statusOptions,
        placeholder: 'Cari Status',
        query: 'status',
        type: 'select'
    },
    {
        name: 'productTypeId',
        options: mapSelectOptions(productTypes, 'title'),
        placeholder: 'Cari Tipe Produk',
        query: 'productTypeId',
        type: 'select'
    }
]

export default filterConfig
