import { default as AntdPagination } from 'antd/lib/pagination'
import colors from 'basic/colors'
import styled from 'styled-components'

export const StyledAntdPagination = styled(AntdPagination)`
    .ant-pagination-item-active a {
        color: ${colors.blueberry};
    }
    .ant-pagination-item-active {
        border: none;
    }
    .ant-select {
        .ant-select-selector {
            color: ${colors.blueberry};
        }
    }
`
