import { default as AntdLayout } from 'antd/lib/layout'
import isMobile from 'is-mobile'
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "AR Techni";
        src: url('/fonts/AR Techni.ttf')
    }
`

const AntdLayoutContent = AntdLayout.Content
export const StyledAntdLayoutContent = styled(AntdLayoutContent)`
    &&& {
        height: 100%;
        ${(props) => {
            if (props.$isLoginPage) {
                return ''
            }
            if (isMobile()) {
                return 'margin: 50px 15px 15px 15px;'
            }
            return 'margin: 50px;'
        }}
    }
`
